/* eslint-disable jsx-a11y/alt-text */
import React from 'react'

const ChampionData = () => {
  return (
    <>
      <div className=" p-4">
        <div className="max-w-[1240px] mx-auto lg:flex justify-between items-center py-[50px] ">
          {/* 1 div */}
          <div className="m-2 flex justify-center">
            <img
              src="https://static.vecteezy.com/system/resources/previews/010/719/387/original/data-analyst-illustration-concept-on-white-background-vector.jpg"
              //   src={banner1}
              className="md:max-w-xl "
            />
          </div>
          {/* 2 div */}
          <div className="m-2 max-w-xl max-md:text-justify ">
            <h1 className="text-[20px] md:text-[35px] font-bold text-black">
              Champion a data-driven HR culture.
            </h1>
            <p className="text-gray mt-5 ">
              We rank your applicants according to test scores certified by our
              experts. This means your HR team can rely on good data to make
              important decisions - and not just their gut feeling.
            </p>
            <p className="text-gray mt-6">
              With Hire Exam, you'll lead the shift to a more professional and
              data-focused HR culture in your company
            </p>

            <button className="btn mt-6 btn-outline btn-sm text-black hover:bg-primary">
              Learn more
            </button>
          </div>
        </div>
      </div>
    </>
  );
}

export default ChampionData