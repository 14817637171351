import React, { useState } from "react";
import { BiSolidEdit } from "react-icons/bi";
import { RxCross2 } from "react-icons/rx";
import { useNavigate } from "react-router-dom";
import { logError, logInfo } from "../../../Elasticsearchinstance";
import useAxiosPublic from "../../../Hooks/useAxiosPublic";

const TestModal = ({
  setEditModal,
  editModal,
  id,
  name,
  email,
  address,
  phone_number,
  job_profile,
  tech_skills,
  group_tag,
  status,
  enrollment_number,
  experience,
  salary_expectation,
  education,
}) => {
  console.log(id);
  console.log(name);

  const axiousPublic = useAxiosPublic();

  // dynamic data ke lie
  const [data, setData] = useState({
    freelancerID: id,
    name: name,
    email: email,
    address: address,
    phone_number: phone_number,
    job_profile: job_profile,
    tech_skills: tech_skills,
    group_tag: group_tag,
    status: status,
    enrollment_number: enrollment_number,
    experience: experience,
    salary_expectation: salary_expectation,
    education: education,
  });
  const inputEvent = (event) => {
    const { name, value } = event.target;
    setData((preVal) => {
      return {
        ...preVal,
        [name]: value,
      };
    });
  };
  const navigate = useNavigate();
  const formSubmit = async (e) => {
    // alert("hello");
    e.preventDefault();
    const candi_data = {
      freelancerID: data.freelancerID || id,
      name: data.name || name,
      email: data.email,
      address: data.address,
      phone_number: data.phone_number,
      job_profile: data.job_profile,
      tech_skills: data.tech_skills,
      group_tag: data.group_tag,
      status: data.status,
      enrollment_number: data.enrollment_number,
      experience: data.experience,
      salary_expectation: data.salary_expectation,
      education: data.education,
    };

    // Log the data before submission
    logInfo("Attempting to update candidate data", candi_data);

    try {
      const res = await axiousPublic.put(`/updateCandidateUsers`, candi_data);
      console.log(res);
      // Log the response data
      logInfo("Candidate data updated", res.data);
      alert("Candidate data Updated!");
      // navigate("/candidate");
      window.location.reload();
    } catch (error) {
      console.error("Error submitting candidate data:", error);
      // Log the error
      logError("Error submitting candidate data", error);
    }
  };

  return (
    <>
      {/* Put this part before </body> tag */}
      <input type="checkbox" id="my_modal_9" className="modal-toggle" />
      <div className="modal" role="dialog">
        <div className="modal-box w-11/12 max-w-4xl">
          <h3 className="font-bold text-lg">Edit Candidate!</h3>
          {/* <p className="py-4">I am Freelancer My ID</p> */}
          {/* <b>{id}</b> */}

          <form onSubmit={formSubmit}>
            <label className=" join mt-7 flex ml-24">
              <input
                className="input input-bordered join-item text-black input-sm"
                placeholder="Freelancer ID"
                required
                value={id}
              />
              <button className="btn btn-sm join-item">Search</button>
            </label>
            <div className="form-control flex flex-row justify-evenly">
              <div>
                <label className="mt-6 flex gap-5 justify-end ">
                  <span className="label-text font-bold text-sm">Name</span>
                  <input
                    type="text"
                    name="name"
                    required
                    placeholder="Enter Your Name"
                    className="input input-bordered text-black input-sm"
                    value={data.name || name}
                    // value={data.name}
                    onChange={inputEvent}
                  />
                </label>

                <label className="mt-6 flex gap-5 justify-end">
                  <span className="label-text font-bold text-sm">Email</span>
                  <input
                    type="email"
                    name="email"
                    placeholder="Enter Your Email"
                    className="input input-bordered text-black input-sm"
                    value={data.email || email}
                    onChange={inputEvent}
                  />
                </label>
                <label className="mt-6 flex gap-5 justify-end">
                  <span className="label-text font-bold text-sm">Address</span>
                  <input
                    type="text"
                    name="address"
                    placeholder="Enter Your Address"
                    className="input input-bordered text-black input-sm"
                    value={data.address || address}
                    onChange={inputEvent}
                  />
                </label>

                <label className="mt-6 flex gap-5 justify-end">
                  <span className="label-text font-bold text-sm">
                    Mobile No
                  </span>
                  <input
                    type="number"
                    name="phone_number"
                    placeholder="Enter Your Mobile No."
                    className="input input-bordered text-black input-sm"
                    value={data.phone_number || phone_number}
                    onChange={inputEvent}
                  />
                </label>

                <label className="mt-6 flex gap-5 justify-end">
                  <span className="label-text font-bold text-sm">
                    Job Profile
                  </span>
                  <input
                    type="text"
                    name="job_profile"
                    placeholder="Enter Your Job Profile"
                    className="input input-bordered text-black input-sm"
                    value={data.job_profile || job_profile}
                    onChange={inputEvent}
                  />
                </label>
                <label className="mt-6 flex gap-5 justify-end">
                  <span className="label-text font-bold text-sm">
                    Tech Skills
                  </span>
                  <input
                    type="text"
                    name="tech_skills"
                    placeholder="Enter Your Skills"
                    className="input input-bordered text-black input-sm"
                    value={data.tech_skills || tech_skills}
                    onChange={inputEvent}
                  />
                </label>
              </div>

              <div>
                <label className="mt-6 flex gap-5 justify-end">
                  <span className="label-text font-bold text-sm">Group</span>
                  <select
                    name="group_tag"
                    value={data.group_tag || group_tag}
                    onChange={inputEvent}
                    className="select select-bordered text-black select-sm w-[198px] max-w-xs"
                  >
                    <option>Select Course</option>
                    <option value="Java Developer">Java Developer</option>
                    <option value="Node Js">Node Js</option>
                    <option value="React Js">React Js</option>
                    <option value="Angular Js">Angular Js</option>
                    <option value="Vue Js">Vue Js</option>
                    <option value="Php">Php</option>
                  </select>
                </label>
                <label className="mt-6 flex gap-5 justify-end">
                  <span className="label-text font-bold text-sm">Status</span>

                  <select
                    name="status"
                    value={data.status || status}
                    onChange={inputEvent}
                    className="select select-bordered text-black select-sm w-[198px] max-w-xs"
                  >
                    <option value="">Select Status</option>
                    <option value="Active">Active</option>
                    <option value="Pending">Pending</option>
                    <option value="Suspend">Suspend</option>
                  </select>
                </label>

                <label className="mt-6 flex gap-5 justify-end">
                  <span className="label-text font-bold text-sm">
                    Enrollment no
                  </span>
                  <input
                    type="text"
                    name="enrollment_number"
                    placeholder=""
                    className="input input-bordered text-black input-sm"
                    value={data.enrollment_number || enrollment_number}
                    onChange={inputEvent}
                  />
                </label>

                <label className="mt-6 flex gap-5 justify-end">
                  <span className="label-text font-bold text-sm">
                    Experience
                  </span>
                  {/* <input
                    type="text"
                    name="experience"
                    placeholder="Enter Your Experience"
                    className="input input-bordered text-black input-sm"
                    value={data.experience || experience}
                    onChange={inputEvent}
                  /> */}
                  <select
                    name="experience"
                    value={data.experience || experience}
                    onChange={inputEvent}
                    className="select select-bordered text-black select-sm w-[198px] max-w-xs"
                  >
                    <option>Select Experience</option>
                    <option value="0-1 yrs">0-1 yrs</option>
                    <option value="1-2 yrs">1-2 yrs</option>
                    <option value="1-3 yrs">1-3 yrs</option>
                    <option value="0-4 yrs">0-4 yrs</option>
                    <option value="1-4 yrs">1-4 yrs</option>
                  </select>
                </label>
                <label className="mt-6 flex gap-5 justify-end">
                  <span className="label-text font-bold text-sm">
                    Salary Expectation
                  </span>
                  <input
                    type="text"
                    name="salary_expectation"
                    placeholder="Enter Your Salary Expectation"
                    className="input input-bordered text-black input-sm"
                    value={data.salary_expectation || salary_expectation}
                    onChange={inputEvent}
                  />
                </label>
                <label className="mt-6 flex gap-5 justify-end">
                  <span className="label-text font-bold text-sm">
                    Education
                  </span>
                  <input
                    type="text"
                    name="education"
                    placeholder="Enter Your Education"
                    className="input input-bordered text-black input-sm"
                    value={data.education || education}
                    onChange={inputEvent}
                  />
                </label>
              </div>
              {/* ))} */}
            </div>

            <div className="flex ml-16 mt-9 gap-3 ">
              <button
                type="submit"
                className="btn btn-success font-bold text-white"
              >
                <span>
                  <BiSolidEdit className="text-lg" />
                </span>
                Update
              </button>

              <form method="dialog">
                <button
                  onClick={() => setEditModal(!editModal)}
                  htmlFor="my_modal_9"
                  className="btn btn-error font-bold text-white"
                >
                  <span>
                    <RxCross2 className="text-lg" />
                  </span>
                  Cancel
                </button>
              </form>
            </div>
          </form>
          {/* <div className="modal-action">
            <label
              onClick={() => setEditModal(!editModal)}
              htmlFor="my_modal_9"
              className="btn"
            >
              Close!
            </label>
          </div> */}
        </div>
      </div>
    </>
  );
};

export default TestModal;
