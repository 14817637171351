import React, { useState } from "react";
import { RxCross2 } from "react-icons/rx";
import { logError, logInfo } from "../../../Elasticsearchinstance";
import useAxiosPublic from "../../../Hooks/useAxiosPublic";


const AddNewCandidate = () => {
  const axiousPublic = useAxiosPublic();
  // dynamic data ke lie
  const [data, setData] = useState({
    freelancerID: "",
    name: "",
    email: "",
    address: "",
    phone_number: "",
    job_profile: "",
    tech_skills: "",
    group_tag: "",
    status: "",
    enrollment_number: "",
    experience: "",
    salary_expectation: "",
    education: "",
  });

  // dynamic data ke lie
  const inputEvent = (event) => {
    const { name, value } = event.target;
    setData((preVal) => {
      return {
        ...preVal,
        [name]: value,
      };
    });
  };

  const formSubmit = async (e) => {
    e.preventDefault();
    const candi_data = {
      freelancerID: data.freelancerID,
      name: data.name,
      email: data.email,
      address: data.address,
      phone_number: data.phone_number,
      job_profile: data.job_profile,
      tech_skills: data.tech_skills,
      group_tag: data.group_tag,
      status: data.status,
      enrollment_number: data.enrollment_number,
      experience: data.experience,
      salary_expectation: data.salary_expectation,
      education: data.education,
    };
    // Log candidate data
    logInfo("Candidate data submitted", candi_data);

    try {
      const res = await axiousPublic.post(`/candidatepost`, candi_data);
      // const res = await axios.post(candidatedetailsURL, candi_data);
      // Log response
      logInfo("Candidate data submission response", res.data);
      console.log(res);
      alert("Candidate data inserted");
      // Refresh the page after form submission
      window.location.reload();
    } catch (error) {
      // Log error
      logError("Error submitting candidate data", error);
      console.error("Error submitting candidate data:", error);
      // Handle error appropriately, e.g., show an error message to the user
    }
  };

  return (
    <>
      <button onClick={() => document.getElementById("my_modal_4").showModal()}>
        Add New Candidate
      </button>
      <form onSubmit={formSubmit}>
        <dialog id="my_modal_4" className="modal">
          <div className="modal-box w-11/12 max-w-4xl">
            <h3 className="font-bold text-black text-2xl">Add Candidate!</h3>

            <div className="form-control flex flex-row justify-evenly">
              {/* 1st div */}
              <div>
                <label className="mt-7 flex gap-5 justify-end">
                  <span className="label-text font-bold text-sm ">
                    Freelancer ID
                  </span>
                  <input
                    type="text"
                    name="freelancerID"
                    placeholder=""
                    className="input input-bordered text-black input-sm"
                    required
                    value={data.freelancerID}
                    onChange={inputEvent}
                  />
                </label>

                <label className="mt-6 flex gap-5 justify-end ">
                  <span className="label-text font-bold text-sm">Name</span>
                  <input
                    type="text"
                    name="name"
                    required
                    placeholder="Enter Your Name"
                    className="input input-bordered text-black input-sm"
                    value={data.name}
                    onChange={inputEvent}
                  />
                </label>

                <label className="mt-6 flex gap-5 justify-end">
                  <span className="label-text font-bold text-sm">Email</span>
                  <input
                    type="email"
                    name="email"
                    placeholder="Enter Your Email"
                    className="input input-bordered text-black input-sm"
                    value={data.email}
                    onChange={inputEvent}
                  />
                </label>
                <label className="mt-6 flex gap-5 justify-end">
                  <span className="label-text font-bold text-sm">Address</span>
                  <input
                    type="text"
                    name="address"
                    placeholder="Enter Your Address"
                    className="input input-bordered text-black input-sm"
                    value={data.address}
                    onChange={inputEvent}
                  />
                </label>

                <label className="mt-6 flex gap-5 justify-end">
                  <span className="label-text font-bold text-sm">
                    Mobile No
                  </span>
                  <input
                    type="number"
                    name="phone_number"
                    placeholder="Enter Your Mobile No."
                    className="input input-bordered text-black input-sm"
                    value={data.phone_number}
                    onChange={inputEvent}
                  />
                </label>

                <label className="mt-6 flex gap-5 justify-end">
                  <span className="label-text font-bold text-sm">
                    Job Profile
                  </span>
                  <input
                    type="text"
                    name="job_profile"
                    placeholder="Enter Your Job Profile"
                    className="input input-bordered text-black input-sm"
                    value={data.job_profile}
                    onChange={inputEvent}
                  />
                </label>
                <label className="mt-6 flex gap-5 justify-end">
                  <span className="label-text font-bold text-sm">
                    Tech Skills
                  </span>
                  <input
                    type="text"
                    name="tech_skills"
                    placeholder="Enter Your Skills"
                    className="input input-bordered text-black input-sm"
                    value={data.tech_skills}
                    onChange={inputEvent}
                  />
                </label>
              </div>
              {/* 2nd div */}
              <div>
                <label className="mt-6 flex gap-5 justify-end">
                  <span className="label-text font-bold text-sm">Group</span>
                  <select
                    name="group_tag"
                    value={data.group_tag}
                    onChange={inputEvent}
                    className="select select-bordered text-black select-sm w-[198px] max-w-xs"
                  >
                    <option>Select Course</option>
                    <option value="Java Developer">Java Developer</option>
                    <option value="Node Js">Node Js</option>
                    <option value="React Js">React Js</option>
                    <option value="Angular Js">Angular Js</option>
                    <option value="Vue Js">Vue Js</option>
                    <option value="Php">Php</option>
                  </select>
                </label>
                <label className="mt-6 flex gap-5 justify-end">
                  <span className="label-text font-bold text-sm">Status</span>
                  <select
                    name="status"
                    value={data.status}
                    onChange={inputEvent}
                    className="select select-bordered text-black select-sm w-[198px] max-w-xs"
                  >
                    <option value="">Select Status</option>
                    <option value="Active">Active</option>
                    <option value="Pending">Pending</option>
                    <option value="Suspend">Suspend</option>
                  </select>
                </label>

                <label className="mt-6 flex gap-5 justify-end">
                  <span className="label-text font-bold text-sm">
                    Enrollment no
                  </span>
                  <input
                    type="text"
                    name="enrollment_number"
                    placeholder=""
                    className="input input-bordered text-black input-sm"
                    value={data.enrollment_number}
                    onChange={inputEvent}
                  />
                </label>

                <label className="mt-6 flex gap-5 justify-end">
                  <span className="label-text font-bold text-sm">
                    Experience
                  </span>
                  <select
                    name="experience"
                    value={data.experience}
                    onChange={inputEvent}
                    className="select select-bordered text-black select-sm w-[198px] max-w-xs"
                  >
                    <option>Select Experience</option>
                    <option value="0-1 yrs">0-1 yrs</option>
                    <option value="1-2 yrs">1-2 yrs</option>
                    <option value="1-3 yrs">1-3 yrs</option>
                    <option value="0-4 yrs">0-4 yrs</option>
                    <option value="1-4 yrs">1-4 yrs</option>
                  </select>
                </label>

                <label className="mt-6 flex gap-5 justify-end">
                  <span className="label-text font-bold text-sm">
                    Salary Expectation
                  </span>
                  <input
                    type="text"
                    name="salary_expectation"
                    placeholder="Enter Your Salary Expectation"
                    className="input input-bordered text-black input-sm"
                    value={data.salary_expectation}
                    onChange={inputEvent}
                  />
                </label>
                <label className="mt-6 flex gap-5 justify-end">
                  <span className="label-text font-bold text-sm">
                    Education
                  </span>
                  <input
                    type="text"
                    name="education"
                    placeholder="Enter Your Education"
                    className="input input-bordered text-black input-sm"
                    value={data.education}
                    onChange={inputEvent}
                  />
                </label>
              </div>
            </div>
            <div className="flex ml-16 mt-9 gap-3 ">
              <form method="dialog">
                <button className="btn btn-error font-bold text-white">
                  <span>
                    <RxCross2 className="text-lg" />
                  </span>
                  Cancel
                </button>
              </form>

              <button
                type="submit"
                className="btn bg-primary hover:bg-primary font-bold text-white"
              >
                Submit
              </button>
            </div>

            <div className="modal-action">
              <form method="dialog">
                <button className="btn btn-sm btn-circle absolute right-2 top-2">
                  ✕
                </button>
              </form>
            </div>
          </div>
        </dialog>
      </form>
    </>
  );
};

export default AddNewCandidate;
