import { useState } from "react";
import { useLocation } from "react-router-dom";

const CheckBoxesQustionPaper = ({ element, currentQuestion, handleRadioChange, setAnswerData }) => {

    const location = useLocation();
    const searchParams = new URLSearchParams(location.search);
    const exam_email = searchParams.get("exam_email");
    const freelancerID = searchParams.get("freelancerID");
    const exam_course = searchParams.get("exam_course");
    const [data, setData] = useState({})

    const handelObjective = async (e) => {
        setData({ ...data, [e.target.name]: e.target.value });
        const answerData = {
            questions: currentQuestion.Questions,
            submit_answer: JSON.stringify(data) || currentQuestion.selectedOption || "", // Assuming selectedOption is the answer
            email: exam_email,
            freelancerID: freelancerID,
            exam_course: exam_course,
            empid: currentQuestion.queid
        };
        console.log(answerData.submit_answer);
        setAnswerData(answerData);

    }

    return (
        <div>
            <form onChange={handelObjective}>

                <div className="flex items-center gap-3 ">
                    <input className="checkbox checkbox-primary  " type="checkbox" name="A" id="chackbox1"
                        checked={currentQuestion?.selectedOption?.A !== undefined ? currentQuestion?.selectedOption?.A : ''}
                        onChange={(event) => {
                            handleRadioChange(data);
                            // inputEvent(event);
                        }}
                        value={element.OptionA}
                    />
                    <label htmlFor="chackbox1">
                        <div className="label cursor-pointer">
                            <span className="">{element.OptionA}</span>
                        </div>
                    </label>
                </div>
                <div className="flex items-center gap-3 ">
                    <input className="checkbox checkbox-primary" type="checkbox" name="B" id="chackbox2"
                        checked={currentQuestion?.selectedOption?.B !== undefined ? currentQuestion?.selectedOption?.B : ''}
                        onChange={(event) => {
                            handleRadioChange(data);
                            // inputEvent(event);
                        }}
                        value={element.OptionB}
                    />
                    <label htmlFor="chackbox2">
                        <div className="label cursor-pointer">
                            <span className="">{element.OptionB}</span>
                        </div>
                    </label>
                </div>
                <div className="flex items-center gap-3 ">
                    <input className="checkbox checkbox-primary" type="checkbox" name="C" id="chackbox3"
                        checked={currentQuestion?.selectedOption?.C !== undefined ? currentQuestion?.selectedOption?.C : ''}
                        onChange={(event) => {
                            handleRadioChange(data);
                            // inputEvent(event);
                        }}
                        value={element.OptionC}
                    />
                    <label htmlFor="chackbox3">
                        <div className="label cursor-pointer">
                            <span className="">{element.OptionC}</span>
                        </div>
                    </label>
                </div>
                <div className="flex items-center gap-3 ">
                    <input className="checkbox checkbox-primary" type="checkbox" name="D" id="chackbox4"
                        checked={currentQuestion?.selectedOption?.D !== undefined ? currentQuestion?.selectedOption?.D : ''}
                        onChange={(event) => {
                            handleRadioChange(data);
                            // inputEvent(event);
                        }}
                        value={element.OptionD}
                    />
                    <label htmlFor="chackbox4">
                        <div className="label cursor-pointer">
                            <span className="">{element.OptionD}</span>
                        </div>
                    </label>
                </div>
                <div className="flex items-center gap-3 ">
                    <input className="checkbox checkbox-primary" type="checkbox" name="E" id="chackbox5"
                        checked={currentQuestion?.selectedOption?.E !== undefined ? currentQuestion?.selectedOption?.E : ''}
                        onChange={(event) => {
                            handleRadioChange(data);
                            // inputEvent(event);
                        }}
                        value={element.OptionE}
                    />
                    <label htmlFor="chackbox5">
                        <div className="label cursor-pointer">
                            <span className="">{element.OptionE}</span>
                        </div>
                    </label>
                </div>
            </form>
        </div>
    );
};

export default CheckBoxesQustionPaper;