import React from "react";
import { policy } from "./policyObj";
import Privacy from "./Privacy";

const PrivacyPolicy = () => {
    return (
        <div className="container py-10 mx-auto">
            <h3 className=" font-bold pb-10 text-3xl capitalize">privacy policy</h3>
            <div className="container mx-auto">
                {policy.map((p, i) => (
                    <Privacy key={i} i={i} p={p} />
                ))}
            </div>
            <p className=" mr-5">
                <h2 className="text-semibold text-xl py-1">
                    Data Protection Officer/ Grievance Officer:
                </h2>
                Grievance Officer
                <br />
                Airepro (India) Limited
                <br />
                Noida
                <br />
                Email: privacy@infoedge.com
            </p>
        </div>
    );
};

export default PrivacyPolicy;
