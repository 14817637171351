
import React, { useState } from "react";
import { RxCross2 } from "react-icons/rx";
import { logError, logInfo } from "../../../Elasticsearchinstance";
import useAxiosPublic from "../../../Hooks/useAxiosPublic";


const AddNewInstructions = () => {
  const axiousPublic = useAxiosPublic();

  // dynamic data ke lie
  const [data, setData] = useState({
    title: "",
    content: "",
  });

  // dynamic data ke lie
  const inputEvent = (event) => {
    const { name, value } = event.target;
    setData((preVal) => {
      return {
        ...preVal,
        [name]: value,
      };
    });
  };

  const formSubmit = async (e) => {
    e.preventDefault();
    const instruction_data = {
      title: data.title,
      content: data.content,
    };
    try {
      const res = await axiousPublic.post(`/instructionAdd`, instruction_data);
      console.log(res);
      // Log the successful insertion
      logInfo('Instruction data inserted', instruction_data);
      
      alert("Instruction data inserted");
      // Refresh the page after form submission
      window.location.reload();
    } catch (error) {
      console.error("Error submitting candidate data:", error);
      // Handle error appropriately, e.g., show an error message to the user
      // Log the error
      logError('Error submitting instruction data', error);
    }
  };

  return (
    <>
      <button onClick={() => document.getElementById("my_modal_4").showModal()}>
        <p className="underline underline-offset-4 text-lg text-slate-700 hover:text-slate-500">
          <span className="font-bold text-2xl">+</span>Add a Instruction
        </p>
      </button>
      <form onSubmit={formSubmit}>
        <dialog id="my_modal_4" className="modal">
          <div className="modal-box w-11/12 max-w-2xl">
            <h3 className="font-bold text-black text-2xl">Add Instruction!</h3>

            <div className="form-control p-9 gap-5">
              {/* 1st div */}
              <div className="flex gap-12 items-center">
                <label htmlFor="" className="label-text font-bold text-lg">
                  Title
                </label>
                <input
                  type="text"
                  placeholder="Type here"
                  className="input input-bordered w-full input-sm max-w-xs"
                  name="title"
                  required
                  value={data.title}
                  onChange={inputEvent}
                />
              </div>
              <div className="flex gap-5 items-center">
                <label htmlFor="" className="label-text font-bold text-lg">
                  Content
                </label>
                <textarea
                  placeholder="Bio"
                  className="textarea textarea-bordered textarea-lg w-full max-w-xs"
                  name="content"
                  required
                  value={data.content}
                  onChange={inputEvent}
                ></textarea>
              </div>
            </div>
            <div className="flex ml-16 mt-9 gap-3 ">
              <button
                type="submit"
                className="btn btn-success font-bold text-white"
              >
                Add Instruction
              </button>

              <form method="dialog">
                <button className="btn btn-error font-bold text-white">
                  <span>
                    <RxCross2 className="text-lg" />
                  </span>
                  Cancel
                </button>
              </form>
            </div>

            <div className="modal-action">
              <form method="dialog">
                <button className="btn btn-sm btn-circle absolute right-2 top-2">
                  ✕
                </button>
              </form>
            </div>
          </div>
        </dialog>
      </form>
    </>
  );
};

export default AddNewInstructions;
