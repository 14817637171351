/* eslint-disable jsx-a11y/alt-text */
import React from 'react'
import banner1 from "../../Assets/img/cyber-security.png"

const Banner = () => {
  return (
    <>
      <div className="bg-[#f83edf1c] w-full">
         <div className="max-w-[1240px] py-[12px] h-auto items-center grid grid-cols-2 mx-auto max-md:grid-cols-1 ">
        
          <div className="col-span-1 text-left flex flex-col justify-between p-2 mt-9 m-4">
            <h1 className="text-4xl font-bold leading-[52px] font-poppins max-md:text-2xl max-md:text-center">
              Unleash the Power of Remote Exams with Secure Remote Proctoring
              Solution. <br /> Elevate Your Exam Process Today.
            </h1>
            <p className="py-6 px-1 justify-center max-md:text-center">
              Get Maximum Accuracy, Integrity and Convenience in Your Remote
              Exams.
            </p>
          </div>

          <div className="col-span-1 flex flex-col justify-center">
            <img src={banner1} className="w-[36rem]" />
          </div>
        </div>
      </div>
    </>
  );
}

export default Banner