import React, { useContext, useState } from "react";
import { AiOutlineEye, AiOutlineEyeInvisible } from "react-icons/ai";
import { Link, useNavigate } from "react-router-dom";
import toast, { Toaster } from "react-hot-toast";

import { logError, logInfo } from "../../Elasticsearchinstance";
import useAxiosPublic from "../../Hooks/useAxiosPublic";
import axiosInstance from "../../utils";


const Login = () => {
  const axiousPublic = useAxiosPublic();
  // const [showPassword, setShowPassword] = useState(true);
  const [checked, setChecked] = useState(false);
  const [showPassword, setShowPassword] = useState(true);
  const [error, setError] = useState(false);
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [recaptcha, setRecaptcha] = useState(false);
  const navigate = useNavigate();
  const [forgEmail, setForgEmail] = useState("");
  // const [recaptchaError, setRecaptchaError] = useState(false);
  // const [forggg, setFor] = useState("");
  const [btnDisable, setBtnDisable] = useState(true);

  const [succForgetCode, setSuccForgetCode] = useState(false);
  const [forError, setForErorr] = useState(false);
  const handleForgotPass = async () => {
    setForErorr("");
    setSuccForgetCode("");
    // console.log(forgEmail);
    setBtnDisable(false);
    if (!forgEmail || !/\S+@\S+\.\S+/.test(forgEmail)) {
      setBtnDisable(true);
      setForErorr("Please provide a valid email");
      return;
    }
    try {
      const response = await axiosInstance.get(
        `/auth/forgetPassword?email=${forgEmail}`
      );
      console.log("forget-pass-->", response);
      if (
        response?.data?.message == "Please check your email for verification code"
      ) {
        setSuccForgetCode("Please check your email for verification code");
      }
    } catch (error) {
      console.log(error?.response?.data?.error?.message);
      if (
        error?.response?.data?.error?.message == "User not found with the given email"
      ) {
        setForErorr("User not found with the given email");
      }
    }
  };

  const handleLogin = async (e) => {
    e.preventDefault();
    const form = e.target;
    const email = form.email.value;
    const password = form.password.value;
    const rules = form.rules.value;


    logInfo("Login form Submitted", { email, password, recaptcha, rules });
    // if (recaptcha) {
    //   setRecaptchaError(false);
    //   if (rules) {
    try {
      // console.log(email, password, recaptcha, rules);

      const response = await axiousPublic.post(`/api/v1/auth/login`, {
        // const response = await axios.post(loginURLS, {
        email,
        password,
      });
      console.log(response);
      const data = response.data;
      logInfo("login server Response", data);
      console.log(data, "new user signed up");

      // Save token to localStorage
      localStorage.setItem(
        "HireInfo",
        JSON.stringify({
          // Convert data to JSON string
          token: data?.data?.token,
          userId: data?.data?.user?.userId,
          email: data?.data?.user?.email,
          accountType: data?.data?.user?.UserAccountType
        })
      );
      // toast.success("Successfully LogedIn!");
      navigate("/dashboard");
    } catch (error) {
      console.log("error");
      console.log(error);
      console.error("Error occurred during login:", error.message);
      logError("Error occurred during login", error);

      if (
        error?.response?.status === 500 &&
        error?.response?.data?.message === "Your password is invalid"
      ) {
        // setError("Check MailId or Password!");
        setError("Incorrect Password!");
      }
      if (
        error?.response?.status === 500 &&
        error?.response?.data?.message ===
        "Cannot read properties of undefined (reading 'password')"
      ) {
        setError("No User Found With This Email");
      }
    }
  };

  // const handelVarifide = async (e) => {
  //   e.preventDefault();
  //   const form = e.target;
  //   const otp = form.otp.value;
  //   // forgEmail
  //   const data = { otp , forgEmail}

  //   const response = await axiosInstance.patch(
  //     `/auth/verifyCode` , data
  //   );

  //   console.log(response);

  // }

  return (
    <>
      <label
        htmlFor="my_modal_6"
        className="btn btn-sm btn-outline mt-3 border-primary hover:border-primary hover:bg-primary text-primary border-solid border-2 font-bold font-sans "
      >
        Log In
      </label>
      <Toaster position="top-center" reverseOrder={false} />

      {/* Put this part before </body> tag */}
      <input type="checkbox" id="my_modal_6" className="modal-toggle" />
      <div className="modal">
        <div className="modal-box">
          <h3 className=" text-lg">Login</h3>
          <div className="divider"></div>
          {/* <form onSubmit={handleSubmit}> */}
          <form onSubmit={handleLogin}>
            <div className="form-control w-full max-w-full mx-auto mb-4">
              <input
                type="email"
                name="email"
                required
                placeholder="Enter your Email"
                className="input input-bordered w-full max-w-full"
              // onChange={(e) => setEmail(e.target.value)}
              />
            </div>
            <div className="form-control w-full max-w-full mx-auto relative">
              <input
                type={showPassword ? "password" : "text"}
                name="password"
                placeholder="Enter Your Password"
                className="input input-bordered w-full max-w-full"
              // onChange={(e) => setPassword(e.target.value)}
              />
              <span className="label-text-alt absolute top-3 right-2">
                <label className="swap">
                  <input
                    type="checkbox"
                    onClick={() => setShowPassword(!showPassword)}
                  />

                  <AiOutlineEye className="text-xl swap-on" />

                  <AiOutlineEyeInvisible className="text-xl swap-off" />
                </label>
              </span>

              {error && (
                <p className="label-text text-xl text-red-700 text-center ">
                  {error}
                </p>
              )}

              <label className="cursor-pointer label justify-start gap-2 mt-10 mb-2">
                <input
                  name="rules"
                  onChange={() => setChecked(!checked)}
                  type="checkbox"
                  className="checkbox checkbox-primary checkbox-sm"
                />
                <span className="label-text">Keep me logged in</span>
              </label>
            </div>

            <div className=" ">
              <button
                type="submit"
                disabled={!checked}
                style={{ padding: "0 50px" }}
                className="btn btn-primary px-6 btn-xs sm:btn-sm md:btn-md  max-w-xs mx-auto"
              >
                Login
              </button>
            </div>
          </form>
          {/* {error && (
            <p className="label-text text-xl text-red-700 text-center ">
              {error}
            </p>
          )}
          {error && <div className="error-message">{error}</div>} */}
          <div className="divider"></div>

          {/* signup or reset password */}
          <div className="flex flex-col w-full lg:flex-row">
            <div className=" text-sm">
              Not a member?
              <Link className="text-primary cursor-pointer">Signup Now</Link>
            </div>
            <div className="divider lg:divider-horizontal"></div>

            <h3
              onClick={() =>
                document.getElementById("forgetPassModal").showModal()
              }
              className="text-primary text-sm cursor-pointer"
            >
              Reset Password
            </h3>
            <dialog
              id="forgetPassModal"
              className="modal modal-bottom sm:modal-middle"
            >
              <div className="modal-box">
                <div className=" text-xl ">
                  <span className="text-[#9b0186]">Hire</span>
                  <span className="text-primary	font-bold">Exam</span>
                </div>
                <form >
                  <h3 className="font-bold text-lg">
                    <div>
                      {/* <img src={logo} alt="" /> */}
                    </div>
                  </h3>
                  <div className="mt-8">
                    <label className="font-semibold text-sm" htmlFor="">
                      Enter User Email
                    </label>
                    <div className="relative">
                      <span className="absolute left-[3%] top-[25%] z-50">
                        {/* <img src={msgIcon} alt="" /> */}
                      </span>
                      <input
                        className="input h-10 pl-10 mt-1 input-bordered join-item focus:outline-none focus:border-primary w-full mb-5"
                        name="email"
                        type="email"
                        // defaultValue={localCred?.email && localCred?.email}
                        onChange={(e) => setForgEmail(e.target.value)}
                        required
                        placeholder="Enter User Email"
                      />
                    </div>
                    {forError ? (
                      <h3 className="ml-2 -mt-4 text-xs text-red-600 font-semibold">
                        {forError}
                      </h3>
                    ) : (
                      <h3 className="ml-2 -mt-4 text-xs text-primary font-semibold">
                        {succForgetCode}
                      </h3>
                    )}

                  </div>
                </form>

                <div className="modal-action">
                  <span
                    // onClick={() => document.getElementById('my_modal_1').showModal()}
                  >
                    <button
                      onClick={handleForgotPass}
                      className="btn text-white bg-[#ff7922]"

                    // disabled={btnDisable}
                    >
                      Send
                    </button>
                  </span>
                  <form method="dialog">
                    {/* if there is a button in form, it will close the modal */}
                    <button className="btn btn-error text-white">
                      Close
                    </button>
                  </form>
                </div>
              </div>
            </dialog>
            {/* Open the modal using document.getElementById('ID').showModal() method */}
            {/* <dialog id="my_modal_1" className="modal modal-bottom sm:modal-middle">


              <div className="modal-box">

                <div className="max-w-md mx-auto border mt-20">
                  <form onSubmit={handelVarifide} className="bg-white shadow-md rounded px-8 py-6">
                    <div className="mb-4">
                      <label className="block text-gray-700 text-sm font-bold mb-2" for="otp">OTP:</label>
                      <input className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline" name="otp" id="otp" type="text" placeholder="Enter OTP" />
                    </div>
                    <div className="flex items-center justify-between">
                      <button className="bg-teal-500 hover:bg-teal-700 text-white font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline" type="button">
                        Verify
                      </button>
                      <a className="inline-block align-baseline font-bold text-sm text-teal-500 hover:text-teal-800" href="#">
                        Resend OTP
                      </a>
                    </div>
                  </form>
                </div>
              </div>

            </dialog> */}
          </div>

          <label
            htmlFor="my_modal_6"
            className="btn btn-sm btn-circle btn-primary absolute right-2 top-2"
          >
            ✕
          </label>
        </div>
      </div>
    </>
  );
};

export default Login;
