
import React, { useState } from 'react'
import { RxCross2 } from 'react-icons/rx';
import { logError, logInfo } from '../../../Elasticsearchinstance';
import useAxiosPublic from "../../../Hooks/useAxiosPublic";


const EditInstruction = ({
  setEditModal,
  editModal,
  id,
  title,
  content,
}) => {
  console.log(id);
  console.log(title);

  // dynamic data ke lie
  const [data, setData] = useState({
    id: id,
    title: title,
    content: content,
  });

  const inputEvent = (event) => {
    const { name, value } = event.target;
    setData((preVal) => {
      return {
        ...preVal,
        [name]: value,
      };
    });
  };

  const axiousPublic = useAxiosPublic();

  const formSubmit = async (e) => {
    // alert("hello");
    e.preventDefault();
    const update_data = {
      id: id,
      title: data.title,
      content: data.content,
    };

    try {
      const res = await axiousPublic.put(`/updateInstruction`, update_data);
      console.log(res);
      // Log the successful update
    logInfo('Instructions updated', update_data);
      alert("Instructions Updated!");
      window.location.reload();
    } catch (error) {
      console.error("Error submitting instruction data:", error);
      // Log the error
      logError('Error submitting instruction data', error);
    }
  };

  return (
    <>
      {/* <button onClick={() => document.getElementById("my_modal_5").showModal()}>
        <p className="underline underline-offset-4 text-lg text-slate-700 hover:text-slate-500">
          <span className="font-bold text-2xl">+</span>Update a Instruction
        </p>
      </button> */}
      <input type="checkbox" id="my_modal_9" className="modal-toggle" />
      <div className="modal" role="dialog">
        <div className="modal-box w-11/12 max-w-3xl">
          <h3 className="font-bold text-lg">Update Instruction!</h3>

          <form onSubmit={formSubmit}>
             <div className="form-control p-9 gap-5">
              {/* 1st div */}
              <div className="flex gap-12 items-center">
                <label htmlFor="" className="label-text font-bold text-lg">
                  Title
                </label>
                <input
                  type="text"
                  placeholder="Type here"
                  className="input input-bordered w-full input-sm max-w-md"
                  name="title"
                  required
                  //   value={data.title}
                  //   onChange={inputEvent}
                  value={data.title || title}
                  // value={data.name}
                  onChange={inputEvent}
                />
              </div>
              <div className="flex gap-5 items-center">
                <label htmlFor="" className="label-text font-bold text-lg">
                  Content
                </label>
                <textarea
                  placeholder="Bio"
                  className="textarea textarea-bordered textarea-lg w-full max-w-md"
                  name="content"
                  required
                  //   value={data.content}
                  //   onChange={inputEvent}
                  value={data.content || content}
                  // value={data.name}
                  onChange={inputEvent}
                ></textarea>
              </div>
            </div>
            <div className="flex ml-16 mt-9 gap-3 ">
              <button
                type="submit"
                className="btn btn-success font-bold text-white"
              >
                Update Instruction
              </button>

              <form method="dialog">
                <button
                  onClick={() => setEditModal(!editModal)}
                  className="btn btn-error font-bold text-white"
                >
                  <span>
                    <RxCross2 className="text-lg" />
                  </span>
                  Cancel
                </button>
              </form>
            </div>

            <div className="modal-action">
              <form method="dialog">
                <button
                  onClick={() => setEditModal(!editModal)}
                  className="btn btn-sm btn-circle absolute right-2 top-2"
                >
                  ✕
                </button>
              </form>
            </div>
            </form>
        </div>
      </div>
    </>
  );
};

export default EditInstruction