
import axios from "axios";
import React, { useState } from "react";
import { RiDeleteBin5Line } from "react-icons/ri";
import { RxCross2 } from "react-icons/rx";
import { logError, logInfo } from "../../../Elasticsearchinstance";
import useAxiosPublic from "../../../Hooks/useAxiosPublic";


const DeleteCandidate = () => {
  const axiousPublic = useAxiosPublic();

  // get data
  const [val, changeVal] = useState("");
  const [datas, changeDatas] = useState([]);
  const [selectedCandidate, setSelectedCandidate] = useState(null); // Add state for selected candidate

  function changehandler(e) {
    changeVal(e.target.value);
  }

    const fetchdata = async (e) => {
      e.preventDefault();
      if (!val.trim()) {
        alert("Please Type Freelancer ID");
        return;
      }
      try {
        const { data } = await axiousPublic.get(`${`/getCandidateUsers`}/${val}`);
        logInfo("fetch candidate data", data);

        if (data.result.length > 0) {
          changeDatas(data.result);
        } else {
          alert("No data found.");
        }
      } catch (error) {
        console.error("Error fetching data:", error);
        logError("Error fetching data:", error);
        alert("Error fetching data. Please try again.");
      }
    };

  // async function fetchdata(e) {
  //   e.preventDefault();
  //   // Assuming 'val' is the input field value
  //   if (!val.trim()) {
  //     // If the input field is blank, show an alert
  //     alert("Please Type Freelancer ID");
  //     return;
  //   }

  //   try {
  //     let data = await fetch(`${getCandidateURL}/${val}`);

  //     if (data.ok) {
  //       let jsonData = await data.json();
  //       console.log(jsonData);
  //       logInfo("fetch candidate data", jsonData);
  //       if (jsonData.result.length > 0) {
  //         // Data is loaded, update UI
  //         changeDatas(jsonData.result);
  //       } else {
  //         // Data not found, show alert
  //         alert("No data found.");
  //       }
  //     } else {
  //       // Handle non-successful response
  //       alert(`Error: ${data.status} - ${data.statusText}`);
  //     }
  //   } catch (error) {
  //     // Handle network or other errors
  //     console.error("Error fetching data:", error);
  //     logError("Error fetching data:", error);
  //     alert("Error fetching data. Please try again.");
  //   }
  // }

  // delete data
  const deleteHandler = async () => {
    if (!window.confirm("Are you sure you want to delete this candidate?")) {
      return;
    }
    // Log the deletion attempt
    logInfo("Attempting to delete candidate", { freelancerID: val });

    try {
      const res = await axiousPublic.delete(`/deleteCandidate`, {
        data: { freelancerID: val },
      });
      // Log the response
      logInfo("Delete candidate response", res.data);

      if (res.data.success) {
        alert("Candidate deleted successfully!");
        window.location.reload();
      } else {
        alert("Failed to delete candidate.");
      }
    } catch (error) {
      console.error("Error deleting candidate:", error);
      alert("Error deleting candidate. Please try again.");
      // Log the error
      logError("Error deleting candidate", error);
    }
  };

  return (
    <>
      <button onClick={() => document.getElementById("my_modal_2").showModal()}>
        Delete
      </button>

      <dialog id="my_modal_2" className="modal">
        <div className="modal-box w-11/12 max-w-4xl">
          <h3 className="font-bold text-black text-2xl text-center">
            Delete Candidate!
          </h3>

          <form>
            <label className=" join mt-7 flex ml-24">
              <input
                className="input input-bordered join-item text-black input-sm"
                placeholder="Freelancer ID"
                required
                onChange={changehandler}
                // value={a.name}
                value={val}
              />
              <button
                type="submit"
                onClick={(e) => fetchdata(e)}
                className="btn btn-sm join-item"
              >
                Search
              </button>
            </label>
            <div className="form-control flex flex-row justify-evenly">
              {/* 1st div */}
              {datas &&
                datas.map((a) => (
                  <div>
                    <label className="mt-6 flex gap-5 justify-end ">
                      <span className="label-text font-bold text-sm">Name</span>
                      <input
                        type="text"
                        name="name"
                        required
                        placeholder="Enter Your Name"
                        className="input input-bordered text-black input-sm"
                        value={a.name}
                      />
                    </label>

                    <label className="mt-6 flex gap-5 justify-end">
                      <span className="label-text font-bold text-sm">
                        Email
                      </span>
                      <input
                        type="email"
                        name="email"
                        placeholder="Enter Your Email"
                        className="input input-bordered text-black input-sm"
                        value={a.email}
                      />
                    </label>
                    <label className="mt-6 flex gap-5 justify-end">
                      <span className="label-text font-bold text-sm">
                        Address
                      </span>
                      <input
                        type="text"
                        name="address"
                        placeholder="Enter Your Address"
                        className="input input-bordered text-black input-sm"
                        value={a.address}
                      />
                    </label>

                    <label className="mt-6 flex gap-5 justify-end">
                      <span className="label-text font-bold text-sm">
                        Mobile No
                      </span>
                      <input
                        type="number"
                        name="phone_number"
                        placeholder="Enter Your Mobile No."
                        className="input input-bordered text-black input-sm"
                        value={a.phone_number}
                      />
                    </label>

                    <label className="mt-6 flex gap-5 justify-end">
                      <span className="label-text font-bold text-sm">
                        Job Profile
                      </span>
                      <input
                        type="text"
                        name="job_profile"
                        placeholder="Enter Your Job Profile"
                        className="input input-bordered text-black input-sm"
                        value={a.job_profile}
                      />
                    </label>
                    <label className="mt-6 flex gap-5 justify-end">
                      <span className="label-text font-bold text-sm">
                        Tech Skills
                      </span>
                      <input
                        type="text"
                        name="tech_skills"
                        placeholder="Enter Your Skills"
                        className="input input-bordered text-black input-sm"
                        value={a.tech_skills}
                      />
                    </label>
                  </div>
                ))}

              {/* 2nd div */}
              {datas &&
                datas.map((a) => (
                  <div>
                    <label className="mt-6 flex gap-5 justify-end">
                      <span className="label-text font-bold text-sm">
                        Group
                      </span>
                      <select
                        name="group_tag"
                        value={a.group_tag}
                        className="select select-bordered text-black select-sm w-[198px] max-w-xs"
                      >
                        <option value="">All Selected</option>
                        <option value="Java Developer">Java Developer</option>
                        <option value="NodeJs Developer">
                          NodeJs Developer
                        </option>
                      </select>
                    </label>
                    <label className="mt-6 flex gap-5 justify-end">
                      <span className="label-text font-bold text-sm">
                        Status
                      </span>

                      <select
                        name="status"
                        value={a.status}
                        className="select select-bordered text-black select-sm w-[198px] max-w-xs"
                      >
                        <option value="">Select Status</option>
                        <option value="Active">Active</option>
                        <option value="Pending">Pending</option>
                        <option value="Suspend">Suspend</option>
                      </select>
                    </label>

                    <label className="mt-6 flex gap-5 justify-end">
                      <span className="label-text font-bold text-sm">
                        Enrollment no
                      </span>
                      <input
                        type="text"
                        name="enrollment_number"
                        placeholder=""
                        className="input input-bordered text-black input-sm"
                        value={a.enrollment_number}
                      />
                    </label>

                    <label className="mt-6 flex gap-5 justify-end">
                      <span className="label-text font-bold text-sm">
                        Experience
                      </span>
                      <input
                        type="text"
                        name="experience"
                        placeholder="Enter Your Experience"
                        className="input input-bordered text-black input-sm"
                        value={a.experience}
                      />
                    </label>
                    <label className="mt-6 flex gap-5 justify-end">
                      <span className="label-text font-bold text-sm">
                        Salary Expectation
                      </span>
                      <input
                        type="text"
                        name="salary_expectation"
                        placeholder="Enter Your Salary Expectation"
                        className="input input-bordered text-black input-sm"
                        value={a.salary_expectation}
                      />
                    </label>
                    <label className="mt-6 flex gap-5 justify-end">
                      <span className="label-text font-bold text-sm">
                        Education
                      </span>
                      <input
                        type="text"
                        name="education"
                        placeholder="Enter Your Education"
                        className="input input-bordered text-black input-sm"
                        value={a.education}
                      />
                    </label>
                  </div>
                ))}
            </div>
          </form>

          <div className="flex justify-center mt-9 gap-3 ">
            <button
              type="submit"
              onClick={deleteHandler}
              className="btn btn-success font-bold text-white"
            >
              <span>
                <RiDeleteBin5Line className="text-lg" />
              </span>
              Delete
            </button>

            <form method="dialog">
              <button
                onClick={() => document.getElementById("my_modal_2").close()}
                className="btn btn-error font-bold text-white"
              >
                <span>
                  <RxCross2 className="text-lg" />
                </span>
                Cancel
              </button>
            </form>
          </div>
          <div className="modal-action">
            <form method="dialog">
              <button className="btn btn-sm btn-circle absolute right-2 top-2">
                ✕
              </button>
            </form>
          </div>
        </div>
      </dialog>
    </>
  );
};

export default DeleteCandidate;
