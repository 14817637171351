/* eslint-disable jsx-a11y/alt-text */
import React from 'react'
import { BiSolidContact } from "react-icons/bi";
import { FaEarthAmericas } from "react-icons/fa6";
import { PiPaperPlaneRightBold } from "react-icons/pi";
import Footer from '../Footer/Footer';
import Header from '../Navbar/Header';

const ContactUs = () => {
  return (
    <>
      <Header />
      {/* banner contact */}
      <div className=" bg-purple-700 h-auto">
        <div className="max-w-[1240px]   mx-auto md:flex justify-between items-center ">
          <div className="m-12 max-w-xl ">
            <h1 className="text-5xl  font-bold text-white  max-md:text-4xl text-center">
              Contact Us
            </h1>
            <p className="m-1 mt-2 text-white max-md:text-center">
              Please feel free to contact us.
            </p>
          </div>
          <div className="m-2">
            <img
              src="https://cdni.iconscout.com/illustration/premium/thumb/contact-us-3483601-2912018.png"
              className="md:max-w-xl "
            />
          </div>
        </div>
      </div>

      <div className="max-w-[1240px] mx-auto md:flex justify-between items-center py-[50px]">
        {/* 2 div */}
        <div className="m-14 max-md:m-3 max-w-xl">
          <h1 className="text-[30px] md:text-[35px] font-semibold text-black">
            Get in Touch
          </h1>
          <div className="text-3xl font-medium mt-5">
            <hr className="bg-red-500 h-1 my-9 w-[50px]" />
          </div>
          <p className="text-gray mt-5 text-lg">
            We are always available to assist you. You can find our contact
            information here.
          </p>
          {/* Get in Touch address */}
          <div className="mt-12">
            <div className="flex gap-10">
              <FaEarthAmericas className="mt-1 text-5xl text-primary" />
              <h1 className="text-lg font-medium">
                G Block ,Pratap Vihar, Ghaziabad,
                <p>201009.</p>
              </h1>
            </div>
            <div className="flex gap-10 mt-9">
              <BiSolidContact className="mt-1 text-5xl text-primary" />
              <h1 className="text-lg font-medium">
                Call us on:
                <p>9971112169</p>
              </h1>
            </div>
            <div className="flex gap-10 mt-9">
              <PiPaperPlaneRightBold className="mt-1 text-5xl text-primary" />
              <a href="https://support@elite-professionals.in/">
                <h1 className="text-lg font-medium">
                  Email us on:
                  <p>support@elite-professionals.in</p>
                </h1>
              </a>
            </div>
          </div>
        </div>

        {/* 1 div */}
        <div className="m-2">
          <div className="rounded-2xl md:w-[34rem] bg-base-100 p-2 shadow-2xl">
            <div className="card-body">
              <h2 className="card-title text-3xl md:mt-7">Drop Us a Line</h2>
              <input
                type="text"
                placeholder="Name"
                className="input input-bordered focus:outline-none focus:ring px-3 py-3 text-sm  placeholder-gray-500 bg-slate-200 border-0 rounded shadow outline-none mt-9 "
              />
              <input
                type="text"
                placeholder="Email"
                className="input input-bordered focus:outline-none focus:ring px-3 py-3 text-sm  placeholder-gray-500 bg-slate-200 border-0 rounded shadow outline-none mt-4"
              />
              <textarea
                placeholder="Your Comment"
                className="textarea textarea-bordered textarea-lg w-full border-0 focus:outline-none focus:ring px-3 py-3 rounded shadow outline-none placeholder-gray-500 bg-slate-200 mt-4"
              ></textarea>
              <div className="card-actions">
                <button className="btn btn-success mt-4">Send a Message</button>
              </div>
            </div>
          </div>
        </div>
      </div>

      {/* footer */}
      <Footer />
    </>
  );
}

export default ContactUs