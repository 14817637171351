/* eslint-disable jsx-a11y/anchor-is-valid */
import axios from "axios";
import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { logError, logInfo } from "../../Elasticsearchinstance";
import useAxiosPublic from "../../Hooks/useAxiosPublic";

const signUpURL = "https://examserver.elite-professionals.in/signuUser";
// const signupgetURL = "http://localhost:5080/sigupgetUser";
const authsignupURL = "https://examserver.elite-professionals.in/api/v1/auth/register";
// const authsignupURL = "http://localhost:5000/api/v1/auth/register";


const SignUp = () => {
  const axiousPublic = useAxiosPublic();

  // dynamic data ke lie
  const [data, setData] = useState({
    firstName: "",
    lastName: "",
    userName: "",
    email: "",
    password: "",
    retypePassword: "",
    country: "",
    accountType: "",
    midName: "",
  });

  // dynamic data ke lie
  const inputEvent = (event) => {
    const { name, value } = event.target;
    setData((preVal) => {
      return {
        ...preVal,
        [name]: value,
      };
    });
  };

  const navigate = useNavigate();

  const formSubmit = async (e) => {
    e.preventDefault();
    logInfo("Signup form Submitted", data);
    if (data.password !== data.retypePassword) {
      alert("Passwords do not match. Please re-enter.");
      return;
    }

    const signup_data = {
      firstName: data.firstName,
      lastName: data.lastName,
      midName: data.firstName,
      userName: data.userName,
      email: data.email,
      password: data.password,
      country: data.country,
      accountType: data.accountType,
    };
    try {
      const res = await axiousPublic.post(`/api/v1/auth/register`, signup_data);
      // const res = await axios.post(authsignupURL, signup_data);
      console.log("response data", res.data.data.user);
      logInfo("Signup form submission Response", res.data);
      if (res.data.status === "success" && res.data.data.user) {
        // if (data?.status === "success" && data?.data?.user) {
        // if (true === res.data.success) {
        // Save token to localStorage
        localStorage.setItem("HireToken", res.data.data.token);
        // Save user info to localStorage
        localStorage.setItem(
          "HireInfo",
          JSON.stringify({
            token: res.data.data.token,
            userId: res.data.data.user.userId,
            email: res.data.data.user.email,
            accountType: data.accountType,
          })
        );
        alert("Sign up successful.");
        // alert("Sign up successful. Please log in.");
        // window.location.reload();
        navigate("/dashboard");
        // Redirect to login page or handle success appropriately
      } else {
        console.log("Server responded with an error:", res.data.error); // Log the specific error message from the server
        logError("Server responded with an error", res.data.error)
        alert("Email already exists. Please use a different email.");
      }
    } catch (error) {
      console.error("Error submitting signup data:", error);
      logError("Error submitting signup data", error);
      alert("Email already exists. Please use a different email.");
      // Handle error appropriately, e.g., show an error message to the user
    }

  };

  return (
    <>

      <label
        htmlFor="my_modal_7"
        className="btn btn-sm mt-3 border-primary text-white bg-primary hover:bg-primary font-bold font-sans"
      >
        Register
      </label>

      {/* Put this part before </body> tag */}
      <input type="checkbox" id="my_modal_7" className="modal-toggle" />
      <div className="modal">
        <div className="modal-box">
          <h3 className=" text-lg text-center">Join For a Good Start</h3>

          <div className="divider"></div>
          <form onSubmit={formSubmit}>
            <div className="flex flex-col">
              <div className="grid grid-cols-2 gap-4">
                <div className="form-control md:max-w-full sm:max-w-xs">

                  <input
                    // required
                    name="firstName"
                    type="text"
                    placeholder="First Name"
                    className="input input-bordered w-full max-w-xs"
                    value={data.firstName}
                    onChange={inputEvent}
                  />
                  {/* </div> */}
                </div>

                <div className="form-control md:max-w-full sm:max-w-xs ">
                  <input
                    // required
                    name="lastName"
                    placeholder="Last Name"
                    className="input input-bordered md:max-w-full sm:max-w-xs"
                    value={data.lastName}
                    onChange={inputEvent}
                  />
                </div>
                <div className="form-control md:max-w-full sm:max-w-xs ">
                  <input
                    name="userName"
                    type="text"
                    placeholder="Username"
                    className="input input-bordered md:max-w-full sm:max-w-xs"
                    value={data.userName}
                    onChange={inputEvent}
                  />
                </div>

                <div className="form-control md:max-w-full sm:max-w-xs  relative">
                  <input
                    name="email"
                    type="email"
                    required
                    placeholder="Email"
                    className="input input-bordered md:max-w-full sm:max-w-xs"
                    value={data.email}
                    onChange={inputEvent}
                  />
                </div>

                <div className="form-control w-full max-w-xs ">
                  <input
                    // required
                    name="password"
                    type="password"
                    placeholder="password "
                    className="input input-bordered w-full max-w-xs "
                    value={data.password}
                    onChange={inputEvent}
                  />
                </div>
                <div className="form-control w-full max-w-xs">
                  <input
                    name="retypePassword"
                    type="password"
                    placeholder="Retype Password"
                    className={`input input-bordered w-full max-w-xs`}
                    value={data.retypePassword}
                    onChange={inputEvent}
                  />
                </div>
                {/* <PersonalDetails /> */}
              </div>
              <br />

              <div className="form-control w-full max-w-xs ">
                <select
                  required
                  name="country"
                  value={data.country}
                  onChange={inputEvent}
                  className="select select-bordered w-full"
                >

                  <option value="">Select Country</option>
                  <option value="India">India</option>
                  <option value="USA">USA</option>
                </select>
              </div>
              <br />
              {/* radio */}
              <div className="">Start as:</div>
              <div className="mt-3">
                <div className="form-control input input-bordered justify-start">
                  <label className="label cursor-pointer justify-start gap-4 mt-1">
                    <input
                      name="accountType"
                      type="radio"
                      className="radio radio-sm  checked:bg-blue-500"
                      // value={data.accountType}
                      value="Admin"
                      onChange={inputEvent}
                      required
                    />
                    <span className="label-text text-md font-semibold">
                      Admin
                    </span>
                  </label>
                </div>

                <div className="form-control input input-bordered justify-start mt-4">
                  <label className="label cursor-pointer justify-start gap-4 mt-1">
                    <input
                      name="accountType"
                      type="radio"
                      className="radio radio-sm  checked:bg-blue-500"
                      // value={data.accountType}
                      value="Custom Creator"
                      onChange={inputEvent}
                      required
                    />
                    <span className="label-text text-md font-semibold">
                      Question Creator
                    </span>
                  </label>
                </div>

                <div className="form-control input input-bordered justify-start mt-4">
                  <label className="label cursor-pointer justify-start gap-4 mt-1">
                    <input
                      name="accountType"
                      type="radio"
                      className="radio radio-sm  checked:bg-blue-500"
                      // value={data.accountType}
                      value="Invisilator"
                      onChange={inputEvent}
                      required
                    />
                    <span className="label-text text-md font-semibold">
                      Invigilator
                    </span>
                  </label>
                </div>
              </div>
              <br />
              <label className="cursor-pointer label justify-start gap-2 ">
                <input
                  type="checkbox"
                  className="checkbox checkbox-primary checkbox-sm"
                />
                <span className="text-xs">
                  Yes, I understand and agree to the Hire Terms of Services,
                  including the User Agreement and Privacy Policy.You will
                  receive marketing messages from Hire Elite and may opt out at
                  any time by following the unsubscribe link in our messages, or
                  as detailed in our Terms & Conditions.
                </span>
              </label>
            </div>
            <br />
            <div className=" ">
              <button type="submit" className="btn btn-primary btn-md mx-auto">
                Confirm
              </button>
            </div>
          </form>
          <br />

          <label
            htmlFor="my_modal_7"
            className="btn btn-sm btn-circle btn-primary absolute right-2 top-2"
          >
            ✕
          </label>
        </div>
      </div>
    </>
  );
};

export default SignUp;
