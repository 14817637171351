import axios from "axios";
import React from "react";
import { RxCross2 } from "react-icons/rx";

const sendEmailURL = "https://www.emailapi.elite-professionals.in/api/sendmail";

const ViewResults = ({ setViewModal, viewModal, name, email, exam_course }) => {
  console.log("name view result",name);
  console.log("email view result", email);
  console.log("exam_course view result", exam_course);

    const handleMailButtonClick = async (email, exam_course) => {
      const mail_data = {
        from_email: email,
        to_email: email,
        temp: "Your result",
        first_name: exam_course,
        last_name: exam_course,
      };
      console.log("mail_data", mail_data);
      try {
        const res = await axios.post(sendEmailURL, mail_data);
        console.log("mail response data", res);
        alert("Mail Sent Succesfully!");
        //  window.location.reload();
      } catch (error) {
        console.error("Error Mail Sending data:", error);
        alert("Error sending mail. Please try again.");
      }
    };

  return (
    <>
      <input type="checkbox" id="my_modal_7" className="modal-toggle" />
      <div className="modal" role="dialog">
        <div className="modal-box w-11/12 max-w-3xl">
          <h3 className="font-bold text-lg">View Result!</h3>

          <form>
            <div className="form-control p-9 gap-5">
              {/* 1st div */}

              <div className="flex gap-12 items-center">
                {/* <label htmlFor="" className="label-text font-bold text-lg">
                  {name}
                </label> */}
                <label className="mt-6 flex gap-5 justify-end ">
                  <span className="label-text font-bold text-sm">Name :</span>
                  <span className="label-text font-bold text-sm">{name}</span>
                </label>
              </div>
              <div className="flex gap-5 items-center">
                {/* <label htmlFor="" className="label-text text-lg">
                  {email}
                </label> */}
                <label className="mt-6 flex gap-5 justify-end ">
                  <span className="label-text font-bold text-sm">Email :</span>
                  <span className="label-text font-bold text-sm">{email}</span>
                </label>
              </div>
              <div className="flex gap-5 items-center">
                {/* <label htmlFor="" className="label-text text-lg">
                  {exam_course}
                </label> */}
                <label className="mt-6 flex gap-5 justify-end ">
                  <span className="label-text font-bold text-sm">
                    Exam Course :
                  </span>
                  <span className="label-text font-bold text-sm">
                    {exam_course}
                  </span>
                </label>
              </div>
            </div>
          </form>
          {/* <div className=" "> */}
          <form method="dialog" className="flex gap-3">
            <button
              onClick={() => setViewModal(!viewModal)}
              htmlFor="my_modal_7"
              className="btn btn-outline font-bold btn-sm"
            >
              <span>
                <RxCross2 className="text-lg" />
              </span>
              Cancel
            </button>

            <button
              name="submit"
              type="submit"
              // onClick={() => viewCourseData(ab.exam_course)}
              // onClick={handleMailButtonClick}
              onClick={() => handleMailButtonClick(email, exam_course)}
              className="btn btn-accent font-bold btn-sm"
            >
              Send email
            </button>
          </form>
          {/* </div> */}
        </div>
      </div>
    </>
  );
};

export default ViewResults;
