import React from "react";
import { Link } from "react-router-dom";

const ReadInstructions = () => {
  return (
    <>
      <div className="bg-sky-50 h-[35rem]">
        <div className="text-primary text-3xl font-semibold pt-8 ml-9 ">
          <h1>Instruction:</h1>
          <div className="divider"></div>
        </div>
        <div className="text-xl font-semibold text-gray-500 w-[53rem]  ml-16">
          <li className="text-primary mt-6">
            This is a <span className="text-[#ea5a7d]">FREE</span> online test.
            Beware of scammers who ask for money to attend this test.
          </li>
          <li className="mt-2">This number of questions: 20.</li>
          <li className="mt-2">Time allotted: 30 minutes.</li>
          <li className="mt-2">
            Each question carries 1 mark; there are no negative marks.
          </li>
          <li className="mt-2">DO NOT refresh the page</li>
          <li className="mt-2">All the best!</li>
        </div>

        <div className="flex justify-center mt-9 w-[48rem]">
          <Link
            to="/question-paper"
            className="btn btn-outline border-primary "
          >
            Start test
          </Link>
        </div>
      </div>
    </>
  );
};

export default ReadInstructions;
