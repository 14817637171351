import React from 'react';
import { Navigate, useLocation } from 'react-router-dom';
import PropTypes from 'prop-types';
import Home from './Home/Home';

const PrivateRoute = ({ children }) => {
    const users = JSON.parse(localStorage.getItem('HireInfo'))
    const location = useLocation()
    console.log(location);

    const loding = 'lodding'

    if (!users?.email) {
        console.log(users?.email);
        return <div className="flex justify-center items-center">
            <Home></Home>
        </div>
    }

    if (users?.email) {
        console.log(users?.email);
        return children
    }


    return <Navigate to={`/`} state={{ from: location }} ></Navigate>;
};

PrivateRoute.propTypes = {
    children: PropTypes.object
}

export default PrivateRoute;