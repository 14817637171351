import axios from 'axios';


const axiousPublic = axios?.create({
    // baseURL: 'https://examserver.elite-professionals.in',
    baseURL: ' https://exambe.airepro.in/',
    // baseURL: 'http://localhost:5050'  
    // headers: { 'X-Custom-Header': 'foobar' }

});
const useAxiosPublic = () => {
    return axiousPublic;
};

export default useAxiosPublic;
