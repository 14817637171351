
import { useState } from "react";
import * as XLSX from "xlsx";
import useAxiosPublic from "../../../Hooks/useAxiosPublic";


const ImportExportStudents = () => {
  const axiousPublic = useAxiosPublic();

  // onchange states
  const [excelFile, setExcelFile] = useState(null);
  const [typeError, setTypeError] = useState(null);

  // submit state
  const [excelData, setExcelData] = useState(null);
  // onchange event

  const handleFile = (e) => {
    let fileTypes = [
      "application/vnd.ms-excel",
      "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
      "text/csv",
    ];
    let selectedFile = e.target.files[0];
    if (selectedFile) {
      if (selectedFile && fileTypes.includes(selectedFile.type)) {
        setTypeError(null);
        let reader = new FileReader();
        reader.readAsArrayBuffer(selectedFile);
        reader.onload = (e) => {
          setExcelFile(e.target.result);
        };
      } else {
        setTypeError("Please select only excel file types");
        setExcelFile(null);
      }
    } else {
      console.log("Please select your file");
    }
  };

  // submit event
  const handleFileSubmit = async (e) => {
    e.preventDefault();
    if (excelFile !== null) {
      const workbook = XLSX.read(excelFile, { type: "buffer" });
      const worksheetName = workbook.SheetNames[0];
      const worksheet = workbook.Sheets[worksheetName];
      const data = XLSX.utils.sheet_to_json(worksheet);
      // Filter out duplicates based on freelancer ID
      // const uniqueData = Array.from(
      //   new Set(data.map((item) => item.freelancerId))
      // ).map((freelancerId) =>
      //   data.find((item) => item.freelancerId === freelancerId)
      // );
      // setExcelData(uniqueData.slice(0, 10));
      // setExcelData(data.slice(0, 10));
      setExcelData(data);
    }
  };

const handleSave = async () => {
  if (excelData !== null) {
    try {
      await Promise.all(
        excelData.map(async (individualData) => {
          try {
            // Check if the freelancer ID already exists
            const existingCandidate = await axiousPublic.get(
              `${`/getCandidateUsers`}?freelancerId=${individualData.freelancerId}`
            );

            if (!existingCandidate.data.length) {
              // If the freelancer ID doesn't exist, save the data
              const response = await axiousPublic.post(`/candidatepost`,individualData);
              console.log("Server response:", response.data);
            } else {
              console.log(
                "Freelancer ID already exists:",
                individualData.freelancerId
              );
              // Handle the case where the freelancer ID already exists
              // You may want to update the existing data or skip saving
            }
          } catch (error) {
            console.error("Error checking/sending data:", error);
          }
        })
      );
      window.location.reload();
    } catch (error) {
      console.error("Error sending data:", error);
    }
  }
};

  return (
    <>
      <button
        onClick={() => document.getElementById("my_modal_13").showModal()}
      >
        ImportExportStudents
      </button>
      <dialog id="my_modal_13" className="modal">
        <div className="modal-box w-11/12 max-w-5xl">
          <h3 className="font-bold text-black text-2xl">
            ImportExportStudents
          </h3>

          <form onSubmit={handleFileSubmit}>
            <label className=" join mt-7 flex gap-2">
              <input
                type="file"
                className="file-input file-input-bordered w-full max-w-xs text-black"
                required
                onChange={handleFile}
              />
              <button type="submit" className="btn btn-success btn-md">
                UPLOAD
              </button>
              <button
                type="submit"
                className="btn btn-primary btn-md"
                onClick={handleSave}
              >
                SAVE
              </button>
            </label>
            {typeError && (
              <div className="alert alert-danger" role="alert">
                {typeError}
              </div>
            )}
          </form>
          {/* <div className="viewer mt-3"> */}
          <div className="m-3 shadow-xl rounded-lg">
            {excelData ? (
              //   <div className="table-responsive">
              <div className="overflow-x-auto">
                <table className="table border-2">
                  <thead className="text-black">
                    <tr className="border-r-2 border-t-2 border-l-2 border-slate-400">
                      {Object.keys(excelData[0]).map((key) => (
                        <th className="border-r-2 border-slate-400" key={key}>
                          {key}
                        </th>
                      ))}
                    </tr>
                  </thead>

                  <tbody className="text-black">
                    {excelData.map((individualExcelData, index) => (
                      <tr
                        className="border-r-2 border-b-2 border-l-2 border-slate-400"
                        key={index}
                      >
                        {Object.keys(individualExcelData).map((key) => (
                          <td className="border-r-2 border-slate-400" key={key}>
                            {individualExcelData[key]}
                          </td>
                        ))}
                      </tr>
                    ))}
                  </tbody>
                </table>
              </div>
            ) : (
              <div className="text-black">No File is uploaded yet!</div>
            )}
          </div>

          <div className="modal-action">
            <form method="dialog">
              <button className="btn btn-sm btn-circle absolute right-2 top-2">
                ✕
              </button>
            </form>
          </div>
        </div>
      </dialog>
    </>
  );
};

export default ImportExportStudents;
