import React from 'react'
import { BiSolidQuoteAltLeft } from "react-icons/bi";
import Tamahanakar from "../../Assets/img/Tamahanakar.jpg";
import Kedar from "../../Assets/img/Kedar.png";
import parag from "../../Assets/img/parag.jpg";

const Testimonial = () => {
  return (
    <>
      <div className="text-center m-16">
        <div className="">
          <button className="btn text-md bg-primary btn-sm m-1 text-white hover:bg-primary">
            Testimonial
          </button>
        </div>
        <div className="text-3xl max-md:text-xl mx-auto font-medium mt-5">
          <h1 className="">
            Don't just take our word for it. Here's what our satisfied customers
            have to say about ExamOnline:
          </h1>
        </div>
        <div className="text-3xl font-medium mt-5">
          <hr className="bg-primary h-2 my-9 md:w-[500px] mx-auto" />
        </div>
      </div>

      {/* slider */}
      <div className="max-w-[1240px] mx-auto my-7 h-auto grid ">
        <div className="carousel  gap-4 ">
          {/* 1 */}
          <div id="item3" className="carousel-item">
            <div className="border rounded-md md:w-[600px] m-1 bg-base-100 max-md:w-[300px]">
              <div className="flex mx-9 my-7">
                <figure>
                  <img src={parag} alt="Movie" className="w-16 rounded-full" />
                </figure>
                <div className="mx-8">
                  <h1 className="text-lg font-medium"> Parag Kulkarni</h1>
                  <p className="text-sm text-slate-500">CA</p>
                </div>
              </div>
              <div className="mx-10">
                <BiSolidQuoteAltLeft className="mt-1 text-5xl text-blue-200" />
                <h2 className="">
                  “Let me appreciate all efforts put in by entire team to
                  successfully complete the examination. We are very thankful to
                  all of you for entire support extended during the
                  examination.We are able to execute our Online Exam Process
                  with secure browsers seamlessly. Thanks once again.”
                </h2>
              </div>
            </div>
          </div>

          {/* 2 */}
          <div id="item2" className="carousel-item">
            <div className="border rounded-md md:w-[600px] m-1 bg-base-100 max-md:w-[300px]">
              <div className="flex mx-9 my-7">
                <figure>
                  <img src={Kedar} alt="Movie" className="w-16 rounded-full" />
                </figure>
                <div className="mx-8">
                  <h1 className="text-lg font-medium"> Kedar Ayachit</h1>
                  <p className="text-sm text-slate-500">
                    Regional Manager – Marketing (ADYPU University)
                  </p>
                </div>
              </div>
              <div className="mx-10">
                <BiSolidQuoteAltLeft className="mt-1 text-5xl text-blue-200" />
                <h2 className="">
                  “We found services extremely useful to manage our pre
                  admissions, admission processes. Our admission process became
                  simplified with online application process. It helped us to
                  filter out suitable students fitting in admission criteria.
                  Thank you for continued support.”
                </h2>
              </div>
            </div>
          </div>
          {/* 3 */}
          <div id="item1" className="carousel-item">
            <div className="border rounded-md md:w-[600px] m-1 bg-base-100  max-md:w-[300px]">
              <div className="flex mx-9 my-7">
                <figure>
                  <img
                    src={Tamahanakar}
                    alt="Movie"
                    className="w-16 rounded-full"
                  />
                </figure>
                <div className="mx-8">
                  <h1 className="text-lg font-medium"> Parag Tamahanakar</h1>
                  <p className="text-sm text-slate-500">
                    Asst. Professor at Department of Computer Science, Abasaheb
                    Garware College, Pune
                  </p>
                </div>
              </div>
              <div className="mx-10">
                <BiSolidQuoteAltLeft className=" text-5xl text-blue-200" />
                <h2 className="">
                  “Each time we have received professional assistance and
                  support from ePravesh. Our students are also happy for the
                  simple admission process. Thanks for the help, I would
                  recommend it to others for admission process automation and
                  simplification”
                </h2>
              </div>
            </div>
          </div>

          {/* 4 */}
          <div id="item4" className="carousel-item">
            <div className="border rounded-md md:w-[600px] m-1 bg-base-100 max-md:w-[300px]">
              <div className="flex mx-9 my-7">
                <figure>
                  <img
                    src="https://ssvminstitutions.ac.in/wp-content/uploads/2021/10/SSVM-Parent-Testimonial.jpeg"
                    alt="Movie"
                    className="w-16 rounded-full"
                  />
                </figure>
                <div className="mx-8">
                  <h1 className="text-lg font-medium"> Muain Haseeb</h1>
                  <p className="text-sm text-slate-500">
                    Executive Director at Al Arab Medical laboratories, Saudi
                    Arabia
                  </p>
                </div>
              </div>
              <div className="mx-10">
                <BiSolidQuoteAltLeft className="mt-1 text-5xl text-blue-200" />
                <h2 className="">
                  “ExamOnline has transformed our exam administration process.
                  The platform’s user-friendly interface, customizable settings,
                  and detailed proctoring reports have made remote exams a
                  smooth and reliable experience for us.”
                </h2>
              </div>
            </div>
          </div>
        </div>
      </div>

      {/* number */}
      <div className="flex justify-center w-full gap-2">
        <a href="#item1" className="btn btn-xs">
          1
        </a>
        <a href="#item2" className="btn btn-xs">
          2
        </a>
        <a href="#item3" className="btn btn-xs">
          3
        </a>
        <a href="#item4" className="btn btn-xs">
          4
        </a>
      </div>

      {/* end slider */}
    </>
  );
}

export default Testimonial