/* eslint-disable jsx-a11y/alt-text */
/* eslint-disable jsx-a11y/anchor-is-valid */
import React from 'react';
import blog from '../../Assets/img/blogging.png';
import Footer from '../Footer/Footer';
import Header from '../Navbar/Header';

const Blog = () => {
  return (
    <>
      <Header />
      {/* banner contact */}
      <div className=" bg-purple-700 h-auto">
        <div className="max-w-[1240px]   mx-auto md:flex justify-between items-center ">
          <div className="m-12 max-w-xl ">
            <h1 className="text-5xl  font-bold text-white  max-md:text-4xl text-center">
              Blog
            </h1>
          </div>
          <div className="m-2">
            <img src={blog} className="md:max-w-xl " />
          </div>
        </div>
      </div>
      <div className="py-[30px] px-4">
        <div className="max-w-[1240px] mx-auto h-auto grid gap-x-14 gap-y-9  md:grid-cols-3">
          {/* 1st */}
          <div className=" card-compact w-[26rem] max-md:w-72 bg-base-100 shadow-xl">
            <a href="">
              <figure>
                <img
                  className="h-64 w-full"
                  src="https://www.itweb.co.za/static/pictures/2018/04/resized/-fs-Education-concept-2018.xl.jpg"
                  alt="Shoes"
                />
              </figure>
            </a>
            <div className="card-body">
              <div className=" flex font-medium gap-4 mt-1">
                <h1 className="hover:text-purple-600">
                  <a href="">Data Security</a>
                </h1>
                <h1 className="hover:text-purple-600">
                  <a href="">Exam Proctoring</a>
                </h1>
                <h1 className="hover:text-purple-600">
                  <a href="">Online Exams</a>
                </h1>
              </div>
              <h2 className="card-title text-3xl max-md:text-2xl hover:text-purple-600 mt-2">
                <a href="">
                  Remote Proctoring 101: Your Definitive Guide for 2023’s Key
                  Insights
                </a>
              </h2>
              <p className="mt-5 text-lg max-md:text-sm items-center">
                Introduction: Welcome to our comprehensive guide on remote
                proctoring, a vital tool reshaping the landscape of modern
                education. In this section, we’ll delve into the significance of
                remote proctoring in{" "}
                <a className="text-purple-600" href="">
                  Read More
                </a>
              </p>
            </div>
          </div>
          {/* 2nd */}
          <div className=" card-compact w-[26rem] max-md:w-72 bg-base-100 shadow-xl">
            <a href="">
              <figure>
                <img
                  className="h-64 w-full"
                  src="https://www.techbooky.com/wp-content/uploads/2018/04/technology-and-us-scaled.jpg"
                  alt="Shoes"
                />
              </figure>
            </a>
            <div className="card-body">
              <div className=" flex font-medium gap-4 mt-1">
                <h1 className="hover:text-purple-600">
                  <a href="">Exam Proctoring</a>
                </h1>
              </div>
              <h2 className="card-title text-3xl max-md:text-2xl hover:text-purple-600 mt-2">
                <a href="">
                  Are Online Proctored Exams Safer than Pen and Paper Exams?
                </a>
              </h2>
              <p className="mt-5 text-lg max-md:text-sm items-center">
                Online proctored exams are probably the best thing that came
                after pen and paper exams. Its ease of accessibility and
                viability for mass testing and examination makes it an easy{" "}
                <a className="text-purple-600" href="">
                  Read More
                </a>
              </p>
            </div>
          </div>

          {/* 3rd */}
          <div className=" card-compact w-[26rem] max-md:w-72 bg-base-100 shadow-xl">
            <a href="">
              <figure>
                <img
                  className="h-64 w-full"
                  src="https://www.mida.gov.my/wp-content/uploads/2020/08/20200817135423_Svs-1.jpg"
                  alt="Shoes"
                />
              </figure>
            </a>
            <div className="card-body">
              <div className=" flex font-medium gap-4 mt-1">
                <h1 className="hover:text-purple-600">
                  <a href="">Online Exams</a>
                </h1>
                <h1 className="hover:text-purple-600">
                  <a href="">Exam Proctoring</a>
                </h1>
              </div>
              <h2 className="card-title text-3xl max-md:text-2xl hover:text-purple-600 mt-2">
                <a href="">
                  Remote Proctoring : The Future of Online Exami- nations
                </a>
              </h2>
              <p className="mt-5 text-lg max-md:text-sm items-center">
                While traditional online examinations have a longstanding
                history, it’s essential to set aside nostalgia and embrace
                innovative approaches that align with the contemporary needs of
                educational institutions, learners, and employers.{" "}
                <a className="text-purple-600" href="">
                  Read More
                </a>
              </p>
            </div>
          </div>
          {/* 4th */}
          <div className=" card-compact w-[26rem] max-md:w-72 bg-base-100 shadow-xl">
            <a href="">
              <figure>
                <img
                  className="h-64 w-full"
                  src="https://universalcoachingsystems.com/wp-content/uploads/2017/05/Online-business-Illust-sm.jpg"
                  alt="Shoes"
                />
              </figure>
            </a>
            <div className="card-body">
              <div className=" flex font-medium gap-4 mt-1">
                <h1 className="hover:text-purple-600">
                  <a href="">Online Exams</a>
                </h1>
              </div>
              <h2 className="card-title text-3xl max-md:text-2xl hover:text-purple-600 mt-2">
                <a href="">
                  Top 9 Online Exam Pre- paration Tips for Students and
                  Institutions
                </a>
              </h2>
              <p className="mt-5 text-lg max-md:text-sm items-center">
                One of the many things that COVID-19 changed for the better is
                examination. Like so many other things, the world moved the
                exams online. However, because this is a new{" "}
                <a className="text-purple-600" href="">
                  Read More
                </a>
              </p>
            </div>
          </div>

          {/* 5th */}
          <div className=" card-compact w-[26rem] max-md:w-72 bg-base-100 shadow-xl">
            <a href="">
              <figure>
                <img
                  className="h-64 w-full"
                  src="https://campustechnology.com/-/media/EDU/CampusTechnology/2021-images/20210317onlinelearning.jpg"
                  alt="Shoes"
                />
              </figure>
            </a>
            <div className="card-body">
              <div className=" flex font-medium gap-4 mt-1">
                <h1 className="hover:text-purple-600">
                  <a href="">Exam Proctoring</a>
                </h1>
                <h1 className="hover:text-purple-600">
                  <a href="">Online Exams</a>
                </h1>
              </div>
              <h2 className="card-title text-3xl max-md:text-2xl hover:text-purple-600 mt-2">
                <a href="">5 Common Myths About Remote Proctoring</a>
              </h2>
              <p className="mt-5 text-lg max-md:text-sm items-center">
                Technology has transformed the education industry, and the world
                has transitioned to online teaching and examinations. The
                emergence of the COVID-19 pandemic made universities, schools,
                and colleges adopt the online{" "}
                <a className="text-purple-600" href="">
                  Read More
                </a>
              </p>
            </div>
          </div>

          {/* 6th */}
          <div className=" card-compact w-[26rem] max-md:w-72 bg-base-100 shadow-xl">
            <a href="">
              <figure>
                <img
                  className="h-64 w-full"
                  src="https://media.istockphoto.com/id/1168910967/vector/online-education-and-e-learning-via-digital-device.jpg?s=612x612&w=0&k=20&c=gTxy_vzWPlR-CIQQ4GoDc7NLDXg-TIcJDTChDvarscc="
                  alt="Shoes"
                />
              </figure>
            </a>
            <div className="card-body">
              <div className=" flex font-medium gap-4 mt-1">
                <h1 className="hover:text-purple-600">
                  <a href="">Exam Proctoring</a>
                </h1>
                <h1 className="hover:text-purple-600">
                  <a href="">Online Exams</a>
                </h1>
              </div>
              <h2 className="card-title text-3xl max-md:text-2xl hover:text-purple-600 mt-2">
                <a href="">
                  How can Edtech improve Data Security in Virtual Exams?
                </a>
              </h2>
              <p className="mt-5 text-lg max-md:text-sm items-center">
                India’s Edtech industry is growing rapidly, with a size of US$
                3.2 billion in 2022. This makes it the second-largest e-learning
                market globally, after the U.S. However, data security issues{" "}
                <a className="text-purple-600" href="">
                  Read More
                </a>
              </p>
            </div>
          </div>
        </div>
      </div>

      {/* footer */}
      <Footer />
    </>
  );
}

export default Blog