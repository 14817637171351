import React from "react";
import { MdOutlineMicNone } from "react-icons/md";
import { RiMic2Line } from "react-icons/ri";
import { Link } from "react-router-dom";
import { useLocation } from "react-router-dom";

const Hardwarecheck = () => {
  
  const location = useLocation();
   const searchParams = new URLSearchParams(location.search);
   const exam_email = searchParams.get("exam_email");
   const password = searchParams.get("password");
   const freelancerID = searchParams.get("freelancerID");
   const exam_course = searchParams.get("exam_course");

  return (
    <>
      <div className="flex flex-col items-center bg-cyan-50 h-[36rem]">
         <div className="">
          <h1 className="text-2xl font-bold mt-12">Hardware Check</h1>
        </div>

        <div className="mt-9">
          <ul className="steps steps-vertical lg:steps-horizontal gap-5">
            <li className="step step-primary ">
              Hardware <br /> Check
            </li>
            <li className="step ">
              Test <br /> Instructions
            </li>
          </ul>
        </div>

        <div className="flex gap-10 mt-12 items-center">
          <MdOutlineMicNone className="text-3xl" />
          <h1 className="text-2xl font-bold">Webcam</h1>
          <label className="cursor-pointer label">
            <input
              type="checkbox"
              checked="checked"
              className="checkbox checkbox-success"
            />
          </label>
        </div>

        <div className="flex gap-10 mt-6 items-center">
          <RiMic2Line className="text-3xl" />
          <h1 className="text-2xl font-bold">Microphone</h1>
          <label className="cursor-pointer label">
             <input
              type="checkbox"
              checked="checked"
              className="checkbox checkbox-success"
            />
          </label>
        </div>

        <div className="mt-7">
          <Link
            to={`/viewinstructions?exam_email=${exam_email}&password=${password}&freelancerID=${freelancerID}&exam_course=${exam_course}`}
            className="btn w-[9rem] bg-blue-600 hover:bg-blue-600 text-white text-lg font-semibold"
          >
            Next
          </Link>
        </div>
      </div>
    </>
  );
};

export default Hardwarecheck;
