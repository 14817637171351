import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { useLocation } from "react-router-dom";

const Instructions = () => {

  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  const exam_email = searchParams.get("exam_email");
  const password = searchParams.get("password");
  const freelancerID = searchParams.get("freelancerID");
  const exam_course = searchParams.get("exam_course");

  const [checked, setChecked] = useState(false);

  // const handleCheckboxChange = (value) => {
  //   setChecked(prevChecked =>
  //     prevChecked.includes(value)
  //       ? prevChecked.filter(item => item !== value)
  //       : [...prevChecked, value]
  //   );
  // };

  console.log(checked);

  const [examStarted, setExamStarted] = useState(false);
  useEffect(() => {
    // Add event listener to detect tab switching or page navigation
    const handleBeforeUnload = (event) => {
      if (examStarted) {
        const confirmationMessage =
          "Leaving this page will result in exam failure.";
        (event || window.event).returnValue = confirmationMessage;
        return confirmationMessage;
      }
    };
    window.addEventListener("beforeunload", handleBeforeUnload);

    return () => {
      window.removeEventListener("beforeunload", handleBeforeUnload);
    };
  }, [examStarted]);

  const startExam = () => {
    setExamStarted(true);
  };



  return (
    <>
      <div className="bg-sky-50 w-full mx-auto h-full py-5">
        <div className=" font-bold px-12 text-4xl mb-5 flex justify-center">
          <span className="text-primary">Hire</span>
          <span className="text-primary	font-bold">Exam</span>
        </div>

        <div className=" flex justify-center">
          <ul className="steps steps-vertical lg:steps-horizontal">
            <li className="step step-primary ">
              Hardware <br /> Check
            </li>
            <li className="step step-primary">
              Test <br /> Instructions
            </li>
          </ul>
        </div>

        <div className="max-w-7xl flex-col mx-auto rounded-t-3xl mt-5 shadow-md ">
          <div className="card-title flex justify-center text-2xl rounded-t-3xl h-14 pl-10 bg-blue-600 text-white font-bold">
            <h2 >
              Instructions
            </h2>
          </div>
          <div className=" grid  mx-auto grid-cols-2 h-[60vh] ">
            <div>
              <div className=" card rounded-none w-full bg-white h-[59vh] overflow-y-auto ">
                <div className="card-body">
                  <ul className="list-decimal font-semibold">
                    <li className="mb-3">You must use only one browser window to access the exam. Opening multiple browser windows will be considered an attempt to cheat and may result in disqualification.</li>
                    <li className="mb-3">The exam must be taken in a single tab of your browser. Opening additional tabs is strictly prohibited and will be monitored.</li>
                    <li className="mb-3">Please use a desktop computer or a laptop to take the exam. The exam interface is optimized for these devices.</li>
                    <li className="mb-3">Mobile phones and tablets are not supported for this exam. The exam page may lag or malfunction on mobile devices, leading to potential issues with your submission.</li>
                    <li className="mb-3">Once you submit your exam, no further changes will be entertained. Ensure that all your answers are finalized before submission.</li>
                    <li className="mb-3">Double-check all your answers and ensure everything is correctly filled out before hitting the submit button.</li>
                    <li className="mb-3">Ensure you have a stable internet connection throughout the exam to avoid any disruptions.</li>
                    <li className="mb-3">Use a compatible and updated browser such as Google Chrome, Mozilla Firefox, or Safari for the best performance.</li>
                    <li className="mb-3">You must complete the exam independently. Receiving help from others or using unauthorized resources is prohibited.</li>
                    <li className="">Be aware that your activity during the exam may be monitored for any suspicious behavior.</li>
                  </ul>
                  <strong>Note: </strong> By following these instructions, you help maintain the integrity of the examination process. Failure to comply with these guidelines may result in penalties or disqualification. If you experience any technical difficulties, please contact support immediately.
                </div>
              </div>
            </div>
            <div className=" w-full bg-white h-[59vh]">
              <div className="card-body">
                <p>Duration: 30 Minutes (20 questions)</p>
                <p>Numbers: All numbers used are real numbers.</p>
                <p>
                  Figures: The diagrams and figures that accompany these questions
                  are for the purpose of providing information useful in answering
                  the questions.Unless it is stated that a specific figure is not
                  drawn to scale, the diagrams and figures are drawn as accurately
                  as possible. All figures are in a plane unless otherwise
                  indicated
                </p>
                <p>You may use a calculator but not the one on your machine.</p>
              </div>
            </div>
          </div>
        </div>

        <div className="flex flex-col font-bold gap-3 justify-center items-center mt-12">
          <div className="w-[50rem] flex gap-9">
            <div>
              <input
                onChange={() => setChecked(!checked)}
                type="checkbox"
                className="checkbox checkbox-md"
              />
            </div>
            <div>
              <p>
                {/* Don't minimize your browser . if you minimize your browser then we close your exam . we are decting you everytime . */}
                I agree to take this assessment based only on my immediate
                abilities. I also agree not to participate in any malpractice by
                copying, taking external help, or using any additional resources
                to improve my performance.
              </p>
            </div>
          </div>
        </div>

        <div className="flex mt-7 justify-center">
          {checked ? <Link
            to={`/question-paper?exam_email=${exam_email}&password=${password}&freelancerID=${freelancerID}&exam_course=${exam_course}`}
            onClick={startExam}
            // disabled={!checked}
            className="btn btn-primary w-48 font-bold"
          >
            Start Assesment
          </Link> : <div>
            <button className="btn btn-disabled w-48 font-bold">Start Assesment</button>
          </div>}
        </div>
      </div>
    </>
  );
};

export default Instructions;
