
import useAxiosPublic from './useAxiosPublic';
import { useQuery } from '@tanstack/react-query';

const useGeteXamUrl = () => {
    
    const axiousPublic = useAxiosPublic()
    const { data : questionData, isPending, refetch } = useQuery({
        queryKey: ['idAdmin'],
        queryFn: async () => {
            const res = await axiousPublic?.get(`/getquestionbank`)
            // console.log(res)
            return res?.data?.result;
        }
    })
    return [questionData, isPending, refetch ] ;
};

export default useGeteXamUrl;