/* eslint-disable jsx-a11y/alt-text */
import React, { useEffect, useState } from 'react'

const OurAchievements = () => {

  const [number, setNumber] = useState(1);
  const [number1, setNumber1] = useState(1);
  const [number2, setNumber2] = useState(1);
  const [number3, setNumber3] = useState(1);

  useEffect(() => {
    const interval = setInterval(() => {
      if (number < 100) {
        setNumber(number + 1);
      }
    }, 9); // Increment every 1 second (you can adjust the interval as needed)

    return () => {
      clearInterval(interval); // Clear the interval when the component unmounts
    };
  }, [number]);

  useEffect(() => {
    const interval = setInterval(() => {
      if (number1 < 150) {
        setNumber1(number1 + 1);
      }
    }, 6); // Increment every 1 second (you can adjust the interval as needed)

    return () => {
      clearInterval(interval); // Clear the interval when the component unmounts
    };
  }, [number1]);

  useEffect(() => {
    const interval = setInterval(() => {
      if (number2 < 12) {
        setNumber2(number2 + 1);
      }
    }, 70); // Increment every 1 second (you can adjust the interval as needed)

    return () => {
      clearInterval(interval); // Clear the interval when the component unmounts
    };
  }, [number2]);

  useEffect(() => {
    const increment = 0.5; // Adjust the increment value as needed
    const maxNumber = 12.5;

    const interval = setInterval(() => {
      if (number3 < maxNumber) {
        setNumber3((prevNumber) => prevNumber + increment);
      } else {
        clearInterval(interval); // Stop the increment when it reaches the max value
      }
    }, 9); // Increment every 1 second (you can adjust the interval as needed)

    return () => {
      clearInterval(interval); // Clear the interval when the component unmounts
    };
  }, [number3]);

  return (
    <>
      <div className="max-w-[1240px] mx-auto py-[2px] mt-9 bg-[#f83edf1c] rounded-lg">
        <div className="text-center m-10">
          <div className="text-[27px]  font-medium mt-5 font-sans">
            <h1 className="mt-5 ">Our Achievements</h1>
          </div>
          <div className="text-3xl font-medium ">
            <hr className="bg-primary h-[5px] my-6 md:w-[350px] mx-auto" />
          </div>
        </div>
        <div className="">
          <div className="  mx-auto md:flex justify-evenly gap-14 items-center">
            {/* 1 div */}
            <div className="m-2">
              <img
                src="https://freepngimg.com/save/66729-google-business-big-analysis-analytics-data/800x800"
                className="md:max-w-xl w-[23rem]"
              />
            </div>
            {/* 2 div */}
            <div className="  grid md:grid-cols-2 gap-16  justify-center">
              <div className="text-primary flex items-center flex-col">
                <h1 className=" text-[49px] font-semibold ">{number}Mn+</h1>
                <span className="text-xl tracking-wide">ASSESSMENTS</span>
              </div>

              <div className="text-primary flex items-center flex-col">
                <h1 className="text-[49px] font-semibold ">{number1}+</h1>
                <span className="text-xl tracking-wide">CUSTOMERS</span>
              </div>

              <div className="text-primary flex items-center flex-col">
                <h1 className="text-[49px] font-semibold ">{number2}+</h1>
                <span className="text-xl tracking-wide">COUNTRIES</span>
              </div>

              <div className="text-primary flex items-center flex-col">
                <h1 className="text-[49px] font-semibold">{number3}Mn+</h1>
                <span className="text-xl tracking-wide">PAPER SAVED</span>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default OurAchievements