/* eslint-disable jsx-a11y/alt-text */
import React from 'react'
import {
  MdOutlineMarkEmailRead,
  MdOutlineComputer,
  MdOutlineMarkChatRead,
} from "react-icons/md";
import { PiNotePencilBold } from "react-icons/pi";


const HowWork = () => {
  return (
    <>

      <div className="p-4 md:grid grid-cols-2 mt-5">
        <div className="flex justify-center items-center">
          <img
            src="https://img.freepik.com/premium-photo/social-media-marketing-agency-concept-designs_891428-528.jpg?size=626&ext=jpg&ga=GA1.1.1413502914.1697414400&semt=ais"
            alt=""
            className="inline w-[33rem]"
          />
        </div>
        <div>
          <div className="max-lg:text-center">
            <button className="btn text-md bg-primary btn-sm text-white hover:bg-">
              How it Works
            </button>
            <h1 className="text-3xl font-semibold leading-[37px] mt-3">
              How it works Using ExamOnline is easy and straightforward. Here's
              how it works:
            </h1>
          </div>
          <div className="md:w-[39rem] max-lg:text-justify">
            <div className=" flex mt-6 gap-6">
              <span>
                <PiNotePencilBold className="mt-1 text-3xl text-primary" />
              </span>
              <p className=" text-lg">
                Create your exam: Set up your exam and customize the proctoring
                settings based on your requirements.
              </p>
            </div>
            <div className=" flex mt-6 gap-6">
              <span>
                <MdOutlineMarkEmailRead className="mt-1 text-3xl text-primary" />
              </span>
              <p className=" text-lg">
                Invite candidates : Send out invitations to your candidates to
                take the exam using ExamOnline.
              </p>
            </div>

            <div className=" flex mt-6 gap-6">
              <span>
                <MdOutlineComputer className="mt-1 text-3xl text-primary" />
              </span>
              <p className="text-lg">
                Monitor in real-time : Our AI-based proctoring technology
                monitors and detects any suspicious activity during the exam in
                real-time.
              </p>
            </div>
            <div className=" flex mt-6 gap-6">
              <span>
                <MdOutlineMarkChatRead className="mt-1 text-3xl text-primary" />
              </span>
              <p className=" text-lg">
                Review the exam : After the exam is completed, review the
                results and monitor data to ensure exam integrity.
              </p>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default HowWork