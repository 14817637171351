
import { useLocation } from "react-router-dom";

const FillBlanksQustionPaper = ({ element, currentQuestion, handleRadioChange, setAnswerData }) => {

    const location = useLocation();
    const searchParams = new URLSearchParams(location.search);
    const exam_email = searchParams.get("exam_email");
    const freelancerID = searchParams.get("freelancerID");
    const exam_course = searchParams.get("exam_course");

    const handelObjective = async (e) => {
        // console.log(e.target.value);
        const answerData = {
            questions: currentQuestion.Questions,
            submit_answer: e.target.value ? e.target.value : '' || currentQuestion.selectedOption || "", // Assuming selectedOption is the answer
            email: exam_email,
            freelancerID: freelancerID,
            exam_course: exam_course,
            empid: currentQuestion.queid
        };
        // console.log(answerData);
        setAnswerData(answerData);

    }

    return (
        <div>
            <form onChange={handelObjective}>
                <div className="flex items-center gap-3 ">
                    <input className="radio radio-primary" type="radio" name="radio" id="fillblank1"
                        checked={currentQuestion.selectedOption === "A"}
                        onChange={(event) => {
                            handleRadioChange("A");
                            // inputEvent(event);
                        }}
                        value={element.OptionA}
                    />
                    <label htmlFor="fillblank1">
                        <div className="label cursor-pointer">
                            <span className="">{element.OptionA}</span>
                        </div>
                    </label>
                </div>
                <div className="flex items-center gap-3 ">
                    <input className="radio radio-primary" type="radio" name="radio" id="fillblank2"
                        checked={currentQuestion.selectedOption === "B"}
                        onChange={(event) => {
                            handleRadioChange("B");
                            // inputEvent(event);
                        }}
                        value={element.OptionB}
                    />
                    <label htmlFor="fillblank2">
                        <div className="label cursor-pointer">
                            <span className="">{element.OptionB}</span>
                        </div>
                    </label>
                </div>
                <div className="flex items-center gap-3 ">
                    <input className="radio radio-primary" type="radio" name="radio" id="fillblank3"
                        checked={currentQuestion.selectedOption === "C"}
                        onChange={(event) => {
                            handleRadioChange("C");
                            // inputEvent(event);
                        }}
                        value={element.OptionC}
                    />
                    <label htmlFor="fillblank3">
                        <div className="label cursor-pointer">
                            <span className="">{element.OptionC}</span>
                        </div>
                    </label>
                </div>

            </form>

        </div>
    );
};

export default FillBlanksQustionPaper;