import React from 'react'

const FaqCollap = () => {
  return (
    <>
      <div className="bg-[#f83edf1c] w-full h-auto">
        <div className="text-center mt-16">
          {/* <div className=""> */}
          <button className="btn mt-14 text-md bg-primary btn-sm m-1 text-white hover:bg-primary">
            Faq
          </button>
          {/* </div> */}
          <div className="text-[18px] font-semibold font-poppins mt-5">
            <h1>Most Frequent Questions and Answers</h1>
          </div>
          <div className="text-3xl font-medium">
            <hr className="bg-primary h-[5px] my-6 md:w-[250px] mx-auto" />
          </div>
        </div>

        <div className="join join-vertical md:w-full mt-9">
          <div className="text-center grid justify-items-center">
            <div
              tabIndex={0}
              className=" p-2 collapse collapse-arrow bg-white md:w-[700px] join-item border border-base-300"
            >
              <input type="checkbox" />
              <div className="collapse-title text-[17px] text-left font-medium">
                What is Remote Proctoring ?
              </div>
              <div className="collapse-content">
                <p className="text-left">
                  Remote Proctoring is a method of monitoring and supervising
                  online exams, where a student is remotely monitored through
                  audio and video technology to ensure the integrity of the
                  examination process.
                </p>
              </div>
            </div>

            <div
              tabIndex={0}
              className="p-2 collapse collapse-arrow bg-white md:w-[700px] join-item border border-base-300"
            >
              <input type="checkbox" />
              <div className="collapse-title text-[17px] text-left font-medium">
                How does Remote Proctoring works?
              </div>
              <div className="collapse-content">
                <p className="text-left">
                  Students log into a proctoring platform, which verifies their
                  identity and monitors them throughout the exam using webcam
                  and microphone. The software flags any suspicious behavior or
                  unusual activities for further investigation.
                </p>
              </div>
            </div>

            <div
              tabIndex={0}
              className="p-2 collapse collapse-arrow bg-white md:w-[700px] join-item border border-base-300 "
            >
              <input type="checkbox" />
              <div className="collapse-title text-[17px] text-left font-medium">
                What are the benefits of proctored exams?
              </div>
              <div className="collapse-content">
                <p className="text-left">
                  Enhanced security, deterrence of cheating, fairness in
                  evaluating student performance, convenience for both students
                  and instructors.
                </p>
              </div>
            </div>

            <div
              tabIndex={0}
              className="p-2 collapse collapse-arrow bg-white md:w-[700px] join-item border border-base-300"
            >
              <input type="checkbox" />
              <div className="collapse-title text-[17px] text-left font-medium">
                Can I take an online exam with remote proctoring on any device?
              </div>
              <div className="collapse-content">
                <p className="text-left">
                  Yes, you can typically take an online exam with remote
                  proctoring on various devices, such as computers, laptops,
                  tablets, and smartphones. However, check the specific
                  requirements and guidelines from the exam provider or remote
                  proctoring platform to ensure compatibility with your chosen
                  device.
                </p>
              </div>
            </div>

            <div
              tabIndex={0}
              className="p-2 collapse collapse-arrow bg-white md:w-[700px] join-item border border-base-300 "
            >
              <input type="checkbox" />
              <div className="collapse-title text-[17px] text-left font-medium">
                Is it easy or difficult to cheat in a remote proctored online
                mode exam?
              </div>
              <div className="collapse-content">
                <p className="text-left">
                  Cheating in a remote proctored online exam is generally more
                  difficult compared to traditional in-person exams. Remote
                  proctoring employs various measures, such as live video
                  monitoring, AI behavior analysis, screen recording, and secure
                  browser integration, to deter and detect cheating attempts.
                  While it is not impossible to cheat, the combination of these
                  measures makes it challenging and significantly reduces the
                  likelihood of successful cheating.
                </p>
              </div>
            </div>
          </div>
        </div>
        <br />
        <br />
        <br />
      </div>
    </>
  );
}

export default FaqCollap