/* eslint-disable jsx-a11y/alt-text */
import React from 'react'
import { PiNotePencilBold, PiFilmStrip } from "react-icons/pi";
import { GoShieldCheck } from "react-icons/go";
import { AiOutlineEye } from "react-icons/ai";
import { BsVolumeUpFill } from "react-icons/bs";

const Features = () => {
  return (
    <>
      <div className="mt-5">
        <div className="max-w-[1240px] flex-row-reverse mx-auto md:flex justify-between items-center ">
          {/* 1 div */}
          <div className="  md:mt-28">
            <img
              src="https://media.istockphoto.com/id/1224590022/vector/office-workers-analyzing-and-researching-business-data.jpg?s=612x612&w=0&k=20&c=lWA3gWVRv9jh-WUrOGMAvAsrkE6O0HNkosd6wcjXQHE="
              className="md:max-w-xl "
            />
          </div>
          {/* 2 div */}
          <div className="p-4 max-w-xl">
            <div className="max-lg:text-center">
              <button className="btn text-md bg-primary btn-sm text-white hover:bg-primary">
                Features
              </button>
              <h1 className="md:text-3xl font-bold leading-[37px] mt-3">
                Our platform offers a range of features that set us apart from
                the competition:
              </h1>
            </div>

            <div className=" flex mt-6 gap-6">
              <span>
                <AiOutlineEye className="mt-1 text-3xl text-primary" />
              </span>
              <p className="text-lg">
                Advanced AI-based technology for real-time monitoring and
                detection of cheating.
              </p>
            </div>

            <div className=" flex mt-6 gap-6">
              <span>
                <GoShieldCheck className="mt-1 text-3xl text-primary" />
              </span>
              <p className=" text-lg">
                Secure browser to prevent candidates from accessing unauthorized
                resources during the exam.
              </p>
            </div>

            <div className=" flex mt-6 gap-6">
              <span>
                <PiNotePencilBold className="mt-1 text-3xl text-primary" />
              </span>
              <p className=" text-lg">
                Customizable proctoring settings to meet your specific exam
                needs.
              </p>
            </div>

            <div className=" flex mt-6 gap-6">
              <span>
                <BsVolumeUpFill className="mt-1 text-3xl text-primary" />
              </span>
              <p className="text-lg">
                High-quality video and audio feeds to ensure accurate and
                reliable monitoring.
              </p>
            </div>

            <div className=" flex mt-6 gap-6 ">
              <span>
                <PiFilmStrip className="mt-1 text-3xl text-primary" />
              </span>
              <p className=" text-lg">
                Seamless integration with your existing learning management
                system (LMS).
              </p>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default Features