import React from "react";
import { RxCross2 } from "react-icons/rx";

const ViewQuestions = ({
  setViewModal,
  viewModal,
  //  queid,
  Course,
  Skills,
  Level,
  Time,
  Questions,
  OptionA,
  OptionB,
  OptionC,
  OptionD,
  OptionE,
  Answer,
  Description,
  Status,
  QuestionType,
}) => {
  return (
    <>
      <input type="checkbox" id="my_modal_10" className="modal-toggle" />
      <div className="modal" role="dialog">
        <div className="modal-box w-11/12 max-w-3xl">
          <h3 className="font-bold text-lg">View Questions/Answers!</h3>

          <form>
            <table className="table m-5 ">
              <tbody className="items-center">
                <tr>
                  <td>
                    <span className="label-text font-bold text-lg">Course</span>
                  </td>
                  <td>{Course}</td>
                </tr>
                <tr>
                  <td>
                    <span className="label-text font-bold text-lg">Skills</span>
                  </td>
                  <td>{Skills}</td>
                </tr>
                <tr>
                  <td>
                    <span className="label-text font-bold text-lg">
                      Questions
                    </span>
                  </td>
                  <td>{Questions}</td>
                </tr>
                <tr>
                  <td>
                    <span className="label-text font-bold text-lg">
                      OptionA
                    </span>
                  </td>
                  <td>{OptionA}</td>
                </tr>
                <tr>
                  <td>
                    <span className="label-text font-bold text-lg">
                      OptionB
                    </span>
                  </td>
                  <td>{OptionB}</td>
                </tr>
                <tr>
                  <td>
                    <span className="label-text font-bold text-lg">
                      OptionC
                    </span>
                  </td>
                  <td>{OptionC}</td>
                </tr>
                <tr>
                  <td>
                    <span className="label-text font-bold text-lg">
                      OptionD
                    </span>
                  </td>
                  <td>{OptionD}</td>
                </tr>
                <tr>
                  <td>
                    <span className="label-text font-bold text-lg">
                      OptionE
                    </span>
                  </td>
                  <td>{OptionE}</td>
                </tr>
                <tr>
                  <td>
                    <span className="label-text font-bold text-lg">Answer</span>
                  </td>
                  <td>{Answer}</td>
                </tr>
                <tr>
                  <td>
                    <span className="label-text font-bold text-lg">
                      Description
                    </span>
                  </td>
                  <td>{Description}</td>
                </tr>
                <tr>
                  <td>
                    <span className="label-text font-bold text-lg">Status</span>
                  </td>
                  <td>{Status}</td>
                </tr>
                <tr>
                  <td>
                    <span className="label-text font-bold text-lg">Time</span>
                  </td>
                  <td>{Time}</td>
                </tr>
                <tr>
                  <td>
                    <span className="label-text font-bold text-lg">
                      QuestionType
                    </span>
                  </td>
                  <td>{QuestionType}</td>
                </tr>
                <tr>
                  <td>
                    <span className="label-text font-bold text-lg">Level</span>
                  </td>
                  <td>{Level}</td>
                </tr>
              </tbody>
            </table>
          </form>
          <div className="modal-action">
            <form method="dialog">
              <button
                onClick={() => setViewModal(!viewModal)}
                className="btn btn-sm btn-circle absolute right-2 top-2"
              >
                ✕
              </button>
            </form>
          </div>

          <form method="dialog">
            <div className="grid justify-items-center">
              <button
                onClick={() => setViewModal(!viewModal)}
                className="btn btn-primary font-bold btn-sm"
              >
                <span>
                  <RxCross2 className="text-lg" />
                </span>
                Cancel
              </button>
            </div>
          </form>
        </div>
      </div>
    </>
  );
};

export default ViewQuestions;
