import React from "react";
import { RiCommunityLine } from "react-icons/ri";
import { BiArchive, BiCart, BiDesktop } from "react-icons/bi";

const Card = () => {
  return (
    <>
      <div className="text-center">
        <div className="">
          <button className="btn text-lg bg-primary btn-sm text-white hover:bg-primary">
            Benefits
          </button>
        </div>
        <div className="text-3xl font-medium mt-5">
          <h1>With Hire Exam, you can enjoy a range of benefits</h1>
        </div>
        <div className="text-3xl font-medium mt-5">
          <hr className="bg-primary h-2 my-9 md:w-[500px] mx-auto" />
        </div>
      </div>

      {/* 1st */}
      <div className="">
        <div className="max-w-[1240px] mx-auto h-auto grid md:grid-cols-4 justify-center">
          <div className="w-72 border-2 shadow-xl flex flex-col p-4 my-4 rounded-lg hover:scale-105 duration-300 max-md:items-center">
            <RiCommunityLine className="text-5xl text-primary" />
            <h2 className="w-52 text-2xl font-bold text-left mt-3  max-md:text-center">
              Improved exam security
            </h2>
            <div className="text-left font-medium">
              <p className=" mt-3">
                Set up your exam and customize the proctoring settings based on
                your requirements.
              </p>
            </div>
          </div>
          {/* 2nd */}
          <div className="w-72 border-2 shadow-xl flex flex-col p-4 my-4 rounded-lg hover:scale-105 duration-300">
            <BiCart className="text-5xl text-primary" />
            <h2 className="w-52 text-2xl font-bold text-left mt-5">
              Enhanced flexibility
            </h2>
            <div className="text-left font-medium">
              <p className=" mt-5">
                Save time and resources with our seamless and user-friendly
                platform.
              </p>
            </div>
          </div>
          {/* 3rd */}
          <div className="w-72 border-2 shadow-xl flex flex-col p-4 my-4 rounded-lg hover:scale-105 duration-300">
            <BiArchive className="text-5xl text-primary" />
            <h2 className="w-52 text-2xl font-bold text-left mt-5">
              Monitor in real-time
            </h2>
            <div className="text-left font-medium">
              <p className=" mt-5">
                Conduct exams anytime, anywhere, with our online proctoring
                solution.
              </p>
            </div>
          </div>
          {/* 4th */}
          <div className="w-72 border-2 shadow-xl flex flex-col p-4 my-4 rounded-lg hover:scale-105 duration-300">
            <BiDesktop className="text-5xl text-primary" />
            <h2 className="w-40 text-2xl font-bold text-left mt-5">
              Reduced costs
            </h2>
            <div className="text-left font-medium">
              <p className=" mt-5">
                Eliminate the need for physical exam centers and reduce
                logistical costs.
              </p>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Card;
