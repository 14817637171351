
import React, { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import axios from "axios";
import useGeteXamUrl from "../../../Hooks/useGeteXamUrl";
import ObjectiveQuestionPaper from "./ObjectiveQuestionPaper";
import TrueFalseQustionPaper from "./TrueFalseQustionPaper";
import FillBlanksQustionPaper from "./FillBlanksQustionPaper";
import SubjectiveQustionPaper from "./SubjectiveQustionPaper";
import CheckBoxesQustionPaper from "./CheckBoxesQustionPaper";
import QuestionPaperProfile from "./QuestionPaperProfile";
import useAxiosPublic from "../../../Hooks/useAxiosPublic";
import "./examQustionsTextDisable.css";
import "./fontStyle.css";
// const sumitanswerURL = "https://examserver.elite-professionals.in/employeeansweradd";
// const sumitanswerURL = "http://localhost:5000/employeeansweradd";

// const updateCandidateExamStatusURL = "https://examserver.elite-professionals.in/updateCandidateExamStatus";
// const updateCandidateExamStatusURL = "http://localhost:5000/updateCandidateExamStatus";

const QuestionPaper = () => {
  const axiousPublic = useAxiosPublic();
  const [questionData, isPending, refetch] = useGeteXamUrl();
  const [timeLeft, setTimeLeft] = useState(() => {
    const savedTime = localStorage.getItem("timeLeft");
    return savedTime ? Number(savedTime) : 3 * 60 * 60;
  });
  const [currentQuestionIndex, setCurrentQuestionIndex] = useState(0);
  const [isWorkspaceVisible, setWorkspaceVisibility] = useState(false);
  const [exam, setExam] = useState([]);
  const [groupTag, setGroupTag] = useState("");
  // const [tabDectCount, setTabDectCount] = useState(1);
  const [questionNumber, setQuestionNumber] = useState(1);
  const [tabDectCountNumber, setTabDectCountNumber] = useState(0);
  const [opacity, setOpacity] = useState(1);
  const navigate = useNavigate();
  const [formAnsData, setAnswerData] = useState({});
  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  const exam_email = searchParams.get("exam_email");
  const password = searchParams.get("password");
  const isLastQuestion = currentQuestionIndex + 1 === exam?.length;

  useEffect(() => {
    const taskbarInfo = window.electronAPI?.getTaskbarWindows();
    console.log(taskbarInfo);
  }, []);

  useEffect(() => {
    const fetchData = async () => {
      try {
        if (questionData && questionData?.length > 0) {
          const matchingQustion = questionData.filter(
            (element) => element.Course === groupTag
          );
          setExam(matchingQustion);
          console.log(matchingQustion)
        }
      } catch (error) {
        console.error("Error fetching exam data:", error);
      }
    };
    fetchData();
  }, [questionData, groupTag]);

  useEffect(() => {
    // console.log(formAnsData);
    axiousPublic.get(`/allEmployeeAnswerDetail/${exam_email}/${groupTag}`)
      .then(async (res) => {
        try {
          if (res?.data?.result?.length <= currentQuestionIndex) {
            if (formAnsData.submit_answer === undefined) {
              return;
            } else {
              console.log('Submitting new answer:', formAnsData.submit_answer);
              try {
                const response = await axiousPublic.post('/employeeansweradd', formAnsData);
                console.log('Response from /employeeansweradd:', response);
                setAnswerData({
                  questions: '',
                  submit_answer: undefined,
                  email: exam_email,
                  freelancerID: '',
                  exam_course: '',
                  empid: undefined
                });

                console.log('next');
              } catch (error) {
                console.error('Error in handling employee answer:', error);
              }
            }
          } else {
            if (formAnsData.submit_answer === undefined) {
              return;
            } else {
              console.log('Updating existing answer:', formAnsData.submit_answer);
              const response = await axiousPublic.put('/updateEmployeeanswer', formAnsData);
              console.log('Response from /updateEmployeeanswer:', response);
              setAnswerData({
                questions: '',
                submit_answer: undefined,
                email: exam_email,
                freelancerID: '',
                exam_course: '',
                empid: undefined
              });
              console.log('back');
            }
          }
        } catch (error) {
          console.error('Error in handling employee answer:', error);
        }
      })
      .catch(error => {
        console.error('Error fetching employee answer details:', error);
      });
  }, [axiousPublic, currentQuestionIndex, formAnsData, exam_email, groupTag]);

  const handleRadioChange = (option) => {
    setExam((prevExam) =>
      prevExam?.map((question, index) =>
        index === currentQuestionIndex
          ? { ...question, selectedOption: option }
          : question
      )
    );
  };

  const renderCurrentQuestion = () => {
    const currentQuestion = exam[currentQuestionIndex];
    // console.log(currentQuestion)
    if (!currentQuestion) return null;

    switch (currentQuestion.QuestionType) {
      case "Objective":
        return (
          <ObjectiveQuestionPaper
            element={currentQuestion}
            currentQuestion={currentQuestion}
            handleRadioChange={handleRadioChange}
            setAnswerData={setAnswerData}
          />
        );
      case "TrueFalse":
        return (
          <TrueFalseQustionPaper
            element={currentQuestion}
            currentQuestion={currentQuestion}
            handleRadioChange={handleRadioChange}
            setAnswerData={setAnswerData}
          />
        );
      case "FillBlanks":
        return (
          <FillBlanksQustionPaper
            element={currentQuestion}
            currentQuestion={currentQuestion}
            handleRadioChange={handleRadioChange}
            setAnswerData={setAnswerData}
          />
        );
        return (
          <SubjectiveQustionPaper
            element={currentQuestion}
            currentQuestion={currentQuestion}
            setAnswerData={setAnswerData}
            handleRadioChange={handleRadioChange}
            formAnsData={formAnsData}
          />
        );
      case "CheckBoxes":
        return (
          <CheckBoxesQustionPaper
            element={currentQuestion}
            currentQuestion={currentQuestion}
            handleRadioChange={handleRadioChange}
            setAnswerData={setAnswerData}
          />
        );
      default:
        return null;
    }
  };

  const nextQuestion = () => {
    setCurrentQuestionIndex(currentQuestionIndex + 1);
    setQuestionNumber(currentQuestionIndex + 2);
  };

  const finishQuestion = async () => {
    await axiousPublic.post(`/employeeansweradd`, formAnsData);
    await axiousPublic.put(`/updateCandidateExamStatus`, {
      exam_status: "completed",
      exam_email: exam_email,
      password: password,
    });
    // navigate("/thankyou");
  };

  const backQuestion = () => {
    if (currentQuestionIndex > 0) {
      setCurrentQuestionIndex(currentQuestionIndex - 1);
      setQuestionNumber(currentQuestionIndex + 1 - 1);
    }
  };

  const clearQuestion = () => {
    if (!isPending) {
      setExam((prevExam) =>
        prevExam.map((question, index) =>
          index === currentQuestionIndex
            ? { ...question, selectedOption: "" }
            : question
        )
      );
    }
  };

  const formatTime = (timeInSeconds) => {
    const hours = Math.floor(timeInSeconds / 3600);
    const minutes = Math.floor((timeInSeconds % 3600) / 60);
    const seconds = timeInSeconds % 60;

    return `${String(hours).padStart(2, "0")}:${String(minutes).padStart(
      2,
      "0"
    )}:${String(seconds).padStart(2, "0")}`;
  };

  const selectQuestion = (questionNumber) => {
    if (questionNumber >= 1 && questionNumber <= exam?.length) {
      setCurrentQuestionIndex(questionNumber - 1);
      setWorkspaceVisibility(false);
    }
    setQuestionNumber(questionNumber);
  };

  // const redirectToThankYou = async () => {
  //   await axiousPublic.put(`/updateCandidateExamStatus`, {
  //     exam_status: "completed",
  //     exam_email: exam_email,
  //     password: password,
  //   });
  //   navigate("/thankyou");
  // };

  useEffect(() => {
    const interval = setInterval(() => {
      setTimeLeft((prevTime) => {
        const newTime = prevTime - 1;
        localStorage.setItem("timeLeft", newTime);
        if (newTime <= 0) {
          clearInterval(interval);
          // redirectToThankYou();
        }
        return newTime;
      });
    }, 1000);

    return () => clearInterval(interval);
  }, []);

  useEffect(() => {
    if (timeLeft === 0) {
      // redirectToThankYou();
    }
  }, [timeLeft]);



  // useEffect(() => {
  //   const handleBeforeUnload = (e) => {
  //     const confirmationMessage = "Are you sure you want to leave this page?";
  //     (e || window.event).returnValue = confirmationMessage;
  //     return confirmationMessage;
  //   };

  //   const handleUnload = () => {
  //     console.log("The page is being unloaded.");
  //   };

  //   const handleVisibilityChange = () => {
  //     if (document.hidden) {
  //       alert(
  //         "You left this tab. You have one last chance. If you leave again, we will finish your exam."
  //       );
  //       setTabDectCountNumber((prevCount) => prevCount + 1);
  //       console.log("The tab is not active.");
  //     } else {
  //       console.log("The tab is active.");
  //     }
  //   };

  //   window.addEventListener("beforeunload", handleBeforeUnload);
  //   window.addEventListener("unload", handleUnload);
  //   document.addEventListener("visibilitychange", handleVisibilityChange);

  //   return () => {
  //     window.removeEventListener("beforeunload", handleBeforeUnload);
  //     window.removeEventListener("unload", handleUnload);
  //     document.removeEventListener("visibilitychange", handleVisibilityChange);
  //   };
  // }, []);

  // useEffect(() => {
  //   if (tabDectCountNumber === 4) {
  //     navigate("/thankyou");
  //   }
  // }, [tabDectCountNumber, navigate]);

  // const hideContainer = () => setOpacity(0);
  // const showContainer = () => setOpacity(1);

  // useEffect(() => {
  //   const handleVisibilityChange = () => {
  //     if (document.hidden) {
  //       hideContainer();
  //       // console.log('blur')
  //     } else {
  //       showContainer();
  //       // console.log('show')
  //       setTabDectCountNumber((prevCount) => prevCount + 1);
  //     }
  //   };

  //   document.addEventListener("visibilitychange", handleVisibilityChange);
  //   window.addEventListener("blur", hideContainer);
  //   window.addEventListener("focus", showContainer);

  //   return () => {
  //     document.removeEventListener("visibilitychange", handleVisibilityChange);
  //     window.removeEventListener("blur", hideContainer);
  //     window.removeEventListener("focus", showContainer);
  //   };
  // }, []);

  const [windows, setWindows] = useState([]);

  // useEffect(() => {
  //   const fetchTaskbarInfo = () => {
  //     axios.get('http://localhost:5024/api/TaskbarInfo')
  //       .then(response => {
  //         // console.log(response.data);
  //         setWindows(response.data);
  //       })
  //       .catch(error => {
  //         console.error("There was an error fetching the taskbar information!", error);
  //       });
  //   };

  //   fetchTaskbarInfo();

  //   const intervalId = setInterval(fetchTaskbarInfo, 5000);

  //   return () => clearInterval(intervalId);
  // }, []);

  return (
    <>
      {windows.length < 1 ? (
        <div
          id="protected-container"
          style={{
            opacity,
            transition: "opacity 0.5s ease",
            width: "100%",
            height: "100vh",
            backgroundColor: "lightgray",
          }}
          className="hidden lg:inline"
        >
          <div class="flex QustionTextDisable gap-10 justify-center py-5">
            <div class="box-border h-[36rem]">
              <div className="flex items-center gap-5">
                <div className="bg-slate-200 mt-1 rounded-md h-auto md:w-[48rem] box-border p-3 ml-3">
                  <p>
                    <span className="text-xl QustionTextDisable font-bold flex justify-start items-center">
                      {currentQuestionIndex + 1}.{" "}
                      {exam
                        .slice(
                          0 + currentQuestionIndex,
                          currentQuestionIndex + 1
                        )
                        .map((qustion) => (
                          <h1> {qustion.Questions}</h1>
                        ))}
                    </span>
                  </p>
                </div>
              </div>
              <div className="p-3 ml-7 text-lg">
                {renderCurrentQuestion()}
                <div className="flex md:justify-end gap-3 mt-2">
                  <label onClick={backQuestion} className="btn">
                    Back
                  </label>
                  <button>
                    {isLastQuestion ? (
                      <span onClick={finishQuestion} className="btn">
                        Finish
                      </span>
                    ) : (
                      <span onClick={nextQuestion} className="btn">
                        Next
                      </span>
                    )}
                  </button>
                  <label onClick={clearQuestion} className="btn">
                    Clear
                  </label>
                </div>
              </div>
            </div>
            <div className="">
              <div className="mb-5">
                <QuestionPaperProfile
                  setGroupTag={setGroupTag}
                ></QuestionPaperProfile>
              </div>
              <div className="box-border h-24 w-[18rem] flex items-center justify-center flex-col border-2 border-black">
                <h1 className="font-bold">Time left: {formatTime(timeLeft)}</h1>
                <p>Welcome Test Preview</p>
              </div>
              <div class="grid grid-cols-6 gap-2 p-2 mt-5 gap-y-[10px] w-72">
                {exam.map((question, index) => (
                  <span
                    className={`${index + 1 === questionNumber
                        ? "bg-red-400 text-white"
                        : "bg-slate-200 text-black"
                      } rounded-md`}
                  >
                    <h1
                      className={`${question.selectedOption
                          ? "bg-red-500 text-white"
                          : "text-black"
                        } text-center rounded-md h-[29px] cursor-pointer`}
                      onClick={() => selectQuestion(index + 1)}
                    >
                      {index + 1}
                    </h1>
                  </span>
                ))}
              </div>
            </div>
          </div>
        </div>
      ) : (
        <div className="flex flex-col mt-10 justify-start items-center">
          <h1 className="text-xl font-bold">
            Please close this software form your Windows Taskbar
          </h1>
          <ul className="mt-5">
            {windows.map((window, index) => (
              <li className="mt-2 text-lg" key={index}>
                <span className="font-bold">{index + 1}. </span>
                {window}
              </li>
            ))}
          </ul>
          <h1 className="text-xl font-bold mt-10">
            If close all software and tools then click this{" "}
            <button
              className="btn go-home text-primary"
              onClick={() => window.location.reload()}
            >
              Relod Paige
            </button>
          </h1>
        </div>
      )}
    </>
  );
};

export default QuestionPaper;

