import axios from "axios";
import React, { useCallback, useEffect, useState } from "react";
import { logError, logInfo } from "../../../Elasticsearchinstance";
import useAxiosPublic from "../../../Hooks/useAxiosPublic";


// const sendEmailURL = "http://localhost:8083/api/v1/email";
const sendEmailURL = "https://emaillocalbe.airepro.in/api/v1/email";

const ExamCreation = () => {
  const axiousPublic = useAxiosPublic();

  // get data Instruction
  const [getinstruction, setGetInstruction] = useState([]);
  const fetchData = useCallback(async () => {
    try {
      const { data } = await axiousPublic.get(`/getinstruction`);
      setGetInstruction(data.result);
      logInfo("fetch instruction data", data);
    } catch (error) {
      logError("Error fetching instruction data", error);
    }
  }, [axiousPublic]);

  useEffect(() => {
    fetchData();
  }, [fetchData]);


  // get data Question paper

  const [getquestions, setGetQuestions] = useState([]);
  const fetchData1 = useCallback(async () => {
    try {
      const { data } = await axiousPublic.get(`/getquestionbank`);
      setGetQuestions(data.result);
      logInfo("fetch question data", data);
    } catch (error) {
      logError("Error fetching question data", error);
    }
  }, [axiousPublic]);

  useEffect(() => {
    fetchData1();
  }, [fetchData1]);


  // fetch data by candidate
  const [getcandidates, setGetCandidates] = useState([]);
  const fetchData2 = useCallback(async () => {
    try {
      const { data } = await axiousPublic.get(`/getCandidateUsers`);
      setGetCandidates(data.result);
      logInfo("fetch candidate data", data);
    } catch (error) {
      logError("Error fetching candidate data", error);
    }
  }, [axiousPublic]);

  useEffect(() => {
    fetchData2();
  }, [fetchData2]);


  const [selectedCourse, setSelectedCourse] = useState("");
  const [filteredData, setFilteredData] = useState([]);
  const [selectedExperience, setSelectedExperience] = useState("");

  const [selectedExamLevel, setSelectedExamLevel] = useState("");
  const [selectedExamGroup, setSelectedExamGroup] = useState("");
  const [selectedInstruction, setSelectedInstruction] = useState("");

  const changehandler = (e) => {
    setSelectedCourse(e.target.value);
  };

  const searchHandler = () => {
    filterData(selectedCourse, selectedExperience);
    const examLevelValue = document.querySelector('select[name="level"]').value;
    const examGroupValue = document.querySelector(
      'select[name="examgroup"]'
    ).value;
    const instructionValue = document.querySelector(
      'select[name="instruction"]'
    ).value;

    setSelectedExamLevel(examLevelValue);
    setSelectedExamGroup(examGroupValue);
    setSelectedInstruction(instructionValue);
  };

  function filterData(group_tag, experience) {
    let filtered = [...getcandidates];
    if (group_tag && group_tag.trim()) {
      filtered = filtered.filter((item) => item.group_tag === group_tag);
    }
    if (experience && experience.trim()) {
      filtered = filtered.filter((item) => item.experience === experience);
    }
    setFilteredData(filtered);
  }

  // single select checkbox
  const [checkedItems, setCheckedItems] = useState({});

  const [atLeastOneChecked, setAtLeastOneChecked] = useState(false);
  const handleCheckboxChange = (index) => {
    setCheckedItems((prevState) => ({
      ...prevState,
      [index]: !prevState[index],
    }));

    const isChecked = !checkedItems[index];
    const anyChecked = Object.values({
      ...checkedItems,
      [index]: isChecked,
    }).some((value) => value);
    setAtLeastOneChecked(anyChecked);
  };

  // all select checkbox
  const [selectAllChecked, setSelectAllChecked] = useState(false);
  const handleSelectAllChange = (e) => {
    const checked = e.target.checked;
    setSelectAllChecked(checked);
    const newCheckedItems = {};
    filteredData.forEach((_, index) => {
      newCheckedItems[index] = checked;
    });
    setCheckedItems(newCheckedItems);
  };

  useEffect(() => {
    if (selectAllChecked) {
      const newCheckedItems = {};
      filteredData.forEach((_, index) => {
        newCheckedItems[index] = true;
      });
      setCheckedItems(newCheckedItems);
    }
  }, [selectAllChecked]);

  const formSubmit = async (e) => {
    e.preventDefault();
    const checkedCandidates = filteredData.filter(
      (_, index) => checkedItems[index]
    );

    // Log the start of the submission process
    logInfo('Exam form submission started for candidates', checkedCandidates);

    // filteredData.forEach(async (candidate) => {
    checkedCandidates.forEach(async (candidate) => {
      const candi_data = {
        email_id: candidate.email,
        freelancerl_id: candidate.freelancerID,
        exam_course: selectedExamGroup,
        // exam_paper: selectedExamPaper,
        exam_level: selectedExamLevel,
        exam_instruction: selectedInstruction,
      };

      try {
        const res = await axiousPublic.post(`/candidateexampost`, candi_data);
        console.log(res);
        // Log the successful response
        logInfo('Candidate exam data submitted', { candidate, response: res.data });

        alert("Add this Students Records in Exam Creation Successfully!");
        // window.location.reload();
      } catch (error) {
        console.error(
          "Error submitting Add this Students Records data:",
          error
        );
        // Log the error
        logError('Error submitting Add this Students Records data', error);
      }

    });
    // window.location.reload();
  };

  // send mail
  const handleMailButtonClick = async () => {
    if (!atLeastOneChecked) {
      alert("Please select at least one candidate to send mail.");
      return;
    }
    const selectedCandidates = filteredData.filter(
      (_, index) => checkedItems[index]
    );

    // Log the start of the mail sending process
    logInfo('Mail sending started for candidates', selectedCandidates);

    let successfulSends = 0; // Track successful email sends
    for (const candidate of selectedCandidates) {
      const mail_data = {
        // from_email: candidate.email,
        // to_email: candidate.email,
        // temp: "Exam Registration",
        // first_name: candidate.email,
        // last_name: candidate.password,
        // 
        templateId: 19,
        to: candidate.email,
        placeholders: {Name: candidate.email, Paasword: candidate.password, }, //Username: "rugi", URL: "jkiuyuhui"
        // subject: subject,
        // 
      };
      console.log("mail_data", mail_data);
      try {
        const res = await axios.post(sendEmailURL, mail_data);
        console.log(res);
        // Log the successful mail send response
        logInfo('Mail sent successfully', { candidate, response: res.data })

        await axiousPublic.post(`/postCandidateExamStatus`, {
          exam_status: "undefine",
          exam_email: candidate.email,
          exam_course: selectedExamGroup,
          password: candidate.password,
          freelancerID: candidate.freelancerID,
        });

        successfulSends++; // Increment successful sends counter
        // alert("Mail Sent Succesfully!");
        //  window.location.reload();
      } catch (error) {
        console.error("Error Mail Sending data:", error);
        // Log the error
        logError('Error sending mail', error);
      }
      // });
    }
    if (successfulSends === selectedCandidates.length) {
      alert("Mail Sent Successfully!");
      // Optionally, you can reload the page after the alert
      // window.location.reload();
    }
  };

  return (
    <>
      <div className="md:grid grid-cols-2 gap-5">
        <div>
          <div className="text-2xl p-2 font-bold bg-primary text-center text-white rounded-t-xl">
            Exam
          </div>
          <table className="table bg-base-300 shadow-xl rounded-lg">
            <tbody className=" ">
              <div>
                <tr>
                  <td>
                    <span className="label-text font-bold text-lg">
                      Set Exam Level
                    </span>
                  </td>
                  <td>
                    <select
                      name="level"
                      required
                      className="md:w-[19rem] select select-bordered text-black select-sm"
                    >
                      <option>Select Level</option>
                      <option value="0-1 yrs">0-1 yrs</option>
                      <option value="1-2 yrs">1-2 yrs</option>
                      <option value="1-3 yrs">1-3 yrs</option>
                      <option value="0-4 yrs">0-4 yrs</option>
                      <option value="1-4 yrs">1-4 yrs</option>
                    </select>
                  </td>
                </tr>
                <tr>
                  <td>
                    <span className="label-text font-bold text-lg ">
                      Set Exam Course
                    </span>
                  </td>
                  <td>
                    <select
                      name="examgroup"
                      className="select select-bordered text-black select-sm w-full max-w-md "
                      required
                    >
                      <option>Select Course</option>
                      <option value="Java Developer">Java Developer</option>
                      <option value="Node Js">Node Js</option>
                      <option value="React Js">React Js</option>
                      <option value="Angular Js">Angular Js</option>
                      <option value="Vue Js">Vue Js</option>
                      <option value="Php">Php</option>
                    </select>
                  </td>
                </tr>

                <tr>
                  <td>
                    <span className="label-text font-bold text-lg ">
                      Set Exam Instruction
                    </span>
                  </td>
                  <td>
                    <select
                      name="instruction"
                      className="select select-bordered text-black select-sm w-full max-w-md "
                      required
                    >
                      <option>Select Instruction</option>
                      {getinstruction &&
                        getinstruction.map((instruction, index) => (
                          <option value={instruction.title}>
                            {instruction.title}
                          </option>
                        ))}
                    </select>
                  </td>
                </tr>
              </div>
            </tbody>
          </table>
        </div>

        <div>
          <div className="text-2xl p-2 font-bold bg-primary text-center text-white rounded-t-xl">
            Candidate
          </div>
          <table className="table bg-base-300 shadow-xl rounded-lg">
            <tbody className="">
              <div>
                <tr className="">
                  <td className="">
                    <span className="label-text font-bold text-lg">
                      Candidate Experience
                    </span>
                  </td>
                  <td>
                    <select
                      name="experience"
                      onChange={(e) => setSelectedExperience(e.target.value)}
                      className="md:w-[19rem] select select-bordered text-black select-sm"
                      required
                    >
                      <option>Select Experience</option>
                      <option value="0-1 yrs">0-1 yrs</option>
                      <option value="1-2 yrs">1-2 yrs</option>
                      <option value="1-3 yrs">1-3 yrs</option>
                      <option value="0-4 yrs">0-4 yrs</option>
                      <option value="1-4 yrs">1-4 yrs</option>
                    </select>
                  </td>
                </tr>
                <tr>
                  <td>
                    <span className="label-text font-bold text-lg ">
                      Candidate Job Profile
                    </span>
                  </td>
                  <td>
                    <select
                      name="group_tag"
                      onChange={changehandler}
                      value={selectedCourse}
                      className="select select-bordered text-black select-sm w-full max-w-md "
                      required
                    >
                      <option>Select Course</option>
                      <option value="Java Developer">Java Developer</option>
                      <option value="Node Js">Node Js</option>
                      <option value="React Js">React Js</option>
                      <option value="Angular Js">Angular Js</option>
                      <option value="Vue Js">Vue Js</option>
                      <option value="Php">Php</option>
                    </select>
                  </td>
                </tr>
              </div>
            </tbody>
          </table>
        </div>

        <div className=" p-5 text-center col-span-2">
          <button
            type="button"
            onClick={searchHandler}
            className="text-base font-bold btn bg-primary hover:bg-primary text-white border-2 border-primary"
          >
            Load students record to add Exam
          </button>
        </div>
      </div>

      <div className="m-3 justify-self-center bg-base-300 shadow-xl rounded-lg ">
        <div className="overflow-x-auto mt-7">
          <table className="table  table-zebra">
            <thead>
              <tr className="bg-primary text-white">
                <th>
                  <label>
                    <input
                      type="checkbox"
                      className="checkbox border-2 bg-white border-blue-400"
                      checked={selectAllChecked}
                      onChange={handleSelectAllChange}
                    />
                  </label>
                </th>
                <th className="font-extrabold">Freelancer ID</th>
                <th className="font-extrabold">Candidate Job Profile</th>
                <th className="font-extrabold">Candidate Experience</th>
                <th className="font-extrabold">Candidate Name</th>
                <th className="font-extrabold">Candidate Email</th>
                {/* <th className="font-extrabold">Address</th>
                <th className="font-extrabold">Mobile No</th> */}
                {/* <th className="font-extrabold">Salary Expectation</th> */}
                {/* <th className="font-extrabold">Date</th>
                <th className="font-extrabold">Time</th> */}
                <th className="font-extrabold">Exam Level</th>
                <th className="font-extrabold">Exam Group</th>
                {/* <th className="font-extrabold">Exam Paper</th> */}
                <th className="font-extrabold">Exam Instruction</th>
                {/* <th className="font-extrabold border-r-2 border-slate-400">
                  Action
                </th> */}
              </tr>
            </thead>
            {filteredData.length > 0 ? (
              filteredData.map((getcandidates, index) => (
                <>
                  <tbody key={index}>
                    <tr>
                      <th>
                        <label>
                          <input
                            type="checkbox"
                            className="checkbox border-2 bg-white border-blue-400"
                            checked={checkedItems[index] || false}
                            onChange={() => handleCheckboxChange(index)}
                          />
                        </label>
                      </th>
                      <td>{getcandidates.freelancerID}</td>
                      <td>{getcandidates.group_tag}</td>
                      <td>{getcandidates.experience}</td>
                      <td>{getcandidates.name}</td>
                      <td>{getcandidates.email}</td>
                      {/* <td>{getcandidates.address}</td>
                      <td>{getcandidates.phone_number}</td> */}
                      {/* <td>{getcandidates.salary_expectation}</td> */}
                      {/* <td>{selectedDate}</td>
                      <td>{selectedTime}</td> */}
                      <td>{selectedExamLevel}</td>
                      <td>{selectedExamGroup}</td>
                      {/* <td>{selectedExamPaper}</td> */}
                      <td>{selectedInstruction}</td>
                      {/* <td>
                        <button
                          onClick={() => handleMailButtonClick(getcandidates)}
                          className="btn btn-sm text-base bg-primary hover:bg-primary font-bold text-white"
                        >
                          Mail
                         </button>
                      </td> */}
                    </tr>
                  </tbody>
                </>
              ))
            ) : (
              <>
                <td colSpan="15" className="text-black text-center">
                  No data found.
                </td>
              </>
            )}
          </table>
          <div className=" p-5 text-center flex gap-5 justify-center">
            <button
              onClick={formSubmit}
              disabled={!atLeastOneChecked}
              className="text-base font-bold btn bg-primary hover:bg-primary text-white"
            >
              Add this students record
            </button>
            <button
              // onClick={() => handleMailButtonClick(getcandidates)}
              onClick={handleMailButtonClick}
              disabled={!atLeastOneChecked} // Disable button if no checkbox is checked
              className="text-base font-bold btn bg-primary hover:bg-primary text-white"
            >
              Send Mail
            </button>
          </div>
        </div>
      </div>
    </>
  );
};

export default ExamCreation;
