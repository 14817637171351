import React from "react";
import { RxCross2 } from "react-icons/rx";

const ViewInstruction = ({
  setViewModal,
  viewModal,
    title,
    content,
}) => {
  return (
    <>
      <input type="checkbox" id="my_modal_10" className="modal-toggle" />
      <div className="modal" role="dialog">
        <div className="modal-box w-11/12 max-w-4xl">
          <h3 className="font-bold text-lg">View Instruction!</h3>

          <form>
            <div className="form-control p-9 gap-5">
              {/* 1st div */}
              <div className="flex gap-12 items-center">
                <label htmlFor="" className="label-text font-bold text-lg">
                  {title}
                </label>
               
              </div>
              <div className="flex gap-5 items-center">
                <label htmlFor="" className="label-text text-lg">
                  {content}
                </label>
                
              </div>
              
            </div>

            
          </form>
          {/* <div className=" "> */}
          <form method="dialog">
            <button
              onClick={() => setViewModal(!viewModal)}
              className="btn btn-outline font-bold btn-sm"
            >
              <span>
                <RxCross2 className="text-lg" />
              </span>
              Cancel
            </button>
          </form>
          {/* </div> */}
        </div>
      </div>
    </>
  );
};

export default ViewInstruction;
