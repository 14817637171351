import React, { useEffect, useState } from "react";
import { AiOutlineEye, AiOutlineEyeInvisible } from "react-icons/ai";
import { Link, useNavigate } from "react-router-dom";
import QuestionPaper from "./QuestionPaper";
import useAxiosPublic from "../../../Hooks/useAxiosPublic";

const Screen = () => {
  const axiousPublic = useAxiosPublic();
  const [showPassword, setShowPassword] = useState(true);
  const [exam_email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const navigate = useNavigate();

  const [loggedInEmail, setLoggedInEmail] = useState(""); // New state variable to hold logged-in email
  const [examStatus, setExamStatus] = useState(""); // State variable to hold the exam status

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      const response = await axiousPublic.post(`/loginCandidateExamStatus`, {
        // const response = await axios.post(loginURL, {
        exam_email,
        password,
      });
      // console.log("emailpassboth", response);

      if (response.data.success) {
        // console.log("Login successful", response.data.result);

        const examStatusResponse = await axiousPublic.post(`/getCandidateExamStatus`, {
        // const examStatusResponse = await axios.post(getCandidateExamStatusURL, {
          exam_email: response.data.result[0].exam_email,
          password: password,
        });

        // console.log("examStatus123", examStatusResponse.data.result[0].exam_status);
        // console.log("freelancerID123", examStatusResponse.data.result[0].freelancerID);
        // console.log("exam_course123", examStatusResponse.data.result[0].exam_course);

        if (examStatusResponse.data.result[0].exam_status === "undefine") {
          await axiousPublic.put(`/updateCandidateExamStatus`, {
            // await axios.put(updateCandidateExamStatusURL, {
            exam_status: "pending",
            exam_email: exam_email,
            // exam_course: exam_course,
            password: password,
          });
          console.log("undefine");
          navigate(`/hardwarecheck?exam_email=${exam_email}&password=${password}&freelancerID=${examStatusResponse.data.result[0].freelancerID}&exam_course=${examStatusResponse.data.result[0].exam_course}`);
        } else if (examStatusResponse.data.result[0].exam_status === "pending") {
          navigate(`/hardwarecheck?exam_email=${exam_email}&password=${password}&freelancerID=${examStatusResponse.data.result[0].freelancerID}&exam_course=${examStatusResponse.data.result[0].exam_course}`);
          console.log("pending");
        } else if (examStatusResponse.data.result[0].exam_status === "completed") {
          navigate("/thankyou");
        } else {
          // Handle other scenarios if needed
        }
      } else {
        console.log("Login failed: ", response.data.message);
        alert("Email does not exists. Please use a different email.");
      }
    } catch (error) {
      console.error("Error during login: ", error);
      alert("Email does not exists. Please use a different email.");
    }
  };

  return (
    <>
      <div className="hidden lg:inline">
        <div className="grid grid-cols-2 max-h-screen overflow-hidden">
          <div className="hero min-h-screen" style={{ backgroundImage: 'url(https://i.ibb.co/SR5h1mt/1e59e0b6ecadbc32046d80ad42ec3921.jpg)' }}>
            <div className="hero-overlay bg-white bg-opacity-60"></div>
            <div className="hero-content text-start text-neutral-content">
              <div className="text-black text-2xl font-normal max-w-2xl min-h-screen p-5 mx-auto">
                <p>
                  Thank you for showing interest in the STE india Exam at Hire Exam.
                  We hope you have a great time taking this assessment. Answer the
                  questions within this assessment to the best of your ability.
                  <br />
                  <br />
                  Before you start the assessment make sure to:
                </p>

                <li>
                  Take up this assessment on a laptop or desktop rather than on a
                  mobile phone
                </li>
                <li>
                  Close all other applications and browser tabs to ensure no
                  distractions.
                </li>
                <li>
                  Block time to start and finish the assessment in one go. Please
                  make sure you are not interrupted
                </li>
                <br />
                <br />
                <p>
                  All the best! <br />
                  This test is active from: <br />
                  <span className="font-bold">
                    Mon, 30 Jan 2023 12:00:00 to Tue,28 Feb 2023 12:00:00
                  </span>
                </p>

              </div>
            </div>
          </div>
          <div className="flex flex-col justify-center items-center">

            <div className=" font-bold px-12 text-6xl mb-8 drop-shadow-lg ">
              <span className="text-[#9b0186]">Hire</span>
              <span className="text-primary	font-bold">Exam</span>
            </div>
            <div className="w-full">
              <form onSubmit={handleSubmit} className="flex flex-col max-w-sm mx-auto ">
                {/* <div className="form-control w-full max-w-full mx-auto mb-4"> */}
                <div className="form-control text-black font-bold w-full">
                  <label className="label">Email</label>
                  <input
                    type="email"
                    name="exam_email"
                    value={exam_email}
                    required
                    placeholder="Enter your Email"
                    onChange={(e) => setEmail(e.target.value)}
                    className="input input-bordered max-w-sm"
                  />
                </div>
                <div className="form-control text-black font-bold max-w-sm relative">
                  <label className="label">Password </label>
                  <input
                    type={showPassword ? "password" : "text"}
                    name="password"
                    required
                    placeholder="Enter Your Password"
                    onChange={(e) => setPassword(e.target.value)}
                    className="input input-bordered max-w-sm"
                  />
                  <span className="label-text-alt absolute top-14 right-2 ">
                    <label className="swap">
                      <input
                        type="checkbox"
                        onClick={() => setShowPassword(!showPassword)}
                      />

                      <AiOutlineEye className="text-xl swap-on" />

                      <AiOutlineEyeInvisible className="text-xl swap-off" />
                    </label>
                  </span>

                  <br />
                  <button className="btn bg-blue-600 hover:bg-blue-600 text-white text-lg font-semibold max-w-sm">
                    Go to Assessment
                  </button>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Screen;
