
import React, { useEffect, useState } from "react";
import { BiSolidEdit } from "react-icons/bi";
import { RxCross2 } from "react-icons/rx";
import { logError, logInfo } from "../../../Elasticsearchinstance";

import useAxiosPublic from "../../../Hooks/useAxiosPublic";


const EditCandidate = () => {
  const axiousPublic = useAxiosPublic();

  const [val, changeVal] = useState("");
  const [datas, changeDatas] = useState([]);
  function changehandler(e) {
    changeVal(e.target.value);
  }

  const fetchdata = async (e) => {
    e.preventDefault();
    if (!val.trim()) {
      alert("Please Type Freelancer ID");
      return;
    }
    try {
      const { data } = await axiousPublic.get(`${`/getCandidateUsers`}/${val}`);
      logInfo("fetch candidate data", data);

      if (data.result.length > 0) {
        changeDatas(data.result);
      } else {
        alert("No data found.");
      }
    } catch (error) {
      console.error("Error fetching data:", error);
      logError("Error fetching data:", error);
      alert("Error fetching data. Please try again.");
    }
  };

  // async function fetchdata(e) {
  //   e.preventDefault();

  //   // Assuming 'val' is the input field value
  //   if (!val.trim()) {
  //     // If the input field is blank, show an alert
  //     alert("Please Type Freelancer ID");
  //     return;
  //   }

  //   try {
  //     let data = await fetch(`${getCandidateURL}/${val}`);

  //     if (data.ok) {
  //       let jsonData = await data.json();
  //       console.log(jsonData);
  //       logInfo("fetch candidate data", jsonData);
  //       if (jsonData.result.length > 0) {
  //         // Data is loaded, update UI
  //         changeDatas(jsonData.result);
  //       } else {
  //         // Data not found, show alert
  //         alert("No data found.");
  //       }
  //     } else {
  //       // Handle non-successful response
  //       alert(`Error: ${data.status} - ${data.statusText}`);
  //     }
  //   } catch (error) {
  //     // Handle network or other errors
  //     console.error("Error fetching data:", error);
  //     logError("Error fetching data:", error);
  //     alert("Error fetching data. Please try again.");
  //   }
  // }


  // dynamic data ke lie
  const [previousData, setPreviousData] = useState({});
  const [data, setData] = useState({
    freelancerID: "",
    name: "",
    email: "",
    address: "",
    phone_number: "",
    job_profile: "",
    tech_skills: "",
    group_tag: "",
    status: "",
    enrollment_number: "",
    experience: "",
    salary_expectation: "",
    education: "",
  });
  useEffect(() => {
    // Update the state for the previous data when datas changes
    if (datas.length > 0) {
      setPreviousData(datas[0]);
      setData(datas[0]);
    }
  }, [datas]);

  // dynamic data ke lie
  const inputEvent = (event) => {
    const { name, value } = event.target;
    setData((preVal) => {
      return {
        ...preVal,
        [name]: value,
      };
    });
  };

  const formSubmit = async (e) => {
    // alert("hello");
    e.preventDefault();
    const candi_data = {
      freelancerID: data.freelancerID || val,
      name: data.name,
      email: data.email,
      address: data.address,
      phone_number: data.phone_number,
      job_profile: data.job_profile,
      tech_skills: data.tech_skills,
      group_tag: data.group_tag,
      status: data.status,
      enrollment_number: data.enrollment_number,
      experience: data.experience,
      salary_expectation: data.salary_expectation,
      education: data.education,
    };
    // Log the data before submission
    logInfo("Attempting to update candidate data", candi_data);

    try {
      const res = await axiousPublic.put(`/updateCandidateUsers`, candi_data);
      // const res = await axios.put(updatecandidatedetailsURL, candi_data);
      // Log the response data
      logInfo("Candidate data updated", res.data);

      console.log(res);
      alert("Candidate data Updated!");
      // navigate("/candidate");
      window.location.reload();
    } catch (error) {
      console.error("Error submitting candidate data:", error);
      // Log the error
      logError("Error submitting candidate data", error);
    }
  };

  return (
    <>
      <button
        onClick={() => document.getElementById("my_modal_12").showModal()}
      >
        Edit
      </button>
      <dialog id="my_modal_12" className="modal">
        <div className="modal-box w-11/12 max-w-4xl">
          <h3 className="font-bold text-black text-2xl">Edit Candidate!</h3>

          <form onSubmit={formSubmit}>
            {/* <form> */}
            <label className=" join mt-7 flex ml-24">
              <input
                className="input input-bordered join-item text-black input-sm"
                placeholder="Freelancer ID"
                required
                onChange={changehandler}
                // value={a.name}
                value={val}
              />
              <button
                type="submit"
                onClick={(e) => fetchdata(e)}
                className="btn btn-sm join-item"
              >
                Search
              </button>
            </label>
            <div className="form-control flex flex-row justify-evenly">
              {/* 1st div */}
              {datas &&
                datas.map((a) => (
                  <div>
                    <label className="mt-6 flex gap-5 justify-end ">
                      <span className="label-text font-bold text-sm">Name</span>
                      <input
                        type="text"
                        name="name"
                        required
                        placeholder="Enter Your Name"
                        className="input input-bordered text-black input-sm"
                        value={data.name || a.name}
                        onChange={inputEvent}
                      />
                    </label>

                    <label className="mt-6 flex gap-5 justify-end">
                      <span className="label-text font-bold text-sm">
                        Email
                      </span>
                      <input
                        type="email"
                        name="email"
                        placeholder="Enter Your Email"
                        className="input input-bordered text-black input-sm"
                        value={data.email || a.email}
                        onChange={inputEvent}
                      />
                    </label>
                    <label className="mt-6 flex gap-5 justify-end">
                      <span className="label-text font-bold text-sm">
                        Address
                      </span>
                      <input
                        type="text"
                        name="address"
                        placeholder="Enter Your Address"
                        className="input input-bordered text-black input-sm"
                        value={data.address || a.address}
                        onChange={inputEvent}
                      />
                    </label>

                    <label className="mt-6 flex gap-5 justify-end">
                      <span className="label-text font-bold text-sm">
                        Mobile No
                      </span>
                      <input
                        type="number"
                        name="phone_number"
                        placeholder="Enter Your Mobile No."
                        className="input input-bordered text-black input-sm"
                        value={data.phone_number || a.phone_number}
                        onChange={inputEvent}
                      />
                    </label>

                    <label className="mt-6 flex gap-5 justify-end">
                      <span className="label-text font-bold text-sm">
                        Job Profile
                      </span>
                      <input
                        type="text"
                        name="job_profile"
                        placeholder="Enter Your Job Profile"
                        className="input input-bordered text-black input-sm"
                        value={data.job_profile || a.job_profile}
                        onChange={inputEvent}
                      />
                    </label>
                    <label className="mt-6 flex gap-5 justify-end">
                      <span className="label-text font-bold text-sm">
                        Tech Skills
                      </span>
                      <input
                        type="text"
                        name="tech_skills"
                        placeholder="Enter Your Skills"
                        className="input input-bordered text-black input-sm"
                        value={data.tech_skills || a.tech_skills}
                        onChange={inputEvent}
                      />
                    </label>
                  </div>
                ))}

              {/* 2nd div */}
              {datas &&
                datas.map((a) => (
                  <div>
                    <label className="mt-6 flex gap-5 justify-end">
                      <span className="label-text font-bold text-sm">
                        Group
                      </span>
                      <select
                        name="group_tag"
                        value={data.group_tag || a.group_tag}
                        onChange={inputEvent}
                        className="select select-bordered text-black select-sm w-[198px] max-w-xs"
                      >
                        <option>Select Course</option>
                        <option value="Java Developer">Java Developer</option>
                        <option value="Node Js">Node Js</option>
                        <option value="React Js">React Js</option>
                        <option value="Angular Js">Angular Js</option>
                        <option value="Vue Js">Vue Js</option>
                        <option value="Php">Php</option>
                      </select>
                    </label>
                    <label className="mt-6 flex gap-5 justify-end">
                      <span className="label-text font-bold text-sm">
                        Status
                      </span>

                      <select
                        name="status"
                        value={data.status || a.status}
                        onChange={inputEvent}
                        className="select select-bordered text-black select-sm w-[198px] max-w-xs"
                      >
                        <option value="">Select Status</option>
                        <option value="Active">Active</option>
                        <option value="Pending">Pending</option>
                        <option value="Suspend">Suspend</option>
                      </select>
                    </label>

                    <label className="mt-6 flex gap-5 justify-end">
                      <span className="label-text font-bold text-sm">
                        Enrollment no
                      </span>
                      <input
                        type="text"
                        name="enrollment_number"
                        placeholder=""
                        className="input input-bordered text-black input-sm"
                        value={data.enrollment_number || a.enrollment_number}
                        onChange={inputEvent}
                      />
                    </label>

                    <label className="mt-6 flex gap-5 justify-end">
                      <span className="label-text font-bold text-sm">
                        Experience
                      </span>
                      {/* <input
                        type="text"
                        name="experience"
                        placeholder="Enter Your Experience"
                        className="input input-bordered text-black input-sm"
                        value={data.experience || a.experience}
                        onChange={inputEvent}
                      /> */}
                      <select
                        name="experience"
                        value={data.experience || a.experience}
                        onChange={inputEvent}
                        className="select select-bordered text-black select-sm w-[198px] max-w-xs"
                      >
                        <option>Select Experience</option>
                        <option value="0-1 yrs">0-1 yrs</option>
                        <option value="1-2 yrs">1-2 yrs</option>
                        <option value="1-3 yrs">1-3 yrs</option>
                        <option value="0-4 yrs">0-4 yrs</option>
                        <option value="1-4 yrs">1-4 yrs</option>
                      </select>
                    </label>
                    <label className="mt-6 flex gap-5 justify-end">
                      <span className="label-text font-bold text-sm">
                        Salary Expectation
                      </span>
                      <input
                        type="text"
                        name="salary_expectation"
                        placeholder="Enter Your Salary Expectation"
                        className="input input-bordered text-black input-sm"
                        value={data.salary_expectation || a.salary_expectation}
                        onChange={inputEvent}
                      />
                    </label>
                    <label className="mt-6 flex gap-5 justify-end">
                      <span className="label-text font-bold text-sm">
                        Education
                      </span>
                      <input
                        type="text"
                        name="education"
                        placeholder="Enter Your Education"
                        className="input input-bordered text-black input-sm"
                        value={data.education || a.education}
                        onChange={inputEvent}
                      />
                    </label>
                  </div>
                ))}
            </div>
            <div className="flex ml-16 mt-9 gap-3 ">
              <button
                type="submit"
                className="btn btn-success font-bold text-white"
              >
                <span>
                  <BiSolidEdit className="text-lg" />
                </span>
                Update
              </button>

              <form method="dialog">
                <button className="btn btn-error font-bold text-white">
                  <span>
                    <RxCross2 className="text-lg" />
                  </span>
                  Cancel
                </button>
              </form>
            </div>
          </form>

          <div className="modal-action">
            <form method="dialog">
              <button className="btn btn-sm btn-circle absolute right-2 top-2">
                ✕
              </button>
            </form>
          </div>
        </div>
      </dialog>
    </>
  );
};

export default EditCandidate;
