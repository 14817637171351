import React from 'react'
import ReactStars from "react-rating-stars-component";
import './fontStyle.css'
import { Link } from 'react-router-dom';

const ratingChanged = (newRating) => {
  console.log(newRating);
};

const Thanku = (props) => {
  return (
    <>
      <div className='content thankYouPage'>
        <div className="wrapper-1">
          <div className="wrapper-2">
            <h1 className=' text-primary'>Thank you !</h1>
            <p>YOU HAVE SUCCESSFULLY <span className='text-primary'>COMPLETED</span> THE ASSIGNMENT </p>
            <p className="mb-2 text-2xl">RATE YOUR EXPRREANCE</p>
            <div className=' w-full flex justify-center items-center'>
              <ReactStars
                count={5}
                onChange={ratingChanged}
                size={75}
                isHalf={true}
                emptyIcon={<i className="far fa-star "></i>}
                halfIcon={<i className="fa fa-star-half-alt"></i>}
                fullIcon={<i className="fa fa-star"></i>}
                activeColor="#ffd700"
              />
            </div>
            <Link to={`/`}>
              <button className="go-home text-primary">
                go home
              </button>
            </Link>
          </div>

        </div>
      </div>
    </>
  );
};

export default Thanku