/* eslint-disable jsx-a11y/anchor-is-valid */
import React from "react";
// import { NavLink } from 'react-router-dom';
import { FaHome, FaCalendarAlt, FaThList } from "react-icons/fa";
import { MdNoteAlt, MdGroups2 } from "react-icons/md";
import { PiBookOpenTextFill } from "react-icons/pi";
import { BiSolidPencil } from "react-icons/bi";
import { TiTick } from "react-icons/ti";
import { SiJetpackcompose } from "react-icons/si";
import { NavLink, useNavigate } from "react-router-dom";

const Sidebar = () => {
  const navigate = useNavigate();
  const Roll = JSON.parse(localStorage.getItem('HireInfo'))

  console.log(Roll);

  if (Roll?.value === null) {
    navigate('/')
  }


  return (
    <>
      <div className="drawer lg:drawer-open z-10">
        <input id="my-drawer-2" type="checkbox" className="drawer-toggle" />
        <div className="drawer-content flex flex-col items-center justify-start">
          <label
            htmlFor="my-drawer-2"
            className="btn btn-primary drawer-button lg:hidden"
          >
            {/* Open Dashboard */}
          </label>
        </div>
        <div className="drawer-side">
          <label
            htmlFor="my-drawer-2"
            aria-label="close sidebar"
            className="drawer-overlay"
          ></label>
          {
            Roll.accountType === 'Admin' && <ul className="menu p-3 block w-72 bg-[#fd03dc13] text-base-content text-base font-medium min-h-full">
              <li>
                <NavLink to="/dashboard ">
                  <span>
                    <FaHome className="text-lg text-black" />
                  </span>
                  Dashboard
                </NavLink>
              </li>
              <li>

                <details open>
                  <summary>
                    <span>
                      <MdNoteAlt className="text-lg text-black" />
                    </span>
                    Question Bank
                  </summary>
                  <ul>
                    <li>
                      <NavLink to="/QuestionBank">Add New</NavLink>
                    </li>
                    <li>
                      <NavLink to="/ImportQuestion">Import Question</NavLink>
                    </li>
                    <li>
                      <NavLink to="/Instructions">Instruction</NavLink>
                    </li>
                    <li>
                      <NavLink to="/ViewQuestionBank">View</NavLink>
                    </li>
                  </ul>
                </details>
                {/*  */}
              </li>
              <li>
                <details open>
                  <summary>
                    <span>
                      <PiBookOpenTextFill className="text-lg text-black" />
                    </span>
                    Exam Creation
                  </summary>
                  <ul>
                    <li>
                      <NavLink to="/ExamCreation">Add Exam Creation</NavLink>
                    </li>
                    <li>
                      <NavLink to="/ViewExamCreation">View Exam Creation</NavLink>
                    </li>
                  </ul>
                </details>
              </li>
              <li>
                <a>
                  <span>
                    <FaCalendarAlt className="text-lg text-black" />
                  </span>
                  Exam Schedule
                </a>
              </li>
              <li>
                <NavLink to="/candidate">
                  <span>
                    <MdGroups2 className="text-lg text-black" />
                  </span>
                  Candidates
                </NavLink>
              </li>
              <li>
                <a>
                  <span>
                    <BiSolidPencil className="text-lg text-black" />
                  </span>
                  Examiner
                </a>
              </li>
              <li>
                <NavLink to="/result">
                  <span>
                    <TiTick className="text-lg text-black" />
                  </span>
                  Result
                </NavLink>
              </li>
              <li>
                <a>
                  <span>
                    <SiJetpackcompose className="text-lg text-black" />
                  </span>
                  Communication
                </a>
              </li>
              <li>
                <a>
                  <span>
                    <FaThList className="text-lg text-black" />
                  </span>
                  Organization
                </a>
              </li>
              {/* screen */}
              <li>
                <NavLink to="/screen">
                  <span>
                    <FaThList className="text-lg text-black" />
                  </span>
                  Examinee Onboard
                </NavLink>
              </li>
              {/* <li>
              <NavLink to="/hardwarecheck">
                <span>
                  <FaThList className="text-lg text-black" />
                </span>
                Hardware Check
              </NavLink>
            </li> */}
              {/* <li>
              <NavLink to="/instructions">
                <span>
                  <FaThList className="text-lg text-black" />
                </span>
                Instructions
              </NavLink>
            </li> */}
              {/* <li>
              <NavLink to="/readinstructions">
                <span>
                  <FaThList className="text-lg text-black" />
                </span>
                Read Instructions
              </NavLink>
            </li> */}
              {/* <li>
              <NavLink to="/thankyou">
                <span>
                  <FaThList className="text-lg text-black" />
                </span>
                Thank you
              </NavLink>
            </li> */}
              {/* <li>
              <NavLink to="/question-paper">
                <span>
                  <FaThList className="text-lg text-black" />
                </span>
                Question Paper
              </NavLink>
            </li> */}
            </ul>
          }
          {
            Roll.accountType === 'Custom Creator' && <ul className="menu p-3 block w-72 bg-base-300 text-base-content text-base font-medium min-h-full">
              <li>
                <NavLink to="/dashboard ">
                  <span>
                    <FaHome className="text-lg text-black" />
                  </span>
                  Custom Creator
                </NavLink>
              </li>
              <li>

                <details open>
                  <summary>
                    <span>
                      <MdNoteAlt className="text-lg text-black" />
                    </span>
                    Question Bank
                  </summary>
                  <ul>
                    <li>
                      <NavLink to="/QuestionBank">Add New</NavLink>
                    </li>
                    <li>
                      <NavLink to="/ImportQuestion">Import Question</NavLink>
                    </li>
                    <li>
                      <NavLink to="/Instructions">Instruction</NavLink>
                    </li>
                    <li>
                      <NavLink to="/ViewQuestionBank">View</NavLink>
                    </li>
                  </ul>
                </details>
                {/*  */}
              </li>
            </ul>
          }
          {
            Roll.accountType === 'Invisilator' && <ul className="menu p-3 block w-72 bg-base-300 text-base-content text-base font-medium min-h-full">
              <li>
                <NavLink to="/dashboard ">
                  <span>
                    <FaHome className="text-lg text-black" />
                  </span>
                  Invisilator
                </NavLink>
              </li>
              <li>
                <NavLink to="/screen">
                  <span>
                    <FaThList className="text-lg text-black" />
                  </span>
                  Examinee Onboard
                </NavLink>
              </li>
            </ul>
          }

        </div>
      </div>
    </>
  );
};

export default Sidebar;
