/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useState } from "react";
import { AiOutlineMenu, AiOutlineClose, AiFillCaretDown } from "react-icons/ai";
import { Link } from "react-router-dom";
import Login from "../Login/Login";
import SignUp from "../Signup/SignUp";

const Header = () => {
  const [toggle, setToggle] = useState(false);
  return (
    <div className="bg-[#feffff] w-full py-[9px] sticky top-0 z-10">
      <div className="max-w-[1240px] py-[12px] items-center flex justify-between mx-auto">
        <div className="text-3xl font-bold">
          <Link to="/">
            <a className=" text-4xl ">
              <span className="text-[#9b0186]">Hire</span>
              <span className="text-primary	font-bold">Exam</span>
            </a>
          </Link>
        </div>

        {toggle ? (
          <AiOutlineClose
            onClick={() => setToggle(!toggle)}
            className="text-black text-2xl md:hidden block"
          />
        ) : (
          <AiOutlineMenu
            onClick={() => setToggle(!toggle)}
            className="text-black text-2xl md:hidden block"
          />
        )}

        <ul className="hidden md:flex text-black font-semibold font-poppins text-lg gap-5">
          <li className="mt-3">
            <div className="dropdown dropdown-hover relative">
              <div className="join cursor-pointer hover:text-primary">
                <label className="join-item cursor-pointer">Solutions</label>
                <AiFillCaretDown className="join-item mt-[6px] ml-[2px]" />
              </div>

              <ul className="dropdown-content z-[1] menu p-2 bg-base-100 w-64 text-md">
                <li>
                  <a>Online Exam Solution</a>
                </li>
                <li>
                  <a>Remote Proctoring Solution</a>
                </li>
                <li>
                  <a>Proctoring as a Service</a>
                </li>
              </ul>
            </div>
          </li>
          <li className="mt-3">Product and Services</li>
          <li className="mt-3">
            <div className="dropdown dropdown-hover relative">
              <div className="join cursor-pointer hover:text-primary">
                <label className="join-item cursor-pointer">Resources</label>
                <AiFillCaretDown className="join-item mt-[6px] ml-[2px]" />
              </div>
              <ul className="dropdown-content z-[1] menu p-2 shadow bg-base-100 w-64 text-md">
                <li>
                  <a href="https://elite-professionals.in/about-us/">
                    About Us
                  </a>
                </li>
                <li>
                  <Link to="/Blog">
                    <a>Blog</a>
                  </Link>
                </li>
                <li>
                  <a href="https://learning.elite-professionals.in/">
                    Learning
                  </a>
                </li>
              </ul>
            </div>
          </li>

          <Login />

          <SignUp />

          {/* <li className="mt-3">
            <button className="btn btn-accent btn-sm">Log Out</button>
          </li> */}
        </ul>

        {/* responsive menu */}
        <ul
          className={`duration-300 md:hidden w-full h-screen text-white fixed bg-black top-[92px] 
                   ${toggle ? "left-[0]" : "left-[-100%]"}
              `}
        >
          <li className="p-3">Solutions</li>
          <li className="p-3">Partners</li>
          <li className="p-3">Resources</li>
          <li>
            <button className="btn btn-warning btn-md m-1">Sign In</button>
          </li>
          <li>
            <button className="btn btn-secondary btn-md m-1">Join Now</button>
          </li>
          {/* <li className="p-5">About</li>
          <li className="p-5">Contact</li> */}
        </ul>
      </div>
    </div>
  );
};

export default Header;
