import { useLocation } from "react-router-dom";

const TrueFalseQustionPaper = ({ element, currentQuestion, handleRadioChange, setAnswerData }) => {

    const location = useLocation();
    const searchParams = new URLSearchParams(location.search);
    const exam_email = searchParams.get("exam_email");
    const freelancerID = searchParams.get("freelancerID");
    const exam_course = searchParams.get("exam_course");

    const handelObjective = async (e) => {
        const answerData = {
            questions: currentQuestion.Questions,
            submit_answer: e.target.value ? e.target.value : '' || currentQuestion.selectedOption || "", // Assuming selectedOption is the answer
            email: exam_email,
            freelancerID: freelancerID,
            exam_course: exam_course,
            empid: currentQuestion.queid
        };
        setAnswerData(answerData);

    }


    return (
        <div>

            <form onChange={handelObjective} >
                <div className="flex items-center gap-3 ">
                    <input
                        type="radio"
                        name="submit_answer"
                        className="radio radio-primary text-primary "
                        checked={currentQuestion.selectedOption === "0"}
                        onChange={(event) => {
                            handleRadioChange("0");
                            // inputEvent(event);
                        }}
                        value='True'
                        id="seletion1"
                    />
                    <label htmlFor="seletion1">
                        <div className="label cursor-pointer">
                            <span className="">

                                True
                            </span>
                        </div>
                    </label>
                </div>

                <div className="flex items-center gap-3">
                    <input
                        type="radio"

                        name="submit_answer"
                        className="radio radio-primary"
                        checked={currentQuestion.selectedOption === "1"}
                        onChange={(event) => {
                            handleRadioChange("1");
                            // inputEvent(event);
                        }}
                        value='False'
                        id="seletion2"
                    />
                    <label htmlFor="seletion2">
                        <div className="label cursor-pointer">
                            <span className="">False</span>
                        </div>
                    </label>
                </div>
            </form>


        </div>
    );
};

export default TrueFalseQustionPaper;