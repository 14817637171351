import React from 'react'
import Banner from './Banner'
import Card from './Card'
import Security from './Security'
import Testimonial from './Testimonial'
import FaqCollap from './FaqCollap'
import ChampionData from './ChampionData'
import BottomFooter from './BottomFooter'
import HowWork from './HowWork'
import Features from './Features'
import OurAchievements from './OurAchievements'
import Footer from '../Footer/Footer'
import Header from '../Navbar/Header'

const Home = () => {

  return (
    <div>
      <Header />
      <Banner />
      <ChampionData />
      <Card />
      <HowWork />
      <Features />
      <Security />
      <OurAchievements />
      <Testimonial />
      <FaqCollap />
      <BottomFooter />
      <Footer />
    </div>
  );
}

export default Home