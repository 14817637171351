import Home from "./Component/Home/Home";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import ContactUs from "./Component/ContactUs/ContactUs";
import Login from "./Component/Login/Login";
import Blog from "./Component/Blogs/Blog";
import Dashboard from "./Component/Dashboard/Dashboard";
import Candidate from "./Component/Dashboard/Candidate/Candidate";
import HomeDashobard from "./Component/Dashboard/HomeDashobard";
import QuestionBank from "./Component/Dashboard/QuestionBank/QuestionBank";
import ViewQuestionBank from "./Component/Dashboard/QuestionBank/ViewQuestionBank";
import ExamCreation from "./Component/Dashboard/ExamCreation/ExamCreation";
import ViewExamCreation from "./Component/Dashboard/ExamCreation/ViewExamCreation";
import Screen from "./Component/Dashboard/Screen/Screen";
import Hardwarecheck from "./Component/Dashboard/Screen/Hardwarecheck";
import Instructions from "./Component/Dashboard/Screen/Instructions";
import ReadInstructions from "./Component/Dashboard/Screen/ReadInstructions";
import Thanku from "./Component/Dashboard/Screen/Thanku";
import QuestionPaper from "./Component/Dashboard/Screen/QuestionPaper";
import ImportQuestion from "./Component/Dashboard/QuestionBank/ImportQuestion";
import Instruction from "./Component/Dashboard/QuestionBank/Instruction";
import Results from "./Component/Dashboard/Results/Results";
import PrivateRoute from "./Component/PrivateRoute";
import TermsAndConditions from "./Component/Signup/TermsAndConditions"
import PrivacyPolicy from "./Component/Signup/PrivacyPolicy"

function App() {
  return (
    <div>
      <BrowserRouter>
        <Routes>
          <Route path="/">
            <Route index element={<Home />} />
            <Route path="/" element={<PrivateRoute><Dashboard /></PrivateRoute>}>
              <Route path="/dashboard" element={<PrivateRoute><HomeDashobard /></PrivateRoute>} />
              <Route path="/candidate" element={<PrivateRoute><Candidate /></PrivateRoute>} />
              <Route path="/QuestionBank" element={<PrivateRoute><QuestionBank /></PrivateRoute>} />
              <Route path="/ImportQuestion" element={<PrivateRoute><ImportQuestion /></PrivateRoute>} />
              <Route path="/Instructions" element={<PrivateRoute><Instruction /></PrivateRoute>} />
              <Route path="/ViewQuestionBank" element={<PrivateRoute><ViewQuestionBank /></PrivateRoute>} />
              <Route path="/ExamCreation" element={<PrivateRoute><ExamCreation /></PrivateRoute>} />
              <Route path="/ViewExamCreation" element={<PrivateRoute><ViewExamCreation /></PrivateRoute>} />
              <Route path="/result" element={<PrivateRoute><Results /></PrivateRoute>} />
            </Route>
            <Route path="Home" element={<Home />} />
            <Route path="ContactUs" element={<ContactUs />} />
            <Route path="Login" element={<Login />} />
            <Route path="Blog" element={<Blog />} />
          </Route>
          <Route path="/screen" element={<Screen />} />
          <Route path="/hardwarecheck" element={<Hardwarecheck />} />
          <Route path="/viewinstructions" element={<Instructions />} />
          <Route path="/readinstructions" element={<ReadInstructions />} />
          <Route path="/thankyou" element={<Thanku />} />
          <Route path="/question-paper" element={<QuestionPaper />} />
          <Route path="/terms&condition" element={<TermsAndConditions />} />
          <Route path="/privacy-policy" element={ <PrivacyPolicy /> } />
        </Routes>
      </BrowserRouter>
    </div>
  );
}

export default App;
