
const TermsAndConditions = () => {
    return (
        <div className="mx-auto container px-4">
            <div>
                <style
                    dangerouslySetInnerHTML={{
                        __html:
                            '\n<!--\n /* Font Definitions */\n @font-face\n\t{font-family:"Cambria Math";\n\tpanose-1:2 4 5 3 5 4 6 3 2 4;}\n /* Style Definitions */\n p.MsoNormal, li.MsoNormal, div.MsoNormal\n\t{margin:0in;\n\tline-height:115%;\n\tfont-size:11.0pt;\n\tfont-family:"Arial",sans-serif;}\n.MsoChpDefault\n\t{font-family:"Arial",sans-serif;}\n.MsoPapDefault\n\t{line-height:115%;}\n@page WordSection1\n\t{size:8.5in 11.0in;\n\tmargin:1.0in 1.0in 1.0in 1.0in;}\ndiv.WordSection1\n\t{page:WordSection1;}\n /* List Definitions */\n ol\n\t{margin-bottom:0in;}\nul\n\t{margin-bottom:0in;}\n-->\n',
                    }}
                />
                <div className="WordSection1">
                    <p
                        className="MsoNormal text-3xl font-bold"
                        style={{
                            marginTop: "12.0pt",
                            marginRight: "0in",
                            marginBottom: "12.0pt",
                            marginLeft: "0in",
                        }}>
                        <span lang="EN" className="text-3xl font-bold">Terms and Conditions</span>
                    </p>
                    <p
                        className="MsoNormal"
                        style={{
                            marginTop: "12.0pt",
                            marginRight: "0in",
                            marginBottom: "12.0pt",
                            marginLeft: "0in",
                        }}>
                        <span lang="EN">PURPOSE:</span>
                    </p>
                    <p
                        className="MsoNormal"
                        style={{
                            marginTop: "12.0pt",
                            marginRight: "0in",
                            marginBottom: "12.0pt",
                            marginLeft: "0in",
                        }}>
                        <span lang="EN">
                            Work-elite is intended only to serve as a preliminary medium of
                            contact and exchange of information for its users / members /
                            visitors who have a bona fide intention to contact and/or be
                            contacted for the purposes related to genuine existing job
                            vacancies and for other career enhancement services.
                        </span>
                    </p>
                    <p
                        className="MsoNormal"
                        style={{
                            marginTop: "12.0pt",
                            marginRight: "0in",
                            marginBottom: "12.0pt",
                            marginLeft: "0in",
                        }}>
                        <b>
                            <span lang="EN">USE TO BE IN CONFORMITY WITH THE PURPOSE</span>
                        </b>
                    </p>
                    <p
                        className="MsoNormal"
                        style={{
                            marginTop: "12.0pt",
                            marginRight: "0in",
                            marginBottom: "12.0pt",
                            marginLeft: "0in",
                        }}>
                        <span lang="EN">
                            Work-elite (and related products) or service or product that is
                            subscribed to or used (whether the same is paid for by you or not)
                            is meant for the Purpose&nbsp;{" "}
                            <b>
                                and only the exclusive use of the subscriber/registered user.{" "}
                            </b>
                            Copying or downloading or recreating or sharing passwords or sub
                            licensing or sharing in any manner which is not in accordance with
                            these terms, <b>is a misuse</b> of the platform or service or
                            product and IEIL reserves its rights to act in such manner as to
                            protect its loss of revenue or reputation or claim damages
                            including stopping your service or access and reporting to
                            relevant authorities. In the event you are found to be copying or
                            misusing or transmitting or crawling any data or photographs or
                            graphics or any information available on Work-elite for any
                            purpose other than that being a bonafide Purpose, we reserve the
                            right to take such action that we deem fit including stopping
                            access and claiming damages
                        </span>
                    </p>
                    <p
                        className="MsoNormal"
                        style={{
                            marginTop: "12.0pt",
                            marginRight: "0in",
                            marginBottom: "12.0pt",
                            marginLeft: "0in",
                        }}>
                        <span lang="EN">
                            The site is a public site with free access and Airepro assumes no
                            liability for the quality and genuineness of responses. Airepro
                            (India) Ltd. cannot monitor the responses that a person may
                            receive in response to information he/she has displayed on the
                            site. The individual/company would have to conduct its own
                            background checks on the bonafide nature of all response(s).
                        </span>
                    </p>
                    <p
                        className="MsoNormal"
                        style={{
                            marginTop: "12.0pt",
                            marginRight: "0in",
                            marginBottom: "12.0pt",
                            marginLeft: "0in",
                        }}>
                        <span lang="EN">
                            You give us permission to use the information about actions that
                            you have taken on Work-elite in connection with ads, offers and
                            other content (whether sponsored or not) that we display across
                            our services, without any compensation to you. We use data and
                            information about you to make relevant suggestions and
                            recommendations to you and others.
                        </span>
                    </p>
                    <p
                        className="MsoNormal"
                        style={{
                            marginTop: "12.0pt",
                            marginRight: "0in",
                            marginBottom: "12.0pt",
                            marginLeft: "0in",
                        }}>
                        <span lang="EN">
                            The platform may contain links to third party websites, these
                            links are provided solely as convenience to You and the presence
                            of these links should not under any circumstances be considered as
                            an endorsement of the contents of the same, if You chose to access
                            these websites you do so at your own risk.
                        </span>
                    </p>
                    <p
                        className="MsoNormal"
                        style={{
                            marginTop: "12.0pt",
                            marginRight: "0in",
                            marginBottom: "12.0pt",
                            marginLeft: "0in",
                        }}>
                        <span lang="EN">
                            Whilst using this platform an obligation is cast upon you to only
                            provide true and correct information and in the case of creating a
                            profile you undertake to at all times keep the information up to
                            date. Airepro (India) Ltd. will not be liable on account of any
                            inaccuracy of information on this web site. It is the
                            responsibility of the visitor to further research the information
                            on the site. Any breach of privacy or of the information provided
                            by the consumer to Airepro (India) Ltd. to be placed on the
                            website by technical or any other means is not the responsibility
                            of Airepro (India) Ltd. Airepro (India) Ltd. does not guarantee
                            confidentiality of information provided to it by any person
                            acquiring/using all/any information displayed on the work-elite
                            website or any of its other websites / domains owned and operated
                            by Airepro (India) Ltd.
                        </span>
                    </p>
                    <p
                        className="MsoNormal"
                        style={{
                            marginTop: "12.0pt",
                            marginRight: "0in",
                            marginBottom: "12.0pt",
                            marginLeft: "0in",
                        }}>
                        <span lang="EN">
                            Airepro (India) Ltd. does not share personally identifiable data
                            of any individual with other companies / entities without
                            obtaining permission except with those acting as our agents..
                            Airepro (India) Ltd. shall share all such information that it has
                            in its possession in response to legal process, such as a court
                            order or subpoena. The user shall not utilize the services offered
                            by work-elite/IEIL in any manner so as to impair the interests and
                            functioning of work-elite/IEIL. The user undertakes not to
                            duplicate, download, publish, modify and distribute material on
                            work-elite unless specifically authorized by IEIL in this regard.
                        </span>
                    </p>
                    <p
                        className="MsoNormal"
                        style={{
                            marginTop: "12.0pt",
                            marginRight: "0in",
                            marginBottom: "12.0pt",
                            marginLeft: "0in",
                        }}>
                        <span lang="EN">
                            The user undertakes to use work-elite for his/her own purposes.
                            Using content from work-elite for derivative works with a
                            commercial motive without prior written consent from IEIL is
                            strictly prohibited.
                        </span>
                    </p>
                    <p
                        className="MsoNormal"
                        style={{
                            marginTop: "12.0pt",
                            marginRight: "0in",
                            marginBottom: "12.0pt",
                            marginLeft: "0in",
                        }}>
                        <span lang="EN">
                            Users undertake that the services offered by work-elite/ IEIL
                            shall not be utilized to upload, post, email, transmit or
                            otherwise make available either directly or indirectly, any
                            unsolicited bulk e-mail or unsolicited commercial e-mail. IEIL
                            reserves the right to filter and monitor and block the emails sent
                            by you/user using the servers maintained by IEIL to relay emails.
                            All attempts shall be made by IEIL and the user to abide by
                            International Best Practices in containing and eliminating Spam.
                        </span>
                    </p>
                    <p
                        className="MsoNormal"
                        style={{
                            marginTop: "12.0pt",
                            marginRight: "0in",
                            marginBottom: "12.0pt",
                            marginLeft: "0in",
                        }}>
                        <span lang="EN">
                            Users shall not spam the platform maintained by work-elite / IEIL
                            or indiscriminately and repeatedly post jobs/forward mail
                            indiscriminately etc. Any conduct of the user in violation of this
                            clause shall entitle IEIL to forthwith terminate all services to
                            the user without notice and to forfeit any amounts paid by him.
                        </span>
                    </p>
                    <p
                        className="MsoNormal"
                        style={{
                            marginTop: "12.0pt",
                            marginRight: "0in",
                            marginBottom: "12.0pt",
                            marginLeft: "0in",
                        }}>
                        <span lang="EN">
                            The user shall not upload, post, transmit, publish, or distribute
                            any material or information that is unlawful, or which may
                            potentially be perceived as being harmful, threatening, abusive,
                            harassing, defamatory, libelous, vulgar, obscene, or racially,
                            ethnically, or otherwise objectionable.
                        </span>
                    </p>
                    <p
                        className="MsoNormal"
                        style={{
                            marginTop: "12.0pt",
                            marginRight: "0in",
                            marginBottom: "12.0pt",
                            marginLeft: "0in",
                        }}>
                        <span lang="EN">
                            The user expressly states that the resume/insertion or
                            information/ data being fed into the network of Airepro (India)
                            Ltd. by the user is correct and complete in all respects and does
                            not contain any false, distorted, manipulated, fraudulent or
                            misleading facts or averments. Airepro (India) Ltd. expressly
                            disclaims any liability arising out of the said resume
                            insertion/information/ data so fed into the network of Airepro
                            (India) Ltd. by the user. Further, the user agrees to indemnify
                            Airepro (India) Ltd. for all losses incurred by Airepro (India)
                            Ltd. due to any false, distorted, manipulated, defamatory,
                            libelous, vulgar, obscene, fraudulent or misleading facts or
                            otherwise objectionable averments made by the user on the network
                            of Airepro.
                        </span>
                    </p>
                    <p
                        className="MsoNormal"
                        style={{
                            marginTop: "12.0pt",
                            marginRight: "0in",
                            marginBottom: "12.0pt",
                            marginLeft: "0in",
                        }}>
                        <span lang="EN">
                            The User is solely responsible for maintaining confidentiality of
                            the User password and user identification and all activities and
                            transmission performed by the User through his user identification
                            and shall be solely responsible for carrying out any online or
                            off-line transaction involving credit cards / debit cards or such
                            other forms of instruments or documents for making such
                            transactions and Airepro (India) Ltd. assumes no responsibility or
                            liability for their improper use of information relating to such
                            usage of credit cards / debit cards used by the subscriber online
                            / off-line.
                        </span>
                    </p>
                    <p
                        className="MsoNormal"
                        style={{
                            marginTop: "12.0pt",
                            marginRight: "0in",
                            marginBottom: "12.0pt",
                            marginLeft: "0in",
                        }}>
                        <span lang="EN">
                            The User/Subscriber/Visitor to Work-elite and/or its affiliated
                            websites does hereby specifically agree that he/she shall, at all
                            times, comply with the requirements of the Information Technology
                            Act, 2000 as also rules, regulations, guidelines, bye laws and
                            notifications made thereunder, while assessing or feeding any
                            resume/ insertion or information/data into the computers, computer
                            systems or computer network of Airepro. The said User/ subscriber/
                            visitor to Work-elite and/or its affiliated websites does further
                            unequivocally declare that in case he violates any provisions of
                            the Information Technology Act, 2000 and/or rules, regulations,
                            guidelines, byelaws and notifications made thereunder, he shall
                            alone be responsible for all his acts, deeds and things and that
                            he alone shall be liable for civil and criminal liability there
                            under or under any other law for the time being in force.
                        </span>
                    </p>
                    <p
                        className="MsoNormal"
                        style={{
                            marginTop: "12.0pt",
                            marginRight: "0in",
                            marginBottom: "12.0pt",
                            marginLeft: "0in",
                        }}>
                        <span lang="EN">
                            The User is solely responsible for obtaining, at his own cost, all
                            licenses, permits, consents, approvals and intellectual property
                            or other rights as may be required by the user for using the
                            Service.
                        </span>
                    </p>
                    <p
                        className="MsoNormal"
                        style={{
                            marginTop: "12.0pt",
                            marginRight: "0in",
                            marginBottom: "12.0pt",
                            marginLeft: "0in",
                        }}>
                        <span className="font-bold" lang="EN">
                            THE USER REPRESENTS, WARRANTS AND COVENANTS THAT ITS USE OF
                            WORK-ELITE SHALL NOT BE DONE IN A MANNER SO AS TO:
                        </span>
                    </p>
                    {/* start */}
                    <ol className="list-alpha mx-8 ">
                        <li>
                            {" "}
                            <p
                                className="MsoNormal"
                                style={{
                                    marginTop: "12.0pt",
                                    marginRight: "0in",
                                    marginBottom: "12.0pt",
                                    marginLeft: "0in",
                                }}>
                                <span lang="EN">
                                    Violate any applicable local, provincial, state, national or
                                    international law, statute, ordinance, rule or regulation;
                                </span>
                            </p>
                        </li>
                        <li>
                            <p
                                className="MsoNormal"
                                style={{
                                    marginTop: "12.0pt",
                                    marginRight: "0in",
                                    marginBottom: "12.0pt",
                                    marginLeft: "0in",
                                }}>
                                <span lang="EN">
                                    Interfere with or disrupt computer networks connected to
                                    Work-elite;
                                </span>
                            </p>
                        </li>
                        <li>
                            {" "}
                            <p
                                className="MsoNormal"
                                style={{
                                    marginTop: "12.0pt",
                                    marginRight: "0in",
                                    marginBottom: "12.0pt",
                                    marginLeft: "0in",
                                }}>
                                <span lang="EN">
                                    Impersonate any other person or entity, or make any
                                    misrepresentation as to your employment by or affiliation with
                                    any other person or entity;
                                </span>
                            </p>
                        </li>
                        <li>
                            {" "}
                            <p
                                className="MsoNormal"
                                style={{
                                    marginTop: "12.0pt",
                                    marginRight: "0in",
                                    marginBottom: "12.0pt",
                                    marginLeft: "0in",
                                }}>
                                <span lang="EN">
                                    Forge headers or in any manner manipulate identifiers in order
                                    to disguise the origin of any user information;
                                </span>
                            </p>
                        </li>
                        <li>
                            {" "}
                            <p
                                className="MsoNormal"
                                style={{
                                    marginTop: "12.0pt",
                                    marginRight: "0in",
                                    marginBottom: "12.0pt",
                                    marginLeft: "0in",
                                }}>
                                <span lang="EN">
                                    Interfere with or disrupt the use of Work-elite by any other
                                    user, nor "stalk", threaten, or in any manner harass another
                                    user;
                                </span>
                            </p>
                        </li>
                        <li>
                            <p
                                className="MsoNormal"
                                style={{
                                    marginTop: "12.0pt",
                                    marginRight: "0in",
                                    marginBottom: "12.0pt",
                                    marginLeft: "0in",
                                }}>
                                <span lang="EN">
                                    Use Work-elite in such a manner as to gain unauthorized entry
                                    or access to the computer systems of others;
                                </span>
                            </p>
                        </li>
                        <li>
                            <p
                                className="MsoNormal"
                                style={{
                                    marginTop: "12.0pt",
                                    marginRight: "0in",
                                    marginBottom: "12.0pt",
                                    marginLeft: "0in",
                                }}>
                                <span lang="EN">
                                    <span>
                                        Reproduce, copy, modify, sell, store, distribute or
                                        otherwise exploit for any commercial purposes Work-elite, or
                                        any component thereof (including, but not limited to any
                                        materials or information accessible through Work-elite);
                                    </span>
                                </span>
                            </p>
                        </li>
                        <li>
                            {" "}
                            <p
                                className="MsoNormal"
                                style={{
                                    marginTop: "12.0pt",
                                    marginRight: "0in",
                                    marginBottom: "12.0pt",
                                    marginLeft: "0in",
                                }}>
                                <span lang="EN">
                                    Use content from the Site for derivative works with a
                                    commercial motive without prior written consent of the IEIL.
                                </span>
                            </p>
                        </li>
                        <li>
                            {" "}
                            <p
                                className="MsoNormal"
                                style={{
                                    marginTop: "12.0pt",
                                    marginRight: "0in",
                                    marginBottom: "12.0pt",
                                    marginLeft: "0in",
                                }}>
                                <span lang="EN">
                                    Use any device, software or routine to interfere or attempt to
                                    interfere with the proper working of Work-elite; or
                                </span>
                            </p>
                        </li>
                        <li>
                            {" "}
                            <p
                                className="MsoNormal"
                                style={{
                                    marginTop: "12.0pt",
                                    marginRight: "0in",
                                    marginBottom: "12.0pt",
                                    marginLeft: "0in",
                                }}>
                                <span lang="EN">
                                    Impose an unreasonable or disproportionately large load on
                                    Work-elite infrastructure.
                                </span>
                            </p>
                        </li>
                        <li>
                            <p
                                className="MsoNormal"
                                style={{
                                    marginTop: "12.0pt",
                                    marginRight: "0in",
                                    marginBottom: "12.0pt",
                                    marginLeft: "0in",
                                }}>
                                <span lang="EN">
                                    Spam Work-elite/IEIL by indiscriminately and repeatedly
                                    posting content or forwarding mail that may be considered spam
                                    etc.
                                </span>
                            </p>
                        </li>
                        <li>
                            <p
                                className="MsoNormal"
                                style={{
                                    marginTop: "12.0pt",
                                    marginRight: "0in",
                                    marginBottom: "12.0pt",
                                    marginLeft: "0in",
                                }}>
                                <span lang="EN">
                                    Access data not intended for you or log into server or account
                                    that you are not authorized to access;
                                </span>
                            </p>
                        </li>
                        <li>
                            <p
                                className="MsoNormal"
                                style={{
                                    marginTop: "12.0pt",
                                    marginRight: "0in",
                                    marginBottom: "12.0pt",
                                    marginLeft: "0in",
                                }}>
                                <span lang="EN">
                                    Constitute an act of reverse engineering, decompiling,
                                    disassembling, deciphering or otherwise attempting to derive
                                    the source code for the Site or any related technology or any
                                    part thereof
                                </span>
                            </p>
                        </li>
                        <li>
                            {" "}
                            <p
                                className="MsoNormal"
                                style={{
                                    marginTop: "12.0pt",
                                    marginRight: "0in",
                                    marginBottom: "12.0pt",
                                    marginLeft: "0in",
                                }}>
                                <span lang="EN">
                                    Engage in "framing," "mirroring," or otherwise simulating the
                                    appearance or function of the Site
                                </span>
                            </p>
                        </li>
                        <li>
                            {" "}
                            <p
                                className="MsoNormal"
                                style={{
                                    marginTop: "12.0pt",
                                    marginRight: "0in",
                                    marginBottom: "12.0pt",
                                    marginLeft: "0in",
                                }}>
                                <span lang="EN">
                                    Attempt to probe, scan or test the vulnerability of a system
                                    or network;
                                </span>
                            </p>
                        </li>
                        <li>
                            {" "}
                            <p
                                className="MsoNormal"
                                style={{
                                    marginTop: "12.0pt",
                                    marginRight: "0in",
                                    marginBottom: "12.0pt",
                                    marginLeft: "0in",
                                }}>
                                <span lang="EN">
                                    Use automated means to crawl and/or scrape content from
                                    Work-elite and to manually scrape content from Work-elite;
                                </span>
                            </p>
                        </li>
                        <li>
                            {" "}
                            <p
                                className="MsoNormal"
                                style={{
                                    marginTop: "12.0pt",
                                    marginRight: "0in",
                                    marginBottom: "12.0pt",
                                    marginLeft: "0in",
                                }}>
                                <span lang="EN">
                                    The Site uses technological means to exclude Robots etc. from
                                    crawling it and scraping content. You undertake not to
                                    circumvent these methods.
                                </span>
                            </p>
                        </li>
                        <li>
                            {" "}
                            <p
                                className="MsoNormal"
                                style={{
                                    marginTop: "12.0pt",
                                    marginRight: "0in",
                                    marginBottom: "12.0pt",
                                    marginLeft: "0in",
                                }}>
                                <span lang="EN">
                                    Access the Site except through the interfaces expressly
                                    provided by IEIL;
                                </span>
                            </p>
                        </li>
                        <li>
                            {" "}
                            <p
                                className="MsoNormal"
                                style={{
                                    marginTop: "12.0pt",
                                    marginRight: "0in",
                                    marginBottom: "12.0pt",
                                    marginLeft: "0in",
                                }}>
                                <span lang="EN">
                                    Attempt or breach security or authentication measures without
                                    proper authorization;
                                </span>
                            </p>
                        </li>
                        <li>
                            {" "}
                            <p
                                className="MsoNormal"
                                style={{
                                    marginTop: "12.0pt",
                                    marginRight: "0in",
                                    marginBottom: "12.0pt",
                                    marginLeft: "0in",
                                }}>
                                <span lang="EN">
                                    Providing deep links into Work-elite without prior permission
                                    of IEIL is prohibited. Extracting data from Work-elite using
                                    any automated process such as spiders, crawlers etc. or
                                    through any manual process for a purpose which has not been
                                    authorized in writing.
                                </span>
                            </p>
                        </li>
                        <li>
                            {" "}
                            <p
                                className="MsoNormal"
                                style={{
                                    marginTop: "12.0pt",
                                    marginRight: "0in",
                                    marginBottom: "12.0pt",
                                    marginLeft: "0in",
                                }}>
                                <span lang="EN">
                                    Upload, post, email, transmit or otherwise make available
                                    either directly or indirectly, any unsolicited bulk e-mail or
                                    unsolicited commercial e-mail.
                                </span>
                            </p>
                        </li>
                        <li>
                            {" "}
                            <p
                                className="MsoNormal"
                                style={{
                                    marginTop: "12.0pt",
                                    marginRight: "0in",
                                    marginBottom: "12.0pt",
                                    marginLeft: "0in",
                                }}>
                                <span lang="EN">
                                    Subscribers shall under no circumstance sublicense, assign, or
                                    transfer the License, and any attempt at such sublicense,
                                    assignment or transfer is void.
                                </span>
                            </p>
                        </li>
                        <li>
                            <p
                                className="MsoNormal"
                                style={{
                                    marginTop: "12.0pt",
                                    marginRight: "0in",
                                    marginBottom: "12.0pt",
                                    marginLeft: "0in",
                                }}>
                                <span lang="EN">
                                    Constitute hosting, modifying, uploading, posting,
                                    transmitting, publishing, or distributing any material or
                                    information
                                </span>
                            </p>
                        </li>
                        <li>
                            {" "}
                            <p
                                className="MsoNormal"
                                style={{
                                    marginTop: "12.0pt",
                                    marginRight: "0in",
                                    marginBottom: "12.0pt",
                                    marginLeft: "0in",
                                }}>
                                <span lang="EN">
                                    For which you do not have all necessary rights and licenses;
                                </span>
                            </p>
                        </li>
                        <li>
                            <p
                                className="MsoNormal"
                                style={{
                                    marginTop: "12.0pt",
                                    marginRight: "0in",
                                    marginBottom: "12.0pt",
                                    marginLeft: "0in",
                                }}>
                                <span lang="EN">
                                    Which infringes, violates, breaches or otherwise contravenes
                                    the rights of any third party, including any copyright,
                                    trademark, patent, rights of privacy or publicity or any other
                                    proprietary right;
                                </span>
                            </p>
                        </li>
                        <li>
                            {" "}
                            <p
                                className="MsoNormal"
                                style={{
                                    marginTop: "12.0pt",
                                    marginRight: "0in",
                                    marginBottom: "12.0pt",
                                    marginLeft: "0in",
                                }}>
                                <span lang="EN">
                                    Which contains a computer virus, or other code, files or
                                    programs intending in any manner to disrupt or interfere with
                                    the functioning of Work-elite, or that of other computer
                                    systems;
                                </span>
                            </p>
                        </li>
                        <li>
                            {" "}
                            <p
                                className="MsoNormal"
                                style={{
                                    marginTop: "12.0pt",
                                    marginRight: "0in",
                                    marginBottom: "12.0pt",
                                    marginLeft: "0in",
                                }}>
                                <span lang="EN">
                                    That is grossly harmful, harassing, invasive of another's
                                    privacy, hateful, disparaging, relating to money laundering or
                                    unlawful, or which may potentially be perceived as being
                                    harmful, threatening, abusive, harassing, defamatory,
                                    libelous/blasphemous, vulgar, obscene, or racially,
                                    ethnically, or otherwise unlawful in any manner whatsoever;
                                </span>
                            </p>
                        </li>
                        <li>
                            {" "}
                            <p
                                className="MsoNormal"
                                style={{
                                    marginTop: "12.0pt",
                                    marginRight: "0in",
                                    marginBottom: "12.0pt",
                                    marginLeft: "0in",
                                }}>
                                <span lang="EN">
                                    Which constitutes or encourages conduct that would constitute
                                    a criminal offense, give rise to other liability, or otherwise
                                    violate applicable law;
                                </span>
                            </p>
                        </li>
                        <li>
                            {" "}
                            <p
                                className="MsoNormal"
                                style={{
                                    marginTop: "12.0pt",
                                    marginRight: "0in",
                                    marginBottom: "12.0pt",
                                    marginLeft: "0in",
                                }}>
                                <span lang="EN">
                                    That deceives or misleads the addressee about the origin of
                                    such messages or communicates any information which is grossly
                                    offensive or menacing in nature;
                                </span>
                            </p>
                        </li>
                        <li>
                            {" "}
                            <p
                                className="MsoNormal"
                                style={{
                                    marginTop: "12.0pt",
                                    marginRight: "0in",
                                    marginBottom: "12.0pt",
                                    marginLeft: "0in",
                                }}>
                                <span lang="EN">
                                    That belongs to another person and to which the user does not
                                    have any right to;
                                </span>
                            </p>
                        </li>
                        <li>
                            {" "}
                            <p
                                className="MsoNormal"
                                style={{
                                    marginTop: "12.0pt",
                                    marginRight: "0in",
                                    marginBottom: "12.0pt",
                                    marginLeft: "0in",
                                }}>
                                <span lang="EN">That harm minors in any way;</span>
                            </p>
                        </li>
                        <li>
                            <p
                                className="MsoNormal"
                                style={{
                                    marginTop: "12.0pt",
                                    marginRight: "0in",
                                    marginBottom: "12.0pt",
                                    marginLeft: "0in",
                                }}>
                                <span lang="EN">
                                    That threatens the unity, integrity, defense, security or
                                    sovereignty of India, friendly relations with foreign states,
                                    or public order or causes incitement to the commission of any
                                    cognisable offense or prevents investigation of any offense or
                                    is insulting any other nation.
                                </span>
                            </p>
                        </li>
                    </ol>

                    {/* end */}

                    <p
                        className="MsoNormal"
                        style={{
                            marginTop: "12.0pt",
                            marginRight: "0in",
                            marginBottom: "12.0pt",
                            marginLeft: "0in",
                        }}>
                        <span lang="EN">
                            The user shall not infringe on any intellectual property rights of
                            any person / entity or retain information / download any
                            information from any computer system or otherwise with an
                            intention to do so.
                        </span>
                    </p>
                    <p
                        className="MsoNormal"
                        style={{
                            marginTop: "12.0pt",
                            marginRight: "0in",
                            marginBottom: "12.0pt",
                            marginLeft: "0in",
                        }}>
                        <span lang="EN">
                            Airepro (India) Ltd. will make best efforts to do so but does not
                            warrant that any of the web sites or any affiliate site(s) or
                            network system linked to it is free of any operational errors nor
                            does it warrant that it will be free of any virus, computer
                            contaminant, worm, or other harmful components. The subscription
                            of a user shall be subject to Quotas as applicable and as advised.
                            E-Mails provided as part of contact details are expected to be
                            genuine and access to such email accounts is available to
                            authorized personnel only.
                        </span>
                    </p>
                    <p
                        className="MsoNormal"
                        style={{
                            marginTop: "12.0pt",
                            marginRight: "0in",
                            marginBottom: "12.0pt",
                            marginLeft: "0in",
                        }}>
                        <span lang="EN">
                            Airepro (India) Ltd. shall not be liable for any loss or damage
                            sustained by reason of any disclosure (inadvertent or otherwise)
                            of any information concerning the user's account and / or
                            information relating to or regarding online transactions using
                            credit cards / debit cards and / or their verification process and
                            particulars nor for any error, omission or inaccuracy with respect
                            to any information so disclosed and used whether or not in
                            pursuance of a legal process or otherwise.
                        </span>
                    </p>
                    <p
                        className="MsoNormal"
                        style={{
                            marginTop: "12.0pt",
                            marginRight: "0in",
                            marginBottom: "12.0pt",
                            marginLeft: "0in",
                        }}>
                        <span lang="EN">
                            Payments for the services offered by Work-elite shall be on a 100%
                            advance basis. Refund if any will be at the sole discretion of
                            Airepro. IEIL offers no guarantees whatsoever for the accuracy or
                            timeliness of the refunds reaching the Customers card/bank
                            accounts. IEIL gives no guarantees of server uptime or
                            applications working properly. All is on a best effort basis and
                            liability is limited to refund of the amount only. IEIL undertakes
                            no liability for free services. IEIL reserves its right to amend /
                            alter or change all or any disclaimers or terms of agreements at
                            any time without any prior notice. All terms / disclaimers whether
                            specifically mentioned or not shall be deemed to be included if
                            any reference is made to them.
                        </span>
                    </p>
                    <p
                        className="MsoNormal"
                        style={{
                            marginTop: "12.0pt",
                            marginRight: "0in",
                            marginBottom: "12.0pt",
                            marginLeft: "0in",
                        }}>
                        <span lang="EN">
                            Unless otherwise specified and notwithstanding anything contained
                            in any other agreement or arrangement, by whatever name called,
                            the performance obligation of IEIL (service provider) is to
                            provide access of its on-line portal to the customer for the
                            duration of the subscription period &amp; reference to any usage,
                            by whatever name called or any other performance obligation, if
                            any, is to provide the upper limit for consumption, which by
                            itself, does not create any additional performance obligation upon
                            IEIL
                        </span>
                    </p>
                    <p
                        className="MsoNormal"
                        style={{
                            marginTop: "12.0pt",
                            marginRight: "0in",
                            marginBottom: "12.0pt",
                            marginLeft: "0in",
                        }}>
                        <span lang="EN">
                            Subscriber/user acknowledges and agrees that IEIL/Work-elite, at
                            its sole discretion and without prejudice to other rights and
                            remedies that it may have under the applicable laws, shall be
                            entitled to set off the amount paid or payable by a
                            subscriber/user against any amount(s) payable by Subscriber/user
                            to IEIL under any other agreement or commercial relationship
                            towards other products/services.
                        </span>
                    </p>
                    <p
                        className="MsoNormal"
                        style={{
                            marginTop: "12.0pt",
                            marginRight: "0in",
                            marginBottom: "12.0pt",
                            marginLeft: "0in",
                        }}>
                        <span lang="EN">
                            Airepro (India) Ltd. further reserves its right to post the data
                            on the website work-elite or on such other affiliated sites and
                            publications as Airepro (India) Ltd. may deem fit and proper at no
                            extra cost to the subscriber / user.
                        </span>
                    </p>
                    <p
                        className="MsoNormal"
                        style={{
                            marginTop: "12.0pt",
                            marginRight: "0in",
                            marginBottom: "12.0pt",
                            marginLeft: "0in",
                        }}>
                        <span lang="EN">
                            The subscription / agreement between IEIL and the subscriber /
                            user is not a "non-poach agreement" nor can the same be termed or
                            used as an alternative to "non-poach agreement" in as much as IEIL
                            / work-elite is a public site and all information posted by IEIL
                            goes to the public domain except information / data which is
                            specifically assigned a non-public / private character.
                        </span>
                    </p>
                    <p
                        className="MsoNormal"
                        style={{
                            marginTop: "12.0pt",
                            marginRight: "0in",
                            marginBottom: "12.0pt",
                            marginLeft: "0in",
                        }}>
                        <span lang="EN">
                            Any agreement for a subscription / usage entered into by IEIL does
                            not confer exclusivity of service on any subscriber / user.
                        </span>
                    </p>
                    <p
                        className="MsoNormal"
                        style={{
                            marginTop: "12.0pt",
                            marginRight: "0in",
                            marginBottom: "12.0pt",
                            marginLeft: "0in",
                        }}>
                        <span lang="EN">
                            Airepro (India) Ltd will not be party to any legal proceedings
                            between a user (e.g. a subscriber) and a party contracted through
                            the site. In case Airepro (India) Ltd. is implicated in any legal
                            proceedings, costs will be recovered from the party that names
                            Airepro (India) Ltd. Airepro however will abide by any court order
                            served on it through due process. IEIL controls and operates this
                            Platform from its headquarters in Noida and makes no
                            representation that the materials on Work-elite are appropriate or
                            available for use in other locations. If you use this Website from
                            other locations, you are responsible for compliance with
                            applicable local laws including but not limited to the export and
                            import regulations of other countries.
                        </span>
                    </p>
                    <p
                        className="MsoNormal"
                        style={{
                            marginTop: "12.0pt",
                            marginRight: "0in",
                            marginBottom: "12.0pt",
                            marginLeft: "0in",
                        }}>
                        <span lang="EN">
                            In case a person using the world wide web/internet receives a spam
                            or virus which includes a link to work-elite or to any other site
                            maintained, operated or owned by IEIL, it should not be held
                            responsible for the same. IEIL assumes no responsibility for such
                            mails.
                        </span>
                    </p>
                    <p
                        className="MsoNormal"
                        style={{
                            marginTop: "12.0pt",
                            marginRight: "0in",
                            marginBottom: "12.0pt",
                            marginLeft: "0in",
                        }}>
                        <span lang="EN">
                            The services provided by the websites maintained, operated or
                            owned by IEIL do not extend to acting as an agent (express or
                            implied) on behalf of any subscriber or user.
                        </span>
                    </p>
                    <p
                        className="MsoNormal"
                        style={{
                            marginTop: "12.0pt",
                            marginRight: "0in",
                            marginBottom: "12.0pt",
                            marginLeft: "0in",
                        }}>
                        <span lang="EN">
                            IEIL has no agents and does not operate through any agents save
                            for those specifically mentioned on the home page of the website.
                        </span>
                    </p>
                    <p
                        className="MsoNormal"
                        style={{
                            marginTop: "12.0pt",
                            marginRight: "0in",
                            marginBottom: "12.0pt",
                            marginLeft: "0in",
                        }}>
                        <span lang="EN">
                            The Terms and conditions mentioned above regulate the usage of
                            work-elite. Any person using work-elite in violation of the
                            stipulations contained in the Terms and Conditions of work-elite
                            shall render himself/herself liable to appropriate action in a
                            court of law both civil and criminal.
                        </span>
                    </p>
                    <p
                        className="MsoNormal"
                        style={{
                            marginTop: "12.0pt",
                            marginRight: "0in",
                            marginBottom: "12.0pt",
                            marginLeft: "0in",
                        }}>
                        <span lang="EN">
                            BY ACCEPTING THESE TERMS AND CONDITIONS, YOU AGREE TO INDEMNIFY
                            AND OTHERWISE HOLD HARMLESS IEIL, ITS DIRECTORS, OFFICERS,
                            EMPLOYERS, AGENTS, SUBSIDIARIES, AFFILIATES AND OTHER PARTNERS
                            FROM ANY DIRECT, INDIRECT, INCIDENTAL, SPECIAL, CONSEQUENTIAL OR
                            EXEMPLARY DAMAGES ARISING OUT OF, RELATING TO, OR RESULTING FROM
                            YOUR USE OF THE SERVICES OBTAINED THROUGH WORK-ELITE INCLUDING BUT
                            NOT LIMITED TO INFORMATION PROVIDED BY YOU OR ANY OTHER MATTER
                            RELATING TO WORK-ELITE. ANY REFERENCE TO DUTIES AND TAXES ETC IN
                            THESE TERMS OF USE SHALL INCLUDE GOODS AND SERVICES TAX (HEREIN
                            REFERRED AS GST) FROM THE DATE GST LAW IS IMPLEMENTED IN INDIA.
                            ANY ADDITIONAL TAX LIABILITY ARISING ON ACCOUNT OF INTRODUCTION OF
                            GST (WHETHER ON ACCOUNT OF INCREASE IN RATE OR ANY CHANGE BROUGHT
                            IN BY THE NEW TAX REGIME) WOULD BE RECOVERED OVER AND ABOVE THE
                            AGREED CONTRACT PRICE / SERVICE FEE.
                        </span>
                    </p>
                    <p
                        className="MsoNormal"
                        style={{
                            marginTop: "12.0pt",
                            marginRight: "0in",
                            marginBottom: "12.0pt",
                            marginLeft: "0in",
                        }}>
                        <span lang="EN">
                            If any dispute arises between a user/users and IEIL arising out of
                            use of Work-elite or thereafter, in connection with the validity,
                            interpretation, implementation or alleged breach of any provision
                            of these Terms and Conditions, the dispute shall be referred to a
                            sole arbitrator who shall be an independent and neutral third
                            party identified by the Company. Decision of the arbitrator shall
                            be final and binding on both the parties to the dispute. The place
                            of arbitration shall be New Delhi, India. The Arbitration &amp;
                            Conciliation Act, 1996 as amended, shall govern the arbitration
                            proceedings.
                        </span>
                    </p>
                    <p
                        className="MsoNormal"
                        style={{
                            marginTop: "12.0pt",
                            marginRight: "0in",
                            marginBottom: "12.0pt",
                            marginLeft: "0in",
                        }}>
                        <span lang="EN">
                            These Terms and Conditions shall be governed by the laws of the
                            Republic of India. The exclusive forum for any disputes arising
                            out of or relating to these Terms of Use shall be a court of law
                            located in New Delhi, India.
                        </span>
                    </p>
                    <p
                        className="MsoNormal"
                        style={{
                            marginTop: "12.0pt",
                            marginRight: "0in",
                            marginBottom: "12.0pt",
                            marginLeft: "0in",
                        }}>
                        <span lang="EN">
                            Airepro, Airepro India, IEIL and Airepro (India) Ltd. are used
                            interchangeably and are synonymous.
                        </span>
                    </p>
                    <p
                        className="MsoNormal"
                        style={{
                            marginTop: "12.0pt",
                            marginRight: "0in",
                            marginBottom: "12.0pt",
                            marginLeft: "0in",
                        }}>
                        <span lang="EN">
                            In case of non compliance of these Terms and Conditions or Privacy
                            Policy IEIL may terminate usage rights and take down any offending
                            information that might might have been uploaded by such
                            subscriber/user
                        </span>
                    </p>
                    <p
                        className="MsoNormal"
                        style={{
                            marginTop: "12.0pt",
                            marginRight: "0in",
                            marginBottom: "12.0pt",
                            marginLeft: "0in",
                        }}>
                        <b>
                            <span lang="EN">
                                ADDITIONALLY, BASIS SERVICES CHOSEN, CERTAIN PRODUCT/SERVICE
                                SPECIFIC CONDITIONS MAY APPLY
                            </span>
                        </b>
                    </p>
                    <ul className="list-decimal mx-8">
                        <li>
                            <p
                                className="MsoNormal"
                                style={{
                                    marginTop: "12.0pt",
                                    marginRight: "0in",
                                    marginBottom: "12.0pt",
                                    marginLeft: "0in",
                                }}>
                                <span lang="EN">
                                    Work-eliteFastForward Services / Resume Posting / Submission
                                    of Resume / Resume Upload / Resume Manager by registering your
                                    resume on work-elite, you agree to the following terms
                                </span>
                            </p>
                        </li>
                        <li>
                            <p
                                className="MsoNormal"
                                style={{
                                    marginTop: "12.0pt",
                                    marginRight: "0in",
                                    marginBottom: "12.0pt",
                                    marginLeft: "0in",
                                }}>
                                <span lang="EN">
                                    The resume/ insertion or information/data fed by the user can
                                    be updated by the user alone, free of cost.
                                </span>
                            </p>
                        </li>
                        <li>
                            <p
                                className="MsoNormal"
                                style={{
                                    marginTop: "12.0pt",
                                    marginRight: "0in",
                                    marginBottom: "12.0pt",
                                    marginLeft: "0in",
                                }}>
                                <span lang="EN">
                                    Airepro offers no guarantee nor warranties that there would be
                                    a satisfactory response or any response at all once the
                                    resume/ insertion or information/data is fed by the user.
                                </span>
                            </p>
                        </li>
                        <li>
                            <p
                                className="MsoNormal"
                                style={{
                                    marginTop: "12.0pt",
                                    marginRight: "0in",
                                    marginBottom: "12.0pt",
                                    marginLeft: "0in",
                                }}>
                                <span lang="EN">
                                    Airepro neither guarantees nor offers any warranty about the
                                    credentials bonafides, status or otherwise of the prospective
                                    employer/organization which downloads the resume/ insertion or
                                    information/data and uses it to contact the user.
                                </span>
                            </p>
                        </li>
                        <li>
                            <p
                                className="MsoNormal"
                                style={{
                                    marginTop: "12.0pt",
                                    marginRight: "0in",
                                    marginBottom: "12.0pt",
                                    marginLeft: "0in",
                                }}>
                                <span lang="EN">
                                    Airepro would not be held liable for loss of any data
                                    technical or otherwise, or of the resume/ insertion or
                                    information/data or particulars supplied by user due to acts
                                    of god as well as reasons beyond its control like corruption
                                    of data or delay or failure to perform as a result of any
                                    cause(s) or conditions that are beyond Airepro's reasonable
                                    control including but not limited to strikes, riots, civil
                                    unrest, Govt. policies, tampering of data by unauthorized
                                    persons like hackers, distributed denial of service attacks,
                                    virus attacks, war and natural calamities.
                                </span>
                            </p>
                        </li>
                        <li>
                            <p
                                className="MsoNormal"
                                style={{
                                    marginTop: "12.0pt",
                                    marginRight: "0in",
                                    marginBottom: "12.0pt",
                                    marginLeft: "0in",
                                }}>
                                <span lang="EN">
                                    It shall be sole prerogative and responsibility of the user to
                                    check the authenticity of all or any response received
                                    pursuant to the resume/ insertion or information/data being
                                    fed into the network system of Airepro by the user, for going
                                    out of station or in station for any job or interview. Airepro
                                    assumes no responsibility in respect thereof and expressly
                                    disclaims any liability for any act, deed or thing which the
                                    user may so do, pursuant to the receipt of the response, if
                                    any, to the resume/ insertion or information/ date being fed
                                    into the network system of Airepro.
                                </span>
                            </p>
                        </li>
                        <li>
                            <p
                                className="MsoNormal"
                                style={{
                                    marginTop: "12.0pt",
                                    marginRight: "0in",
                                    marginBottom: "12.0pt",
                                    marginLeft: "0in",
                                }}>
                                <span lang="EN">
                                    Uploading of multiple resumes beyond a reasonable limit by the
                                    same individual, using the same or different accounts shall
                                    entitle Airepro to remove the Resumes without notice to the
                                    subscriber. 6.a This service is only meant for candidates
                                    looking for suitable jobs. Any usage with commercial intent is
                                    prohibited.
                                </span>
                            </p>
                        </li>
                        <li>
                            <p
                                className="MsoNormal"
                                style={{
                                    marginTop: "12.0pt",
                                    marginRight: "0in",
                                    marginBottom: "12.0pt",
                                    marginLeft: "0in",
                                }}>
                                <span lang="EN">
                                    Airepro reserves its right to reject and delete any resume/
                                    insertion or information/data fed in by the user without
                                    assigning any reason.
                                </span>
                            </p>
                        </li>
                        <li>
                            <p
                                className="MsoNormal"
                                style={{
                                    marginTop: "12.0pt",
                                    marginRight: "0in",
                                    marginBottom: "12.0pt",
                                    marginLeft: "0in",
                                }}>
                                <span lang="EN">
                                    This free service entitles the user alone i.e the same person,
                                    to add modify or change the data/information fed in by him but
                                    does not entitle him to use the free service to feed fresh
                                    insertion or information/data /resume of another person in
                                    place of the insertion or information/data already fed in by
                                    such user.
                                </span>
                            </p>
                        </li>
                        <li>
                            <p
                                className="MsoNormal"
                                style={{
                                    marginTop: "12.0pt",
                                    marginRight: "0in",
                                    marginBottom: "12.0pt",
                                    marginLeft: "0in",
                                }}>
                                <span lang="EN">
                                    Airepro has the right to make all such modifications/editing
                                    of resumes in order to fit resumes in its database.
                                </span>
                            </p>
                        </li>
                        <li>
                            <p
                                className="MsoNormal"
                                style={{
                                    marginTop: "12.0pt",
                                    marginRight: "0in",
                                    marginBottom: "12.0pt",
                                    marginLeft: "0in",
                                }}>
                                <span lang="EN">
                                    The subscriber shall be assigned a password (s) by Airepro to
                                    enable the subscriber to access all the information received
                                    through work-elite, but the sole responsibility of the safe
                                    custody of the password shall be that of the subscriber and
                                    Airepro shall not be responsible for data loss/theft of
                                    data/corruption of data or the wrong usage/misuse of the
                                    password and any damage or leak of information and its
                                    consequential usage by a third party. Airepro undertakes to
                                    take all reasonable precautions at its end to ensure that
                                    there is no leakage/misuse of the password granted to the
                                    subscriber. When you indicate your interest in a Job Listing
                                    on work-elite, you are sending your CV and application
                                    information including relevant documents to work-elite, and
                                    you are requesting and authorizing work-elite to make
                                    available such application information to the applicable
                                    Employer(s) for such Job Listing(s).
                                </span>
                            </p>
                        </li>
                        <li>
                            {" "}
                            <p
                                className="MsoNormal"
                                style={{
                                    marginTop: "12.0pt",
                                    marginRight: "0in",
                                    marginBottom: "12.0pt",
                                    marginLeft: "0in",
                                }}>
                                <span lang="EN">
                                    In addition, by using work-elite, you agree that work-elite is
                                    not responsible for the content of the Employer's application
                                    form, messages, screener questions, testing assessments;
                                    required documents, or their format or method of delivery.
                                </span>
                            </p>
                        </li>
                        <li>
                            {" "}
                            <p
                                className="MsoNormal"
                                style={{
                                    marginTop: "12.0pt",
                                    marginRight: "0in",
                                    marginBottom: "12.0pt",
                                    marginLeft: "0in",
                                }}>
                                <span lang="EN">
                                    You consent to your application, documents and any responses
                                    sent to you by the Employer or vice versa through work-elite
                                    being processed and analyzed by work-elite according to these
                                    terms of use and work-elite's Privacy Policy. Work-elite shall
                                    store and process such information regardless of whether a job
                                    vacancy has been filled. Work-elite may use your application
                                    materials (including public CVs and responses to employer's
                                    questions) to determine whether you may be interested in a Job
                                    Listing, and work-elite may reach out to you about such Job
                                    Listing.
                                </span>
                            </p>
                        </li>
                        <li>
                            <p
                                className="MsoNormal"
                                style={{
                                    marginTop: "12.0pt",
                                    marginRight: "0in",
                                    marginBottom: "12.0pt",
                                    marginLeft: "0in",
                                }}>
                                <span lang="EN">&nbsp;</span>
                            </p>
                        </li>
                        <li>
                            {" "}
                            <p
                                className="MsoNormal"
                                style={{
                                    marginTop: "12.0pt",
                                    marginRight: "0in",
                                    marginBottom: "12.0pt",
                                    marginLeft: "0in",
                                }}>
                                <span lang="EN">
                                    Information you post in public areas of Work-elite sites or
                                    applications or make visible in the resume and profile
                                    database may be accessed, used, and stored by others around
                                    the world, including those in countries that might not have
                                    legislation that guarantees adequate protection of personal
                                    information as defined by your country of residence. While
                                    Work-elite takes measures to safeguard your information from
                                    unauthorized access or inappropriate use, Work-elite does not
                                    control these third parties and we are not responsible for
                                    their use of information you give to us. Accordingly, you
                                    should not post sensitive information or any other information
                                    you would not want made public, to any portion of Work-elite
                                    or application or to a public website.
                                </span>
                            </p>
                        </li>
                        <li>
                            {" "}
                            <p
                                className="MsoNormal"
                                style={{
                                    marginTop: "12.0pt",
                                    marginRight: "0in",
                                    marginBottom: "12.0pt",
                                    marginLeft: "0in",
                                }}>
                                <span lang="EN">
                                    In order to use Work-elite, you may sign in using your
                                    Facebook/Google login. If you do so, you authorize us to
                                    access and use certain Facebook/Google account information,
                                    including but not limited to your public Facebook profile and
                                    posts. For more details regarding the information we collect
                                    from you and how we use it, please visit our Privacy Policy.
                                </span>
                            </p>
                        </li>
                        <li>
                            {" "}
                            <p
                                className="MsoNormal"
                                style={{
                                    marginTop: "12.0pt",
                                    marginRight: "0in",
                                    marginBottom: "12.0pt",
                                    marginLeft: "0in",
                                }}>
                                <span lang="EN">
                                    It shall be the sole responsibility of the user to ensure that
                                    it uses the privacy setting options as it deems fit to debar /
                                    refuse access of the data fed by it, to such corporate
                                    entities, individuals or consultants. Airepro shall not be
                                    responsible for such insertions / data being accessed by its
                                    subscribers or users whose access has not been specifically
                                    blocked /debarred by the user while using the privacy
                                    settings.
                                </span>
                            </p>
                        </li>
                        <li>
                            <p
                                className="MsoNormal"
                                style={{
                                    marginTop: "12.0pt",
                                    marginRight: "0in",
                                    marginBottom: "12.0pt",
                                    marginLeft: "0in",
                                }}>
                                <span lang="EN">
                                    Even though you may have marked your profile as unsearchable,
                                    on viewing a Work-elite Recruiter profile when you are logged
                                    into your Work-elite account, a snapshot of your profile may
                                    be made visible to the recruiter.
                                </span>
                            </p>
                        </li>
                        <li>
                            {" "}
                            <p
                                className="MsoNormal"
                                style={{
                                    marginTop: "12.0pt",
                                    marginRight: "0in",
                                    marginBottom: "12.0pt",
                                    marginLeft: "0in",
                                }}>
                                <span lang="EN">
                                    Although Airepro will make all possible efforts to adhere to
                                    these privacy settings, it will not be responsible for a
                                    resume being seen by a blocked user for any reason. For best
                                    privacy settings it is recommended that you do not allow your
                                    resume to be searched at all.
                                </span>
                            </p>
                        </li>
                        <li>
                            {" "}
                            <p
                                className="MsoNormal"
                                style={{
                                    marginTop: "12.0pt",
                                    marginRight: "0in",
                                    marginBottom: "12.0pt",
                                    marginLeft: "0in",
                                }}>
                                <span lang="EN">
                                    The user represents that he/she is not a minor and is not
                                    under any legal or other disability which limits his/her
                                    ability to comply with these Terms or to install and use the
                                    services subscribed and purchased with minimal risk of harm to
                                    you or others. You further represent that you are not
                                    purchasing the products/services for resale to others and will
                                    not do so without Airepro (India) Limited's prior written
                                    consent.
                                </span>
                            </p>
                        </li>
                        <li>
                            <p
                                className="MsoNormal"
                                style={{
                                    marginTop: "12.0pt",
                                    marginRight: "0in",
                                    marginBottom: "12.0pt",
                                    marginLeft: "0in",
                                }}>
                                <span lang="EN">
                                    All changes / modifications made by the user to the data /
                                    information shall be effected and will come into operation
                                    only after 24-48 hours of such changes / modifications being
                                    made.
                                </span>
                            </p>
                        </li>
                    </ul>

                    {/* end */}

                    <p
                        className="MsoNormal"
                        style={{
                            marginTop: "12.0pt",
                            marginRight: "0in",
                            marginBottom: "12.0pt",
                            marginLeft: "0in",
                        }}>
                        <span lang="EN">
                            On registration you agree to: a) Making your resume searchable to
                            clients of IEIL who subscribe to RESDEX (Resume Database Access).
                            You may be contacted by subscribers of RESDEX via email, telephone
                            (even though you may have registered with NDNC) and/or post. If
                            you wish to modify this setting and make yourself non-searchable,
                            you may reset this in the Privacy Setting section of your resume.
                            We recommend that you read the privacy settings carefully and IEIL
                            will not be held responsible for contacts/mails received by you.
                            b) Receive job alerts (mails detailing jobs that match your
                            profile as entered on work-elite) via email. You may remove
                            yourself from the job alert email database by resetting this
                            option in the Privacy Setting section of your resume c) Receive
                            promotional mailers/ special offers. You may remove yourself from
                            the promotional mailer email database by resetting this option on
                            the Privacy Setting section of your resume.
                        </span>
                    </p>
                    <p
                        className="MsoNormal"
                        style={{
                            marginTop: "12.0pt",
                            marginRight: "0in",
                            marginBottom: "12.0pt",
                            marginLeft: "0in",
                        }}>
                        <span lang="EN">
                            The user agreement between a user/subscriber and IEIL will be
                            treated as having been terminated in the following events: ( i )
                            On completion of the term for which the user/subscriber engages
                            the services of the website; or ( ii ) In case the user/subscriber
                            violates any of the conditions of this agreement or any other
                            agreement entered into by him with IEIL, however, such termination
                            will be at the option and discretion of IEIL; or ( iii )On writing
                            and on such terms as agreed to by the parties mutually.
                        </span>
                    </p>
                    <p
                        className="MsoNormal"
                        style={{
                            marginTop: "12.0pt",
                            marginRight: "0in",
                            marginBottom: "12.0pt",
                            marginLeft: "0in",
                        }}>
                        <span lang="EN">
                            The User of these services does not claim any copyright or other
                            Intellectual Property Right over the data uploaded by him/her on
                            the website.
                        </span>
                    </p>
                    <p
                        className="MsoNormal"
                        style={{
                            marginTop: "12.0pt",
                            marginRight: "0in",
                            marginBottom: "12.0pt",
                            marginLeft: "0in",
                        }}>
                        <span lang="EN">&nbsp;</span>
                    </p>
                    <p
                        className="MsoNormal"
                        style={{
                            marginTop: "12.0pt",
                            marginRight: "0in",
                            marginBottom: "12.0pt",
                            marginLeft: "0in",
                        }}>
                        <span lang="EN">&nbsp;</span>
                    </p>
                    <ol style={{ marginTop: "0in" }} start={1} type={1}>
                        <li
                            className="MsoNormal"
                            style={{ marginTop: "8.0pt", marginBottom: "5.0pt" }}>
                            <span
                                className="text-lg block text-center font-semibold"
                                lang="EN">
                                Resume Display:
                            </span>
                        </li>
                    </ol>
                    <ol style={{ marginTop: "0in" }} start={1} type={1}>
                        <li className="MsoNormal">
                            <span lang="EN">
                                In case necessary inputs required by us for commencing the
                                services are not received by us within 30 days of the payment,
                                the order shall stand canceled and any amounts paid shall be
                                appropriated.
                            </span>
                        </li>
                    </ol>
                    <ol style={{ marginTop: "0in" }} start={1} type={1}>
                        <li className="MsoNormal">
                            <span lang="EN">
                                The payment for service once subscribed to by the subscriber is
                                not refundable and any amount paid shall stand appropriated.
                            </span>
                        </li>
                    </ol>
                    <ol style={{ marginTop: "0in" }} start={1} type={1}>
                        <li className="MsoNormal">
                            <span lang="EN">
                                The amount paid entitles the subscriber alone to the service by
                                Airepro for a period of subscription opted for from the date of
                                up-linking of the resume on the website Work-elite or such other
                                mirror or parallel site(s) as Airepro may deem fit and proper
                                but such web host shall be without any extra cost to the
                                subscriber / user.
                            </span>
                        </li>
                    </ol>
                    <ol style={{ marginTop: "0in" }} start={1} type={1}>
                        <li className="MsoNormal">
                            <span lang="EN">
                                The resume displayed can be updated by the subscriber alone free
                                of cost.
                            </span>
                        </li>
                    </ol>
                    <ol style={{ marginTop: "0in" }} start={1} type={1}>
                        <li className="MsoNormal">
                            <span lang="EN">
                                Notwithstanding anything contained herein, through Resume
                                Display service your resume is made available from the home page
                                of Work-elite and can be freely accessed by anyone.
                            </span>
                        </li>
                    </ol>
                    <ol style={{ marginTop: "0in" }} start={1} type={1}>
                        <li className="MsoNormal">
                            <span lang="EN">
                                Additionally, through this service your resume is also made a
                                part of Work-elite's proprietary database, accessed only by
                                companies/recruiters registered with us. Please log into your
                                account and set the visibility of the resume as desired by you,
                                here you can selectively block a company/recruiter from
                                accessing your resume. (Please note that the confidentiality
                                settings of the resume which has been made part of the exclusive
                                database is independent of the confidentiality settings of the
                                resume made part of the free search service)
                            </span>
                        </li>
                    </ol>
                    <ol style={{ marginTop: "0in" }} start={1} type={1}>
                        <li className="MsoNormal">
                            <span lang="EN">
                                Airepro offers neither guarantee nor warranties that there would
                                be a satisfactory response or any response at all once the
                                resume is put on display.
                            </span>
                        </li>
                    </ol>
                    <ol style={{ marginTop: "0in" }} start={1} type={1}>
                        <li className="MsoNormal">
                            <span lang="EN">
                                Airepro neither guarantees nor offers any warranty about the
                                credentials of the prospective employer/organization which
                                downloads the information and uses it to contact the prospective
                                employee / visitor / user / subscriber.
                            </span>
                        </li>
                    </ol>
                    <ol style={{ marginTop: "0in" }} start={1} type={1}>
                        <li className="MsoNormal" style={{ marginBottom: "12.0pt" }}>
                            <span lang="EN">
                                Airepro would not be held liable for loss of any data technical
                                or otherwise, and particulars supplied by subscribers due to
                                reasons beyond its control like corruption of data or delay or
                                failure to perform as a result of any causes or conditions that
                                are beyond Airepro's reasonable control including but not
                                limited to strikes, riots, civil unrest, Govt. policies,
                                tampering of data by unauthorized persons like hackers, war and
                                natural calamities.
                            </span>
                        </li>
                    </ol>
                    <p
                        className="MsoNormal"
                        style={{
                            marginTop: "12.0pt",
                            marginRight: "0in",
                            marginBottom: "12.0pt",
                            marginLeft: "0in",
                        }}>
                        <span lang="EN">
                            1.&nbsp;&nbsp; It shall be the sole prerogative and responsibility
                            of the individual to check the authenticity of all or any response
                            received pursuant to the resume being displayed by Airepro for
                            going out of station or in station for any job / interview and
                            Airepro assumes no responsibility in respect thereof.
                        </span>
                    </p>
                    <p
                        className="MsoNormal"
                        style={{
                            marginTop: "12.0pt",
                            marginRight: "0in",
                            marginBottom: "12.0pt",
                            marginLeft: "0in",
                        }}>
                        <span lang="EN">
                            2.&nbsp;&nbsp; Airepro reserves its right to reject any insertion
                            or information/data provided by the subscriber without assigning
                            any reason either before uploading or after uploading the vacancy
                            details, refund if any shall be on a pro-rata basis at the sole
                            discretion of Airepro.
                        </span>
                    </p>
                    <p
                        className="MsoNormal"
                        style={{
                            marginTop: "12.0pt",
                            marginRight: "0in",
                            marginBottom: "12.0pt",
                            marginLeft: "0in",
                        }}>
                        <span lang="EN">
                            3.&nbsp;&nbsp; Airepro will commence providing services only upon
                            receipt of amount/charges upfront either from subscriber or from a
                            third party on behalf of the subscriber.
                        </span>
                    </p>
                    <p
                        className="MsoNormal"
                        style={{
                            marginTop: "12.0pt",
                            marginRight: "0in",
                            marginBottom: "12.0pt",
                            marginLeft: "0in",
                        }}>
                        <span lang="EN">
                            3.&nbsp;&nbsp; This subscription is not transferable i.e. it is
                            for the same person throughout the period of subscription.
                        </span>
                    </p>
                    <p
                        className="MsoNormal"
                        style={{
                            marginTop: "12.0pt",
                            marginRight: "0in",
                            marginBottom: "12.0pt",
                            marginLeft: "0in",
                        }}>
                        <span lang="EN">
                            4.&nbsp;&nbsp; Airepro has the right to make all such
                            modifications/editing of resume in order to fit the resume in its
                            database.
                        </span>
                    </p>
                    <p
                        className="MsoNormal"
                        style={{
                            marginTop: "12.0pt",
                            marginRight: "0in",
                            marginBottom: "12.0pt",
                            marginLeft: "0in",
                        }}>
                        <span lang="EN">
                            5.&nbsp;&nbsp; The liability, if any, of Airepro is limited to the
                            extent of the amount paid by the subscriber.
                        </span>
                    </p>
                    <p
                        className="MsoNormal"
                        style={{
                            marginTop: "12.0pt",
                            marginRight: "0in",
                            marginBottom: "12.0pt",
                            marginLeft: "0in",
                        }}>
                        <span lang="EN">
                            6.&nbsp;&nbsp; The subscriber shall be assigned password(s) by
                            Airepro to enable the subscriber to access all the information
                            received through its site Work-elite, but the sole responsibility
                            of the safe custody of the password shall be that of the
                            subscriber and Airepro shall not be responsible for data
                            loss/theft or data/corruption or the wrong usage/misuse of the
                            password and any damage or leak of information and its
                            consequential usage by a third party. Airepro undertakes to take
                            all reasonable precautions at its end to ensure that there is no
                            leakage/misuse of the password granted to the subscriber
                        </span>
                    </p>
                    <p
                        className="MsoNormal"
                        style={{
                            marginTop: "12.0pt",
                            marginRight: "0in",
                            marginBottom: "12.0pt",
                            marginLeft: "0in",
                        }}>
                        <span lang="EN">
                            7.&nbsp;&nbsp; The subscriber undertakes that the data/information
                            provided by him is true and correct in all respects.
                        </span>
                    </p>
                    <p
                        className="MsoNormal"
                        style={{
                            marginTop: "12.0pt",
                            marginRight: "0in",
                            marginBottom: "12.0pt",
                            marginLeft: "0in",
                        }}>
                        <span lang="EN">
                            8.&nbsp;&nbsp; The User of these services does not claim any
                            copyright or other Intellectual Property Right over the data
                            uploaded by him/her on the website
                        </span>
                    </p>
                    <p
                        className="MsoNormal"
                        style={{
                            marginTop: "12.0pt",
                            marginRight: "0in",
                            marginBottom: "12.0pt",
                            marginLeft: "0in",
                        }}>
                        <span lang="EN">
                            9.&nbsp;&nbsp; (With Resume writing - 4 working days after you
                            approve final resume) &amp; (Without Resume writing - 4 working
                            days after receiving your updated Resume)
                        </span>
                    </p>
                    <p
                        className="MsoNormal"
                        style={{
                            marginTop: "12.0pt",
                            marginRight: "0in",
                            marginBottom: "12.0pt",
                            marginLeft: "0in",
                        }}>
                        <span lang="EN">
                            10.&nbsp;&nbsp; Service will be deemed approved if a user fail to
                            review the profile for 7 days. Users are advised to add or remove
                            any information on profile which are not relevant to the user.
                        </span>
                    </p>
                    <p
                        className="MsoNormal"
                        style={{
                            marginTop: "12.0pt",
                            marginRight: "0in",
                            marginBottom: "12.0pt",
                            marginLeft: "0in",
                        }}>
                        <span lang="EN">&nbsp;</span>
                    </p>
                    <p
                        className="MsoNormal"
                        style={{
                            marginTop: "12.0pt",
                            marginRight: "0in",
                            marginBottom: "12.0pt",
                            marginLeft: "0in",
                        }}>
                        <span lang="EN">&nbsp;</span>
                    </p>
                    <ol style={{ marginTop: "0in" }} start={1} type={1}>
                        <li className="MsoNormal" style={{ marginTop: "12.0pt" }}>
                            <span
                                className="text-lg block text-center font-semibold"
                                lang="EN">
                                RecruiterConnection
                            </span>
                        </li>
                    </ol>
                    <ol style={{ marginTop: "0in" }} start={1} type={1}>
                        <li className="MsoNormal">
                            <span lang="EN">
                                The payment for service once subscribed to by the subscriber is
                                not refundable and any amount paid shall stand appropriated.
                            </span>
                        </li>
                    </ol>
                    <ol style={{ marginTop: "0in" }} start={1} type={1}>
                        <li className="MsoNormal">
                            <span lang="EN">
                                Through RecruiterConnection, the subscriber can buy credits to
                                send messages to the recruiter of his/her choice.
                            </span>
                        </li>
                    </ol>
                    <ol style={{ marginTop: "0in" }} start={1} type={1}>
                        <li className="MsoNormal">
                            <span lang="EN">
                                If the recruiter does not view the message sent to him/her
                                within 15 days, the period being subject to change without prior
                                notice, then the credit would be returned to the jobseeker.
                                Credits will be returned only once.
                            </span>
                        </li>
                    </ol>
                    <ol style={{ marginTop: "0in" }} start={1} type={1}>
                        <li className="MsoNormal">
                            <span lang="EN">
                                All the credits are valid for one year from the date of
                                purchase.
                            </span>
                        </li>
                    </ol>
                    <ol style={{ marginTop: "0in" }} start={1} type={1}>
                        <li className="MsoNormal">
                            <span lang="EN">
                                After contacting a recruiter, the jobseeker cannot send a
                                message to the same recruiter for 30 days, the period being
                                subject to change without prior notice.
                            </span>
                        </li>
                    </ol>
                    <ol style={{ marginTop: "0in" }} start={1} type={1}>
                        <li className="MsoNormal">
                            <span lang="EN">
                                Using the service, the jobseeker would be able to send a message
                                of maximum 500 characters along with a subject line of maximum
                                200 characters.
                            </span>
                        </li>
                    </ol>
                    <ol style={{ marginTop: "0in" }} start={1} type={1}>
                        <li className="MsoNormal">
                            <span lang="EN">
                                Airepro offers neither guarantee nor warranties that there would
                                be a satisfactory response or any response at all once the
                                message is sent to the recruiter.
                            </span>
                        </li>
                    </ol>
                    <ol style={{ marginTop: "0in" }} start={1} type={1}>
                        <li className="MsoNormal">
                            <span lang="EN">
                                Airepro neither guarantees nor offers any warranty about the
                                credentials of the prospective recruiter/organization which sees
                                the message and downloads the information and uses it to contact
                                the prospective employee / visitor / user / subscriber.
                            </span>
                        </li>
                    </ol>
                    <ol style={{ marginTop: "0in" }} start={1} type={1}>
                        <li className="MsoNormal" style={{ marginBottom: "12.0pt" }}>
                            <span lang="EN">
                                It shall be the sole prerogative and responsibility of the
                                individual to check the authenticity of all or any response
                                received pursuant to the connect message for going out of
                                station or in station for any job / interview and Airepro
                                assumes no responsibility in respect thereof.
                            </span>
                        </li>
                    </ol>
                    <p
                        className="MsoNormal"
                        style={{
                            marginTop: "12.0pt",
                            marginRight: "0in",
                            marginBottom: "12.0pt",
                            marginLeft: "0in",
                        }}>
                        <span lang="EN">
                            10.&nbsp;&nbsp; This subscription is not transferable i.e. it is
                            for the same person throughout the period of subscription.
                        </span>
                    </p>
                    <p
                        className="MsoNormal"
                        style={{
                            marginTop: "12.0pt",
                            marginRight: "0in",
                            marginBottom: "12.0pt",
                            marginLeft: "0in",
                        }}>
                        <span lang="EN">
                            11.&nbsp;&nbsp; The subscriber undertakes that the
                            data/information provided by him is true and correct in all
                            respects.
                        </span>
                    </p>
                    <p
                        className="MsoNormal"
                        style={{
                            marginTop: "12.0pt",
                            marginRight: "0in",
                            marginBottom: "12.0pt",
                            marginLeft: "0in",
                        }}>
                        <span lang="EN">
                            12.&nbsp;&nbsp; This service shall not be utilized by the user for
                            uploading/transmitting content which is illegal or objectionable
                            in any manner.
                        </span>
                    </p>
                    <p
                        className="MsoNormal"
                        style={{
                            marginTop: "12.0pt",
                            marginRight: "0in",
                            marginBottom: "12.0pt",
                            marginLeft: "0in",
                        }}>
                        <span lang="EN">
                            13.&nbsp;&nbsp; The User of these services does not claim any
                            copyright or other Intellectual Property Right over the data
                            uploaded by him/her on the website.
                        </span>
                    </p>
                    <p
                        className="MsoNormal"
                        style={{
                            marginTop: "12.0pt",
                            marginRight: "0in",
                            marginBottom: "12.0pt",
                            marginLeft: "0in",
                        }}>
                        <span lang="EN">&nbsp;</span>
                    </p>
                    <ol style={{ marginTop: "0in" }} start={1} type={1}>
                        <li className="MsoNormal" style={{ marginTop: "12.0pt" }}>
                            <span
                                className="text-lg block text-center font-semibold"
                                lang="EN">
                                Resume Writing
                            </span>
                        </li>
                    </ol>
                    <ol style={{ marginTop: "0in" }} start={1} type={1}>
                        <li className="MsoNormal">
                            <span lang="EN">
                                In case necessary inputs required by us for commencing the
                                services are not received by us within 30 days of the payment,
                                the order shall stand canceled and any amounts paid shall be
                                appropriated.
                            </span>
                        </li>
                    </ol>
                    <ol style={{ marginTop: "0in" }} start={1} type={1}>
                        <li className="MsoNormal">
                            <span lang="EN">
                                The subscriber shall certify that the information/data supplied
                                by it to Airepro is accurate and correct.
                            </span>
                        </li>
                    </ol>
                    <ol style={{ marginTop: "0in" }} start={1} type={1}>
                        <li className="MsoNormal">
                            <span lang="EN">
                                The subscriber shall give reasonable time to Airepro for writing
                                of resumes.
                            </span>
                        </li>
                    </ol>
                    <ol style={{ marginTop: "0in" }} start={1} type={1}>
                        <li className="MsoNormal">
                            <span lang="EN">
                                The subscriber must respond to the queries raised (sent by
                                email) by Airepro within 7 days of such a query being raised
                                failing which, Airepro shall stand absolved of all its
                                obligation, if any.
                            </span>
                        </li>
                    </ol>
                    <ol style={{ marginTop: "0in" }} start={1} type={1}>
                        <li className="MsoNormal">
                            <span lang="EN">
                                The subscription amount once paid for by the subscribers is not
                                refundable and all amount(s) paid on behalf of the subscriber
                                shall stand appropriated.
                            </span>
                        </li>
                    </ol>
                    <ol style={{ marginTop: "0in" }} start={1} type={1}>
                        <li className="MsoNormal">
                            <span lang="EN">
                                The liability, if any, of Airepro is limited to the extent of
                                the amount paid by the subscriber.
                            </span>
                        </li>
                    </ol>
                    <ol style={{ marginTop: "0in" }} start={1} type={1}>
                        <li className="MsoNormal">
                            <span lang="EN">
                                Airepro offers no guarantee nor warranties that there would be a
                                satisfactory response or any response at all once the resume is
                                written and used by the subscriber to apply for jobs.
                            </span>
                        </li>
                    </ol>
                    <ol style={{ marginTop: "0in" }} start={1} type={1}>
                        <li className="MsoNormal">
                            <span lang="EN">
                                Airepro shall not be held liable for loss of any data technical
                                or otherwise, information, particulars supplied by subscriber
                                due to reasons beyond its control like corruption of data or
                                delay or failure to perform as a result of any causes or
                                conditions that are beyond Airepro's reasonable control
                                including but not limited to strikes, riots, civil unrest, Govt.
                                policies, tampering of data by unauthorized persons like
                                hackers, war and natural calamities.
                            </span>
                        </li>
                    </ol>
                    <ol style={{ marginTop: "0in" }} start={1} type={1}>
                        <li className="MsoNormal" style={{ marginBottom: "12.0pt" }}>
                            <span lang="EN">
                                Airepro reserves its right to reject any insertion or
                                information/data provided by the subscriber without assigning
                                any reason, but in such an eventuality, any amount so paid for,
                                shall be refunded to the subscriber on a pro-rata basis at the
                                sole discretion of Airepro.
                            </span>
                        </li>
                    </ol>
                    <p
                        className="MsoNormal"
                        style={{
                            marginTop: "12.0pt",
                            marginRight: "0in",
                            marginBottom: "12.0pt",
                            marginLeft: "0in",
                        }}>
                        <span lang="EN">
                            10.&nbsp;&nbsp; Airepro will commence providing services only upon
                            receipt of amount/charges upfront either from subscriber or from a
                            third party on behalf of the subscriber.
                        </span>
                    </p>
                    <p
                        className="MsoNormal"
                        style={{
                            marginTop: "12.0pt",
                            marginRight: "0in",
                            marginBottom: "12.0pt",
                            marginLeft: "0in",
                        }}>
                        <span lang="EN">
                            11.&nbsp;&nbsp; This subscription is not transferable i.e. it is
                            for the same person throughout the period of subscription.
                        </span>
                    </p>
                    <p
                        className="MsoNormal"
                        style={{
                            marginTop: "12.0pt",
                            marginRight: "0in",
                            marginBottom: "12.0pt",
                            marginLeft: "0in",
                        }}>
                        <span lang="EN">
                            12.&nbsp;&nbsp; The subscriber shall be assigned password(s) by
                            Airepro to enable the subscriber to access all the information
                            received through Work-elite, but the sole responsibility of the
                            safe custody of the password shall be that of the subscriber and
                            Airepro shall not be responsible for data loss/theft of
                            data/corruption of data or the wrong usage/misuse of the password
                            and any damage or leak of information and its consequential usage
                            by a third party. Airepro undertakes to take all reasonable
                            precautions at its end to ensure that there is no leakage/misuse
                            of the password granted to the subscriber.
                        </span>
                    </p>
                    <p
                        className="MsoNormal"
                        style={{
                            marginTop: "12.0pt",
                            marginRight: "0in",
                            marginBottom: "12.0pt",
                            marginLeft: "0in",
                        }}>
                        <span lang="EN">
                            13.&nbsp;&nbsp; The User of these services does not claim any
                            copyright or other Intellectual Property Right over the data
                            uploaded by him/her on the website.
                        </span>
                    </p>
                    <p
                        className="MsoNormal"
                        style={{
                            marginTop: "12.0pt",
                            marginRight: "0in",
                            marginBottom: "12.0pt",
                            marginLeft: "0in",
                        }}>
                        <span lang="EN">
                            14.&nbsp;&nbsp; Content and Images( Visuals) in the visual resume
                            are provided by Subscriber.
                            <br />
                            Airepro India Limited disclaims all warranties against
                            infringement.
                        </span>
                    </p>
                    <p
                        className="MsoNormal"
                        style={{
                            marginTop: "12.0pt",
                            marginRight: "0in",
                            marginBottom: "12.0pt",
                            marginLeft: "0in",
                        }}>
                        <span lang="EN">&nbsp;</span>
                    </p>
                    <ol style={{ marginTop: "0in" }} start={1} type={1}>
                        <li className="MsoNormal" style={{ marginTop: "12.0pt" }}>
                            <span lang="EN">
                                Jobs on the Move (Jobs4U) &amp; Priority Applicant
                            </span>
                        </li>
                    </ol>
                    <ol style={{ marginTop: "0in" }} start={1} type={1}>
                        <li className="MsoNormal">
                            <span lang="EN">
                                The payment for service once subscribed to by the subscriber is
                                not refundable and any amount paid shall stand appropriated.
                            </span>
                        </li>
                    </ol>
                    <ol style={{ marginTop: "0in" }} start={1} type={1}>
                        <li className="MsoNormal">
                            <span lang="EN">
                                The amount paid entitles the subscriber alone to the service by
                                Airepro for a period of subscription opted for from the date of
                                sending the first job SMS.
                            </span>
                        </li>
                    </ol>
                    <ol style={{ marginTop: "0in" }} start={1} type={1}>
                        <li className="MsoNormal">
                            <span lang="EN">
                                Airepro uses an automated algorithm to match jobs against the
                                keywords/attributes present in the profile submitted by the
                                user, and hence offers no guarantee nor warranties that the jobs
                                sent in the job SMS will be relevant to the profile.
                            </span>
                        </li>
                    </ol>
                    <ol style={{ marginTop: "0in" }} start={1} type={1}>
                        <li className="MsoNormal">
                            <span lang="EN">
                                Airepro neither guarantees nor offers any warranty about the
                                credentials of the prospective employer/organization whose
                                details are sent in the Job SMS.
                            </span>
                        </li>
                    </ol>
                    <ol style={{ marginTop: "0in" }} start={1} type={1}>
                        <li className="MsoNormal">
                            <span lang="EN">
                                By opting for this service you are agreeing to receive details
                                of Jobs from Work-elite, on your mobile, till the tenure of the
                                service.
                            </span>
                        </li>
                    </ol>
                    <ol style={{ marginTop: "0in" }} start={1} type={1}>
                        <li className="MsoNormal">
                            <span lang="EN">
                                Airepro will try its utmost best to try and deliver all SMS
                                sent, however, it does not take any responsibility for SMSes not
                                delivered (which may be on account of phones being out of order,
                                operator network problems, SIM not being used anymore etcetera).
                                It does take the responsibility of the SMSes leaving its vendor
                                SMS gateway.
                            </span>
                        </li>
                    </ol>
                    <ol style={{ marginTop: "0in" }} start={1} type={1}>
                        <li className="MsoNormal">
                            <span lang="EN">
                                Airepro will not be responsible in any way for failure of any
                                backend technology of SMS applications at the Operators end and
                                resultant inability of a user to receive SMS.
                            </span>
                        </li>
                    </ol>
                    <ol style={{ marginTop: "0in" }} start={1} type={1}>
                        <li className="MsoNormal">
                            <span lang="EN">
                                Airepro reserves the right to regulate the number of SMSes sent
                                out to a particular user in a single day.
                            </span>
                        </li>
                    </ol>
                    <ol style={{ marginTop: "0in" }} start={1} type={1}>
                        <li className="MsoNormal" style={{ marginBottom: "12.0pt" }}>
                            <span lang="EN">
                                The service will be delivered on the mobile number provided at
                                the time of purchase of the service, and will continue on the
                                new number if changed by the user.
                            </span>
                        </li>
                    </ol>
                    <p
                        className="MsoNormal"
                        style={{
                            marginTop: "12.0pt",
                            marginRight: "0in",
                            marginBottom: "12.0pt",
                            marginLeft: "0in",
                        }}>
                        <span lang="EN">
                            10.&nbsp;&nbsp; If there are no jobs found matching the user's
                            profile, an intimation SMS &amp; email is sent to the user, on the
                            7th day and the user is asked for changing/editing his profile.
                        </span>
                    </p>
                    <p
                        className="MsoNormal"
                        style={{
                            marginTop: "12.0pt",
                            marginRight: "0in",
                            marginBottom: "12.0pt",
                            marginLeft: "0in",
                        }}>
                        <span lang="EN">
                            11.&nbsp;&nbsp; Airepro would not be held liable for loss of any
                            data technical or otherwise, and particulars supplied by
                            subscribers due to reasons beyond its control like corruption of
                            data or delay or failure to perform as a result of any causes or
                            conditions that are beyond Airepro's reasonable control including
                            but not limited to strikes, riots, civil unrest, Govt. policies,
                            tampering of data by unauthorized persons like hackers, war and
                            natural calamities.
                        </span>
                    </p>
                    <p
                        className="MsoNormal"
                        style={{
                            marginTop: "12.0pt",
                            marginRight: "0in",
                            marginBottom: "12.0pt",
                            marginLeft: "0in",
                        }}>
                        <span lang="EN">
                            12.&nbsp;&nbsp; It shall be the sole prerogative and
                            responsibility of the individual to check the authenticity of all
                            or any response received pursuant to the resume being displayed by
                            Airepro for going out of station or in station for any job /
                            interview and Airepro assumes no responsibility in respect
                            thereof.
                        </span>
                    </p>
                    <p
                        className="MsoNormal"
                        style={{
                            marginTop: "12.0pt",
                            marginRight: "0in",
                            marginBottom: "12.0pt",
                            marginLeft: "0in",
                        }}>
                        <span lang="EN">
                            13.&nbsp;&nbsp; Airepro reserves its right to reject any insertion
                            or information/data provided by the subscriber without assigning
                            any reason either before uploading or after uploading the vacancy
                            details, refund if any shall be on a pro-rata basis at the sole
                            discretion of Airepro.
                        </span>
                    </p>
                    <p
                        className="MsoNormal"
                        style={{
                            marginTop: "12.0pt",
                            marginRight: "0in",
                            marginBottom: "12.0pt",
                            marginLeft: "0in",
                        }}>
                        <span lang="EN">
                            14.&nbsp;&nbsp; Airepro will commence providing services only upon
                            receipt of amount/charges upfront either from subscriber or from a
                            third party on behalf of the subscriber.
                        </span>
                    </p>
                    <p
                        className="MsoNormal"
                        style={{
                            marginTop: "12.0pt",
                            marginRight: "0in",
                            marginBottom: "12.0pt",
                            marginLeft: "0in",
                        }}>
                        <span lang="EN">
                            15.&nbsp;&nbsp; This subscription is not transferable i.e. it is
                            for the same person throughout the period of subscription.
                        </span>
                    </p>
                    <p
                        className="MsoNormal"
                        style={{
                            marginTop: "12.0pt",
                            marginRight: "0in",
                            marginBottom: "12.0pt",
                            marginLeft: "0in",
                        }}>
                        <span lang="EN">
                            16.&nbsp;&nbsp; The liability, if any, of Airepro is limited to
                            the extent of the amount paid by the subscriber.
                        </span>
                    </p>
                    <p
                        className="MsoNormal"
                        style={{
                            marginTop: "12.0pt",
                            marginRight: "0in",
                            marginBottom: "12.0pt",
                            marginLeft: "0in",
                        }}>
                        <span lang="EN">
                            17.&nbsp;&nbsp; The subscriber shall be assigned password (s) by
                            Airepro to enable the subscriber to access all the information
                            received through its site Work-elite, but the sole responsibility
                            of the safe custody of the password shall be that of the
                            subscriber and Airepro shall not be responsible for data
                            loss/theft or data/corruption or the wrong usage/misuse of the
                            password and any damage or leak of information and its
                            consequential usage by a third party. Airepro undertakes to take
                            all reasonable precautions at its end to ensure that there is no
                            leakage/misuse of the password granted to the subscriber.
                        </span>
                    </p>
                    <p
                        className="MsoNormal"
                        style={{
                            marginTop: "12.0pt",
                            marginRight: "0in",
                            marginBottom: "12.0pt",
                            marginLeft: "0in",
                        }}>
                        <span lang="EN">
                            18.&nbsp;&nbsp; The subscriber undertakes that the
                            data/information provided by him is true and correct in all
                            respects.
                        </span>
                    </p>
                    <p
                        className="MsoNormal"
                        style={{
                            marginTop: "12.0pt",
                            marginRight: "0in",
                            marginBottom: "12.0pt",
                            marginLeft: "0in",
                        }}>
                        <span lang="EN">
                            19.&nbsp;&nbsp; The User of these services does not claim any
                            copyright or other Intellectual Property Right over the data
                            uploaded by him/her on the website.
                        </span>
                    </p>
                    <p
                        className="MsoNormal"
                        style={{
                            marginTop: "12.0pt",
                            marginRight: "0in",
                            marginBottom: "12.0pt",
                            marginLeft: "0in",
                        }}>
                        <span lang="EN">
                            20.&nbsp;&nbsp; If you would like to stop the service before the
                            due date, please write an email to service@Work-elite
                        </span>
                    </p>
                    <p
                        className="MsoNormal"
                        style={{
                            marginTop: "12.0pt",
                            marginRight: "0in",
                            marginBottom: "12.0pt",
                            marginLeft: "0in",
                        }}>
                        <span lang="EN">
                            21.&nbsp;&nbsp; The J4U service will be processed within 4 working
                            days after we receive your updated resume. In case we do not
                            receive the same within 7 days, we will use your last updated
                            resume on Work-elite for processing your service
                        </span>
                    </p>
                    <p
                        className="MsoNormal"
                        style={{
                            marginTop: "12.0pt",
                            marginRight: "0in",
                            marginBottom: "12.0pt",
                            marginLeft: "0in",
                        }}>
                        <span lang="EN">
                            22.&nbsp;&nbsp; You will receive 1 call from Work-eliteExpert
                            within 4 working days after you purchase Jobs4U service.
                        </span>
                    </p>
                    <p
                        className="MsoNormal"
                        style={{
                            marginTop: "12.0pt",
                            marginRight: "0in",
                            marginBottom: "12.0pt",
                            marginLeft: "0in",
                        }}>
                        <span lang="EN">
                            23.&nbsp;&nbsp; We will initiate 3 calling attempts to connect
                            with you. In case No-response from your end post 3rd attempt, we
                            will initiate the activation of J4U service &amp; take it as
                            deemed approved. Users are advised to connect to TFN- 18001025557
                            for any related information
                        </span>
                    </p>
                    <p
                        className="MsoNormal"
                        style={{
                            marginTop: "12.0pt",
                            marginRight: "0in",
                            marginBottom: "12.0pt",
                            marginLeft: "0in",
                        }}>
                        <span lang="EN">&nbsp;</span>
                    </p>
                    <ol style={{ marginTop: "0in" }} start={1} type={1}>
                        <li className="MsoNormal" style={{ marginTop: "12.0pt" }}>
                            <span
                                className="text-lg block text-center font-semibold"
                                lang="EN">
                                Interview Pro
                            </span>
                        </li>
                    </ol>
                    <ol style={{ marginTop: "0in" }} start={1} type={1}>
                        <li className="MsoNormal">
                            <span lang="EN">
                                The payment for service once subscribed to by the subscriber is
                                not refundable and any amount paid shall stand appropriated.
                            </span>
                        </li>
                    </ol>
                    <ol style={{ marginTop: "0in" }} start={1} type={1}>
                        <li className="MsoNormal" style={{ marginBottom: "12.0pt" }}>
                            <span lang="EN">The user understands that:</span>
                        </li>
                    </ol>
                    <p
                        className="MsoNormal"
                        style={{
                            marginTop: "12.0pt",
                            marginRight: "0in",
                            marginBottom: "12.0pt",
                            marginLeft: "0in",
                        }}>
                        <span lang="EN">
                            a.&nbsp; &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; Services once
                            subscribed can only be activated post the subscriber having
                            uploaded his/her updated profile.
                        </span>
                    </p>
                    <p
                        className="MsoNormal"
                        style={{
                            marginTop: "12.0pt",
                            marginRight: "0in",
                            marginBottom: "12.0pt",
                            marginLeft: "0in",
                        }}>
                        <span lang="EN">
                            b.&nbsp; &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; The Industry expert
                            who conducts the interview can only do so once, service has been
                            activated and the resume of the user/subscriber has been shared by
                            the user/subscriber
                        </span>
                    </p>
                    <p
                        className="MsoNormal"
                        style={{
                            marginTop: "12.0pt",
                            marginRight: "0in",
                            marginBottom: "12.0pt",
                            marginLeft: "0in",
                        }}>
                        <span lang="EN">
                            c.&nbsp; &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; The user understands
                            that this is a time based product to be utilized within a
                            specified period of time. Once subscribed therefore the Interview
                            Pro product must be activated and utilized by him/her within a
                            reasonable period of time of the same being subscribed.
                        </span>
                    </p>
                    <ol style={{ marginTop: "0in" }} start={1} type={1}>
                        <li className="MsoNormal" style={{ marginTop: "12.0pt" }}>
                            <span lang="EN">
                                Airepro (India) Ltd. does not share personally identifiable data
                                of any individual with other companies/entities without
                                obtaining permission. Airepro (India) Ltd. may share all such
                                information that it has in its possession for its own purposes
                                including sending promotional mailers etc and in response to
                                legal process, such as a court order or subpoena.
                            </span>
                        </li>
                    </ol>
                    <ol style={{ marginTop: "0in" }} start={1} type={1}>
                        <li className="MsoNormal">
                            <span lang="EN">
                                The user undertakes that he/she will not disseminate
                                false/objectionable/offensive material using these services.
                            </span>
                        </li>
                    </ol>
                    <ol style={{ marginTop: "0in" }} start={1} type={1}>
                        <li className="MsoNormal">
                            <span lang="EN">
                                Airepro would not be held liable for loss of any data technical
                                or otherwise, and particulars supplied by subscribers due to
                                reasons beyond its control like corruption of data or delay or
                                failure to perform as a result of any causes or conditions that
                                are beyond Airepro's reasonable control including but not
                                limited to strikes, riots, civil unrest, Govt. policies,
                                tampering of data by unauthorized persons like hackers, war and
                                natural calamities. Airepro will commence providing services
                                only upon receipt of amount/charges upfront either from
                                subscriber or from a third party on behalf of the subscriber.
                            </span>
                        </li>
                    </ol>
                    <ol style={{ marginTop: "0in" }} start={1} type={1}>
                        <li className="MsoNormal">
                            <span lang="EN">
                                This subscription is not transferable i.e. it is for the same
                                person throughout the period of subscription.
                            </span>
                        </li>
                    </ol>
                    <ol style={{ marginTop: "0in" }} start={1} type={1}>
                        <li className="MsoNormal">
                            <span lang="EN">
                                The liability, if any, of Airepro is limited to the extent of
                                the amount paid by the subscriber.
                            </span>
                        </li>
                    </ol>
                    <ol style={{ marginTop: "0in" }} start={1} type={1}>
                        <li className="MsoNormal">
                            <span lang="EN">
                                The subscriber shall be assigned password (s) by Airepro to
                                enable the subscriber to access all the information received
                                through its site Work-elite, but the sole responsibility of the
                                safe custody of the password shall be that of the subscriber and
                                Airepro shall not be responsible for data loss/theft or
                                data/corruption or the wrong usage/misuse of the password and
                                any damage or leak of information and its consequential usage by
                                a third party. Airepro undertakes to take all reasonable
                                precautions at its end to ensure that there is no leakage/misuse
                                of the password granted to the subscriber.
                            </span>
                        </li>
                    </ol>
                    <ol style={{ marginTop: "0in" }} start={1} type={1}>
                        <li className="MsoNormal" style={{ marginBottom: "12.0pt" }}>
                            <span lang="EN">
                                The subscriber undertakes that the data/information provided by
                                him is true and correct in all respects.
                            </span>
                        </li>
                    </ol>
                    <p
                        className="MsoNormal"
                        style={{
                            marginTop: "12.0pt",
                            marginRight: "0in",
                            marginBottom: "12.0pt",
                            marginLeft: "0in",
                        }}>
                        <span lang="EN">&nbsp;</span>
                    </p>
                    <p
                        className="MsoNormal"
                        style={{
                            marginTop: "12.0pt",
                            marginRight: "0in",
                            marginBottom: "12.0pt",
                            marginLeft: "0in",
                        }}>
                        <span lang="EN">&nbsp;</span>
                    </p>
                    <ol style={{ marginTop: "0in" }} start={1} type={1}>
                        <li className="MsoNormal" style={{ marginTop: "12.0pt" }}>
                            <span
                                className="text-lg block text-center font-semibold"
                                lang="EN">
                                Applications by Non Registered Users
                            </span>
                        </li>
                    </ol>
                    <ol style={{ marginTop: "0in" }} start={1} type={1}>
                        <li className="MsoNormal">
                            <span lang="EN">
                                The user undertakes that the data/information being provided by
                                him/her in the resume is true and correct in all respects..
                            </span>
                        </li>
                    </ol>
                    <ol style={{ marginTop: "0in" }} start={1} type={1}>
                        <li className="MsoNormal">
                            <span lang="EN">
                                Airepro (India) Ltd. does not share personally identifiable data
                                of any individual with other companies/entities without
                                obtaining permission. Airepro (India) Ltd. may share all such
                                information that it has in its possession for its own purposes
                                including sending promotional mailers etc and in response to
                                legal process, such as a court order or subpoena.
                            </span>
                        </li>
                    </ol>
                    <ol style={{ marginTop: "0in" }} start={1} type={1}>
                        <li className="MsoNormal">
                            <span lang="EN">
                                The user undertakes that he/she will not disseminate
                                false/objectionable/offensive material using these services.
                            </span>
                        </li>
                    </ol>
                    <ol style={{ marginTop: "0in" }} start={1} type={1}>
                        <li className="MsoNormal">
                            <span lang="EN">
                                This interface shall be exclusively for the purposes of bona
                                fide job applications; usage of the interface in any other
                                fashion is strictly prohibited.
                            </span>
                        </li>
                    </ol>
                    <ol style={{ marginTop: "0in" }} start={1} type={1}>
                        <li className="MsoNormal">
                            <span lang="EN">
                                Airepro neither guarantees nor offers any warranty about the
                                credentials bonafides, status or otherwise of the prospective
                                employer/organization which downloads the resume/ insertion or
                                information/data and uses it to contact the user.
                            </span>
                        </li>
                    </ol>
                    <ol style={{ marginTop: "0in" }} start={1} type={1}>
                        <li className="MsoNormal">
                            <span lang="EN">
                                The user shall not infringe on any intellectual property rights
                                of any person/entity or retain information/download any
                                information from any computer system or otherwise with an
                                intention to do so.
                            </span>
                        </li>
                    </ol>
                    <ol style={{ marginTop: "0in" }} start={1} type={1}>
                        <li className="MsoNormal">
                            <span lang="EN">
                                The User/subscriber/visitor to work-elite or affiliated site(s)
                                is prohibited from introducing/posting or transmitting
                                information or software, which contains a computer virus, or a
                                contaminant, worm or other harmful components on the internet or
                                on work-elite site or sub-domains or on any affiliate sites or
                                any other network system
                            </span>
                        </li>
                    </ol>
                    <ol style={{ marginTop: "0in" }} start={1} type={1}>
                        <li className="MsoNormal">
                            <span lang="EN">
                                Airepro (India) Ltd. will not be party to any legal proceedings
                                between a user (e.g. a subscriber) and a party contracted
                                through the site. In case Airepro (India) Ltd. is implicated in
                                any legal proceedings, costs will be recovered from the party
                                that names Airepro (India) Ltd. Airepro however will abide by
                                any court order served on it through due process.
                            </span>
                        </li>
                    </ol>
                    <ol style={{ marginTop: "0in" }} start={1} type={1}>
                        <li className="MsoNormal" style={{ marginBottom: "12.0pt" }}>
                            <span lang="EN">
                                When you indicate your interest in a Job Listing on work-elite,
                                you are sending your CV and application information including
                                relevant documents to work-elite, and you are requesting and
                                authorizing work-elite to make available such application
                                information to the applicable Employer(s) for such Job
                                Listing(s).
                            </span>
                        </li>
                    </ol>
                    <p
                        className="MsoNormal"
                        style={{
                            marginTop: "12.0pt",
                            marginRight: "0in",
                            marginBottom: "12.0pt",
                            marginLeft: "0in",
                        }}>
                        <span lang="EN">
                            10.&nbsp;&nbsp; In addition, by using work-elite, you agree that
                            work-elite is not responsible for the content of the Employer's
                            application form, messages, screener questions, testing
                            assessments; required documents, or their format or method of
                            delivery.
                        </span>
                    </p>
                    <p
                        className="MsoNormal"
                        style={{
                            marginTop: "12.0pt",
                            marginRight: "0in",
                            marginBottom: "12.0pt",
                            marginLeft: "0in",
                        }}>
                        <span lang="EN">
                            11.&nbsp;&nbsp; You consent to your application, documents and any
                            responses sent to you by the Employer or vice versa through
                            work-elite being processed and analysed by work-elite according to
                            these terms of use and work-elite's Privacy Policy. Work-elite
                            shall store and process such information regardless of whether a
                            job vacancy has been filled. Work-elite may use your application
                            materials (including public CVs and responses to employer's
                            questions) to determine whether you may be interested in a Job
                            Listing, and work-elite may reach out to you about such Job
                            Listing.
                        </span>
                    </p>
                    <p
                        className="MsoNormal"
                        style={{
                            marginTop: "12.0pt",
                            marginRight: "0in",
                            marginBottom: "12.0pt",
                            marginLeft: "0in",
                        }}>
                        <span lang="EN">
                            12.&nbsp;&nbsp; Information you post in public areas of Work-elite
                            sites or applications or make visible in the resume and profile
                            database may be accessed, used, and stored by others around the
                            world, including those in countries that might not have
                            legislation that guarantees adequate protection of personal
                            information as defined by your country of residence. While
                            Work-elite takes measures to safeguard your information from
                            unauthorized access or inappropriate use, Work-elite does not
                            control these third parties and we are not responsible for their
                            use of information you give to us. Accordingly, you should not
                            post sensitive information or any other information you would not
                            want made public, to any portion of Work-elite or application or
                            to a public website.
                        </span>
                    </p>
                    <p
                        className="MsoNormal"
                        style={{
                            marginTop: "12.0pt",
                            marginRight: "0in",
                            marginBottom: "12.0pt",
                            marginLeft: "0in",
                        }}>
                        <span lang="EN">&nbsp;</span>
                    </p>
                    <p
                        className="MsoNormal"
                        style={{
                            marginTop: "12.0pt",
                            marginRight: "0in",
                            marginBottom: "12.0pt",
                            marginLeft: "0in",
                        }}>
                        <span lang="EN">&nbsp;</span>
                    </p>
                    <ol style={{ marginTop: "0in" }} start={1} type={1}>
                        <li className="MsoNormal" style={{ marginTop: "12.0pt" }}>
                            <span
                                className="text-lg block text-center font-semibold"
                                lang="EN">
                                Job Search &amp; Resume Services on SMS
                            </span>
                        </li>
                    </ol>
                    <ol style={{ marginTop: "0in" }} start={1} type={1}>
                        <li className="MsoNormal">
                            <span lang="EN">
                                The subscriber availing this service shall be deemed to have
                                consented to be bound by all the applicable terms and conditions
                                of this service.
                            </span>
                        </li>
                    </ol>
                    <ol style={{ marginTop: "0in" }} start={1} type={1}>
                        <li className="MsoNormal">
                            <span lang="EN">
                                Decision of Airepro regarding all transactions under this
                                service shall be final and binding and no correspondence shall
                                be entertained in this regard.
                            </span>
                        </li>
                    </ol>
                    <ol style={{ marginTop: "0in" }} start={1} type={1}>
                        <li className="MsoNormal">
                            <span lang="EN">
                                Airepro reserves the right to extend, cancel, discontinue,
                                prematurely withdraw, change, alter or modify this service or
                                any part thereof including charges, at its sole discretion at
                                any time as may be required in view of business exigencies
                                and/or regulatory or statutory changes.
                            </span>
                        </li>
                    </ol>
                    <ol style={{ marginTop: "0in" }} start={1} type={1}>
                        <li className="MsoNormal">
                            <span lang="EN">
                                Your mobile phone number (MSISDN) will be used during the
                                transmission of text messages through the mobile service
                                provider's server for SMS Service.
                            </span>
                        </li>
                    </ol>
                    <ol style={{ marginTop: "0in" }} start={1} type={1}>
                        <li className="MsoNormal">
                            <span lang="EN">
                                The membership is for your personal use only. You cannot
                                transfer, assign or authorize your membership to any other
                                person.
                            </span>
                        </li>
                    </ol>
                    <ol style={{ marginTop: "0in" }} start={1} type={1}>
                        <li className="MsoNormal">
                            <span lang="EN">
                                The subscriber understands that he/she can avail SMS Services at
                                his/her discretion and the said service shall be availed in such
                                options as are made available by Airepro from time to time.
                            </span>
                        </li>
                    </ol>
                    <ol style={{ marginTop: "0in" }} start={1} type={1}>
                        <li className="MsoNormal">
                            <span lang="EN">
                                This service is subject to guidelines/directions issued by
                                Telecom Regulatory Authority of India or any other statutory
                                authority from time to time.
                            </span>
                        </li>
                    </ol>
                    <ol style={{ marginTop: "0in" }} start={1} type={1}>
                        <li className="MsoNormal">
                            <span lang="EN">
                                The SMS or its contents once sent for availing the SMS services
                                shall be treated as final and the same cannot be withdrawn,
                                changed or retrieved subsequently under any circumstances.
                            </span>
                        </li>
                    </ol>
                    <ol style={{ marginTop: "0in" }} start={1} type={1}>
                        <li className="MsoNormal" style={{ marginBottom: "12.0pt" }}>
                            <span lang="EN">
                                WAP Services enable you to access our Services and to submit
                                and/or receive Content through your wireless Device. Your access
                                to our WAP Services may be dependent on the wireless Device you
                                use to access the applicable WAP services.
                            </span>
                        </li>
                    </ol>
                    <p
                        className="MsoNormal"
                        style={{
                            marginTop: "12.0pt",
                            marginRight: "0in",
                            marginBottom: "12.0pt",
                            marginLeft: "0in",
                        }}>
                        <span lang="EN">
                            10.&nbsp;&nbsp; Subscription Services provide you with access to
                            certain Content for a selected period of time, which will be as
                            indicated and chosen by you prior to purchase. The frequency with
                            which you will receive the relevant Content will be notified to
                            you at the time you subscribe for the service.
                        </span>
                    </p>
                    <p
                        className="MsoNormal"
                        style={{
                            marginTop: "12.0pt",
                            marginRight: "0in",
                            marginBottom: "12.0pt",
                            marginLeft: "0in",
                        }}>
                        <span lang="EN">
                            11.&nbsp;&nbsp; You will not post or transmit any content that is
                            abusive, obscene, sexually oriented or against national interest.
                            Airepro reserves the right to suspend your profile if any
                            prohibitive or objectionable content is found and may further
                            initiate appropriate legal proceedings against you.
                        </span>
                    </p>
                    <p
                        className="MsoNormal"
                        style={{
                            marginTop: "12.0pt",
                            marginRight: "0in",
                            marginBottom: "12.0pt",
                            marginLeft: "0in",
                        }}>
                        <span lang="EN">
                            12.&nbsp;&nbsp; The Service is an additional service offered by
                            Airepro. The functions of the Service are dependent on the
                            Operator owning the network to facilitate this service (Operator),
                            for which Airepro does not undertake any responsibility for
                            failure of this network transmission or failure of message
                            transmission for any reasons whatsoever. From time to time,
                            Airepro may include additional features and services.
                        </span>
                    </p>
                    <p
                        className="MsoNormal"
                        style={{
                            marginTop: "12.0pt",
                            marginRight: "0in",
                            marginBottom: "12.0pt",
                            marginLeft: "0in",
                        }}>
                        <span lang="EN">
                            13.&nbsp;&nbsp; Airepro reserves the right to modify/delete the
                            profile contents at its own discretion without prior notice if the
                            contents of profile are deemed unfit for broadcast.
                        </span>
                    </p>
                    <p
                        className="MsoNormal"
                        style={{
                            marginTop: "12.0pt",
                            marginRight: "0in",
                            marginBottom: "12.0pt",
                            marginLeft: "0in",
                        }}>
                        <span lang="EN">
                            14.&nbsp;&nbsp; Airepro is not responsible for the authenticity of
                            the content arising thereto.
                        </span>
                    </p>
                    <p
                        className="MsoNormal"
                        style={{
                            marginTop: "12.0pt",
                            marginRight: "0in",
                            marginBottom: "12.0pt",
                            marginLeft: "0in",
                        }}>
                        <span lang="EN">
                            15.&nbsp;&nbsp; The subscriber must maintain such minimum balance
                            in his/her prepaid account as is specified by Airepro for availing
                            the particular option offered under these services. All incidental
                            costs/taxes/levies, if any, related to the VAS shall be entirely
                            borne by the customer.
                        </span>
                    </p>
                    <p
                        className="MsoNormal"
                        style={{
                            marginTop: "12.0pt",
                            marginRight: "0in",
                            marginBottom: "12.0pt",
                            marginLeft: "0in",
                        }}>
                        <span lang="EN">
                            16.&nbsp;&nbsp; The users specifically note and agree that the
                            content and service or part thereof may be varied, added,
                            withdrawn, withheld or suspended by Airepro at its sole discretion
                            without prior notice to the users.
                        </span>
                    </p>
                    <p
                        className="MsoNormal"
                        style={{
                            marginTop: "12.0pt",
                            marginRight: "0in",
                            marginBottom: "12.0pt",
                            marginLeft: "0in",
                        }}>
                        <span lang="EN">
                            17.&nbsp;&nbsp; Airepro shall not be liable for any costs, loss or
                            damage (whether direct or indirect), or for loss of revenue, loss
                            of profits or any consequential loss whatsoever as a result of the
                            user using the Service.
                        </span>
                    </p>
                    <p
                        className="MsoNormal"
                        style={{
                            marginTop: "12.0pt",
                            marginRight: "0in",
                            marginBottom: "12.0pt",
                            marginLeft: "0in",
                        }}>
                        <span lang="EN">
                            18.&nbsp;&nbsp; No reversal of deducted charges shall be allowed
                            under any circumstances.
                        </span>
                    </p>
                    <p
                        className="MsoNormal"
                        style={{
                            marginTop: "12.0pt",
                            marginRight: "0in",
                            marginBottom: "12.0pt",
                            marginLeft: "0in",
                        }}>
                        <span lang="EN">
                            19.&nbsp;&nbsp; The users shall remain solely responsible for all
                            content, information, data originated from the users and
                            transmitted via the Service (content), and the users shall
                            accordingly indemnify Airepro and / or the Operator, against all
                            third party claims relating to the users content or due to the
                            users act, negligence or omission.
                        </span>
                    </p>
                    <p
                        className="MsoNormal"
                        style={{
                            marginTop: "12.0pt",
                            marginRight: "0in",
                            marginBottom: "12.0pt",
                            marginLeft: "0in",
                        }}>
                        <span lang="EN">
                            20.&nbsp;&nbsp; You are bound by the terms and conditions as
                            mentioned herein and as stated on the site.
                        </span>
                    </p>
                    <p
                        className="MsoNormal"
                        style={{
                            marginTop: "12.0pt",
                            marginRight: "0in",
                            marginBottom: "12.0pt",
                            marginLeft: "0in",
                        }}>
                        <span lang="EN">
                            21.&nbsp;&nbsp; Message delivery is conditional to Mobile
                            operator's technical infrastructure and its network uptime.
                        </span>
                    </p>
                    <p
                        className="MsoNormal"
                        style={{
                            marginTop: "12.0pt",
                            marginRight: "0in",
                            marginBottom: "12.0pt",
                            marginLeft: "0in",
                        }}>
                        <span lang="EN">
                            22.&nbsp;&nbsp; By using various SMS based services from Airepro
                            like Job Search, Job Alert, Work-eliteforum Chat, Career services
                            etc , you agree to receive phone calls, messages etc. from Airepro
                            and/or its associates tailored to provide with better job
                            opportunities.
                        </span>
                    </p>
                    <p
                        className="MsoNormal"
                        style={{
                            marginTop: "12.0pt",
                            marginRight: "0in",
                            marginBottom: "12.0pt",
                            marginLeft: "0in",
                        }}>
                        <span lang="EN">
                            23.&nbsp;&nbsp; Subscribing or using various paid/free services of
                            Airepro on SMS/Voice/WAP either directly or indirectly doesn't in
                            any manner guarantee the user a job.
                        </span>
                    </p>
                    <p
                        className="MsoNormal"
                        style={{
                            marginTop: "12.0pt",
                            marginRight: "0in",
                            marginBottom: "12.0pt",
                            marginLeft: "0in",
                        }}>
                        <span lang="EN">
                            24.&nbsp;&nbsp; Airepro and/or its respective suppliers make no
                            representations about the suitability, reliability, availability,
                            timeliness, lack of viruses or other harmful components and
                            accuracy of the information, software, products, services and
                            related graphics contained within the Airepro sites/services for
                            any purpose. All such information, software, products, services
                            and related graphics are provided "as is" without warranty of any
                            kind. Airepro and/or its respective suppliers hereby disclaim all
                            warranties and conditions with regard to this information,
                            software, products, services and related graphics, including all
                            implied warranties and conditions of merchantability, fitness for
                            a particular purpose, workmanlike effort, title and
                            non-infringement. Airepro shall not be responsible or liable for
                            any consequential damages arising thereto.
                        </span>
                    </p>
                    <p
                        className="MsoNormal"
                        style={{
                            marginTop: "12.0pt",
                            marginRight: "0in",
                            marginBottom: "12.0pt",
                            marginLeft: "0in",
                        }}>
                        <span lang="EN">
                            25.&nbsp;&nbsp; By agreeing to register at Work-elite, a user
                            allows Airepro to get in touch with him/her from time to time on
                            events or offers regarding jobs and ancillary services on mobile.
                            This can include exciting offers, information, as well as
                            promotions.
                        </span>
                    </p>
                    <p
                        className="MsoNormal"
                        style={{
                            marginTop: "12.0pt",
                            marginRight: "0in",
                            marginBottom: "12.0pt",
                            marginLeft: "0in",
                        }}>
                        <span lang="EN">
                            26.&nbsp;&nbsp; The subscriber shall comply with all
                            directions/instructions etc. issued by the Company relating to the
                            network, the services and any/all matters connected therewith and
                            provide the Company all other and further information and
                            co-operation as the Company may require from time to time.
                        </span>
                    </p>
                    <p
                        className="MsoNormal"
                        style={{
                            marginTop: "12.0pt",
                            marginRight: "0in",
                            marginBottom: "12.0pt",
                            marginLeft: "0in",
                        }}>
                        <span lang="EN">
                            27.&nbsp;&nbsp; Registration presumes that the users have read,
                            understood and accepted the terms and conditions.
                        </span>
                    </p>
                    <p
                        className="MsoNormal"
                        style={{
                            marginTop: "12.0pt",
                            marginRight: "0in",
                            marginBottom: "12.0pt",
                            marginLeft: "0in",
                        }}>
                        <span lang="EN">
                            28.&nbsp;&nbsp; This service is live in India only.
                        </span>
                    </p>
                    <p
                        className="MsoNormal"
                        style={{
                            marginTop: "12.0pt",
                            marginRight: "0in",
                            marginBottom: "12.0pt",
                            marginLeft: "0in",
                        }}>
                        <span lang="EN">&nbsp;</span>
                    </p>
                    <p
                        className="MsoNormal"
                        style={{
                            marginTop: "12.0pt",
                            marginRight: "0in",
                            marginBottom: "12.0pt",
                            marginLeft: "0in",
                        }}>
                        <b>
                            <span
                                className="text-lg block text-center font-semibold"
                                lang="EN">
                                Recruiter
                            </span>
                        </b>
                    </p>
                    <p
                        className="MsoNormal"
                        style={{
                            marginTop: "12.0pt",
                            marginRight: "0in",
                            marginBottom: "12.0pt",
                            marginLeft: "0in",
                        }}>
                        <span lang="EN">&nbsp;</span>
                    </p>
                    <p
                        className="MsoNormal"
                        style={{
                            marginTop: "12.0pt",
                            marginRight: "0in",
                            marginBottom: "12.0pt",
                            marginLeft: "0in",
                        }}>
                        <span lang="EN">
                            Additional Terms applicable to Recruiters "You" accessing any
                            portion of the website Work-elite:
                        </span>
                    </p>
                    <ol style={{ marginTop: "0in" }} start={1} type={1}>
                        <li className="MsoNormal" style={{ marginTop: "12.0pt" }}>
                            <span lang="EN">
                                You will comply with all applicable data protection laws in
                                relation to the processing of personal data; and not process
                                personal data in an unlawful manner and excessive with regard to
                                agreed purposes as defined in the privacy policy and this terms
                                and conditions
                            </span>
                        </li>
                    </ol>
                    <ol style={{ marginTop: "0in" }} start={1} type={1}>
                        <li className="MsoNormal">
                            <span lang="EN">
                                You shall implement adequate technical and organizational
                                controls to protect the shared personal data obtained from the
                                Company against unauthorized or unlawful processing and against
                                accidental loss, destruction, damage, alteration or disclosure
                            </span>
                        </li>
                    </ol>
                    <ol style={{ marginTop: "0in" }} start={1} type={1}>
                        <li className="MsoNormal">
                            <span lang="EN">
                                The onus of any misuse of personal details accessed through your
                                account lies on You. Access to services subscribed by You may be
                                availed of and extended to authorized personnel only i.e.
                                persons who are bound by employment agreements and
                                confidentiality agreements
                            </span>
                        </li>
                    </ol>
                    <ol style={{ marginTop: "0in" }} start={1} type={1}>
                        <li className="MsoNormal">
                            <span lang="EN">
                                You agree to provide reasonable assistance as is necessary to
                                facilitate the handling of any Data Security Breach (as relevant
                                under privacy laws applicable) in an expeditious and compliant
                                manner
                            </span>
                        </li>
                    </ol>
                    <ol style={{ marginTop: "0in" }} start={1} type={1}>
                        <li className="MsoNormal">
                            <span lang="EN">
                                You agree that the responsibility for complying with a data
                                subject /data principal request lies with the Party which
                                holds/processes the Personal Data collected/shared
                            </span>
                        </li>
                    </ol>
                    <ol style={{ marginTop: "0in" }} start={1} type={1}>
                        <li className="MsoNormal">
                            <span lang="EN">
                                You warrant and represent that the institution shall not
                                disclose or transfer Personal Data obtained from the Company to
                                any sub-processors without ensuring adequate and equivalent
                                safeguards to the Personal Data.
                            </span>
                        </li>
                    </ol>
                    <ol style={{ marginTop: "0in" }} start={1} type={1}>
                        <li className="MsoNormal">
                            <span lang="EN">
                                You shall retain or process shared Personal Data for no longer
                                than is necessary to carry out the agreed purposes.
                            </span>
                        </li>
                    </ol>
                    <ol style={{ marginTop: "0in" }} start={1} type={1}>
                        <li className="MsoNormal">
                            <span lang="EN">
                                You shall act as an independent Data Controller in respect of
                                shared personal data obtained from the Company once the data is
                                collected by You and You shall be responsible for its secure use
                                at all times.
                            </span>
                        </li>
                    </ol>
                    <ol style={{ marginTop: "0in" }} start={1} type={1}>
                        <li className="MsoNormal">
                            <span lang="EN">Classified Job listing (single)</span>
                        </li>
                    </ol>
                    <ol style={{ marginTop: "0in" }} start={1} type={1}>
                        <li className="MsoNormal">
                            <span lang="EN">
                                Airepro shall place the information relating to vacancies in the
                                Hot Vacancies &amp; such other Classified sections on the
                                website work-elite or such other mirror or parallel site(s) or
                                in allied publications as Airepro may deem fit and proper but
                                such additional web hosting shall be without any extra cost to
                                the subscriber / user.
                            </span>
                        </li>
                    </ol>
                    <ol style={{ marginTop: "0in" }} start={1} type={1}>
                        <li className="MsoNormal">
                            <span lang="EN">
                                The insertion so displayed in the classified section of
                                work-elite shall be for a fixed period (currently 30 days),
                                which period is subject to change without notice. Every instance
                                of refreshing and existing listing entitles you to an additional
                                fixed period (currently 30 days) starting from the date on which
                                the listing is refreshed and shall be counted as fresh posting.
                            </span>
                        </li>
                    </ol>
                    <ol style={{ marginTop: "0in" }} start={1} type={1}>
                        <li className="MsoNormal">
                            <span lang="EN">
                                Airepro reserves its right to reject any insertion or
                                information/data provided by the subscriber without assigning
                                any reason either before uploading or after uploading the
                                vacancy details, but in such an eventuality, any amount so paid
                                for, may be refunded to the subscriber on a pro-rata basis at
                                the sole discretion of Airepro.
                            </span>
                        </li>
                    </ol>
                    <ol style={{ marginTop: "0in" }} start={1} type={1}>
                        <li className="MsoNormal">
                            <span lang="EN">
                                By posting/uploading a job posting on the website you confirm
                                that you have obtained all licenses/permits as are necessary for
                                recruitment and to indemnify IEIL against all claims, damages
                                arising out of actions/claims that may be made in relation to
                                the same.
                            </span>
                        </li>
                    </ol>
                    <ol style={{ marginTop: "0in" }} start={1} type={1}>
                        <li className="MsoNormal">
                            <span lang="EN">
                                Airepro has the right to make all such modifications/editing of
                                the vacancy details in order to fit its database.
                            </span>
                        </li>
                    </ol>
                    <ol style={{ marginTop: "0in" }} start={1} type={1}>
                        <li className="MsoNormal">
                            <span lang="EN">
                                The Subscriber/Recruiter will provide up to a maximum of 2 email
                                id's for vacancies posted on work-elite in the Classified
                                section to receive email notifications of applications, if any.
                                The contact information given by the subscriber for all listings
                                should be the same and the subscriber cannot give multiple
                                contact information/data for the purpose of listing.
                            </span>
                        </li>
                    </ol>
                    <ol style={{ marginTop: "0in" }} start={1} type={1}>
                        <li className="MsoNormal">
                            <span lang="EN">
                                All information intimated by the subscriber / recruiter and
                                displayed by Airepro on work-elite becomes public knowledge and
                                Airepro may at its sole discretion include the vacancy
                                intimidated by a subscriber for display on work-elite in any
                                other media including the print media at no extra costs to the
                                subscriber and Airepro shall not be held liable for
                                usage/publicity of such information.
                            </span>
                        </li>
                    </ol>
                    <ol style={{ marginTop: "0in" }} start={1} type={1}>
                        <li className="MsoNormal">
                            <span lang="EN">
                                Airepro offers no guarantee nor warranties that there would be a
                                satisfactory response or any at all response once the job is put
                                on display.
                            </span>
                        </li>
                    </ol>
                    <ol style={{ marginTop: "0in" }} start={1} type={1}>
                        <li className="MsoNormal" style={{ marginBottom: "12.0pt" }}>
                            <span lang="EN">
                                Airepro shall in no way be held liable for any information
                                received by the subscriber and it shall be the sole
                                responsibility of the subscriber to check, authenticate and
                                verify the information/response received at its own cost and
                                expense.
                            </span>
                        </li>
                    </ol>
                    <p
                        className="MsoNormal"
                        style={{
                            marginTop: "12.0pt",
                            marginRight: "0in",
                            marginBottom: "12.0pt",
                            marginLeft: "0in",
                        }}>
                        <span lang="EN">
                            10.&nbsp;&nbsp; Airepro would not be held liable for any loss of
                            data, technical or otherwise, information, particulars supplied by
                            the subscriber, due the reasons beyond its control like corruption
                            of data or delay or failure to perform as a result of any causes
                            or conditions that are beyond Airepro's reasonable control
                            including but not limited to strike, riots, civil unrest, Govt.
                            policies, tampering of data by unauthorized persons like hackers,
                            war and natural calamities.
                        </span>
                    </p>
                    <p
                        className="MsoNormal"
                        style={{
                            marginTop: "12.0pt",
                            marginRight: "0in",
                            marginBottom: "12.0pt",
                            marginLeft: "0in",
                        }}>
                        <span lang="EN">
                            11.&nbsp;&nbsp; Airepro will commence providing services only upon
                            receipt of amount/charges upfront either from the subscriber or
                            from a third party on behalf of the subscriber.
                        </span>
                    </p>
                    <p
                        className="MsoNormal"
                        style={{
                            marginTop: "12.0pt",
                            marginRight: "0in",
                            marginBottom: "12.0pt",
                            marginLeft: "0in",
                        }}>
                        <span lang="EN">
                            12.&nbsp;&nbsp; The subscriber/Recruiter shall be deemed to give
                            an undertaking to Airepro that the jobs sought to be advertised on
                            the classified section of work-elite are in existence, are genuine
                            and that the subscriber / recruiter has the authority to advertise
                            for such jobs.
                        </span>
                    </p>
                    <p
                        className="MsoNormal"
                        style={{
                            marginTop: "12.0pt",
                            marginRight: "0in",
                            marginBottom: "12.0pt",
                            marginLeft: "0in",
                        }}>
                        <span lang="EN">
                            13.&nbsp;&nbsp; The subscriber/Recruiter must give an undertaking
                            to Airepro that there will be no fee charged from any person who
                            responds to jobs advertised on the classified section of
                            work-elite for processing of applications / responses from such
                            persons.
                        </span>
                    </p>
                    <p
                        className="MsoNormal"
                        style={{
                            marginTop: "12.0pt",
                            marginRight: "0in",
                            marginBottom: "12.0pt",
                            marginLeft: "0in",
                        }}>
                        <span lang="EN">
                            14.&nbsp;&nbsp; Airepro reserves its right to change the look,
                            feel, design, prominence, depiction, classification of the
                            classified section of Work-elite at any time without assigning any
                            reason and without giving any notice.
                        </span>
                    </p>
                    <p
                        className="MsoNormal"
                        style={{
                            marginTop: "12.0pt",
                            marginRight: "0in",
                            marginBottom: "12.0pt",
                            marginLeft: "0in",
                        }}>
                        <span lang="EN">
                            15.&nbsp;&nbsp; The subscription is neither re-saleable nor
                            transferable by the subscriber to any other person, corporate
                            body, firm or individual. Usage from third party premises/networks
                            without prior written permission of IEIL is not permitted.
                        </span>
                    </p>
                    <p
                        className="MsoNormal"
                        style={{
                            marginTop: "12.0pt",
                            marginRight: "0in",
                            marginBottom: "12.0pt",
                            marginLeft: "0in",
                        }}>
                        <span lang="EN">
                            16.&nbsp;&nbsp; The subscriber shall be assigned password (s) by
                            Airepro to enable the subscriber to post vacancies on the site in
                            agreed upon section(s), but the sole responsibility of the safe
                            custody of the password shall be that of the subscriber and
                            Airepro shall not be responsible for data loss/theft of
                            data/corruption of data or the wrong usage/misuse of the password
                            and any damage or leak of information and its consequential usage
                            by a third party. Airepro undertakes to take all reasonable
                            precautions at its end to ensure that there is no leakage/misuse
                            of the password granted to the subscriber.
                        </span>
                    </p>
                    <p
                        className="MsoNormal"
                        style={{
                            marginTop: "12.0pt",
                            marginRight: "0in",
                            marginBottom: "12.0pt",
                            marginLeft: "0in",
                        }}>
                        <span lang="EN">
                            17.&nbsp;&nbsp; The User of these services does not claim any
                            copyright or other Intellectual Property Right over the data
                            uploaded by him/her on the website
                        </span>
                    </p>
                    <p
                        className="MsoNormal"
                        style={{
                            marginTop: "12.0pt",
                            marginRight: "0in",
                            marginBottom: "12.0pt",
                            marginLeft: "0in",
                        }}>
                        <span lang="EN">&nbsp;</span>
                    </p>
                    <ol style={{ marginTop: "0in" }} start={1} type={1}>
                        <li className="MsoNormal" style={{ marginTop: "12.0pt" }}>
                            <span
                                className="text-lg block text-center font-semibold"
                                lang="EN">
                                Classified Subscriptions for various period
                            </span>
                        </li>
                    </ol>
                    <ol style={{ marginTop: "0in" }} start={1} type={1}>
                        <li className="MsoNormal">
                            <span lang="EN">
                                Airepro shall place the information relating to vacancies in the
                                Hot Vacancies &amp; such other Classified sections on the
                                website work-elite or such other mirror or parallel site(s) or
                                in allied publications as Airepro may deem fit and proper but
                                such additional web hosting shall be without any extra cost to
                                the subscriber / user.
                            </span>
                        </li>
                    </ol>
                    <ol style={{ marginTop: "0in" }} start={1} type={1}>
                        <li className="MsoNormal">
                            <span lang="EN">
                                Each insertion so displayed in the classified section of
                                Work-elite shall be for a fixed period (currently 30 days),
                                which period is subject to change without notice. Every instance
                                of refreshing and existing listing entitles you to an additional
                                fixed period (currently 30 days) starting from the date on which
                                the listing is refreshed and shall be counted as a fresh
                                posting.
                            </span>
                        </li>
                    </ol>
                    <ol style={{ marginTop: "0in" }} start={1} type={1}>
                        <li className="MsoNormal">
                            <span lang="EN">
                                Airepro reserves its right to reject any insertion or
                                information/data provided by the subscriber without assigning
                                any reason either before uploading or after uploading the
                                vacancy details, but in such an eventuality, any amount so paid
                                for, may be refunded to the subscriber on a pro-rata basis at
                                the sole discretion of Airepro.
                            </span>
                        </li>
                    </ol>
                    <ol style={{ marginTop: "0in" }} start={1} type={1}>
                        <li className="MsoNormal">
                            <span lang="EN">
                                By posting/uploading a job posting on the website you confirm
                                that you have obtained all licenses/permits as are necessary for
                                recruitment and to indemnify IEIL against all claims, damages
                                arising out of actions/claims that may be made in relation to
                                the same.
                            </span>
                        </li>
                    </ol>
                    <ol style={{ marginTop: "0in" }} start={1} type={1}>
                        <li className="MsoNormal">
                            <span lang="EN">
                                Airepro has the right to make all such modifications/editing of
                                the vacancy details in order to fit its database.
                            </span>
                        </li>
                    </ol>
                    <ol style={{ marginTop: "0in" }} start={1} type={1}>
                        <li className="MsoNormal">
                            <span lang="EN">
                                The Subscriber/Recruiter will provide up to a maximum of 2 email
                                id's for vacancies posted on work-elite in the Classified
                                section to receive email notifications of applications, if any.
                                The contact information given by the subscriber for all listings
                                should be the same and the subscriber cannot give multiple
                                contact information/data for the purpose of listing.
                            </span>
                        </li>
                    </ol>
                    <ol style={{ marginTop: "0in" }} start={1} type={1}>
                        <li className="MsoNormal">
                            <span lang="EN">
                                All information intimated by the client and displayed by Airepro
                                on work-elite becomes public knowledge and Airepro may at its
                                sole discretion include the vacancy intimidated by a client for
                                display on work-elite in any other media including the print
                                media at no extra costs to the client and Airepro cannot be held
                                liable for usage/publicity of such information.
                            </span>
                        </li>
                    </ol>
                    <ol style={{ marginTop: "0in" }} start={1} type={1}>
                        <li className="MsoNormal">
                            <span lang="EN">
                                Airepro offers no guarantee or warranties that there would be a
                                satisfactory response or any response at all once the job is put
                                on display.
                            </span>
                        </li>
                    </ol>
                    <ol style={{ marginTop: "0in" }} start={1} type={1}>
                        <li className="MsoNormal" style={{ marginBottom: "12.0pt" }}>
                            <span lang="EN">
                                Airepro shall in no way be held liable for any information
                                received by the subscriber and it shall be the sole
                                responsibility of the subscriber to check, authenticate and
                                verify the information/response received at its own cost and
                                expense.
                            </span>
                        </li>
                    </ol>
                    <p
                        className="MsoNormal"
                        style={{
                            marginTop: "12.0pt",
                            marginRight: "0in",
                            marginBottom: "12.0pt",
                            marginLeft: "0in",
                        }}>
                        <span lang="EN">
                            10.&nbsp;&nbsp; Airepro would not be held liable for loss of any
                            data whether of technical nature or otherwise or all or any ,
                            information or particulars supplied by the customers due to the
                            reasons beyond its control like corruption of data or delay or
                            failure to perform as a result of any causes or conditions that
                            are beyond Airepro's reasonable control including but not limited
                            to strike, riots, civil unrest, Govt. policies, tampering of data
                            by unauthorized persons like hackers, war and natural calamities.
                        </span>
                    </p>
                    <p
                        className="MsoNormal"
                        style={{
                            marginTop: "12.0pt",
                            marginRight: "0in",
                            marginBottom: "12.0pt",
                            marginLeft: "0in",
                        }}>
                        <span lang="EN">
                            11.&nbsp;&nbsp; Airepro will commence providing services only upon
                            receipt of amount/charges upfront either from the subscriber or
                            from a third party on behalf of the subscriber.
                        </span>
                    </p>
                    <p
                        className="MsoNormal"
                        style={{
                            marginTop: "12.0pt",
                            marginRight: "0in",
                            marginBottom: "12.0pt",
                            marginLeft: "0in",
                        }}>
                        <span lang="EN">
                            12.&nbsp;&nbsp; The subscriber/Recruiter shall give an undertaking
                            to Airepro that the jobs sought to be advertised on the classified
                            section of Work-elite are in existence, are genuine and that the
                            subscriber has the authority to advertise the jobs
                        </span>
                    </p>
                    <p
                        className="MsoNormal"
                        style={{
                            marginTop: "12.0pt",
                            marginRight: "0in",
                            marginBottom: "12.0pt",
                            marginLeft: "0in",
                        }}>
                        <span lang="EN">
                            13.&nbsp;&nbsp; The subscriber/Recruiter must give an undertaking
                            to Airepro that there will be no fee charged from any person who
                            responds to jobs advertised on the classified section of
                            Work-elite for processing of such person
                        </span>
                    </p>
                    <p
                        className="MsoNormal"
                        style={{
                            marginTop: "12.0pt",
                            marginRight: "0in",
                            marginBottom: "12.0pt",
                            marginLeft: "0in",
                        }}>
                        <span lang="EN">
                            14.&nbsp;&nbsp; Airepro reserves its right to change the look,
                            feel, design, prominence, depiction, classification of the
                            classified section of Work-elite at any time without assigning any
                            reason and without giving any notice
                        </span>
                    </p>
                    <p
                        className="MsoNormal"
                        style={{
                            marginTop: "12.0pt",
                            marginRight: "0in",
                            marginBottom: "12.0pt",
                            marginLeft: "0in",
                        }}>
                        <span lang="EN">
                            15.&nbsp;&nbsp; The subscriber to this service shall be entitled
                            to such number of listings during the period of subscription as
                            may be agreed upon
                        </span>
                    </p>
                    <p
                        className="MsoNormal"
                        style={{
                            marginTop: "12.0pt",
                            marginRight: "0in",
                            marginBottom: "12.0pt",
                            marginLeft: "0in",
                        }}>
                        <span lang="EN">
                            16.&nbsp;&nbsp; The subscription is neither re-saleable nor
                            transferable by the subscriber to any other person, corporate
                            body, firm or individual
                        </span>
                    </p>
                    <p
                        className="MsoNormal"
                        style={{
                            marginTop: "12.0pt",
                            marginRight: "0in",
                            marginBottom: "12.0pt",
                            marginLeft: "0in",
                        }}>
                        <span lang="EN">
                            17.&nbsp;&nbsp; Only one office of one corporate entity / firm
                            will be entitled to use this service and in case other offices of
                            the same company/ associated companies, want to use the said
                            service, then, they shall have to pay additional subscription
                            charges for the service
                        </span>
                    </p>
                    <p
                        className="MsoNormal"
                        style={{
                            marginTop: "12.0pt",
                            marginRight: "0in",
                            marginBottom: "12.0pt",
                            marginLeft: "0in",
                        }}>
                        <span lang="EN">
                            18.&nbsp;&nbsp; The subscriber shall be assigned password (s) by
                            Airepro to enable the subscriber to post vacancies on the site in
                            agreed upon section(s), but the sole responsibility of the safe
                            custody of the password shall be that of the subscriber and
                            Airepro shall not be responsible for data loss/theft of data or
                            corruption of data or the wrong usage/misuse of the password and
                            any damage or leak of information and its consequential usage by a
                            third party. Airepro undertakes to take all reasonable precautions
                            at its end to ensure that there is no leakage/misuse of the
                            password granted to the subscriber
                        </span>
                    </p>
                    <p
                        className="MsoNormal"
                        style={{
                            marginTop: "12.0pt",
                            marginRight: "0in",
                            marginBottom: "12.0pt",
                            marginLeft: "0in",
                        }}>
                        <span lang="EN">
                            19.&nbsp;&nbsp; Only insertions with contact information
                            registered with Airepro will be displayed on the site
                        </span>
                    </p>
                    <p
                        className="MsoNormal"
                        style={{
                            marginTop: "12.0pt",
                            marginRight: "0in",
                            marginBottom: "12.0pt",
                            marginLeft: "0in",
                        }}>
                        <span lang="EN">
                            20.&nbsp;&nbsp; The User of these services does not claim any
                            copyright or other Intellectual Property Right over the data
                            uploaded by him/her on the website.
                        </span>
                    </p>
                    <p
                        className="MsoNormal"
                        style={{
                            marginTop: "12.0pt",
                            marginRight: "0in",
                            marginBottom: "12.0pt",
                            marginLeft: "0in",
                        }}>
                        <span lang="EN">
                            21.&nbsp;&nbsp; All disputes arising out of the transactions
                            between a user and Airepro will be resolved in accordance with the
                            laws of India as applicable.
                        </span>
                    </p>
                    <p
                        className="MsoNormal"
                        style={{
                            marginTop: "12.0pt",
                            marginRight: "0in",
                            marginBottom: "12.0pt",
                            marginLeft: "0in",
                        }}>
                        <span lang="EN">&nbsp;</span>
                    </p>
                    <p
                        className="MsoNormal"
                        style={{
                            marginTop: "12.0pt",
                            marginRight: "0in",
                            marginBottom: "12.0pt",
                            marginLeft: "0in",
                        }}>
                        <span lang="EN">&nbsp;</span>
                    </p>
                    <ol style={{ marginTop: "0in" }} start={1} type={1}>
                        <li className="MsoNormal" style={{ marginTop: "12.0pt" }}>
                            <span
                                className="text-lg block text-center font-semibold"
                                lang="EN">
                                Hot Vacancies (single)
                            </span>
                        </li>
                    </ol>
                    <ol style={{ marginTop: "0in" }} start={1} type={1}>
                        <li className="MsoNormal">
                            <span lang="EN">
                                Airepro shall place the information relating to vacancies in the
                                Hot Vacancies &amp; such other Classified sections on the
                                website work-elite or such other mirror or parallel site(s) or
                                in allied publications as Airepro may deem fit and proper but
                                such additional web hosting shall be without any extra cost to
                                the subscriber / user.
                            </span>
                        </li>
                    </ol>
                    <ol style={{ marginTop: "0in" }} start={1} type={1}>
                        <li className="MsoNormal">
                            <span lang="EN">
                                Each insertion so displayed in the classified and hot vacancies
                                and section of Work-elite shall be for a fixed period (currently
                                30 days), which period is subject to change without notice.Every
                                instance of refreshing and existing listing entitles you to and
                                additional fixed period (currently 30 days) starting from the
                                date on which the listing is refreshed and shall be counted as
                                fresh posting.
                            </span>
                        </li>
                    </ol>
                    <ol style={{ marginTop: "0in" }} start={1} type={1}>
                        <li className="MsoNormal">
                            <span lang="EN">
                                The Subscriber / Recruiter can select up to a maximum of 2 email
                                ids per job, out of unlimited email ids configured for the
                                account, to receive email notifications of applications.
                            </span>
                        </li>
                    </ol>
                    <ol style={{ marginTop: "0in" }} start={1} type={1}>
                        <li className="MsoNormal">
                            <span lang="EN">
                                Airepro reserves its right to reject any insertion or
                                information/data provided by the subscriber without assigning
                                any reason either before uploading or after uploading the
                                vacancy details, but in such an eventuality, any amount so paid
                                for, may be refunded to the subscriber on a pro-rata basis at
                                the sole discretion of Airepro.
                            </span>
                        </li>
                    </ol>
                    <ol style={{ marginTop: "0in" }} start={1} type={1}>
                        <li className="MsoNormal">
                            <span lang="EN">
                                By posting/uploading a job posting on the website you confirm
                                that you have obtained all licenses/permits as are necessary for
                                recruitment and to indemnify IEIL against all claims, damages
                                arising out of actions/claims that may be made in relation to
                                the same.
                            </span>
                        </li>
                    </ol>
                    <ol style={{ marginTop: "0in" }} start={1} type={1}>
                        <li className="MsoNormal">
                            <span lang="EN">
                                Airepro has the right to make all such modifications/editing of
                                the vacancy details in order to fit its database.
                            </span>
                        </li>
                    </ol>
                    <ol style={{ marginTop: "0in" }} start={1} type={1}>
                        <li className="MsoNormal">
                            <span lang="EN">
                                All information intimated by the subscriber and displayed by
                                Airepro on work-elite becomes public knowledge and Airepro may
                                at its sole discretion include the vacancy intimidated by a
                                client for display on work-elite in any other media including
                                the print media at no extra costs to the client and Airepro
                                shall not be held liable for usage/publicity of such information
                                / data.
                            </span>
                        </li>
                    </ol>
                    <ol style={{ marginTop: "0in" }} start={1} type={1}>
                        <li className="MsoNormal">
                            <span lang="EN">
                                Airepro offers no guarantee nor warranties that there would be a
                                satisfactory response or any response at all once the job
                                vacancy is put on display.
                            </span>
                        </li>
                    </ol>
                    <ol style={{ marginTop: "0in" }} start={1} type={1}>
                        <li className="MsoNormal" style={{ marginBottom: "12.0pt" }}>
                            <span lang="EN">
                                Airepro shall in no way be held liable for any information
                                received by the subscriber and it shall be the sole
                                responsibility of the subscriber to check, authenticate and
                                verify the information/response received at its own cost and
                                expense.
                            </span>
                        </li>
                    </ol>
                    <p
                        className="MsoNormal"
                        style={{
                            marginTop: "12.0pt",
                            marginRight: "0in",
                            marginBottom: "12.0pt",
                            marginLeft: "0in",
                        }}>
                        <span lang="EN">
                            10.&nbsp;&nbsp; Airepro would not be held liable for any loss of
                            data whether technical or otherwise or for loss of information or
                            particulars supplied by the subscriber due to the reasons beyond
                            its control like corruption of data or delay or failure to perform
                            as a result of any causes or conditions that are beyond Airepro's
                            reasonable control including but not limited to strike, riots,
                            civil unrest, Govt. policies, tampering of data by unauthorized
                            persons like hackers, war and natural calamities.
                        </span>
                    </p>
                    <p
                        className="MsoNormal"
                        style={{
                            marginTop: "12.0pt",
                            marginRight: "0in",
                            marginBottom: "12.0pt",
                            marginLeft: "0in",
                        }}>
                        <span lang="EN">
                            11.&nbsp;&nbsp; The subscriber/Recruiter shall give an undertaking
                            to Airepro that the jobs sought to be advertised on the 'Hot
                            Vacancies' and 'Classified' sections of work-elite are in
                            existence, genuine and the subscriber has the authority to
                            advertise the said jobs vacancies.
                        </span>
                    </p>
                    <p
                        className="MsoNormal"
                        style={{
                            marginTop: "12.0pt",
                            marginRight: "0in",
                            marginBottom: "12.0pt",
                            marginLeft: "0in",
                        }}>
                        <span lang="EN">
                            12.&nbsp;&nbsp; Airepro will commence providing services only upon
                            receipt of amount/charges upfront either from the subscriber or
                            from a third party on behalf of the subscriber.
                        </span>
                    </p>
                    <p
                        className="MsoNormal"
                        style={{
                            marginTop: "12.0pt",
                            marginRight: "0in",
                            marginBottom: "12.0pt",
                            marginLeft: "0in",
                        }}>
                        <span lang="EN">
                            13.&nbsp;&nbsp; The subscriber/Recruiter must give an undertaking
                            to Airepro that there will be no fee charged from any person who
                            responds to jobs / vacancies advertised on the 'Hot Vacancy' and
                            'Classified' sections of Work-elite for processing of such a
                            person.
                        </span>
                    </p>
                    <p
                        className="MsoNormal"
                        style={{
                            marginTop: "12.0pt",
                            marginRight: "0in",
                            marginBottom: "12.0pt",
                            marginLeft: "0in",
                        }}>
                        <span lang="EN">
                            14.&nbsp;&nbsp; Airepro reserves its right to change the look,
                            feel, design, prominence, depiction, classification of the 'Hot
                            Vacancies' and `Classified' sections of Work-elite at any time
                            without assigning any reason and without giving any prior notice.
                        </span>
                    </p>
                    <p
                        className="MsoNormal"
                        style={{
                            marginTop: "12.0pt",
                            marginRight: "0in",
                            marginBottom: "12.0pt",
                            marginLeft: "0in",
                        }}>
                        <span lang="EN">
                            15.&nbsp;&nbsp; The subscription is neither re-saleable nor
                            transferable by the subscriber to any other person, corporate
                            body, firm or individual. Usage from third party premises/networks
                            without prior written permission of IEIL is not permitted.
                        </span>
                    </p>
                    <p
                        className="MsoNormal"
                        style={{
                            marginTop: "12.0pt",
                            marginRight: "0in",
                            marginBottom: "12.0pt",
                            marginLeft: "0in",
                        }}>
                        <span lang="EN">
                            16.&nbsp;&nbsp; The subscriber shall be assigned password (s) by
                            Airepro to enable the subscriber to post vacancies on work-elite
                            site in agreed upon section(s), but the sole responsibility of the
                            safe custody of the password shall be that of the subscriber and
                            Airepro shall not be responsible for data loss/theft of
                            data/corruption of data or the wrong usage/misuse of the password
                            and any damage or leak of information and its consequential usage
                            by a third party. Airepro undertakes to take all reasonable
                            precautions at its end to ensure that there is no leakage/misuse
                            of the password granted to the subscriber
                        </span>
                    </p>
                    <p
                        className="MsoNormal"
                        style={{
                            marginTop: "12.0pt",
                            marginRight: "0in",
                            marginBottom: "12.0pt",
                            marginLeft: "0in",
                        }}>
                        <span lang="EN">
                            17.&nbsp;&nbsp; The User of these services does not claim any
                            copyright or other Intellectual Property Right over the data
                            uploaded by him/her on the website
                        </span>
                    </p>
                    <p
                        className="MsoNormal"
                        style={{
                            marginTop: "12.0pt",
                            marginRight: "0in",
                            marginBottom: "12.0pt",
                            marginLeft: "0in",
                        }}>
                        <span lang="EN">&nbsp;</span>
                    </p>
                    <p
                        className="MsoNormal"
                        style={{
                            marginTop: "12.0pt",
                            marginRight: "0in",
                            marginBottom: "12.0pt",
                            marginLeft: "0in",
                        }}>
                        <span lang="EN">&nbsp;</span>
                    </p>
                    <ol style={{ marginTop: "0in" }} start={1} type={1}>
                        <li className="MsoNormal" style={{ marginTop: "12.0pt" }}>
                            <span
                                className="text-lg block text-center font-semibold"
                                lang="EN">
                                Hot Vacancies Subscriptions
                            </span>
                        </li>
                    </ol>
                    <ol style={{ marginTop: "0in" }} start={1} type={1}>
                        <li className="MsoNormal">
                            <span lang="EN">
                                Airepro shall place the information relating to vacancies in the
                                Hot Vacancies &amp; such other Classified sections on the
                                website work-elite or such other mirror or parallel site(s) or
                                in allied publications as Airepro may deem fit and proper but
                                such additional web hosting shall be without any extra cost to
                                the subscriber / user.
                            </span>
                        </li>
                    </ol>
                    <ol style={{ marginTop: "0in" }} start={1} type={1}>
                        <li className="MsoNormal">
                            <span lang="EN">
                                Each insertion so displayed in the hot vacancies and classified
                                vacancy section of the site work-elite shall be for a fixed
                                period (currently 30 days), which period is subject to change
                                without prior notice.
                            </span>
                        </li>
                    </ol>
                    <ol style={{ marginTop: "0in" }} start={1} type={1}>
                        <li className="MsoNormal">
                            <span lang="EN">
                                The Subscriber / Recruiter can select up to a maximum of 2 email
                                ids per job, out of unlimited email ids configured for the
                                account, to receive email notifications of applications.
                            </span>
                        </li>
                    </ol>
                    <ol style={{ marginTop: "0in" }} start={1} type={1}>
                        <li className="MsoNormal">
                            <span lang="EN">
                                By posting/uploading a job posting on the website you confirm
                                that you have obtained all licenses/permits as are necessary for
                                recruitment and to indemnify IEIL against all claims, damages
                                arising out of actions/claims that may be made in relation to
                                the same.
                            </span>
                        </li>
                    </ol>
                    <ol style={{ marginTop: "0in" }} start={1} type={1}>
                        <li className="MsoNormal">
                            <span lang="EN">
                                Airepro has the right to make all such modifications/editing of
                                the vacancy details in order to fit its database.
                            </span>
                        </li>
                    </ol>
                    <ol style={{ marginTop: "0in" }} start={1} type={1}>
                        <li className="MsoNormal">
                            <span lang="EN">
                                Airepro reserves its right to reject any insertion or
                                information/data provided by the subscriber without assigning
                                any reason either before uploading or after uploading the
                                vacancy details, but in such an eventuality, any amount so paid
                                for, may be refunded to the subscriber on a pro-rata basis at
                                the sole discretion of Airepro.
                            </span>
                        </li>
                    </ol>
                    <ol style={{ marginTop: "0in" }} start={1} type={1}>
                        <li className="MsoNormal">
                            <span lang="EN">
                                All information intimated by the client and displayed by Airepro
                                on work-elite becomes public knowledge and Airepro may at its
                                sole discretion include the vacancy intimidated by a client for
                                display on work-elite in any other media including the print
                                media at no extra costs to the client and Airepro cannot be held
                                liable for usage/publicity of such information.
                            </span>
                        </li>
                    </ol>
                    <ol style={{ marginTop: "0in" }} start={1} type={1}>
                        <li className="MsoNormal">
                            <span lang="EN">
                                Airepro offers no guarantee nor warranties that there would be a
                                satisfactory response or any response at all once the job
                                vacancy is put on display.
                            </span>
                        </li>
                    </ol>
                    <ol style={{ marginTop: "0in" }} start={1} type={1}>
                        <li className="MsoNormal" style={{ marginBottom: "12.0pt" }}>
                            <span lang="EN">
                                Airepro shall in no way be held liable for any information
                                received by the subscriber and it shall be the sole
                                responsibility of the subscriber to check, authenticate and
                                verify the information/response received at its own cost and
                                expense.
                            </span>
                        </li>
                    </ol>
                    <p
                        className="MsoNormal"
                        style={{
                            marginTop: "12.0pt",
                            marginRight: "0in",
                            marginBottom: "12.0pt",
                            marginLeft: "0in",
                        }}>
                        <span lang="EN">
                            10.&nbsp;&nbsp; Airepro would not be held liable for any loss of
                            data technical or otherwise, information, particulars supplied by
                            the customers due to reasons beyond its control like corruption of
                            data or delay or failure to perform as a result of any causes or
                            conditions that are beyond Airepro's reasonable control including
                            but not limited to strike, riots, civil unrest, Govt. policies,
                            tampering of data by unauthorized persons like hackers, war and
                            natural calamities.
                        </span>
                    </p>
                    <p
                        className="MsoNormal"
                        style={{
                            marginTop: "12.0pt",
                            marginRight: "0in",
                            marginBottom: "12.0pt",
                            marginLeft: "0in",
                        }}>
                        <span lang="EN">
                            11.&nbsp;&nbsp; The subscriber/Recruiter shall give an undertaking
                            to Airepro that the jobs sought to be advertised on the 'Hot
                            Vacancies' and `Classified' sections of work-elite are in
                            existence, genuine and the subscriber has the authority to
                            advertise the jobs.
                        </span>
                    </p>
                    <p
                        className="MsoNormal"
                        style={{
                            marginTop: "12.0pt",
                            marginRight: "0in",
                            marginBottom: "12.0pt",
                            marginLeft: "0in",
                        }}>
                        <span lang="EN">
                            12.&nbsp;&nbsp; Airepro will commence providing the services only
                            upon receipt of amount/charges upfront either from the subscriber
                            or from a third party on behalf of the subscriber.
                        </span>
                    </p>
                    <p
                        className="MsoNormal"
                        style={{
                            marginTop: "12.0pt",
                            marginRight: "0in",
                            marginBottom: "12.0pt",
                            marginLeft: "0in",
                        }}>
                        <span lang="EN">
                            13.&nbsp;&nbsp; The subscriber/Recruiter must give an undertaking
                            to Airepro that there will be no fee charged from any person who
                            responds to jobs advertised on the 'Hot Vacancy' and 'Classified'
                            sections of Work-elite for processing of such a person.
                        </span>
                    </p>
                    <p
                        className="MsoNormal"
                        style={{
                            marginTop: "12.0pt",
                            marginRight: "0in",
                            marginBottom: "12.0pt",
                            marginLeft: "0in",
                        }}>
                        <span lang="EN">
                            14.&nbsp;&nbsp; Airepro reserves its right to change the look,
                            feel, design, prominence, depiction, classification of the
                            classified and/or Hot Vacancies section of Work-elite at any time
                            without assigning any reason and without giving any notice.
                        </span>
                    </p>
                    <p
                        className="MsoNormal"
                        style={{
                            marginTop: "12.0pt",
                            marginRight: "0in",
                            marginBottom: "12.0pt",
                            marginLeft: "0in",
                        }}>
                        <span lang="EN">
                            15.&nbsp;&nbsp; The subscriber to this service shall be entitled
                            to such number of listings during the period of subscription as
                            may be agreed upon.
                        </span>
                    </p>
                    <p
                        className="MsoNormal"
                        style={{
                            marginTop: "12.0pt",
                            marginRight: "0in",
                            marginBottom: "12.0pt",
                            marginLeft: "0in",
                        }}>
                        <span lang="EN">
                            16.&nbsp;&nbsp; This subscription is neither re-saleable nor
                            transferable by the subscriber to any other person, corporate
                            body, firm or individual.
                        </span>
                    </p>
                    <p
                        className="MsoNormal"
                        style={{
                            marginTop: "12.0pt",
                            marginRight: "0in",
                            marginBottom: "12.0pt",
                            marginLeft: "0in",
                        }}>
                        <span lang="EN">
                            17.&nbsp;&nbsp; The subscriber shall be assigned a password (s) by
                            Airepro to enable the subscriber to post vacancies on the site in
                            agreed upon section(s), but the sole responsibility of the safe
                            custody of the password shall be that of the subscriber and
                            Airepro shall not be responsible for data loss/theft of
                            data/corruption of data or the wrong usage/misuse of the password
                            and any damage or leak of information and its consequential usage
                            by a third party. Airepro undertakes to take all reasonable
                            precautions at its end to ensure that there is no leakage/misuse
                            of the password granted to the subscriber.
                        </span>
                    </p>
                    <p
                        className="MsoNormal"
                        style={{
                            marginTop: "12.0pt",
                            marginRight: "0in",
                            marginBottom: "12.0pt",
                            marginLeft: "0in",
                        }}>
                        <span lang="EN">
                            18.&nbsp;&nbsp; The User of these services does not claim any
                            copyright or other Intellectual Property Right over the data
                            uploaded by him/her on the website
                        </span>
                    </p>
                    <p
                        className="MsoNormal"
                        style={{
                            marginTop: "12.0pt",
                            marginRight: "0in",
                            marginBottom: "12.0pt",
                            marginLeft: "0in",
                        }}>
                        <span lang="EN">&nbsp;</span>
                    </p>
                    <p
                        className="MsoNormal"
                        style={{
                            marginTop: "12.0pt",
                            marginRight: "0in",
                            marginBottom: "12.0pt",
                            marginLeft: "0in",
                        }}>
                        <span lang="EN">&nbsp;</span>
                    </p>
                    <ol style={{ marginTop: "0in" }} start={1} type={1}>
                        <li className="MsoNormal" style={{ marginTop: "12.0pt" }}>
                            <span
                                className="text-lg block text-center font-semibold"
                                lang="EN">
                                Job Gallery
                            </span>
                        </li>
                    </ol>
                    <ol style={{ marginTop: "0in" }} start={1} type={1}>
                        <li className="MsoNormal">
                            <span lang="EN">
                                Airepro shall place the information relating to vacancies in the
                                Hot Vacancies &amp; such other Classified sections on the
                                website work-elite or such other mirror or parallel site(s) or
                                in allied publications as Airepro may deem fit and proper but
                                such additional web hosting shall be without any extra cost to
                                the subscriber / user.
                            </span>
                        </li>
                    </ol>
                    <ol style={{ marginTop: "0in" }} start={1} type={1}>
                        <li className="MsoNormal">
                            <span lang="EN">
                                Each insertion so displayed in the classified and hot vacancies
                                section of work-elite shall be for a fixed period (currently 30
                                days), which period is subject to change without notice.
                            </span>
                        </li>
                    </ol>
                    <ol style={{ marginTop: "0in" }} start={1} type={1}>
                        <li className="MsoNormal">
                            <span lang="EN">
                                The Subscriber/Recruiter will provide up to a maximum of 2 email
                                id's for vacancies posted on work-elite either in the classified
                                and hot vacancies section to collect response(s) if any. In the
                                case of Hot Vacancies, these 2 email id's can be selected from
                                out of the unlimited email ids that may be configured for the
                                account. However in the case of a classified listing, the
                                contact information given by the subscriber for all listings
                                should be the same and the subscriber cannot give multiple
                                contact information/data for the purpose of listing.
                            </span>
                        </li>
                    </ol>
                    <ol style={{ marginTop: "0in" }} start={1} type={1}>
                        <li className="MsoNormal">
                            <span lang="EN">
                                Airepro reserves its right to reject any insertion or
                                information/data provided by the subscriber without assigning
                                any reason either before uploading or after uploading the
                                vacancy details, but in such an eventuality, any amount so paid
                                for, may be refunded to the subscriber on a pro-rata basis at
                                the sole discretion of Airepro.
                            </span>
                        </li>
                    </ol>
                    <ol style={{ marginTop: "0in" }} start={1} type={1}>
                        <li className="MsoNormal">
                            <span lang="EN">
                                By posting/uploading a job posting on the website you confirm
                                that you have obtained all licenses/permits as are necessary for
                                recruitment and to indemnify IEIL against all claims, damages
                                arising out of actions/claims that may be made in relation to
                                the same.
                            </span>
                        </li>
                    </ol>
                    <ol style={{ marginTop: "0in" }} start={1} type={1}>
                        <li className="MsoNormal">
                            <span lang="EN">
                                Airepro has the right to make all such modifications/editing of
                                the vacancy details in order to fit its database
                            </span>
                        </li>
                    </ol>
                    <ol style={{ marginTop: "0in" }} start={1} type={1}>
                        <li className="MsoNormal">
                            <span lang="EN">
                                Airepro offers no guarantee nor warranties that there would be a
                                satisfactory response or any response at all once the job
                                vacancy is put on display.
                            </span>
                        </li>
                    </ol>
                    <ol style={{ marginTop: "0in" }} start={1} type={1}>
                        <li className="MsoNormal">
                            <span lang="EN">
                                All information intimated by the client and displayed by Airepro
                                on work-elite becomes public knowledge and Airepro may at its
                                sole discretion include the vacancy intimidated by a client for
                                display on work-elite in any other media including the print
                                media at no extra costs to the client and Airepro cannot be held
                                liable for usage/publicity of such information.
                            </span>
                        </li>
                    </ol>
                    <ol style={{ marginTop: "0in" }} start={1} type={1}>
                        <li className="MsoNormal" style={{ marginBottom: "12.0pt" }}>
                            <span lang="EN">
                                Airepro shall in no way be held liable for any information
                                received by the subscriber and it shall be the sole
                                responsibility of the subscriber to check, authenticate and
                                verify the information/response received at its own cost and
                                expense.
                            </span>
                        </li>
                    </ol>
                    <p
                        className="MsoNormal"
                        style={{
                            marginTop: "12.0pt",
                            marginRight: "0in",
                            marginBottom: "12.0pt",
                            marginLeft: "0in",
                        }}>
                        <span lang="EN">
                            10.&nbsp;&nbsp; Airepro would not be held liable for any loss of
                            data technical or otherwise, information, particulars supplied by
                            the customers due to the reasons beyond its control like
                            corruption of data or delay or failure to perform as a result of
                            any causes or conditions that are beyond Airepro's reasonable
                            control including but not limited to strike, riots, civil unrest,
                            Govt. policies, tampering of data by unauthorized persons like
                            hackers, war and natural calamities.
                        </span>
                    </p>
                    <p
                        className="MsoNormal"
                        style={{
                            marginTop: "12.0pt",
                            marginRight: "0in",
                            marginBottom: "12.0pt",
                            marginLeft: "0in",
                        }}>
                        <span lang="EN">
                            11.&nbsp;&nbsp; The subscriber/Recruiter shall give an undertaking
                            to Airepro that the jobs sought to be advertised on the 'Job
                            Gallery' `Hot Vacancies' and `Classified' sections of work-elite
                            are in existence, genuine and the subscriber has the authority to
                            advertise the jobs.
                        </span>
                    </p>
                    <p
                        className="MsoNormal"
                        style={{
                            marginTop: "12.0pt",
                            marginRight: "0in",
                            marginBottom: "12.0pt",
                            marginLeft: "0in",
                        }}>
                        <span lang="EN">
                            12.&nbsp;&nbsp; Airepro will commence providing the services only
                            upon receipt of amount/charges upfront either from the subscriber
                            or from a third party on behalf of the subscriber.
                        </span>
                    </p>
                    <p
                        className="MsoNormal"
                        style={{
                            marginTop: "12.0pt",
                            marginRight: "0in",
                            marginBottom: "12.0pt",
                            marginLeft: "0in",
                        }}>
                        <span lang="EN">
                            13.&nbsp;&nbsp; The subscriber/Recruiter must give an undertaking
                            to Airepro that there will be no fee charged from any person who
                            responds to jobs advertised on the 'Job Gallery' 'Hot Vacancy' and
                            'Classified' sections of Work-elite for processing of such a
                            person.
                        </span>
                    </p>
                    <p
                        className="MsoNormal"
                        style={{
                            marginTop: "12.0pt",
                            marginRight: "0in",
                            marginBottom: "12.0pt",
                            marginLeft: "0in",
                        }}>
                        <span lang="EN">
                            14.&nbsp;&nbsp; Airepro reserves its right to change the look,
                            feel, design, prominence, depiction, classification of any section
                            of Work-elite at any time without assigning any reason and without
                            giving any notice.
                        </span>
                    </p>
                    <p
                        className="MsoNormal"
                        style={{
                            marginTop: "12.0pt",
                            marginRight: "0in",
                            marginBottom: "12.0pt",
                            marginLeft: "0in",
                        }}>
                        <span lang="EN">
                            15.&nbsp;&nbsp; The subscriber to this service shall be entitled
                            to such number of listings during the period of subscription as
                            may be agreed upon.
                        </span>
                    </p>
                    <p
                        className="MsoNormal"
                        style={{
                            marginTop: "12.0pt",
                            marginRight: "0in",
                            marginBottom: "12.0pt",
                            marginLeft: "0in",
                        }}>
                        <span lang="EN">
                            16.&nbsp;&nbsp; This subscription is neither re-saleable nor
                            transferable by the subscriber to any other person, corporate
                            body, firm or individual.
                        </span>
                    </p>
                    <p
                        className="MsoNormal"
                        style={{
                            marginTop: "12.0pt",
                            marginRight: "0in",
                            marginBottom: "12.0pt",
                            marginLeft: "0in",
                        }}>
                        <span lang="EN">
                            17.&nbsp;&nbsp; This service entitles one company/entity alone to
                            put up a Micro site within the domain of Work-elite having a
                            maximum size of 10 K during the validity period of subscription.
                        </span>
                    </p>
                    <p
                        className="MsoNormal"
                        style={{
                            marginTop: "12.0pt",
                            marginRight: "0in",
                            marginBottom: "12.0pt",
                            marginLeft: "0in",
                        }}>
                        <span lang="EN">
                            18.&nbsp;&nbsp; The subscriber shall be assigned a password (s) by
                            Airepro to enable the subscriber to post vacancies on the site in
                            agreed upon section(s), but the sole responsibility of the safe
                            custody of the password shall be that of the subscriber and
                            Airepro shall not be responsible for data loss/theft of
                            data/corruption of data or the wrong usage/misuse of the password
                            and any damage or leak of information and its consequential usage
                            by a third party. Airepro undertakes to take all reasonable
                            precautions at its end to ensure that there is no leakage/misuse
                            of the password granted to the subscriber.
                        </span>
                    </p>
                    <p
                        className="MsoNormal"
                        style={{
                            marginTop: "12.0pt",
                            marginRight: "0in",
                            marginBottom: "12.0pt",
                            marginLeft: "0in",
                        }}>
                        <span lang="EN">
                            19.&nbsp;&nbsp; The User of these services does not claim any
                            copyright or other Intellectual Property Right over the data
                            uploaded by him/her on the website
                        </span>
                    </p>
                    <p
                        className="MsoNormal"
                        style={{
                            marginTop: "12.0pt",
                            marginRight: "0in",
                            marginBottom: "12.0pt",
                            marginLeft: "0in",
                        }}>
                        <span lang="EN">&nbsp;</span>
                    </p>
                    <p
                        className="MsoNormal"
                        style={{
                            marginTop: "12.0pt",
                            marginRight: "0in",
                            marginBottom: "12.0pt",
                            marginLeft: "0in",
                        }}>
                        <span lang="EN">&nbsp;</span>
                    </p>
                    <ol style={{ marginTop: "0in" }} start={1} type={1}>
                        <li className="MsoNormal" style={{ marginTop: "12.0pt" }}>
                            <span
                                className="text-lg block text-center font-semibold"
                                lang="EN">
                                Best Places to Work
                            </span>
                        </li>
                    </ol>
                    <ol style={{ marginTop: "0in" }} start={1} type={1}>
                        <li className="MsoNormal">
                            <span lang="EN">
                                Airepro shall place the information relating to vacancies in the
                                Hot Vacancies &amp; such other Classified sections on the
                                website work-elite or such other mirror or parallel site(s) or
                                in allied publications as Airepro may deem fit and proper but
                                such additional web hosting shall be without any extra cost to
                                the subscriber / user.
                            </span>
                        </li>
                    </ol>
                    <ol style={{ marginTop: "0in" }} start={1} type={1}>
                        <li className="MsoNormal">
                            <span lang="EN">
                                Each insertion so displayed in the classified hot vacancies and
                                other section(s) of Work-elite shall be for a fixed period
                                (currently 30 days), which period is subject to change without
                                notice
                            </span>
                        </li>
                    </ol>
                    <ol style={{ marginTop: "0in" }} start={1} type={1}>
                        <li className="MsoNormal">
                            <span lang="EN">
                                The Subscriber/Recruiter will provide up to a maximum of 2 email
                                id's for vacancies posted on work-elite either in the classified
                                and hot vacancies section to collect response(s) if any. In the
                                case of Hot Vacancies, these 2 email id's can be selected from
                                out of the unlimited email ids that may be configured for the
                                account. However in the case of a classified listing, the
                                contact information given by the subscriber for all listings
                                should be the same and the subscriber cannot give multiple
                                contact information/data for the purpose of listing.
                            </span>
                        </li>
                    </ol>
                    <ol style={{ marginTop: "0in" }} start={1} type={1}>
                        <li className="MsoNormal">
                            <span lang="EN">
                                Airepro reserves its right to reject any insertion or
                                information/data provided by the subscriber without assigning
                                any reason either before uploading or after uploading the
                                vacancy details, but in such an eventuality, any amount so paid
                                for, may be refunded to the subscriber on a pro-rata basis at
                                the sole discretion of Airepro
                            </span>
                        </li>
                    </ol>
                    <ol style={{ marginTop: "0in" }} start={1} type={1}>
                        <li className="MsoNormal">
                            <span lang="EN">
                                By posting/uploading a job posting on the website you confirm
                                that you have obtained all licenses/permits as are necessary for
                                recruitment and to indemnify IEIL against all claims, damages
                                arising out of actions/claims that may be made in relation to
                                the same.
                            </span>
                        </li>
                    </ol>
                    <ol style={{ marginTop: "0in" }} start={1} type={1}>
                        <li className="MsoNormal">
                            <span lang="EN">
                                Airepro has the right to make all such modifications/editing of
                                the vacancy details in order to fit its database
                            </span>
                        </li>
                    </ol>
                    <ol style={{ marginTop: "0in" }} start={1} type={1}>
                        <li className="MsoNormal">
                            <span lang="EN">
                                Airepro offers no guarantee nor warranties that there would be a
                                satisfactory response or any response at all once the job
                                vacancy is put on display
                            </span>
                        </li>
                    </ol>
                    <ol style={{ marginTop: "0in" }} start={1} type={1}>
                        <li className="MsoNormal">
                            <span lang="EN">
                                All information intimated by the client and displayed by Airepro
                                on work-elite becomes public knowledge and Airepro may at its
                                sole discretion include the vacancy intimidated by a client for
                                display on work-elite in any other media including the print
                                media at no extra costs to the client and Airepro cannot be held
                                liable for usage/publicity of such information
                            </span>
                        </li>
                    </ol>
                    <ol style={{ marginTop: "0in" }} start={1} type={1}>
                        <li className="MsoNormal" style={{ marginBottom: "12.0pt" }}>
                            <span lang="EN">
                                Airepro shall in no way be held liable for any information
                                received by the subscriber and it shall be the sole
                                responsibility of the subscriber to check, authenticate and
                                verify the information/response received at its own cost and
                                expense
                            </span>
                        </li>
                    </ol>
                    <p
                        className="MsoNormal"
                        style={{
                            marginTop: "12.0pt",
                            marginRight: "0in",
                            marginBottom: "12.0pt",
                            marginLeft: "0in",
                        }}>
                        <span lang="EN">
                            10.&nbsp;&nbsp; The subscriber may also be offered other services,
                            including use of the e-Apps, access to Resdex (Resume Database)
                            for the period of subscription and banners on a "run of the
                            website" basis as agreed with Airepro
                        </span>
                    </p>
                    <p
                        className="MsoNormal"
                        style={{
                            marginTop: "12.0pt",
                            marginRight: "0in",
                            marginBottom: "12.0pt",
                            marginLeft: "0in",
                        }}>
                        <span lang="EN">
                            11.&nbsp;&nbsp; Airepro would not be held liable for any loss of
                            data technical or otherwise, information, particulars supplied by
                            the customers due to the reasons beyond its control like
                            corruption of data or delay or failure to perform as a result of
                            any causes or conditions that are beyond Airepro's reasonable
                            control including but not limited to strike, riots, civil unrest,
                            Govt. policies, tampering of data by unauthorized persons like
                            hackers, war and natural calamities
                        </span>
                    </p>
                    <p
                        className="MsoNormal"
                        style={{
                            marginTop: "12.0pt",
                            marginRight: "0in",
                            marginBottom: "12.0pt",
                            marginLeft: "0in",
                        }}>
                        <span lang="EN">
                            12.&nbsp;&nbsp; Airepro will commence providing services only upon
                            receipt of amount/charges upfront either from the subscriber or
                            from a third party on behalf of the subscriber
                        </span>
                    </p>
                    <p
                        className="MsoNormal"
                        style={{
                            marginTop: "12.0pt",
                            marginRight: "0in",
                            marginBottom: "12.0pt",
                            marginLeft: "0in",
                        }}>
                        <span lang="EN">
                            13.&nbsp;&nbsp; The subscriber/Recruiter shall give an undertaking
                            to Airepro that the jobs sought to be advertised on the 'Best
                            Places to Work' `Hot Vacancies' and `Classified' sections of
                            work-elite are in existence, genuine and the subscriber has the
                            authority to advertise the jobs.
                        </span>
                    </p>
                    <p
                        className="MsoNormal"
                        style={{
                            marginTop: "12.0pt",
                            marginRight: "0in",
                            marginBottom: "12.0pt",
                            marginLeft: "0in",
                        }}>
                        <span lang="EN">
                            14.&nbsp;&nbsp; The subscriber/Recruiter must give an undertaking
                            to Airepro that there will be no fee charged from any person who
                            responds to jobs advertised on the 'Best Places to Work' 'Hot
                            Vacancy' and 'Classified' sections of Work-elite for processing of
                            such person
                        </span>
                    </p>
                    <p
                        className="MsoNormal"
                        style={{
                            marginTop: "12.0pt",
                            marginRight: "0in",
                            marginBottom: "12.0pt",
                            marginLeft: "0in",
                        }}>
                        <span lang="EN">
                            15.&nbsp;&nbsp; Airepro reserves its right to change the look,
                            feel, design, prominence, depiction, classification of any section
                            of Work-elite at any time without assigning any reason and without
                            giving any notice
                        </span>
                    </p>
                    <p
                        className="MsoNormal"
                        style={{
                            marginTop: "12.0pt",
                            marginRight: "0in",
                            marginBottom: "12.0pt",
                            marginLeft: "0in",
                        }}>
                        <span lang="EN">
                            16.&nbsp;&nbsp; The subscriber to this service shall be entitled
                            to such number of listings during the period of subscription as
                            may be agreed upon
                        </span>
                    </p>
                    <p
                        className="MsoNormal"
                        style={{
                            marginTop: "12.0pt",
                            marginRight: "0in",
                            marginBottom: "12.0pt",
                            marginLeft: "0in",
                        }}>
                        <span lang="EN">
                            17.&nbsp;&nbsp; This subscription is neither re-saleable nor
                            transferable by the subscriber to any other person, corporate
                            body, firm or individual
                        </span>
                    </p>
                    <p
                        className="MsoNormal"
                        style={{
                            marginTop: "12.0pt",
                            marginRight: "0in",
                            marginBottom: "12.0pt",
                            marginLeft: "0in",
                        }}>
                        <span lang="EN">
                            18.&nbsp;&nbsp; This service entitles one company/entity alone to
                            put up a Micro site within the domain of Work-elite having a
                            maximum size of 50 K during the validity period of subscription
                        </span>
                    </p>
                    <p
                        className="MsoNormal"
                        style={{
                            marginTop: "12.0pt",
                            marginRight: "0in",
                            marginBottom: "12.0pt",
                            marginLeft: "0in",
                        }}>
                        <span lang="EN">
                            19.&nbsp;&nbsp; The subscriber shall be assigned a password (s) by
                            Airepro to enable the subscriber to post vacancies on the site in
                            agreed upon section(s), but the sole responsibility of the safe
                            custody of the password shall be that of the subscriber and
                            Airepro shall not be responsible for data loss/theft of
                            data/corruption of data or the wrong usage/misuse of the password
                            and any damage or leak of information and its consequential usage
                            by a third party. Airepro undertakes to take all reasonable
                            precautions at its end to ensure that there is no leakage/misuse
                            of the password granted to the subscriber.
                        </span>
                    </p>
                    <p
                        className="MsoNormal"
                        style={{
                            marginTop: "12.0pt",
                            marginRight: "0in",
                            marginBottom: "12.0pt",
                            marginLeft: "0in",
                        }}>
                        <span lang="EN">
                            20.&nbsp;&nbsp; The User of these services does not claim any
                            copyright or other Intellectual Property Right over the data
                            uploaded by him/her or on his behalf on the website
                        </span>
                    </p>
                    <p
                        className="MsoNormal"
                        style={{
                            marginTop: "12.0pt",
                            marginRight: "0in",
                            marginBottom: "12.0pt",
                            marginLeft: "0in",
                        }}>
                        <span lang="EN">&nbsp;</span>
                    </p>
                    <p
                        className="MsoNormal"
                        style={{
                            marginTop: "12.0pt",
                            marginRight: "0in",
                            marginBottom: "12.0pt",
                            marginLeft: "0in",
                        }}>
                        <span lang="EN">&nbsp;</span>
                    </p>
                    <ol style={{ marginTop: "0in" }} start={1} type={1}>
                        <li className="MsoNormal" style={{ marginTop: "12.0pt" }}>
                            <span
                                className="text-lg block text-center font-semibold"
                                lang="EN">
                                Manual Shortlisting
                            </span>
                        </li>
                    </ol>
                    <ol style={{ marginTop: "0in" }} start={1} type={1}>
                        <li className="MsoNormal">
                            <span lang="EN">
                                Airepro agrees to provide the service to the subscriber only for
                                the duration and the number of vacancies contracted for, to the
                                best of its ability and Airepro shall in no way be held liable
                                for any information received by the subscriber and it shall be
                                the sole responsibility of the subscriber to check, authenticate
                                and verify the information/response received at its own cost and
                                expense.
                            </span>
                        </li>
                    </ol>
                    <ol style={{ marginTop: "0in" }} start={1} type={1}>
                        <li className="MsoNormal">
                            <span lang="EN">
                                Airepro will make best efforts to use the parameters provided by
                                the subscriber to short list, but takes no responsibility for
                                the accuracy of the short listing based on the parameters for
                                selection as specified by the subscriber
                            </span>
                        </li>
                    </ol>
                    <ol style={{ marginTop: "0in" }} start={1} type={1}>
                        <li className="MsoNormal">
                            <span lang="EN">
                                Airepro would not be held liable for any loss of data technical
                                or otherwise, information, particulars supplied by the customers
                                due to the reasons beyond its control like corruption of data or
                                delay or failure to perform as a result of any causes or
                                conditions that are beyond Airepro's reasonable control
                                including but not limited to strike, riots, civil unrest, Govt.
                                policies, tampering of data by unauthorized persons like
                                hackers, war and natural calamities
                            </span>
                        </li>
                    </ol>
                    <ol style={{ marginTop: "0in" }} start={1} type={1}>
                        <li className="MsoNormal">
                            <span lang="EN">
                                Airepro will commence providing services only upon receipt of
                                amount/charges upfront either from the subscriber or from a
                                third party on behalf of the subscriber. The payment for service
                                once subscribed to by the subscriber is not refundable and any
                                amount paid shall stand appropriated.
                            </span>
                        </li>
                    </ol>
                    <ol style={{ marginTop: "0in" }} start={1} type={1}>
                        <li className="MsoNormal">
                            <span lang="EN">
                                This subscription is neither re-saleable nor transferable by the
                                subscriber to any other person, corporate body, firm or
                                individual
                            </span>
                        </li>
                    </ol>
                    <ol style={{ marginTop: "0in" }} start={1} type={1}>
                        <li className="MsoNormal">
                            <span lang="EN">
                                The User of these services does not claim any copyright or other
                                Intellectual Property Right over the data uploaded by him/her on
                                the website
                            </span>
                        </li>
                    </ol>
                    <ol style={{ marginTop: "0in" }} start={1} type={1}>
                        <li className="MsoNormal">
                            <span lang="EN">
                                It is the responsibility of Job Seekers to verify the
                                correctness and authenticity of the claims made by recruiters.
                                We merely act as facilitators and do not act as agents for any
                                recruiter.
                            </span>
                        </li>
                    </ol>
                    <ol style={{ marginTop: "0in" }} start={1} type={1}>
                        <li className="MsoNormal" style={{ marginBottom: "12.0pt" }}>
                            <span lang="EN">
                                Payments to recruiters are not advised and shall be at your own
                                risk.
                            </span>
                        </li>
                    </ol>
                    <p
                        className="MsoNormal"
                        style={{
                            marginTop: "12.0pt",
                            marginRight: "0in",
                            marginBottom: "12.0pt",
                            marginLeft: "0in",
                        }}>
                        <span lang="EN">&nbsp;</span>
                    </p>
                    <p
                        className="MsoNormal"
                        style={{
                            marginTop: "12.0pt",
                            marginRight: "0in",
                            marginBottom: "12.0pt",
                            marginLeft: "0in",
                        }}>
                        <span lang="EN">&nbsp;</span>
                    </p>
                    <p
                        className="MsoNormal"
                        style={{
                            marginTop: "12.0pt",
                            marginRight: "0in",
                            marginBottom: "12.0pt",
                            marginLeft: "0in",
                        }}>
                        <span lang="EN">&nbsp;</span>
                    </p>
                    <ol style={{ marginTop: "0in" }} start={1} type={1}>
                        <li className="MsoNormal" style={{ marginTop: "12.0pt" }}>
                            <span
                                className="text-lg block text-center font-semibold"
                                lang="EN">
                                Display of Banners
                            </span>
                        </li>
                    </ol>
                    <ol style={{ marginTop: "0in" }} start={1} type={1}>
                        <li className="MsoNormal">
                            <span lang="EN">
                                Airepro agrees to provide the service to the subscriber only for
                                the duration or the number of impressions contracted for, to the
                                best of its ability.
                            </span>
                        </li>
                    </ol>
                    <ol style={{ marginTop: "0in" }} start={1} type={1}>
                        <li className="MsoNormal">
                            <span lang="EN">
                                Airepro will display the banners on all the relevant/specified
                                sections of the site on a rotation basis
                            </span>
                        </li>
                    </ol>
                    <ol style={{ marginTop: "0in" }} start={1} type={1}>
                        <li className="MsoNormal">
                            <span lang="EN">
                                Airepro reserves its right to reject any insertion or
                                information/data provided by the subscriber without assigning
                                any reason, but in such an eventuality, any amount so paid for,
                                may be refunded to the subscriber on a pro-rata basis at the
                                sole discretion of Airepro
                            </span>
                        </li>
                    </ol>
                    <ol style={{ marginTop: "0in" }} start={1} type={1}>
                        <li className="MsoNormal">
                            <span lang="EN">
                                Airepro offers no guarantee nor warranties that there would be a
                                satisfactory response or any response at all once the banners
                                are put on display
                            </span>
                        </li>
                    </ol>
                    <ol style={{ marginTop: "0in" }} start={1} type={1}>
                        <li className="MsoNormal">
                            <span lang="EN">
                                Airepro would not be held liable for any loss of data technical
                                or otherwise, information, particulars supplied by the customers
                                due to the reasons beyond its control like corruption of data or
                                delay or failure to perform as a result of any causes or
                                conditions that are beyond Airepro's reasonable control
                                including but not limited to strike, riots, civil unrest, Govt.
                                policies, tampering of data by unauthorized persons like
                                hackers, war and natural calamities
                            </span>
                        </li>
                    </ol>
                    <ol style={{ marginTop: "0in" }} start={1} type={1}>
                        <li className="MsoNormal">
                            <span lang="EN">
                                Airepro will commence providing services only upon receipt of
                                amount/charges upfront either from the subscriber or from a
                                third party on behalf of the subscriber
                            </span>
                        </li>
                    </ol>
                    <ol style={{ marginTop: "0in" }} start={1} type={1}>
                        <li className="MsoNormal">
                            <span lang="EN">
                                This subscription is neither re-saleable nor transferable by the
                                subscriber to any other person, corporate body, firm or
                                individual
                            </span>
                        </li>
                    </ol>
                    <ol style={{ marginTop: "0in" }} start={1} type={1}>
                        <li className="MsoNormal">
                            <span lang="EN">
                                The subscriber/Recruiter/Advertiser must give an undertaking to
                                Airepro that there will be no fee charged from any person who
                                responds to jobs advertised on work-elite for processing of
                                applications / responses from such person
                            </span>
                        </li>
                    </ol>
                    <ol style={{ marginTop: "0in" }} start={1} type={1}>
                        <li className="MsoNormal" style={{ marginBottom: "12.0pt" }}>
                            <span lang="EN">
                                The User of these services does not claim any copyright, Trade
                                Mark or other Intellectual Property Right over the data uploaded
                                by him/her on the website. The Banners displayed on work-elite
                                shall be prepared as per the instructions received from the
                                users, Airepro shall not be responsible for the users
                                misappropriation of the TradeMark/ Copyright or any other
                                Intellectual Property Right sought to be passed of as that of
                                the user.
                            </span>
                        </li>
                    </ol>
                    <p
                        className="MsoNormal"
                        style={{
                            marginTop: "12.0pt",
                            marginRight: "0in",
                            marginBottom: "12.0pt",
                            marginLeft: "0in",
                        }}>
                        <span lang="EN">&nbsp;</span>
                    </p>
                    <p
                        className="MsoNormal"
                        style={{
                            marginTop: "12.0pt",
                            marginRight: "0in",
                            marginBottom: "12.0pt",
                            marginLeft: "0in",
                        }}>
                        <span lang="EN">&nbsp;</span>
                    </p>
                    <p
                        className="MsoNormal"
                        style={{
                            marginTop: "12.0pt",
                            marginRight: "0in",
                            marginBottom: "12.0pt",
                            marginLeft: "0in",
                        }}>
                        <span lang="EN">&nbsp;</span>
                    </p>
                    <ol style={{ marginTop: "0in" }} start={1} type={1}>
                        <li className="MsoNormal" style={{ marginTop: "12.0pt" }}>
                            <span
                                className="text-lg block text-center font-semibold"
                                lang="EN">
                                e-Apps
                            </span>
                        </li>
                    </ol>
                    <ol style={{ marginTop: "0in" }} start={1} type={1}>
                        <li className="MsoNormal">
                            <span lang="EN">
                                Airepro agrees to provide the service to the subscriber only for
                                the duration contracted for to the best of its ability.
                            </span>
                        </li>
                    </ol>
                    <ol style={{ marginTop: "0in" }} start={1} type={1}>
                        <li className="MsoNormal">
                            <span lang="EN">
                                Airepro reserves its right to suspend/terminate the services
                                contracted for by the subscriber either prior to or during the
                                contracted period without assigning any reason. In such an
                                eventuality, any amount so paid for by the subscriber for this
                                service, may be refunded by Airepro to the subscriber at the
                                discretion of Airepro.
                            </span>
                        </li>
                    </ol>
                    <ol style={{ marginTop: "0in" }} start={1} type={1}>
                        <li className="MsoNormal">
                            <span lang="EN">
                                Airepro offers no guarantee nor warranties that there would be a
                                satisfactory response or any response at all subscriber for
                                applications received using the e-Apps software
                            </span>
                        </li>
                    </ol>
                    <ol style={{ marginTop: "0in" }} start={1} type={1}>
                        <li className="MsoNormal">
                            <span lang="EN">
                                Airepro shall in no way be held liable for any information
                                received by the subscriber and it shall be the sole
                                responsibility of the subscriber to check, authenticate and
                                verify the information/response received at its own cost and
                                expense
                            </span>
                        </li>
                    </ol>
                    <ol style={{ marginTop: "0in" }} start={1} type={1}>
                        <li className="MsoNormal">
                            <span lang="EN">
                                Airepro would not be held liable for any loss of data technical
                                or otherwise, information, particulars supplied by the customers
                                due to the reasons beyond its control like corruption of data or
                                delay or failure to perform as a result of any causes or
                                conditions that are beyond Airepro's reasonable control
                                including but not limited to strike, riots, civil unrest, Govt.
                                policies, tampering of data by unauthorized persons like
                                hackers, war and natural calamities
                            </span>
                        </li>
                    </ol>
                    <ol style={{ marginTop: "0in" }} start={1} type={1}>
                        <li className="MsoNormal">
                            <span lang="EN">
                                Airepro will commence providing services only upon receipt of
                                amount/charges upfront either from the subscriber or from a
                                third party on behalf of the subscriber
                            </span>
                        </li>
                    </ol>
                    <ol style={{ marginTop: "0in" }} start={1} type={1}>
                        <li className="MsoNormal">
                            <span lang="EN">
                                The subscriber/Recruiter shall give an undertaking to Airepro
                                that the jobs sought to be advertised on the work-elite are in
                                existence, genuine and the subscriber has the authority to
                                recruit /advertise for such vacancies
                            </span>
                        </li>
                    </ol>
                    <ol style={{ marginTop: "0in" }} start={1} type={1}>
                        <li className="MsoNormal">
                            <span lang="EN">
                                Airepro reserves its right to change the look, feel, design,
                                prominence, depiction, classification of any section of
                                Work-elite at any time without assigning any reason and without
                                giving any notice
                            </span>
                        </li>
                    </ol>
                    <ol style={{ marginTop: "0in" }} start={1} type={1}>
                        <li className="MsoNormal" style={{ marginBottom: "12.0pt" }}>
                            <span lang="EN">
                                The subscriber/Recruiter must give an undertaking to Airepro
                                that there will be no fee charged from any person who responds
                                to jobs advertised on work-elite for processing of such person
                            </span>
                        </li>
                    </ol>
                    <p
                        className="MsoNormal"
                        style={{
                            marginTop: "12.0pt",
                            marginRight: "0in",
                            marginBottom: "12.0pt",
                            marginLeft: "0in",
                        }}>
                        <span lang="EN">
                            10.&nbsp;&nbsp; Applications against jobs listed on Work-elite
                            (Hot Vacancy / Classified) Jobs will be available for a period of
                            90 days post the date on which an application in response to the
                            job listing was received. Application shall be deleted post this
                            period and Airepro shall not be liable for restoring the said
                            data. This period may be extended in case the Subscriber opts for
                            appropriate higher variants.
                        </span>
                    </p>
                    <p
                        className="MsoNormal"
                        style={{
                            marginTop: "12.0pt",
                            marginRight: "0in",
                            marginBottom: "12.0pt",
                            marginLeft: "0in",
                        }}>
                        <span lang="EN">
                            11.&nbsp;&nbsp; This subscription is neither re-saleable nor
                            transferable by the subscriber to any other person, corporate
                            body, firm or individual
                        </span>
                    </p>
                    <p
                        className="MsoNormal"
                        style={{
                            marginTop: "12.0pt",
                            marginRight: "0in",
                            marginBottom: "12.0pt",
                            marginLeft: "0in",
                        }}>
                        <span lang="EN">
                            12.&nbsp;&nbsp; The subscriber shall be assigned a password (s) by
                            Airepro to enable the subscriber to access all the information
                            received through the software, but the sole responsibility of the
                            safe custody of the password shall be that of the subscriber and
                            Airepro shall not be responsible for data loss/theft of
                            data/corruption of data or the wrong usage/misuse of the password
                            and any damage or leak of information and its consequential usage
                            by a third party. Airepro undertakes to take all reasonable
                            precautions at its end to ensure that there is no leakage/misuse
                            of the password granted to the subscriber
                        </span>
                    </p>
                    <p
                        className="MsoNormal"
                        style={{
                            marginTop: "12.0pt",
                            marginRight: "0in",
                            marginBottom: "12.0pt",
                            marginLeft: "0in",
                        }}>
                        <span lang="EN">
                            13.&nbsp;&nbsp; The User of these services does not claim any
                            copyright or other Intellectual Property Right over the data
                            uploaded by him/her on the website
                        </span>
                    </p>
                    <p
                        className="MsoNormal"
                        style={{
                            marginTop: "12.0pt",
                            marginRight: "0in",
                            marginBottom: "12.0pt",
                            marginLeft: "0in",
                        }}>
                        <span lang="EN">&nbsp;</span>
                    </p>
                    <p
                        className="MsoNormal"
                        style={{
                            marginTop: "12.0pt",
                            marginRight: "0in",
                            marginBottom: "12.0pt",
                            marginLeft: "0in",
                        }}>
                        <span lang="EN">&nbsp;</span>
                    </p>
                    <p
                        className="MsoNormal"
                        style={{
                            marginTop: "12.0pt",
                            marginRight: "0in",
                            marginBottom: "12.0pt",
                            marginLeft: "0in",
                        }}>
                        <span lang="EN">&nbsp;</span>
                    </p>
                    <p
                        className="MsoNormal"
                        style={{
                            marginTop: "12.0pt",
                            marginRight: "0in",
                            marginBottom: "12.0pt",
                            marginLeft: "0in",
                        }}>
                        <span lang="EN">10. Resdex</span>
                    </p>
                    <ol style={{ marginTop: "0in" }} start={1} type={1}>
                        <li className="MsoNormal" style={{ marginTop: "12.0pt" }}>
                            <span lang="EN">
                                Airepro agrees to provide the service to the subscriber only for
                                the duration contracted for to the best of its ability
                            </span>
                        </li>
                    </ol>
                    <ol style={{ marginTop: "0in" }} start={1} type={1}>
                        <li className="MsoNormal">
                            <span lang="EN">
                                Airepro reserves its right to suspend/terminate the services
                                contracted for by the subscriber either prior to or during the
                                contracted period without assigning any reason. In such an
                                eventuality, any amount so paid for by the subscriber for this
                                service, may be refunded by Airepro at a prorata basis to the
                                subscriber at its discretion. 2A. The subscriber shall be
                                entitled to 1 user name / password to access the resdex service
                                alone and additional user names / passwords may be provided by
                                Airepro on such terms and conditions as may be mutually agreed
                                upon.
                            </span>
                        </li>
                    </ol>
                    <ol style={{ marginTop: "0in" }} start={1} type={1}>
                        <li className="MsoNormal">
                            <span lang="EN">
                                Airepro offers no guarantee nor warranties that there would be a
                                satisfactory response or any response at all subscribers for
                                applications received using the RESDEX software.
                            </span>
                        </li>
                    </ol>
                    <ol style={{ marginTop: "0in" }} start={1} type={1}>
                        <li className="MsoNormal">
                            <span lang="EN">
                                Airepro shall in no way be held liable for any information
                                received by the subscriber and it shall be the sole
                                responsibility of the subscriber to check, authenticate and
                                verify the information/response received at its own cost and
                                expense.
                            </span>
                        </li>
                    </ol>
                    <ol style={{ marginTop: "0in" }} start={1} type={1}>
                        <li className="MsoNormal">
                            <span lang="EN">
                                Any actions taken by an employer/recruiter on the basis of the
                                background check report or otherwise, is the
                                employer's/recruiter's responsibility alone and Airepro will not
                                be liable in any manner for the consequences of such action
                                taken by the user
                            </span>
                        </li>
                    </ol>
                    <ol style={{ marginTop: "0in" }} start={1} type={1}>
                        <li className="MsoNormal">
                            <span lang="EN">
                                Airepro would not be held liable for any loss of data technical
                                or otherwise, information, particulars supplied by the customers
                                due to the reasons beyond its control like corruption of data or
                                delay or failure to perform as a result of any causes or
                                conditions that are beyond Airepro's reasonable control
                                including but not limited to strike, riots, civil unrest, Govt.
                                policies, tampering of data by unauthorized persons like
                                hackers, war and natural calamities.
                            </span>
                        </li>
                    </ol>
                    <ol style={{ marginTop: "0in" }} start={1} type={1}>
                        <li className="MsoNormal">
                            <span lang="EN">
                                Only the activities performed in the last 365 days on a profile
                                mapped to a Resdex Requirement will be retained. The older
                                activities shall be automatically deleted. For the purposes of
                                this clause, Resdex Requirements includes, but is not limited to
                                activities including profile viewed, called, emailed, and added
                                to folder.
                            </span>
                        </li>
                    </ol>
                    <ol style={{ marginTop: "0in" }} start={1} type={1}>
                        <li className="MsoNormal">
                            <span lang="EN">
                                Airepro will commence providing services only upon receipt of
                                amount/charges upfront either from the subscriber or from a
                                third party on behalf of the subscriber.
                            </span>
                        </li>
                    </ol>
                    <ol style={{ marginTop: "0in" }} start={1} type={1}>
                        <li className="MsoNormal" style={{ marginBottom: "12.0pt" }}>
                            <span lang="EN">The subscriber/Recruiter</span>
                        </li>
                    </ol>
                    <p
                        className="MsoNormal"
                        style={{
                            marginTop: "12.0pt",
                            marginRight: "0in",
                            marginBottom: "12.0pt",
                            marginLeft: "0in",
                        }}>
                        <span lang="EN">
                            i. shall give an undertaking to Airepro that the jobs sought to be
                            filled through work-elite are in existence, genuine and the
                            subscriber has the authority to recruit /advertise for such
                            vacancies. Also the subscriber undertakes that the database will
                            be used to contact candidates for jobs only
                            <br />
                            ii. shall ensure compliance with all applicable laws for the
                            protection of the personal details of the users whose profiles are
                            accessed by them through Work-elite including but not limited to
                            compliance the Telecom Commercial Communications Customer
                            Preference Regulations, 2010 as also rules, regulations,
                            guidelines, bye laws and notifications made there under, while
                            accessing or feeding any resume/ insertion or information/data
                            into the computers, computer systems or computer networks of
                            Airepro.
                        </span>
                    </p>
                    <p
                        className="MsoNormal"
                        style={{
                            marginTop: "12.0pt",
                            marginRight: "0in",
                            marginBottom: "12.0pt",
                            marginLeft: "0in",
                        }}>
                        <span lang="EN">
                            10.&nbsp;&nbsp; Airepro reserves its right to change the look,
                            feel, design, prominence, depiction, classification of any section
                            of Work-elite at any time without assigning any reason and without
                            giving any notice.
                        </span>
                    </p>
                    <p
                        className="MsoNormal"
                        style={{
                            marginTop: "12.0pt",
                            marginRight: "0in",
                            marginBottom: "12.0pt",
                            marginLeft: "0in",
                        }}>
                        <span lang="EN">
                            11.&nbsp;&nbsp; The subscriber/Recruiter must give an undertaking
                            to Airepro that there will be no fee charged from any person who
                            is contacted through RESDEX for processing of such a person.
                        </span>
                    </p>
                    <p
                        className="MsoNormal"
                        style={{
                            marginTop: "12.0pt",
                            marginRight: "0in",
                            marginBottom: "12.0pt",
                            marginLeft: "0in",
                        }}>
                        <span lang="EN">
                            12.&nbsp;&nbsp; This subscription is neither re-saleable nor
                            transferable by the subscriber to any other person, corporate
                            body, firm or individual concern
                        </span>
                    </p>
                    <p
                        className="MsoNormal"
                        style={{
                            marginTop: "12.0pt",
                            marginRight: "0in",
                            marginBottom: "12.0pt",
                            marginLeft: "0in",
                        }}>
                        <span lang="EN">
                            13.&nbsp;&nbsp; The subscriber shall be assigned a password (s) by
                            Airepro to enable the subscriber to access all the information
                            received through the software, but the sole responsibility of the
                            safe custody of the password shall be that of the subscriber and
                            Airepro shall not be responsible for data loss/theft of
                            data/corruption of data or the wrong usage/misuse of the password
                            and any damage or leak of information and its consequential usage
                            by a third party. Airepro undertakes to take all reasonable
                            precautions at its end to ensure that there is no leakage/misuse
                            of the password granted to the subscriber.
                        </span>
                    </p>
                    <p
                        className="MsoNormal"
                        style={{
                            marginTop: "12.0pt",
                            marginRight: "0in",
                            marginBottom: "12.0pt",
                            marginLeft: "0in",
                        }}>
                        <span lang="EN">
                            14.&nbsp;&nbsp; The information on Airepro is for use by its
                            subscribers alone and does not authorize the subscriber to
                            download and use the data for commercial purposes. In case any one
                            is found to be in violation of this then Airepro at its discretion
                            may suspend its service/subscription and also may take such action
                            as it may be advised
                        </span>
                    </p>
                    <p
                        className="MsoNormal"
                        style={{
                            marginTop: "12.0pt",
                            marginRight: "0in",
                            marginBottom: "12.0pt",
                            marginLeft: "0in",
                        }}>
                        <span lang="EN">
                            15.&nbsp;&nbsp; The subscriber shall not use / circulate / forward
                            a person's resume hosted on the Work-eliteNetwork / Resumes to his
                            / her current employer as mentioned by the person in his / her
                            resume
                        </span>
                    </p>
                    <p
                        className="MsoNormal"
                        style={{
                            marginTop: "12.0pt",
                            marginRight: "0in",
                            marginBottom: "12.0pt",
                            marginLeft: "0in",
                        }}>
                        <span lang="EN">
                            16.&nbsp;&nbsp; The User of these services does not claim any
                            copyright or other Intellectual Property Right over the data
                            uploaded by him or on his behalf on the website or supplied to
                            IEIL.
                        </span>
                    </p>
                    <p
                        className="MsoNormal"
                        style={{
                            marginTop: "12.0pt",
                            marginRight: "0in",
                            marginBottom: "12.0pt",
                            marginLeft: "0in",
                        }}>
                        <span lang="EN">
                            17.&nbsp;&nbsp; Resdex Quota/CV access policy is as mentioned
                            below:-
                        </span>
                    </p>
                    <p
                        className="MsoNormal"
                        style={{
                            marginTop: "12.0pt",
                            marginRight: "0in",
                            marginBottom: "12.0pt",
                            marginLeft: "0in",
                        }}>
                        <span lang="EN">
                            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                            &nbsp;&nbsp; i.&nbsp;&nbsp;&nbsp;&nbsp; &nbsp;&nbsp;&nbsp;&nbsp;
                            Single CV Access (1 CV quota) will get utilized for the following
                            actions -
                        </span>
                    </p>
                    <p
                        className="MsoNormal"
                        style={{
                            marginTop: "12.0pt",
                            marginRight: "0in",
                            marginBottom: "12.0pt",
                            marginLeft: "0in",
                        }}>
                        <span lang="EN">
                            a.&nbsp; &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; Viewing a CV
                        </span>
                    </p>
                    <p
                        className="MsoNormal"
                        style={{
                            marginTop: "12.0pt",
                            marginRight: "0in",
                            marginBottom: "12.0pt",
                            marginLeft: "0in",
                        }}>
                        <span lang="EN">
                            b.&nbsp; &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; Clicking on "View
                            Phone Number"
                        </span>
                    </p>
                    <p
                        className="MsoNormal"
                        style={{
                            marginTop: "12.0pt",
                            marginRight: "0in",
                            marginBottom: "12.0pt",
                            marginLeft: "0in",
                        }}>
                        <span lang="EN">
                            c.&nbsp; &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; Forwarding a profile
                        </span>
                    </p>
                    <p
                        className="MsoNormal"
                        style={{
                            marginTop: "12.0pt",
                            marginRight: "0in",
                            marginBottom: "12.0pt",
                            marginLeft: "0in",
                        }}>
                        <span lang="EN">
                            d.&nbsp; &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; Messaging a
                            candidate
                        </span>
                    </p>
                    <p
                        className="MsoNormal"
                        style={{
                            marginTop: "12.0pt",
                            marginRight: "0in",
                            marginBottom: "12.0pt",
                            marginLeft: "0in",
                        }}>
                        <span lang="EN">
                            e.&nbsp; &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; Exporting a CV to
                            eApps/RMS**
                        </span>
                    </p>
                    <p
                        className="MsoNormal"
                        style={{
                            marginTop: "12.0pt",
                            marginRight: "0in",
                            marginBottom: "12.0pt",
                            marginLeft: "0in",
                        }}>
                        <span lang="EN">
                            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                            &nbsp;&nbsp;&nbsp; ii.&nbsp;&nbsp;&nbsp;&nbsp; &nbsp;&nbsp;&nbsp;
                            Double CV Access (2 CV quota) will get utilized for the following
                            actions -
                        </span>
                    </p>
                    <p
                        className="MsoNormal"
                        style={{
                            marginTop: "12.0pt",
                            marginRight: "0in",
                            marginBottom: "12.0pt",
                            marginLeft: "0in",
                        }}>
                        <span lang="EN">
                            a.&nbsp; &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; Downloading a CV in
                            Excel Format**
                        </span>
                    </p>
                    <p
                        className="MsoNormal"
                        style={{
                            marginTop: "12.0pt",
                            marginRight: "0in",
                            marginBottom: "12.0pt",
                            marginLeft: "0in",
                        }}>
                        <span lang="EN">
                            18.&nbsp;&nbsp; Repeated access (view/download/Click2View) of a
                            CV, however, will not be counted again within a period of 90 days
                            from the date of first access of a particular CV.
                        </span>
                    </p>
                    <p
                        className="MsoNormal"
                        style={{
                            marginTop: "12.0pt",
                            marginRight: "0in",
                            marginBottom: "12.0pt",
                            marginLeft: "0in",
                        }}>
                        <span lang="EN">
                            19.&nbsp;&nbsp; CV Access quota is debited at account level. i.e.
                            a CV accessed by two or more sub-users under the same account will
                            be counted only once.
                        </span>
                    </p>
                    <p
                        className="MsoNormal"
                        style={{
                            marginTop: "12.0pt",
                            marginRight: "0in",
                            marginBottom: "12.0pt",
                            marginLeft: "0in",
                        }}>
                        <span lang="EN">
                            20.&nbsp;&nbsp; This also includes different profiles under one
                            resume, i.e. if different profiles under the same user name is
                            accessed, it will still be counted a 1 access (for 90 days from
                            the date of first access).
                        </span>
                    </p>
                    <p
                        className="MsoNormal"
                        style={{
                            marginTop: "12.0pt",
                            marginRight: "0in",
                            marginBottom: "12.0pt",
                            marginLeft: "0in",
                        }}>
                        <span lang="EN">
                            21.&nbsp;&nbsp; Export to eApps/RMS will consume 1 CV Access Quota
                            and profiles downloaded in excel format in Resdex or taken out
                            from RMS in bulk will consume 2 CV Access quota. Single CV
                            Download from CV detail page will not consume any additional
                            download quota.**
                        </span>
                    </p>
                    <p
                        className="MsoNormal"
                        style={{
                            marginTop: "12.0pt",
                            marginRight: "0in",
                            marginBottom: "12.0pt",
                            marginLeft: "0in",
                        }}>
                        <span lang="EN">
                            22.&nbsp;&nbsp; In case, 1 CV Access quota has been utilized for a
                            CV (due to CV View / Click2View), then only 1 additional CV Access
                            quota will be utilized if the user downloads CV within a period of
                            90 days from the date of first access of a particular CV.
                        </span>
                    </p>
                    <p
                        className="MsoNormal"
                        style={{
                            marginTop: "12.0pt",
                            marginRight: "0in",
                            marginBottom: "12.0pt",
                            marginLeft: "0in",
                        }}>
                        <span lang="EN">
                            23.&nbsp;&nbsp; In case, 1 CV Access quota has been utilized for a
                            CV (due to CV View / Click2View), no additional CV Quota will be
                            utilized if the CV is exported to eApps/ RMS within a period of 90
                            days from the date of first access of a particular CV.
                        </span>
                    </p>
                    <p
                        className="MsoNormal"
                        style={{
                            marginTop: "12.0pt",
                            marginRight: "0in",
                            marginBottom: "12.0pt",
                            marginLeft: "0in",
                        }}>
                        <span lang="EN">
                            24.&nbsp;&nbsp; Transfer of CVs from Resdex to e-Apps / RMS is
                            supported for e-Apps Pro, RMS Consultant lite, RMS Consultant, RMS
                            Consultant Plus, RMS Pro, RMS Pro Plus, RMS Enterprise only.
                        </span>
                    </p>
                    <p
                        className="MsoNormal"
                        style={{
                            marginTop: "12.0pt",
                            marginRight: "0in",
                            marginBottom: "12.0pt",
                            marginLeft: "0in",
                        }}>
                        <span lang="EN">
                            25.&nbsp;&nbsp; Customers using Resdex through Zwayam will consume
                            Double CV Access (2 CV Quota) on
                        </span>
                    </p>
                    <p
                        className="MsoNormal"
                        style={{
                            marginTop: "12.0pt",
                            marginRight: "0in",
                            marginBottom: "12.0pt",
                            marginLeft: "0in",
                        }}>
                        <span lang="EN">
                            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                            &nbsp;&nbsp; i.&nbsp;&nbsp;&nbsp;&nbsp; &nbsp;&nbsp;&nbsp;&nbsp;
                            Single CV download from CV detail page
                        </span>
                    </p>
                    <p
                        className="MsoNormal"
                        style={{
                            marginTop: "12.0pt",
                            marginRight: "0in",
                            marginBottom: "12.0pt",
                            marginLeft: "0in",
                        }}>
                        <span lang="EN">
                            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                            &nbsp;&nbsp;&nbsp; ii.&nbsp;&nbsp;&nbsp;&nbsp; &nbsp;&nbsp;&nbsp;
                            Adding a candidate to a job in Zwayam
                        </span>
                    </p>
                    <p
                        className="MsoNormal"
                        style={{
                            marginTop: "12.0pt",
                            marginRight: "0in",
                            marginBottom: "12.0pt",
                            marginLeft: "0in",
                        }}>
                        <span lang="EN">
                            26.&nbsp;&nbsp; Using the send email feature, a
                            subscriber/recruiter can send a job intimation to the jobseekers
                            by consuming email quota.
                        </span>
                    </p>
                    <p
                        className="MsoNormal"
                        style={{
                            marginTop: "12.0pt",
                            marginRight: "0in",
                            marginBottom: "12.0pt",
                            marginLeft: "0in",
                        }}>
                        <span lang="EN">
                            27.&nbsp;&nbsp; **Not applicable when accessing Resdex through
                            Zwayam
                        </span>
                    </p>
                    <p
                        className="MsoNormal"
                        style={{
                            marginTop: "12.0pt",
                            marginRight: "0in",
                            marginBottom: "12.0pt",
                            marginLeft: "0in",
                        }}>
                        <b>
                            <span
                                className="text-lg block text-center font-semibold"
                                lang="EN">
                                ANTI SPAM POLICY
                            </span>
                        </b>
                    </p>
                    <p
                        className="MsoNormal"
                        style={{
                            marginTop: "12.0pt",
                            marginRight: "0in",
                            marginBottom: "12.0pt",
                            marginLeft: "0in",
                        }}>
                        <span lang="EN">
                            The use and access to the RESDEX database is subject to this
                            policy. The services provided to you are aimed at providing
                            recruitment solutions and should be restricted to contacting
                            suitable candidates for genuine jobs in existence.
                        </span>
                    </p>
                    <p
                        className="MsoNormal"
                        style={{
                            marginTop: "12.0pt",
                            marginRight: "0in",
                            marginBottom: "12.0pt",
                            marginLeft: "0in",
                        }}>
                        <span lang="EN">
                            Mailing practices such as transmitting marketing and promotional
                            mailers/ Offensive messages/ messages with misleading subject
                            lines in order to intentionally obfuscate the original message,
                            are strictly prohibited.
                        </span>
                    </p>
                    <p
                        className="MsoNormal"
                        style={{
                            marginTop: "12.0pt",
                            marginRight: "0in",
                            marginBottom: "12.0pt",
                            marginLeft: "0in",
                        }}>
                        <span lang="EN">
                            We reserve the right to terminate services, without prior notice,
                            to the originator of Spam. No refund shall be admissible under
                            such circumstances.
                        </span>
                    </p>
                    <p
                        className="MsoNormal"
                        style={{
                            marginTop: "12.0pt",
                            marginRight: "0in",
                            marginBottom: "12.0pt",
                            marginLeft: "0in",
                        }}>
                        <span lang="EN">
                            Following is an illustrative (not exhaustive) list of the kinds of
                            messages which can be classified as spam:
                        </span>
                    </p>
                    <ol style={{ marginTop: "0in" }} start={1} type={1}>
                        <li className="MsoNormal" style={{ marginTop: "12.0pt" }}>
                            <span lang="EN">
                                Unsolicited Bulk Messages/Unsolicited Commercial Communications.
                            </span>
                        </li>
                    </ol>
                    <ol style={{ marginTop: "0in" }} start={1} type={1}>
                        <li className="MsoNormal">
                            <span lang="EN">
                                Voice Calls/SMS to telephone numbers registered on the National
                                Consumer Preference Register.
                            </span>
                        </li>
                    </ol>
                    <ol style={{ marginTop: "0in" }} start={1} type={1}>
                        <li className="MsoNormal">
                            <span lang="EN">Non Job related mails.</span>
                        </li>
                    </ol>
                    <ol style={{ marginTop: "0in" }} start={1} type={1}>
                        <li className="MsoNormal">
                            <span lang="EN">Messages with misleading subject lines.</span>
                        </li>
                    </ol>
                    <ol style={{ marginTop: "0in" }} start={1} type={1}>
                        <li className="MsoNormal">
                            <span lang="EN">Blank Messages.</span>
                        </li>
                    </ol>
                    <ol style={{ marginTop: "0in" }} start={1} type={1}>
                        <li className="MsoNormal">
                            <span lang="EN">Extraordinary High Number of mails.</span>
                        </li>
                    </ol>
                    <ol style={{ marginTop: "0in" }} start={1} type={1}>
                        <li className="MsoNormal">
                            <span lang="EN">Mails soliciting payments.</span>
                        </li>
                    </ol>
                    <ol style={{ marginTop: "0in" }} start={1} type={1}>
                        <li className="MsoNormal" style={{ marginBottom: "12.0pt" }}>
                            <span lang="EN">Misleading/Fraudulent mails.</span>
                        </li>
                    </ol>
                    <p
                        className="MsoNormal"
                        style={{
                            marginTop: "12.0pt",
                            marginRight: "0in",
                            marginBottom: "12.0pt",
                            marginLeft: "0in",
                        }}>
                        <span lang="EN">
                            Users agree to indemnify and hold harmless Airepro (India) Limited
                            from any damages or claims arising out of usage of their RESDEX
                            accounts for transmitting spam
                            <br />
                            Users are advised to change their passwords frequently in order to
                            reduce the possibility of misuse of their accounts.
                        </span>
                    </p>
                    <p
                        className="MsoNormal"
                        style={{
                            marginTop: "12.0pt",
                            marginRight: "0in",
                            marginBottom: "12.0pt",
                            marginLeft: "0in",
                        }}>
                        <span lang="EN">11. Insta Hire</span>
                    </p>
                    <ol style={{ marginTop: "0in" }} start={1} type={1}>
                        <li className="MsoNormal" style={{ marginTop: "12.0pt" }}>
                            <span lang="EN">
                                SMS packs once bought will have minimum validity for a period of
                                3 months but cannot be refunded or exchanged for any other
                                work-elite products.
                            </span>
                        </li>
                    </ol>
                    <ol style={{ marginTop: "0in" }} start={1} type={1}>
                        <li className="MsoNormal">
                            <span lang="EN">
                                SMS packs will not be clubbed with any other work-elite
                                products.
                            </span>
                        </li>
                    </ol>
                    <ol style={{ marginTop: "0in" }} start={1} type={1}>
                        <li className="MsoNormal">
                            <span lang="EN">
                                Insta recruit (from resdex*) can only be used as long as the
                                recruiter has a live subscription to the resdex. Incase
                                subscription to resdex* has expired then the recruiter will not
                                be able to send SMS until resdex is renewed (even if the
                                recruiter has balance smses).
                            </span>
                        </li>
                    </ol>
                    <ol style={{ marginTop: "0in" }} start={1} type={1}>
                        <li className="MsoNormal">
                            <span lang="EN">
                                However smses not used will remain with the recruiter and the
                                recruiter can use the same once he renews the resdex*.
                            </span>
                        </li>
                    </ol>
                    <ol style={{ marginTop: "0in" }} start={1} type={1}>
                        <li className="MsoNormal">
                            <span lang="EN">
                                In Insta recruit, recruiters can sms only those candidates on
                                that search page that show up post conducting search.
                            </span>
                        </li>
                    </ol>
                    <ol style={{ marginTop: "0in" }} start={1} type={1}>
                        <li className="MsoNormal">
                            <span lang="EN">
                                A minimum of 1000 sms need to be purchased in one go and
                                thereafter in multiples thereof.
                            </span>
                        </li>
                    </ol>
                    <ol style={{ marginTop: "0in" }} start={1} type={1}>
                        <li className="MsoNormal">
                            <span lang="EN">
                                Recruiters at all times should try and ensure that smses are
                                being sent to relevant candidates to get maximum response.
                            </span>
                        </li>
                    </ol>
                    <ol style={{ marginTop: "0in" }} start={1} type={1}>
                        <li className="MsoNormal">
                            <span lang="EN">
                                Airepro reserves the right to scan all content being sent out on
                                sms and block smses if necessary if content of the SMS is deemed
                                as spam or any non-recruitment content whatsoever.
                            </span>
                        </li>
                    </ol>
                    <ol style={{ marginTop: "0in" }} start={1} type={1}>
                        <li className="MsoNormal" style={{ marginBottom: "12.0pt" }}>
                            <span lang="EN">
                                Airepro reserves the right to regulate number of smses sent out
                                to a particular profile on a particular day (this is to
                                safeguard consumer interests in case consumer is getting spammed
                                by some recruiter)
                            </span>
                        </li>
                    </ol>
                    <p
                        className="MsoNormal"
                        style={{
                            marginTop: "12.0pt",
                            marginRight: "0in",
                            marginBottom: "12.0pt",
                            marginLeft: "0in",
                        }}>
                        <span lang="EN">
                            10.&nbsp;&nbsp; While Airepro will try its utmost best to try and
                            deliver all SMS sent out, it does not take any responsibility for
                            smses not delivered (which may be on account of phones being out
                            of order, operator network problems, sims not being used anymore
                            etcâ.). It does take the responsibility of the smses leaving its
                            vendor sms gateway.
                        </span>
                    </p>
                    <p
                        className="MsoNormal"
                        style={{
                            marginTop: "12.0pt",
                            marginRight: "0in",
                            marginBottom: "12.0pt",
                            marginLeft: "0in",
                        }}>
                        <span lang="EN">
                            11.&nbsp;&nbsp; Airepro reserves the right to change, withdraw,
                            terminate the scope and scale of service at any point of time,
                            without any reason whatsoever.
                        </span>
                    </p>
                    <p
                        className="MsoNormal"
                        style={{
                            marginTop: "12.0pt",
                            marginRight: "0in",
                            marginBottom: "12.0pt",
                            marginLeft: "0in",
                        }}>
                        <span lang="EN">
                            12.&nbsp;&nbsp; Smses being sent out after 7 in the night will cue
                            up in the Airepro system and will be delivered to candidates after
                            8 in the morning (this is in keeping with telecom regulations)
                        </span>
                    </p>
                    <p
                        className="MsoNormal"
                        style={{
                            marginTop: "12.0pt",
                            marginRight: "0in",
                            marginBottom: "12.0pt",
                            marginLeft: "0in",
                        }}>
                        <span lang="EN">
                            13.&nbsp;&nbsp; The service is available across all operators in
                            India only.
                        </span>
                    </p>
                    <p
                        className="MsoNormal"
                        style={{
                            marginTop: "12.0pt",
                            marginRight: "0in",
                            marginBottom: "12.0pt",
                            marginLeft: "0in",
                        }}>
                        <span lang="EN">
                            14.&nbsp;&nbsp; This subscription is neither re-saleable nor
                            transferable by the subscriber to any other person, corporate
                            body, firm or individual concern. Usage from third party
                            premises/networks without prior written permission of IEIL is not
                            permitted.
                        </span>
                    </p>
                    <p
                        className="MsoNormal"
                        style={{
                            marginTop: "12.0pt",
                            marginRight: "0in",
                            marginBottom: "12.0pt",
                            marginLeft: "0in",
                        }}>
                        <span lang="EN">
                            15.&nbsp;&nbsp; * For candidates who will reply to these smses,
                            VAS rates as levied by their respective operator would be
                            applicable. For further details please contact your respective
                            mobile operator.
                        </span>
                    </p>
                    <p
                        className="MsoNormal"
                        style={{
                            marginTop: "12.0pt",
                            marginRight: "0in",
                            marginBottom: "12.0pt",
                            marginLeft: "0in",
                        }}>
                        <span lang="EN">&nbsp;</span>
                    </p>
                    <p
                        className="MsoNormal"
                        style={{
                            marginTop: "12.0pt",
                            marginRight: "0in",
                            marginBottom: "12.0pt",
                            marginLeft: "0in",
                        }}>
                        <span lang="EN">12. Work-elite Recruiter</span>
                    </p>
                    <ol style={{ marginTop: "0in" }} start={1} type={1}>
                        <li className="MsoNormal" style={{ marginTop: "12.0pt" }}>
                            <span lang="EN">
                                The Work-elite Recruiter profile may be updated/edited etc. by
                                the user alone. The user shall not upload, post, transmit,
                                publish, or distribute any material or information that is
                                unlawful, or which may potentially be perceived as being
                                harmful, threatening, abusive, harassing, defamatory, libellous,
                                vulgar, obscene, or racially, ethnically, or otherwise
                                objectionable.
                            </span>
                        </li>
                    </ol>
                    <ol style={{ marginTop: "0in" }} start={1} type={1}>
                        <li className="MsoNormal">
                            <span lang="EN">
                                Uploading of multiple profiles by the same recruiter using the
                                same or different accounts shall entitle Airepro to remove the
                                profiles without notice to the subscriber.
                            </span>
                        </li>
                    </ol>
                    <ol style={{ marginTop: "0in" }} start={1} type={1}>
                        <li className="MsoNormal">
                            <span lang="EN">
                                IEIL reserves its right to reject and delete any profile or
                                information/data fed in by the user without assigning any
                                reason.
                            </span>
                        </li>
                    </ol>
                    <ol style={{ marginTop: "0in" }} start={1} type={1}>
                        <li className="MsoNormal">
                            <span lang="EN">
                                The sole responsibility of the safe custody of the log in
                                details shall be that of the user and IEIL shall not be
                                responsible for data loss/theft of data/corruption of data or
                                the wrong usage/misuse of the password and any damage or leak of
                                information and its consequential usage by a third party. IEIL
                                undertakes to take all reasonable precautions at its end to
                                ensure that there is no leakage/misuse of the password created
                                by the user/recruiter.
                            </span>
                        </li>
                    </ol>
                    <ol style={{ marginTop: "0in" }} start={1} type={1}>
                        <li className="MsoNormal">
                            <span lang="EN">
                                IEIL shall in no way be held liable for any information received
                                by the user and it shall be the sole responsibility of the user
                                to check, authenticate and verify the information/response
                                received at its own cost and expense.
                            </span>
                        </li>
                    </ol>
                    <ol style={{ marginTop: "0in" }} start={1} type={1}>
                        <li className="MsoNormal">
                            <span lang="EN">
                                The user represents that he/she is not a minor and is not under
                                any legal or other disability which limits his/her ability to
                                comply with these Terms or to install and use the services
                                subscribed and purchased with minimal risk of harm to you or
                                others.
                            </span>
                        </li>
                    </ol>
                    <ol style={{ marginTop: "0in" }} start={1} type={1}>
                        <li className="MsoNormal">
                            <span lang="EN">
                                All changes / modifications made by the user to the data /
                                information shall be effected and will come into operation only
                                after 24-48 hours of such changes / modifications being made.
                            </span>
                        </li>
                    </ol>
                    <ol style={{ marginTop: "0in" }} start={1} type={1}>
                        <li className="MsoNormal" style={{ marginBottom: "12.0pt" }}>
                            <span lang="EN">On registration you agree:</span>
                        </li>
                    </ol>
                    <p
                        className="MsoNormal"
                        style={{
                            marginTop: "12.0pt",
                            marginRight: "0in",
                            marginBottom: "12.0pt",
                            marginLeft: "0in",
                        }}>
                        <span lang="EN">
                            a.&nbsp; &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; to make your profile
                            available for display in the public domain.
                        </span>
                    </p>
                    <p
                        className="MsoNormal"
                        style={{
                            marginTop: "12.0pt",
                            marginRight: "0in",
                            marginBottom: "12.0pt",
                            marginLeft: "0in",
                        }}>
                        <span lang="EN">
                            b.&nbsp; &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; and understand that
                            when you post a job on Work-elite or trigger an email to a job
                            seeker through RESDEX, a snapshot of your public profile on
                            Work-elite Recruiter may automatically get inserted into the
                            posting/mail (existing or new job listing on Work-elite).
                        </span>
                    </p>
                    <p
                        className="MsoNormal"
                        style={{
                            marginTop: "12.0pt",
                            marginRight: "0in",
                            marginBottom: "12.0pt",
                            marginLeft: "0in",
                        }}>
                        <span lang="EN">
                            c.&nbsp; &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; that you have the
                            requisite authority to upload the job listings that are posted
                            through the profile created by you in this section of Work-elite
                            i.e. NaukriRrecruiter.
                        </span>
                    </p>
                    <p
                        className="MsoNormal"
                        style={{
                            marginTop: "12.0pt",
                            marginRight: "0in",
                            marginBottom: "12.0pt",
                            marginLeft: "0in",
                        }}>
                        <span lang="EN">
                            d.&nbsp; &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; and understand that
                            IEIL may place the information relating to vacancies posted by me
                            through my Work-elite Recruiter account in any of Classified
                            sections on the website work-elite or such other mirror or
                            parallel site(s) or in allied publications as IEIL may deem fit
                            and proper.
                        </span>
                    </p>
                    <p
                        className="MsoNormal"
                        style={{
                            marginTop: "12.0pt",
                            marginRight: "0in",
                            marginBottom: "12.0pt",
                            marginLeft: "0in",
                        }}>
                        <span lang="EN">&nbsp;</span>
                    </p>
                    <p
                        className="MsoNormal"
                        style={{
                            marginTop: "12.0pt",
                            marginRight: "0in",
                            marginBottom: "12.0pt",
                            marginLeft: "0in",
                        }}>
                        <b>
                            <span lang="EN">Note:</span>
                        </b>
                        <span lang="EN">
                            {" "}
                            The terms in this agreement may be changed by Airepro at any time.
                            Airepro is free to offer its services to any client/prospective
                            client without restriction.
                        </span>
                    </p>
                    <p
                        className="MsoNormal"
                        style={{
                            marginTop: "12.0pt",
                            marginRight: "0in",
                            marginBottom: "12.0pt",
                            marginLeft: "0in",
                        }}>
                        <span lang="EN">&nbsp;</span>
                    </p>
                    <p className="MsoNormal">
                        <span lang="EN">&nbsp;</span>
                    </p>
                </div>
            </div>
        </div>
    );
};

export default TermsAndConditions;