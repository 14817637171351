import React from "react";

const ViewExamCreation = () => {
  return (
    <>
      <div className="grid grid-cols-6">
        {/*  */}
        <div class="col-start-1 col-end-7 box-border h-10 md:w-[20rem] p-1 text-3xl font-bold">
          View Students
        </div>

        {/*  */}
        <div className="m-3 col-start-1 col-end-7 bg-base-300 shadow-xl rounded-lg ">
          <div className="overflow-x-auto ">
            <table className="table  table-zebra border-2 border-slate-400">
              {/* head */}
              <thead>
                <tr className="bg-slate-300 border-slate-400">
                  <th className="font-extrabold border-r-2 border-slate-400">
                    Course
                  </th>
                  <th className="font-extrabold border-r-2 border-slate-400">
                    Subject Code
                  </th>
                  <th className="font-extrabold border-r-2 border-slate-400">
                    Subject
                  </th>
                  <th className="font-extrabold border-r-2 border-slate-400">
                    Date Time
                  </th>
                </tr>
              </thead>
              <tbody>
                {/* row 1 */}
                <tr>
                  <td className="border-r-2 border-slate-400">BCA</td>
                  <td className="border-r-2 border-slate-400">BCA2023</td>
                  <td className="border-r-2 border-slate-400">
                    Computer Science
                  </td>
                  <td className="border-r-2 border-slate-400">Dummyy</td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>

        {/*  */}
        <div className="col-start-1 col-end-7 ml-3 mt-5 text-lg font-bold">
          <h1>Students Lists:</h1>
        </div>
        <div className="m-3 col-start-1 col-end-7 bg-base-300 shadow-xl rounded-lg ">
          <div className="overflow-x-auto ">
            <table className="table  table-zebra border-2 border-slate-400">
              {/* head */}
              <thead>
                <tr className="bg-slate-300 border-slate-400">
                  <th className="border-r-2 border-slate-400">
                    <label>
                      <input
                        type="checkbox"
                        className="checkbox border-2 bg-white border-blue-400"
                      />
                    </label>
                  </th>
                  <th className="font-extrabold border-r-2 border-slate-400">
                    Freelancer ID
                  </th>
                  <th className="font-extrabold border-r-2 border-slate-400">
                    Name
                  </th>
                  <th className="font-extrabold border-r-2 border-slate-400">
                    Email
                  </th>
                  <th className="font-extrabold border-r-2 border-slate-400">
                    Address
                  </th>
                  <th className="font-extrabold border-r-2 border-slate-400">
                    Mobile No
                  </th>
                  <th className="font-extrabold border-r-2 border-slate-400">
                    Job Profile
                  </th>
                  <th className="font-extrabold border-r-2 border-slate-400">
                    Status
                  </th>
                  <th className="font-extrabold border-r-2 border-slate-400">
                    Enrollment no
                  </th>
                  <th className="font-extrabold border-r-2 border-slate-400">
                    Experience
                  </th>
                  <th className="font-extrabold border-r-2 border-slate-400">
                    Salary Expectation
                  </th>
                  <th className="font-extrabold border-r-2 border-slate-400">
                    Education
                  </th>
                  <th className="font-extrabold border-r-2 border-slate-400">
                    Tech Skills
                  </th>
                  <th className="font-extrabold border-r-2 border-slate-400">
                    Date
                  </th>
                  <th className="font-extrabold border-r-2 border-slate-400">
                    Time
                  </th>
                  <th className="font-extrabold border-r-2 border-slate-400">
                    Actions
                  </th>
                  {/* <th className="font-extrabold">View Students</th> */}
                  {/* <th></th> */}
                </tr>
              </thead>
              <tbody>
                {/* row 1 */}
                <tr className="bg-slate-300 border-slate-400">
                  <th className="border-r-2 border-slate-400">
                    <label>
                      <input
                        type="checkbox"
                        className="checkbox border-2 bg-white border-blue-400"
                      />
                    </label>
                  </th>
                  <td className="border-r-2 border-slate-400">1</td>
                  <td className="border-r-2 border-slate-400">
                    <div>
                      <div className="">Hary</div>
                    </div>
                  </td>
                  <td className="border-r-2 border-slate-400">Zemlak</td>
                  <td className="border-r-2 border-slate-400">Purple</td>
                  <td className="border-r-2 border-slate-400">Purple</td>
                  <td className="border-r-2 border-slate-400">Purple</td>
                  <td className="border-r-2 border-slate-400">Purple</td>
                  <td className="border-r-2 border-slate-400">Purple</td>
                  <td className="border-r-2 border-slate-400">Purple</td>
                  <td className="border-r-2 border-slate-400">Purple</td>
                  <td className="border-r-2 border-slate-400">Purple</td>
                  <td className="border-r-2 border-slate-400">Purple</td>
                  <td className="border-r-2 border-slate-400">Purple</td>
                  <td className="border-r-2 border-slate-400">Purple</td>
                  <td className="">
                    <button className="btn btn-sm bg-red-500 font-bold text-white">
                      Delete
                    </button>
                  </td>
                </tr>
                {/* row 2 */}
                <tr className="bg-slate-300 border-slate-400">
                  <th className="border-r-2 border-slate-400">
                    <label>
                      <input
                        type="checkbox"
                        className="checkbox border-2 bg-white border-blue-400"
                      />
                    </label>
                  </th>
                  <td className="border-r-2 border-slate-400">2</td>
                  <td className="border-r-2 border-slate-400">
                    <div>
                      <div className="">Hary</div>
                    </div>
                  </td>
                  <td className="border-r-2 border-slate-400">Zemlak</td>
                  <td className="border-r-2 border-slate-400">Purple</td>
                  <td className="border-r-2 border-slate-400">Purple</td>
                  <td className="border-r-2 border-slate-400">Purple</td>
                  <td className="border-r-2 border-slate-400">Purple</td>
                  <td className="border-r-2 border-slate-400">Purple</td>
                  <td className="border-r-2 border-slate-400">Purple</td>
                  <td className="border-r-2 border-slate-400">Purple</td>
                  <td className="border-r-2 border-slate-400">Purple</td>
                  <td className="border-r-2 border-slate-400">Purple</td>
                  <td className="border-r-2 border-slate-400">Purple</td>
                  <td className="border-r-2 border-slate-400">Purple</td>
                  <td className="">
                    <button className="btn btn-sm bg-red-500 font-bold text-white">
                      Delete
                    </button>
                  </td>
                </tr>
                {/* row 3 */}
                <tr className="bg-slate-300 border-slate-400 ">
                  <th className="border-r-2 border-slate-400">
                    <label>
                      <input
                        type="checkbox"
                        className="checkbox border-2 bg-white border-blue-400"
                      />
                    </label>
                  </th>
                  <td className="border-r-2 border-slate-400">3</td>
                  <td className="border-r-2 border-slate-400">
                    <div>
                      <div className="">Hary</div>
                    </div>
                  </td>
                  <td className="border-r-2 border-slate-400">Zemlak</td>
                  <td className="border-r-2 border-slate-400">Purple</td>
                  <td className="border-r-2 border-slate-400">Purple</td>
                  <td className="border-r-2 border-slate-400">Purple</td>
                  <td className="border-r-2 border-slate-400">Purple</td>
                  <td className="border-r-2 border-slate-400">Purple</td>
                  <td className="border-r-2 border-slate-400">Purple</td>
                  <td className="border-r-2 border-slate-400">Purple</td>
                  <td className="border-r-2 border-slate-400">Purple</td>
                  <td className="border-r-2 border-slate-400">Purple</td>
                  <td className="border-r-2 border-slate-400">Purple</td>
                  <td className="border-r-2 border-slate-400">Purple</td>
                  <td className="">
                    <button className="btn btn-sm bg-red-500 font-bold text-white">
                      Delete
                    </button>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </>
  );
};

export default ViewExamCreation;
