import axios from "axios";
import React, { useCallback, useEffect, useState } from "react";
import useAxiosPublic from "../../../Hooks/useAxiosPublic";

// import { FiCheckSquare } from "react-icons/fi";
import ViewResults from "./ViewResults";

// const sendEmailURL = "http://localhost:8083/api/v1/email";
const sendEmailURL = "https://emaillocalbe.airepro.in/api/v1/email";


const Results = () => {
  const axiousPublic = useAxiosPublic();

  const [val1, changeVal1] = useState("");
  const [datas1, changeDatas1] = useState([]);
  const [selectedEmail, setSelectedEmail] = useState(""); // Added state to hold selected email

  console.log(selectedEmail);

  function changehandler1(e) {
    changeVal1(e.target.value);
  }

  const getdata = async (e) => {
    e.preventDefault();

    if (!val1.trim()) {
      alert("Please Type Email ID");
      return;
    }
    try {
      const response = await axiousPublic.get(
        `/getEmployeeAnswerDetail/${val1}`
      );
      console.log("get course", response);
      if (response.data.result.length > 0) {
        changeDatas1(response.data.result);
        setSelectedEmail(val1);
      } else {
        alert("No data found.");
      }
    } catch (error) {
      console.error("Error fetching data:", error);
      alert("Error fetching data. Please try again.");
    }
  };


  // async function getdata1(e) {
  //   e.preventDefault();
  //   // Assuming 'val' is the input field value
  //   if (!val1.trim()) {
  //     // If the input field is blank, show an alert
  //     alert("Please Type Email ID");
  //     return;
  //   }
  //   try {
  //     let data = await fetch(`${getEmployeeAnswerDetail}/${val1}`);

  //     if (data.ok) {
  //       let jsonData = await data.json();
  //       console.log("get course",jsonData);

  //       if (jsonData.result.length > 0) {
  //         // Data is loaded, update UI
  //         changeDatas1(jsonData.result);
  //         // Set selected email
  //         setSelectedEmail(val1);
  //       } else {
  //         // Data not found, show alert
  //         alert("No data found.");
  //       }
  //     } else {
  //       // Handle non-successful response
  //       alert(`Error: ${data.status} - ${data.statusText}`);
  //     }
  //   } catch (error) {
  //     // Handle network or other errors
  //     console.error("Error fetching data:", error);
  //     alert("Error fetching data. Please try again.");
  //   }
  // }


  // fetch all data
  const [selectedCourseData, setSelectedCourseData] = useState(null);

  console.log(selectedCourseData);

  async function viewCourseData(exam_course) {
    // console.log("emaill", email);
    try {
      const response = await axiousPublic.get(
        `/allEmployeeAnswerDetail/${selectedEmail}/${exam_course}`
      );
      // console.log("datamsg", response);
      // console.log(response.data.result);
      console.log(response);
      if (response.data.success) {
        setSelectedCourseData(response.data.result);
        console.log(response.data.result);
      } else {
        alert(`Error: ${response.status} - ${response.statusText}`);
      }
    } catch (error) {
      console.error("Error fetching data:", error);
      alert("Error fetching data. Please try again.");
    }
  }

  // get all data
  const [getallexamdata, setAllExamData] = useState([]);
  const fetchData = useCallback(async () => {
    try {
      const { data } = await axiousPublic.get(`/allCandidateDataShow`);
      setAllExamData(data.result);
      // logInfo("fetch instruction data", data);
    } catch (error) {
      // logError("Error fetching instruction data", error);
    }
  }, [axiousPublic]);

  useEffect(() => {
    fetchData();
  }, [fetchData]);


  const handleMailButtonClick = async (email, courseData, candidateName) => {
    // const handleMailButtonClick = async (email, exam_course) => {
    const passFailStatus = calculatePassFail(courseData);
    let subject;
    if (passFailStatus === "Pass") {
      subject = `Congratulations on Passing the ${courseData[0]?.exam_course} Exam and Welcome to Airepro Solution PVT LTD! Dear Candidate, I am pleased to inform you that you have successfully passed the ${courseData[0]?.exam_course} Exam for the position at Airepro Solution PVT LTD. Your outstanding performance has demonstrated your strong skills and knowledge in ${courseData[0]?.exam_course}, and we are thrilled to offer you the ${courseData[0]?.exam_course} role at our company.`;
    } else {
      subject = `Dear Candidate, Thank you for taking the time to complete the ${courseData[0]?.exam_course} Exam for the position at Airepro Solution PVT LTD. We appreciate your interest in joining our team and the effort you put into the assessment process. After carefully reviewing your exam results, we regret to inform you that you did not pass the exam. Below are the details of your performance.`;
    }

    const mail_data = {
      templateId: 19,
      to: email,
      placeholders: {
        Exam_Course: courseData[0]?.exam_course || "",
      },

      subject: subject,

    };
    console.log("mail_data", mail_data);
    try {
      const res = await axios.post(sendEmailURL, mail_data);
      console.log("mail response data", res);
      alert("Mail Sent Succesfully!");
      //  window.location.reload();
    } catch (error) {
      console.error("Error Mail Sending data:", error);
      alert("Error sending mail. Please try again.");
    }
  };

  // show modal
  const [viewModal, setViewModal] = useState(false);
  const [vid, setVId] = useState("");
  const [vname, setName] = useState("");
  const [vemail, setEmail] = useState("");
  const [vexamcourse, setExamCourse] = useState("");
  const viewHandler = (id, name, email, exam_course) => {
    setViewModal(!viewModal);
    setVId(id);
    setName(name);
    setEmail(email);
    setExamCourse(exam_course);
  };
  // console.log("viewHandler result", viewHandler);

  // dropdown search bar
  const [selectedExamStatus, setExamStatus] = useState("completed");
  const [filteredData, setFilteredData] = useState([]);

  const changehandler = (e) => {
    setExamStatus(e.target.value);
    // filterData(e.target.value);
  };
  // const searchHandler = () => {
  //   filterData(selectedExamStatus);
  // };
  useEffect(() => {
    // Update filtered data whenever selectedExamStatus or getallexamdata changes
    filterData(selectedExamStatus);
  }, [selectedExamStatus, getallexamdata]);
  function filterData(ExamStatus) {
    if (!ExamStatus.trim()) {
      // If no course is selected, show all data
      setFilteredData(getallexamdata);
    } else {
      // Filter data based on the selected course
      const filtered = getallexamdata.filter(
        (item) => item.exam_status === ExamStatus
      );
      setFilteredData(filtered);
    }
  }

  // Calculate pass/fail status
  const calculatePassFail = (data) => {
    // const totalQuestions = 60;
    const totalQuestions = data.length;
    const correctAnswers = data.reduce((acc, item) => {
      return acc + (item.Answer === item.submit_answer ? 1 : 0);
    }, 0);
    const percentage = (correctAnswers / totalQuestions) * 100;
    return percentage >= 75 ? "Pass" : "Fail";
  };

  const [checkBoxData, setCheckBoxData] = useState({})

  useEffect(() => {
    selectedCourseData?.map(element => {
      const chackboxqustions = element.QuestionType === 'CheckBoxes' ? JSON.parse(element.submit_answer) : ''
      setCheckBoxData(chackboxqustions);
      console.log(chackboxqustions);
    })

  }, [selectedCourseData])

  return (
    <>
      <div className="flex flex-col gap-10">
        <div className="mt-9 flex  gap-3 justify-center">
          <input
            className="input input-bordered text-black input-md max-w-sm w-full"
            placeholder="Email ID "
            required
            onChange={changehandler1}
            value={val1}
          />
          <button
            type="submit"
            onClick={(e) => getdata(e)}
            className="btn btn-md text-lg font-bold"
          >
            Search
          </button>
        </div>

        {datas1 &&
          datas1.map((ab) => (
            <>
              {/* <div className=""> */}
              <div key={ab.exam_course} className=" flex gap-7 items-center">
                <label htmlFor="" className="text-xl font-bold">
                  {ab.exam_course}
                </label>
                <button
                  onClick={() => viewCourseData(ab.exam_course)}
                  className="btn btn-sm btn-accent"
                >
                  view
                </button>
              </div>
            </>
          ))}

        {selectedCourseData && (
          <div>
            <div className="text-lg font-bold mb-5">
              <h1>Candidate Details:</h1>
            </div>
            <div className="overflow-x-auto ">
              <table className="table  table-zebra border-2 border-slate-400">
                <thead>
                  <tr className="bg-slate-300 border-slate-400">
                    <th className="font-extrabold border-r-2 border-slate-400">
                      Question
                    </th>
                    <th className="font-extrabold border-r-2 border-slate-400">
                      Answer
                    </th>
                    <th className="font-extrabold border-r-2 border-slate-400">
                      Candidate Answer
                    </th>
                    <th className="font-extrabold border-r-2 border-slate-400">
                      Result
                    </th>
                    <th className="font-extrabold border-r-2 border-slate-400">
                      Mark
                    </th>
                    <th className="font-extrabold border-r-2 border-slate-400">
                      Exam Course
                    </th>

                    <th className="font-extrabold border-r-2 border-slate-400">
                      Email
                    </th>
                  </tr>
                </thead>
                <tbody>
                  {selectedCourseData &&
                    selectedCourseData.map((item, index) => (
                      <tr key={index} className="bg-slate-300 border-slate-400">
                        <td className="border-r-2 border-slate-400">
                          {/* {a.questions} */}
                          {item.questions}
                        </td>
                        <td className="border-r-2 border-slate-400">
                          {/* {a.submit_answer} */}
                          {item.Answer}
                        </td>
                        <td className="border-r-2 border-slate-400">
                          {
                            item?.QuestionType === 'CheckBoxes' ? checkBoxData.A || checkBoxData.B || checkBoxData.C || checkBoxData.D : item.submit_answer
                          }
                        </td>

                        <td className="border-r-2 border-slate-400">
                          {item.Answer === item.submit_answer
                            ? "Correct"
                            : "Incorrect"}
                        </td>
                        <td className="border-r-2 border-slate-400">
                          {item.Answer === item.submit_answer ? 1 : 0}
                        </td>
                        <td className="border-r-2 border-slate-400">
                          {item.exam_course}
                        </td>
                        <td className="border-r-2 border-slate-400">
                          {item.email}
                        </td>
                      </tr>
                    ))}
                </tbody>
              </table>
            </div>

            <div className="flex justify-center items-center">
              <button
                name="submit"
                type="submit"
                onClick={() => handleMailButtonClick(val1, selectedCourseData)}
                // onClick={() =>
                //   handleMailButtonClick(
                //     selectedCourseData[0].email,
                //     selectedCourseData[0].exam_course
                //   )
                // }
                className="m-3 font-bold btn btn-accent"
              >
                Send email
              </button>
              <label className="text-xl font-bold" htmlFor="">
                {/* Pass / Fail */}
                Result: {calculatePassFail(selectedCourseData)}
              </label>
            </div>
          </div>
        )}

        {/* all data show */}
        <div>
          <div className="gap-7 rounded-t-lg flex flex-row p-5">
            <div className="text-lg font-bold mb-5">
              <h1>All Details:</h1>
            </div>
            {/* dropdown */}
            <div className="w-1/4">
              <select
                name="Course"
                onChange={changehandler}
                value={selectedExamStatus}
                className="select select-bordered text-black select-sm max-w-md w-full"
              >
                <option value="">All Exam Status</option>
                <option value="completed">Completed</option>
                <option value="undefine">Undefine</option>
                <option value="pending">Pending</option>
              </select>
            </div>
            {/* <button
              type="button"
              onClick={searchHandler}
              // type="submit"
              className="text-lg font-bold btn btn-sm"
            >
              Search
            </button> */}
          </div>

          {/* end dropdown */}
          <div className="overflow-x-auto ">
            <table className="table  table-zebra border-2 border-slate-400">
              <thead>
                <tr className="bg-slate-300 border-slate-400">
                  <th className="font-extrabold border-r-2 border-slate-400">
                    FreelancerID
                  </th>
                  <th className="font-extrabold border-r-2 border-slate-400">
                    name
                  </th>
                  <th className="font-extrabold border-r-2 border-slate-400">
                    Email
                  </th>
                  <th className="font-extrabold border-r-2 border-slate-400">
                    Mobile
                  </th>
                  <th className="font-extrabold border-r-2 border-slate-400">
                    Exam Course
                  </th>

                  <th className="font-extrabold border-r-2 border-slate-400">
                    Exam Status
                  </th>

                  <th className="font-extrabold border-r-2 border-slate-400">
                    Action
                  </th>
                </tr>
              </thead>
              <tbody>
                {filteredData.length > 0 ? (
                  filteredData.map((alldata, index) => (
                    // {/* {filteredData.map((alldata, index) => ( */}
                    <>
                      {" "}
                      <tr key={index} className="bg-slate-300 border-slate-400">
                        <td className="border-r-2 border-slate-400">
                          {alldata.freelancerID}
                        </td>
                        <td className="border-r-2 border-slate-400">
                          {alldata.name}
                        </td>
                        <td className="border-r-2 border-slate-400">
                          {alldata.email}
                        </td>

                        <td className="border-r-2 border-slate-400">
                          {alldata.phone_number}
                        </td>
                        <td className="border-r-2 border-slate-400">
                          {alldata.exam_course}
                        </td>
                        <td className="border-r-2 border-slate-400">
                          {alldata.exam_status}
                        </td>
                        <td className="border-r-2 border-slate-400">
                          {/* view */}
                          <label
                            // onClick={() => viewCourseData(alldata.exam_course)}
                            onClick={() =>
                              viewHandler(
                                alldata.id,
                                alldata.name,
                                alldata.email,
                                alldata.exam_course
                              )
                            }
                            htmlFor="my_modal_7"
                            className="btn btn-xs btn-info text-white"
                          >
                            view
                            {/* <FiCheckSquare /> */}
                          </label>
                        </td>
                      </tr>
                    </>
                  ))
                ) : (
                  <>
                    {getallexamdata &&
                      getallexamdata.map((alldata, index) => (
                        <tr className="bg-slate-300 border-slate-400">
                          <td className="border-r-2 border-slate-400">
                            {alldata.freelancerID}
                          </td>
                          <td className="border-r-2 border-slate-400">
                            {alldata.name}
                          </td>
                          <td className="border-r-2 border-slate-400">
                            {alldata.email}
                          </td>

                          <td className="border-r-2 border-slate-400">
                            {alldata.phone_number}
                          </td>
                          <td className="border-r-2 border-slate-400">
                            {alldata.exam_course}
                          </td>
                          <td className="border-r-2 border-slate-400">
                            {alldata.exam_status}
                          </td>
                          <td className="border-r-2 border-slate-400">
                            {/* view */}
                            <label
                              // onClick={() => viewCourseData(alldata.exam_course)}
                              onClick={() =>
                                viewHandler(
                                  alldata.id,
                                  alldata.name,
                                  alldata.email,
                                  alldata.exam_course
                                )
                              }
                              htmlFor="my_modal_7"
                              className="btn btn-xs btn-info text-white"
                            >
                              view
                              {/* <FiCheckSquare /> */}
                            </label>
                          </td>
                        </tr>
                      ))}
                  </>
                )}
                {/* ))} */}
              </tbody>
            </table>
          </div>
        </div>
        {/*  end all data show*/}
      </div>

      {viewModal && (
        <ViewResults
          setViewModal={setViewModal}
          viewModal={viewModal}
          id={vid}
          name={vname}
          email={vemail}
          exam_course={vexamcourse}
        />
      )}
    </>
  );
};

export default Results;
