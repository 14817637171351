import React, { useCallback, useEffect, useState } from "react";
import { BsArrowLeftRight, BsPlusCircle } from "react-icons/bs";
import AddNewCandidate from "./AddNewCandidate";
import { BiSolidEdit } from "react-icons/bi";
import { RiDeleteBin5Line } from "react-icons/ri";
import EditCandidate from "./EditCandidate";
import DeleteCandidate from "./DeleteCandidate";
import TestModal from "./TestModal";
import ImportExportStudents from "./ImportExportStudents";
import ReactHTMLTableToExcel from "react-html-table-to-excel";
import { CSVLink } from "react-csv";
import { logError, logInfo } from "../../../Elasticsearchinstance";
import useAxiosPublic from "../../../Hooks/useAxiosPublic";

const Candidate = () => {
  const axiousPublic = useAxiosPublic();

  // get data
  const [candidates, setCandidates] = useState([]);
  const [editModal, setEditModal] = useState(false);

  const [freelancerID, setFreelancerId] = useState("");
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [address, setAddress] = useState("");
  const [phone_number, setPhone_number] = useState("");
  const [job_profile, setJob_profile] = useState("");
  const [tech_skills, setTech_skills] = useState("");
  const [group_tag, setGroup_tag] = useState("");
  const [status, setStatus] = useState("");
  const [enrollment_number, setEnrollment_number] = useState("");
  const [experience, setExperience] = useState("");
  const [salary_expectation, setSalary_expectation] = useState("");
  const [education, setEducation] = useState("");

   const fetchData = useCallback(async () => {
     try {
       const { data } = await axiousPublic.get(`/getCandidateUsers`);
       setCandidates(data.result);
       logInfo("fetch candidate data", data);
     } catch (error) {
       logError("Error fetching candidate data", error);
     }
   }, [axiousPublic]);

   useEffect(() => {
     fetchData();
   }, [fetchData]);


  // useEffect(() => {
  //   const fetchData = async () => {
  //     let data = await fetch(getcandidateURL);
  //     let jsonData = await data.json();
  //     // console.log(jsonData);
  //     setCandidates(jsonData.result);
  //     logInfo("fetch candidate data", jsonData);
  //   };
  //   fetchData();
  // }, []);

  const deleteHandler = async (freelancerID) => {
    if (!window.confirm("Are you sure you want to delete this candidate?")) {
      return;
    }
    // Log the deletion attempt
    logInfo("Attempting to delete candidate", { freelancerID });

    try {
      const res = await axiousPublic.delete(`/deleteCandidate`, {
        data: { freelancerID: freelancerID },
      });
      // Log the response
      logInfo("Delete candidate response", res.data);

      if (res.data.success) {
        alert("Candidate deleted successfully!");
        window.location.reload();
      } else {
        alert("Failed to delete candidate.");
      }
    } catch (error) {
      // Log the error
      logError("Error deleting candidate", error);
      console.error("Error deleting candidate:", error);
      alert("Error deleting candidate. Please try again.");
    }
  };

  const updateHandler = (
    id,
    name,
    email,
    address,
    phone_number,
    job_profile,
    tech_skills,
    group_tag,
    status,
    enrollment_number,
    experience,
    salary_expectation,
    education
  ) => {
    setFreelancerId(id);
    setEditModal(!editModal);
    setName(name);
    setEmail(email);
    setAddress(address);
    setPhone_number(phone_number);
    setJob_profile(job_profile);
    setTech_skills(tech_skills);
    setGroup_tag(group_tag);
    setStatus(status);
    setEnrollment_number(enrollment_number);
    setExperience(experience);
    setSalary_expectation(salary_expectation);
    setEducation(education);
  };

  const clonedCandidates = candidates.map(({ id, ...rest }) => rest);

  return (
    <>
      <div className="flex max-md:flex-col max-md:flex   hover:text-white ">
        <div className="cursor-pointer border-solid border-2 bg-primary   hover:bg-purple-900 hover:text-white text-white">
          <h2 className=" join font-medium text-base p-4 ">
            <BsPlusCircle className="mt-[5px] mr-[5px]" />
            {/* Add New Candidate */}
            <AddNewCandidate />
          </h2>
        </div>

        <div className="cursor-pointer border-solid border-2 bg-yellow-500   hover:bg-purple-900 hover:text-white text-white">
          <h2 className=" join font-medium text-base p-4 ">
            <BiSolidEdit className="mt-[5px] mr-1" />
            <EditCandidate />
          </h2>
        </div>

        <div className="cursor-pointer border-solid border-2 bg-red-800  hover:bg-purple-900 hover:text-white text-white">
          <h2 className=" join font-medium text-base p-4 ">
            <RiDeleteBin5Line className="mt-[5px] mr-1" />
            <DeleteCandidate />
          </h2>
        </div>

        <div className="cursor-pointer border-solid border-2 bg-slate-500 hover:bg-purple-900 hover:text-white text-white">
          <h2 className=" join font-medium text-base p-4 ">
            <BsArrowLeftRight className="mt-[5px] mr-1" />
            {/* Import Export Students */}
            <ImportExportStudents />
          </h2>
        </div>

      </div>

      <div className="overflow-x-auto mt-7">
        <div className="flex gap-3">
          <ReactHTMLTableToExcel
            id="test-table-xls-button"
            className="download-table-xls-button btn btn-success"
            table="table-to-xls"
            filename="tablexls"
            sheet="tablexls"
            buttonText="Export Data to Excel Sheet"
            exclude={["Id"]} // Exclude the ID column
          />
          <CSVLink
            data={clonedCandidates}
            filename="RegisterUserData"
            className="btn btn-success mb-3"
          >
            Export Data TO CSV
          </CSVLink>
        </div>

        <table className="table" id="table-to-xls">
          {/* head */}
          <thead>
            <tr>
              <th>
                <label>
                  <input type="checkbox" className="checkbox" />
                </label>
              </th>
              {/* <th className="font-extrabold">ID</th> */}
              <th className="font-extrabold">Freelancer ID</th>
              <th className="font-extrabold">Name</th>
              <th className="font-extrabold">Email</th>
              <th className="font-extrabold">Address</th>
              <th className="font-extrabold">Mobile No</th>
              <th className="font-extrabold">Job Profile</th>
              <th className="font-extrabold">Tech Skills</th>
              <th className="font-extrabold">Group Tag</th>
              <th className="font-extrabold">Status</th>
              <th className="font-extrabold">Enrollment no</th>
              <th className="font-extrabold">Experience</th>
              <th className="font-extrabold">Salary Expectation</th>
              <th className="font-extrabold">Education</th>

              <th className="font-extrabold">Actions</th>
            </tr>
          </thead>
          <tbody>
            {/* row 1 */}
            {candidates &&
              candidates.map((candidate, index) => (
                <tr>
                  <th>
                    <label>
                      <input type="checkbox" className="checkbox" />
                    </label>
                  </th>
                  {/* <td>{candidate.id}</td> */}
                  <td>{candidate.freelancerID}</td>
                  <td>{candidate.name}</td>
                  <td>{candidate.email}</td>
                  <td>{candidate.address}</td>
                  <td>{candidate.phone_number}</td>
                  <td>{candidate.job_profile}</td>
                  <td>{candidate.tech_skills}</td>
                  <td>{candidate.group_tag}</td>
                  <td>{candidate.status}</td>
                  <td>{candidate.enrollment_number}</td>
                  <td>{candidate.experience}</td>
                  <td>{candidate.salary_expectation}</td>
                  <td>{candidate.education}</td>
                  <td className="flex gap-2">
                    {/* edit */}

                    <label
                      onClick={() =>
                        updateHandler(
                          candidate.freelancerID,
                          candidate.name,
                          candidate.email,
                          candidate.address,
                          candidate.phone_number,
                          candidate.job_profile,
                          candidate.tech_skills,
                          candidate.group_tag,
                          candidate.status,
                          candidate.enrollment_number,
                          candidate.experience,
                          candidate.salary_expectation,
                          candidate.education
                        )
                      }
                      htmlFor="my_modal_9"
                      className="btn btn-sm btn-primary"
                    >
                      Edit
                    </label>

                    {/* delete */}
                    <button
                      // onClick={() => {
                      //   deleteHandler(b);
                      // }}
                      onClick={() => deleteHandler(candidate.freelancerID)}
                      // onClick={() => deleteHandler(candidate.freelancerID)}
                      className="btn btn-sm bg-red-500 hover:bg-red-500 font-bold text-white"
                    >
                      Delete
                    </button>
                  </td>
                </tr>
              ))}
          </tbody>
        </table>
      </div>
      {editModal && (
        <TestModal
          setEditModal={setEditModal}
          editModal={editModal}
          id={freelancerID}
          name={name}
          email={email}
          address={address}
          phone_number={phone_number}
          job_profile={job_profile}
          tech_skills={tech_skills}
          group_tag={group_tag}
          status={status}
          enrollment_number={enrollment_number}
          experience={experience}
          salary_expectation={salary_expectation}
          education={education}
        />
      )}
    </>
  );
};

export default Candidate;
