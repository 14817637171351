
import { useEffect, useState } from "react";
import useAxiosPublic from "../../../Hooks/useAxiosPublic";
import { useLocation } from "react-router-dom";

const QuestionPaperProfile = ({ setGroupTag }) => {
    const location = useLocation();
    const searchParams = new URLSearchParams(location.search);
    const inputEmail = searchParams.get("exam_email");
    const axiousPublic = useAxiosPublic()
    const [profileDat, setProfileData] = useState({})
    const profileLogo = profileDat?.name?.slice(0, 1)

    useEffect(() => {
        axiousPublic.get(`/getCandidateUsers`)
            .then(res => {
                console.log(res.data.result)
                const matchingEmail = res.data.result.find((element) => element.email === inputEmail)
                setProfileData(matchingEmail)
                setGroupTag(matchingEmail.group_tag)
                console.log('user', matchingEmail)
            })
            .catch(error => {
                console.log(error);
            })
    }, [axiousPublic, inputEmail, setGroupTag, profileDat?.group_tag])

    return (
        <div className="text-end flex justify-end items-center gap-5 ">
            <div>
                <h1 className="text-2xl mb-2">{profileDat?.name}</h1>
                <h1 className="text-lg">Exam {profileDat?.group_tag}</h1>
            </div>
            <div>
                {/* heare you gate candidate image */}
                <h1 className="h-20 w-20 rounded-full border-2 border-black flex justify-center items-center text-6xl font-bold  shadow-2xl text-primary pb-2">{profileLogo}</h1>
                {/* <img className="h-20 w-20 rounded-full border-4 border-black" src="https://i.ibb.co/cD2z7JQ/male-avatar-profile-picture-vector-10210618.jpg" alt="" /> */}
            </div>
        </div>
    );
};

export default QuestionPaperProfile;