import React from 'react'
import { AiOutlineCreditCard } from 'react-icons/ai';
import { BiPackage } from 'react-icons/bi';
import { FiClock, FiMic, FiUserCheck } from 'react-icons/fi';
import { TfiPieChart } from 'react-icons/tfi';
import { NavLink } from 'react-router-dom';

const HomeDashobard = () => {
  const menuItems = [
    {
      id: 1,
      text: "Candidate",
      number: 1,
      to: "/candidate",
    },
    {
      id: 2,
      text: "Exam",
      number: 2,
    },
    {
      id: 3,
      text: "Result",
      number: 3,
    },
    {
      id: 4,
      text: "LogOut",
      number: 4,
    },
  ];

  const handelLogout = () => {
    localStorage.setItem(

      "HireInfo",
      JSON.stringify({value : null})

    );
  }

  return (
    <>
      {/* <div className=" col-span-4"> */}
      {/* tab */}
      <div className="grid grid-cols-4 max-md:flex-col max-md:flex   hover:text-white ">
        {menuItems &&
          menuItems.map((li) => (
            <div>
              <div
                htmlFor={li.id}
                className="cursor-pointer p-3 border-solid border-2 border-black hover:bg-black hover:text-white text-black"
              >
                {/* <div className=" items-center"> */}
                <h2 className="card-title justify-center ">
                  <li className="list-none" id={li.id} key={li?.id}>
                    <NavLink to={li.to} activeClassName="active-link">
                      {
                        li?.id === 4 ? <p onClick={handelLogout} className="  w-7">{li?.text}</p> : <p className="  w-7">{li?.text}</p>
                      }
                    </NavLink>

                  </li>
                </h2>
                {/* </div> */}
              </div>
            </div>
          ))}
      </div>
      {/* end tab */}

      <div className="grid my-16 grid-cols-3 gap-3 max-md:flex-col max-md:flex">
        {/* 1st*/}
        <div className="rounded-lg  bg-emerald-500 text-primary-content">
          <div className="card-body items-center">
            <h2 className="card-title btn btn-link text-white no-underline max-md:flex-col max-md:flex">
              <img
                src="https://www.buyerforesight.com/wp-content/uploads/2022/11/IT-Support-Engineer.png"
                alt=""
              />
            </h2>
          </div>
        </div>
        {/* end 1st*/}

        {/* 2nd */}
        <div className="grid col-span-2 grid-cols-3 gap-3 max-md:flex-col max-md:flex">
          <div className="rounded-lg  bg-orange-600 text-primary-content">
            <div className="card-body flex flex-row justify-evenly  items-center gap-9">
              <div>
                <BiPackage className="w-10 h-16" />
              </div>
              <div className="flex flex-col items-center">
                <h2 className="card-title text-2xl text-white ">
                  Subscription
                </h2>
                <p className="card-title text-white mt-1">Plan</p>
                <p className="card-title text-white mt-1">Free</p>
              </div>
            </div>
          </div>

          <div className="rounded-lg  bg-sky-500 text-primary-content">
            <div className="card-body flex flex-row justify-evenly  items-center gap-9">
              <div className="flex flex-col">
                <h2 className="card-title text-xl text-white ">
                  Allocated Exam Credit
                </h2>
                <p className="card-title text-white mt-1">0/25</p>
                <hr className="bg-white h-1 my-1 md:w-[200px] mx-auto" />
                <div className="flex">
                  <p className=" text-white">Consumed</p>
                  <p className=" text-white flex justify-end">0.00%</p>
                </div>
              </div>
            </div>
          </div>

          <div className="rounded-lg  bg-primary text-primary-content">
            <div className="card-body flex flex-row justify-evenly  items-center gap-9">
              <div>
                <FiUserCheck className="w-10 h-16" />
              </div>
              <div className="flex flex-col items-center">
                <h2 className="card-title text-2xl text-white ">Registered</h2>
                <p className="card-title text-white mt-1">Users</p>
                <p className="card-title text-white mt-2 text-2xl">
                  <FiMic />
                </p>
              </div>
            </div>
          </div>

          <div className="rounded-lg  bg-blue-800 text-primary-content">
            <div className="card-body flex flex-row justify-evenly  items-center gap-9">
              <div>
                <FiClock className="w-10 h-16" />
              </div>
              <div className="flex flex-col items-center">
                <h2 className="card-title text-2xl text-white ">
                  Subscription
                </h2>
                <p className="card-title text-white mt-1">Expiry Date</p>
                <p className="card-title text-white mt-1">26-08-2022</p>
              </div>
            </div>
          </div>

          <div className="rounded-lg  bg-purple-500 text-primary-content">
            <div className="card-body ">
              <div className="flex justify-between ">
                <AiOutlineCreditCard className="w-10 h-16" />
                <div className="flex flex-col items-center">
                  <h2 className="card-title text-xl text-white ">
                    Question Count
                  </h2>
                  <p className="card-title text-white mt-1">20</p>
                </div>
              </div>
              <div className="flex ">
                <p className=" text-white">Objective</p>
                <p className=" text-white ">20</p>
              </div>
              <div className="flex ">
                <p className=" text-white">Subjective</p>
                <p className=" text-white">0</p>
              </div>
            </div>
          </div>

          <div className="rounded-lg  bg-sky-900 text-primary-content">
            <div className="card-body flex flex-row justify-evenly  items-center md:gap-9">
              <div className="">
                <TfiPieChart className="w-10 h-16" />
              </div>
              <div className="flex flex-col items-center">
                <h2 className="card-title text-2xl text-white ">Total Exam</h2>
                <p className="card-title text-white mt-1">Count</p>
                <p className="card-title text-white mt-2 text-2xl">
                  <FiMic />
                </p>
              </div>
            </div>
          </div>
        </div>
        {/* end 2nd */}
      </div>
      {/* </div> */}
    </>
  );
}

export default HomeDashobard