import React from "react";
import { BsFillCheckCircleFill } from "react-icons/bs";

const Security = () => {
  return (
    <>

      <div className="p-4 md:grid grid-cols-2 mt-5">
        <div className="flex justify-center items-center">
          <img
            src="https://static.vecteezy.com/system/resources/previews/006/584/841/original/illustration-graphic-cartoon-character-of-cyber-security-vector.jpg"
            alt=""
            className="inline w-[33rem]"
          />
        </div>
        <div>
          <div className="max-lg:text-center">
            <button className="btn text-md bg-primary btn-sm text-white hover:bg-primary">
              Security
            </button>
          </div>
          <div className="lg:w-[39rem]">
            <h1 className="text-3xl font-semibold leading-[37px] mt-3 max-lg:text-xl">
              At ExamOnline, we take security seriously. Our platform is
              designed to provide the highest level of exam security, with
              features such as:
            </h1>
          </div>
          <div className="md:w-[39rem]">
            <div className=" flex mt-6 gap-6">
              <span>
                <BsFillCheckCircleFill className="mt-1 text-3xl text-primary" />
              </span>
              <p className=" text-lg">
                Data encryption: All data is encrypted in transit and at rest to
                prevent unauthorized access
              </p>
            </div>
            <div className=" flex mt-6 gap-6">
              <span>
                <BsFillCheckCircleFill className="mt-1 text-3xl text-primary" />
              </span>
              <p className=" text-lg">
                User authentication: Candidates are required to go through a
                strict authentication process before starting the exam.
              </p>
            </div>

            <div className=" flex mt-6 gap-6">
              <span>
                <BsFillCheckCircleFill className="mt-1 text-3xl text-primary" />
              </span>
              <p className="text-lg">
                Secure browser: Our secure browser prevents candidates from
                accessing unauthorized resources during the exam.
              </p>
            </div>
            <div className=" flex mt-6 gap-6">
              <span>
                <BsFillCheckCircleFill className="mt-1 text-3xl text-primary" />
              </span>
              <p className=" text-lg">
                Proctoring technology: Our advanced AI-based technology detects
                and prevents cheating in real-time.
              </p>
            </div>
            <div className=" flex mt-6 gap-6">
              <span>
                <BsFillCheckCircleFill className="mt-1 text-3xl text-primary" />
              </span>
              <p className=" text-lg">
                Access controls: Our platform includes strict access controls to
                ensure only authorized users can access exam data.
              </p>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Security;
