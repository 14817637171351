import React, { useCallback, useEffect, useState } from "react";
import AddNewInstructions from "./AddNewInstructions";
import EditInstruction from "./EditInstruction";

import { RiDeleteBinLine } from "react-icons/ri";
import { FaRegEdit } from "react-icons/fa";
import { FiCheckSquare } from "react-icons/fi";
import ViewInstruction from "./ViewInstruction";
import ReactHTMLTableToExcel from "react-html-table-to-excel";
import { CSVLink } from "react-csv";
import { logError, logInfo } from "../../../Elasticsearchinstance";
import useAxiosPublic from "../../../Hooks/useAxiosPublic";


const Instruction = () => {
  const axiousPublic = useAxiosPublic();

  // get data
  const [getinstruction, setGetInstruction] = useState([]);
  const fetchData = useCallback(async () => {
    try {
      const { data } = await axiousPublic.get(`/getinstruction`);
      setGetInstruction(data.result);
      logInfo("fetch instruction data", data);
    } catch (error) {
      logError("Error fetching instruction data", error);
    }
  }, [axiousPublic]);

  useEffect(() => {
    fetchData();
  }, [fetchData]);


  const [editModal, setEditModal] = useState(false);
  const [id, setId] = useState("");
  const [title, setTitle] = useState("");
  const [content, setContent] = useState("");
  const updateHandler = (id, title, content) => {
    setId(id);
    setEditModal(!editModal);
    setTitle(title);
    setContent(content);
  };

  const [viewModal, setViewModal] = useState(false);
  const [vid, setVId] = useState("");
  const [vtitle, setVTitle] = useState("");
  const [vcontent, setVContent] = useState("");
  const viewHandler = (id, title, content) => {
    setViewModal(!viewModal);
    // setViewModal(true);
    setVId(id);
    setVTitle(title);
    setVContent(content);
  };

  //   delete
  const deleteHandler = async (id) => {
    if (!window.confirm("Are you sure you want to delete this ins?")) {
      return;
    }

    try {
      //  const res = await axios.delete(`${delInstructionURL}${id}`);
      const res = await axiousPublic.delete(`/delInstruction`, {
        data: { id: id },
      });
      console.log("heloo delete", res);
      // Log the successful deletion
      logInfo('Instruction deleted successfully', { id })
      if (res.data.success) {
        alert("ins deleted successfully!");
        window.location.reload();
      } else {
        alert("Failed to delete ins.");
        // Log the failed deletion
        logError('Failed to delete instruction', { id });
      }
    } catch (error) {
      console.error("Error deleting ins:", error);
      // Log the error
      logError('Error deleting instruction', error);
      alert("Error deleting ins. Please try again.");
    }
  };

  const clonedCandidates = getinstruction.map(({ id, ...rest }) => rest);

  return (
    <>
      <div className="p-4">
        {/* <p className="underline underline-offset-4 text-lg text-slate-700 hover:text-slate-500 cursor-pointer">
          <span className="font-bold text-2xl">+</span>Add a Instruction
        </p> */}
        <AddNewInstructions />
      </div>
      <div className="flex gap-3">
        <ReactHTMLTableToExcel
          id="test-table-xls-button"
          className="download-table-xls-button btn btn-sm  btn-outline"
          table="table-to-xls"
          filename="tablexls"
          sheet="tablexls"
          buttonText=" Excel"
           />
        <CSVLink
          data={clonedCandidates}
          filename="RegisterUserData"
          className="btn btn-sm btn-outline mb-3"
        >
          CSV
        </CSVLink>
      </div>
      {/* 2nd div */}
      {/* <div className="grid grid-cols-6"> */}
      <div className="">
        <div className="col-start-1 col-end-7 ml-3 mt-5 text-lg font-bold">
          {/* <h1>Questions Lists:</h1> */}
        </div>
        <div className="m-3 col-start-1 col-end-7 bg-base-300 shadow-xl rounded-lg ">
          <div className="overflow-x-auto ">
            <table
              className="table  table-zebra border-2 border-slate-400"
              id="table-to-xls"
            >
              <thead>
                <tr className="bg-slate-300 border-slate-400">
                  {/* <th className="font-extrabold border-r-2 border-slate-400">
                    Course
                  </th> */}
                  <th className="font-extrabold border-r-2 border-slate-400">
                    <input type="checkbox" className="checkbox" />
                  </th>
                  <th className="font-extrabold border-r-2 border-slate-400">
                    Title
                  </th>
                  <th className="font-extrabold border-r-2 border-slate-400">
                    Content
                  </th>
                  <th className="font-extrabold border-r-2 border-slate-400">
                    Action
                  </th>
                </tr>
              </thead>
              <tbody>
                {getinstruction &&
                  getinstruction.map((instruction, index) => (
                    <tr className="bg-slate-300 border-slate-400">
                      {/* <td className="border-r-2 border-slate-400">
                      </td> */}
                      <th className="border-r-2 border-slate-400">
                        <input type="checkbox" className="checkbox" />
                      </th>
                      <td className="border-r-2 border-slate-400">
                        {instruction.title}
                      </td>
                      <td className="border-r-2 border-slate-400">
                        {instruction.content}
                      </td>
                      <td className="flex gap-2">
                        {/* view */}
                        <div className="tooltip" data-tip="View">
                          <label
                            onClick={() =>
                              viewHandler(
                                instruction.id,
                                instruction.title,
                                instruction.content
                              )
                            }
                            htmlFor="my_modal_10"
                            className="btn btn-xs btn-info text-white"
                          >
                            {/* view */}
                            <FiCheckSquare />
                          </label>
                        </div>

                        {/* edit */}
                        <div className="tooltip" data-tip="Edit">
                          <label
                            onClick={() =>
                              updateHandler(
                                instruction.id,
                                instruction.title,
                                instruction.content
                              )
                            }
                            htmlFor="my_modal_9"
                            className="btn btn-xs btn-warning text-white"
                          >
                            {/* Edit */}
                            <FaRegEdit />
                          </label>
                        </div>

                        {/* delete */}
                        <div className="tooltip" data-tip="Delete">
                          <button
                            onClick={() => deleteHandler(instruction.id)}
                            className="btn btn-xs bg-red-500 hover:bg-red-500 font-bold text-white"
                          >
                            {/* Delete */}
                            <RiDeleteBinLine />
                          </button>
                        </div>
                      </td>
                    </tr>
                  ))}
              </tbody>
            </table>
          </div>
        </div>
      </div>

      {editModal && (
        <EditInstruction
          setEditModal={setEditModal}
          editModal={editModal}
          id={id}
          title={title}
          content={content}
        />
      )}
      {viewModal && (
        <ViewInstruction
          setViewModal={setViewModal}
          viewModal={viewModal}
          id={vid}
          title={vtitle}
          content={vcontent}
        />
      )}
    </>
  );
};

export default Instruction;
