/* eslint-disable jsx-a11y/anchor-is-valid */
import React from 'react'
import { BsTwitter, BsInstagram, BsYoutube } from "react-icons/bs";
import { FaLinkedinIn, FaFacebookF } from "react-icons/fa6";
import { Link } from "react-router-dom";


const Footer = () => {
  return (
    <>
      <footer className="footer p-10 bg-black justify-around  text-base-content">
        <nav className="text-white text-lg">
          <header className="footer-title">Company</header>
          <a
            className="link link-hover text-gray-600"
            href="https://elite-professionals.in/about-us/"
          >
            About us
          </a>
          <Link to="/ContactUs" className="link link-hover text-gray-600">
            Contact
          </Link>
          <a className="link link-hover text-gray-600">Testimonials</a>
          <a
            href="http://www.hire.elite-professionals.in/privacy&policy"
            className="link link-hover text-gray-600"
          >
            Privacy and Data Security
          </a>
        </nav>
        <nav className="text-white text-lg">
          <header className="footer-title">SOLUTIONS</header>
          <a
            href="http://www.hire.elite-professionals.in/"
            className="link link-hover text-gray-600"
          >
            Hiring Solution
          </a>
          <a className="link link-hover text-gray-600">Online Learning</a>
        </nav>
        <nav className="text-gray-400">
          <div className="grid grid-flow-col gap-5">
            <a href="twitter@elite-professionals.in">
              <BsTwitter className="w-8 h-9" />
            </a>
            <a href="youtube@elite-professionals.in">
              <BsYoutube className="w-8 h-9" />
            </a>
            <a href="facebook@elite-professionals.in">
              <FaFacebookF className="w-7 h-8" />
            </a>
            <a href="linkedin@elite-professionals.in">
              <FaLinkedinIn className="w-7 h-9" />
            </a>
            <a href="instagram@elite-professionals.in">
              <BsInstagram className="w-7 h-9" />
            </a>
          </div>
        </nav>
      </footer>
      <footer className="footer md:px-[11rem] py-6  text-gray-500 bg-black border-base-300">
        <aside className="items-center grid-flow-col">
          <p className="text-lg text-gray-400">© 2023 ExamOnline</p>
        </aside>
      </footer>
    </>
  );
}

export default Footer