import React, { useState } from "react";
import { TiTick } from "react-icons/ti";
import { PiBookOpenTextFill } from "react-icons/pi";
import { HiOutlineChatBubbleBottomCenterText } from "react-icons/hi2";
import { MdOutlineReportProblem } from "react-icons/md";

import { logError, logInfo } from "../../../Elasticsearchinstance";
import useAxiosPublic from "../../../Hooks/useAxiosPublic";


const QuestionBank = () => {
  const [selectedCheckboxes, setSelectedCheckboxes] = useState([]);
  const [isRadioSelected, setIsRadioSelected] = useState(false); // Track if a radio button is selected

  const [data, setData] = useState({
    Course: "",
    Skills: "",
    Questions: "",
    OptionA: "",
    OptionB: "",
    OptionC: "",
    OptionD: "",
    Answer: "",
    Description: "",
    Status: "",
    Time: "",
    OptionE: "",
    QuestionType: "",
    Level: "",
  });

  const inputEvent = (event) => {
    const { name, value, type, checked } = event.target;

    setData((preVal) => {
      return {
        ...preVal,
        [name]: type === "checkbox" ? selectedCheckboxes : value,
      };
    });
    if (type === "checkbox") {
      // Update the selected checkboxes array
      setSelectedCheckboxes((prevCheckboxes) => {
        if (checked) {
          return [...prevCheckboxes, value];
        } else {
          return prevCheckboxes.filter((checkbox) => checkbox !== value);
        }
      });
    }
  };

  const axiousPublic = useAxiosPublic();

  const formSubmit = async (e) => {
    e.preventDefault();
    const questionbank_data = {
      Course: data.Course,
      Skills: data.Skills,
      Questions: data.Questions,
      OptionA: data.OptionA,
      OptionB: data.OptionB,
      OptionC: data.OptionC,
      OptionD: data.OptionD,
      OptionE: data.OptionE,
      Answer: data.Answer,
      Description: data.Description,
      Status: data.Status,
      Time: data.Time,
      QuestionType: data.QuestionType,
      Level: data.Level,
    };
    try {
      const res = await axiousPublic.post(`/questionbankcreater`, questionbank_data);
      console.log(res);
      // Log the successful response
      logInfo("Exam created successfully", {
        questionbank_data,
        response: res.data,
      });
      alert("Exam Created Successfully!!!");
      // Refresh the page after form submission
      window.location.reload();
    } catch (error) {
      console.error("Error submitting Exam Created:", error);
      // Handle error appropriately, e.g., show an error message to the user
      // Log the error
      logError("Error submitting Exam Created", error);
    }
  };

  const [questionType, setQuestionType] = useState(
    "",
    "Objective",
    "TrueFalse",
    "FillBlanks",
    "CheckBoxes",
    "Subjective"
  ); // Default to "Objective"

  const handleQuestionTypeChange = (type) => {
    setQuestionType(type);
    setIsRadioSelected(true); // Set the flag to true when a radio button is selected

    // Log the question type change
    logInfo("Question type changed", { newQuestionType: type });
  };

  return (
    <>
      <div className="grid grid-cols-6">
        <div className="m-3 max-md:w-full justify-self-center w-full bg-base-300 shadow-xl rounded-lg col-start-1 col-end-7 ">
          <form onSubmit={formSubmit}>
            <div className="col-start-1 col-end-7 w-full bg-base-100 shadow-xl">
              <label htmlFor="" className="text-3xl font-bold p-1">
                Add Question
              </label>
              <div className="card-body">
                <h2 className="card-title">Question Type</h2>
                <div className="md:flex gap-9">
                  <label className="cursor-pointer label justify-start gap-2 ">
                    <input
                      type="radio"
                      name="QuestionType"
                      value="Objective"
                      className="radio checked:bg-blue-500"
                      checked={questionType === "Objective"}
                      // onChange={() => handleQuestionTypeChange("Objective")}
                      onChange={(e) => {
                        handleQuestionTypeChange("Objective");
                        inputEvent(e);
                      }}
                    />
                    <span className="label-text">Objective</span>
                  </label>

                  <label className="cursor-pointer label justify-start gap-2  ">
                    <input
                      type="radio"
                      name="QuestionType"
                      value="TrueFalse"
                      className="radio checked:bg-blue-500"
                      checked={questionType === "TrueFalse"}
                      // onChange={() => handleQuestionTypeChange("TrueFalse")}
                      onChange={(e) => {
                        handleQuestionTypeChange("TrueFalse");
                        inputEvent(e);
                      }}
                    />
                    <span className="label-text">True False</span>
                  </label>

                  <label className="cursor-pointer label justify-start gap-2  ">
                    <input
                      type="radio"
                      name="QuestionType"
                      value="FillBlanks"
                      className="radio checked:bg-blue-500"
                      checked={questionType === "FillBlanks"}
                      onChange={(e) => {
                        handleQuestionTypeChange("FillBlanks");
                        inputEvent(e);
                      }}
                      // checked
                    />
                    <span className="label-text">Fill in the Blanks</span>
                  </label>

                  <label className="cursor-pointer label justify-start gap-2  ">
                    <input
                      type="radio"
                      name="QuestionType"
                      value="Subjective"
                      className="radio checked:bg-blue-500"
                      checked={questionType === "Subjective"}
                      // onChange={() => handleQuestionTypeChange("Subjective")}
                      onChange={(e) => {
                        handleQuestionTypeChange("Subjective");
                        inputEvent(e);
                      }}
                    />
                    <span className="label-text">Subjective</span>
                  </label>

                  <label className="cursor-pointer label justify-start gap-2  ">
                    <input
                      type="radio"
                      name="QuestionType"
                      value="CheckBoxes"
                      className="radio checked:bg-blue-500"
                      checked={questionType === "CheckBoxes"}
                      // onChange={() => handleQuestionTypeChange("CheckBoxes")}
                      onChange={(e) => {
                        handleQuestionTypeChange("CheckBoxes");
                        inputEvent(e);
                      }}
                    />
                    <span className="label-text">Check Boxes</span>
                  </label>
                </div>
              </div>
            </div>

            {/*  */}
            <div className="mt-3">
              <div className="text-2xl p-2 font-bold bg-primary text-center text-white rounded-t-xl">
                Question Table
              </div>

              <table className="table table-zebra">
                <tbody className="items-center">
                  {/* row 2 */}
                  <tr className="max-md:grid">
                    <td>
                      <span className="label-text font-bold text-lg ">
                        Course
                      </span>
                    </td>
                    <td className="max-md:w-[14rem]">
                      <select
                        // value={selectedCourse}
                        // onChange={handleCourseChange}
                        name="Course"
                        value={data.Course}
                        onChange={inputEvent}
                        className="select select-bordered text-black select-sm w-full max-w-md "
                      >
                        <option>Select Course</option>
                        <option value="Java Developer">Java Developer</option>
                        <option value="Node Js">Node Js</option>
                        <option value="React Js">React Js</option>
                        <option value="Angular Js">Angular Js</option>
                        <option value="Vue Js">Vue Js</option>
                        <option value="Php">Php</option>
                        {/* <option>Other</option> */}
                      </select>
                    </td>
                  </tr>

                  {/* row 4 */}
                  <tr className="max-md:grid">
                    <td>
                      <span className="label-text font-bold text-lg">
                        Skills
                      </span>
                    </td>
                    <td className="max-md:w-[15rem]">
                      <select
                        name="Skills"
                        value={data.Skills}
                        onChange={inputEvent}
                        className="select select-bordered text-black select-sm w-full max-w-md"
                      >
                        <option>Select skills</option>
                        <option value="html">html</option>
                        <option value="css">css</option>
                        <option value="spring">spring</option>
                        <option value="javascript">javascript</option>
                        <option value="Php">Php</option>
                      </select>
                    </td>
                  </tr>
                  {/* row 4 */}
                  <tr className="max-md:grid">
                    <td>
                      <span className="label-text font-bold text-lg">
                        Level
                      </span>
                    </td>
                    <td className="max-md:w-[15rem]">
                      <select
                        name="Level"
                        value={data.Level}
                        onChange={inputEvent}
                        className="select select-bordered text-black select-sm w-full max-w-md"
                      >
                        <option>Select Level</option>
                        <option value="0-1 yrs">0-1 yrs</option>
                        <option value="1-2 yrs">1-2 yrs</option>
                        <option value="1-3 yrs">1-3 yrs</option>
                        <option value="0-4 yrs">0-4 yrs</option>
                        <option value="1-4 yrs">1-4 yrs</option>
                      </select>
                    </td>
                  </tr>
                  {/* row 4 */}
                  <tr className="max-md:grid">
                    <td>
                      <span className="label-text font-bold text-lg">Time</span>
                    </td>
                    <td className="max-md:w-[15rem]">
                      <select
                        name="Time"
                        value={data.Time}
                        onChange={inputEvent}
                        className="select select-bordered text-black select-sm w-full max-w-md"
                      >
                        <option value="">Select Time</option>
                        <option value="01:00">01:00 min</option>
                        <option value="02:00">02:00 min</option>
                        <option value="03:00">03:00 min</option>
                        <option value="04:00">04:00 min</option>
                        <option value="05:00">05:00 min</option>
                        <option value="06:00">06:00 min</option>
                        <option value="07:00">07:00 min</option>
                        <option value="08:00">08:00 min</option>
                        <option value="09:00">09:00 min</option>
                        <option value="10:00">10:00 min</option>
                      </select>
                    </td>
                  </tr>

                  {/* ----- 1. Obective start card -----*/}
                  {questionType === "Objective" && (
                    <>
                      <tr className="max-md:grid">
                        <td>
                          <span className="label-text font-bold text-lg">
                            Questions
                          </span>
                        </td>
                        <td className="max-md:w-[15rem]">
                          <input
                            required
                            type="text"
                            name="Questions"
                            value={data.Questions}
                            onChange={inputEvent}
                            placeholder="Type here"
                            className="input input-bordered w-full max-w-md text-black input-lg"
                          />
                        </td>
                      </tr>
                      {/* row 9  */}
                      <tr className="max-md:grid">
                        <td>
                          <span className="label-text font-bold text-lg">
                            Option A
                          </span>
                        </td>
                        <td className="max-md:w-[15rem]">
                          <input
                            required
                            type="text"
                            name="OptionA"
                            value={data.OptionA}
                            onChange={inputEvent}
                            placeholder="Type here"
                            className="input input-bordered w-full max-w-md text-black input-sm"
                          />
                        </td>
                      </tr>
                      {/* row 10  */}
                      <tr className="max-md:grid">
                        <td>
                          <span className="label-text font-bold text-lg">
                            Option B
                          </span>
                        </td>
                        <td className="max-md:w-[15rem]">
                          <input
                            required
                            type="text"
                            name="OptionB"
                            value={data.OptionB}
                            onChange={inputEvent}
                            placeholder="Type here"
                            className="input input-bordered w-full max-w-md text-black input-sm"
                          />
                        </td>
                      </tr>
                      {/* row 11  */}
                      <tr className="max-md:grid">
                        <td>
                          <span className="label-text font-bold text-lg">
                            Option C
                          </span>
                        </td>
                        <td className="max-md:w-[15rem]">
                          <input
                            required
                            type="text"
                            name="OptionC"
                            value={data.OptionC}
                            onChange={inputEvent}
                            placeholder="Type here"
                            className="input input-bordered w-full max-w-md text-black input-sm"
                          />
                        </td>
                      </tr>
                      {/* row 12  */}
                      <tr className="max-md:grid">
                        <td>
                          <span className="label-text font-bold text-lg">
                            Option D
                          </span>
                        </td>
                        <td className="max-md:w-[15rem]">
                          <input
                            required
                            type="text"
                            name="OptionD"
                            value={data.OptionD}
                            onChange={inputEvent}
                            placeholder="Type here"
                            className="input input-bordered w-full max-w-md text-black input-sm"
                          />
                        </td>
                      </tr>
                      <tr className="max-md:grid">
                        <td>
                          <span className="label-text font-bold text-lg">
                            Answer
                          </span>
                        </td>
                        <td className="max-md:w-[15rem]">
                          {/* <select
                            name="Answer"
                            value={data.Answer}
                            onChange={inputEvent}
                            className="select select-bordered text-black select-sm w-full max-w-md"
                          >
                            <option value="">Select Answer</option>
                            <option value="A">A</option>
                            <option value="B">B</option>
                            <option value="C">C</option>
                            <option value="D">D</option>
                          </select> */}
                          <input
                            required
                            type="text"
                            name="Answer"
                            value={data.Answer}
                            onChange={inputEvent}
                            placeholder="Type here"
                            className="input input-bordered w-full max-w-md text-black input-sm"
                          />
                        </td>
                      </tr>
                    </>
                  )}
                  {/* ----- 1. Obective end card----- */}

                  {/* ----- 2. True/False start card -----*/}
                  {questionType === "TrueFalse" && (
                    <>
                      <tr className="max-md:grid">
                        <td>
                          <span className="label-text font-bold text-lg">
                            Questions
                          </span>
                        </td>
                        <td className="max-md:w-[15rem]">
                          <input
                            required
                            type="text"
                            name="Questions"
                            value={data.Questions}
                            onChange={inputEvent}
                            placeholder="Type here"
                            className="input input-bordered w-full max-w-md text-black input-lg"
                          />
                        </td>
                      </tr>
                      {/* row 9  */}
                      <tr className="max-md:grid">
                        <td>
                          <span className="label-text font-bold text-lg">
                            True
                          </span>
                        </td>
                        <td className="max-md:w-[15rem]">
                          <input
                            type="radio"
                            name="radio-btn"
                            value={data.OptionA}
                            onChange={inputEvent}
                            // placeholder="Type here"
                            className="radio radio-primary"
                          />
                        </td>
                      </tr>
                      {/* row 10  */}
                      <tr className="max-md:grid">
                        <td>
                          <span className="label-text font-bold text-lg">
                            False
                          </span>
                        </td>
                        <td className="max-md:w-[15rem]">
                          <input
                            type="radio"
                            name="radio-btn"
                            value={data.OptionB}
                            onChange={inputEvent}
                            // placeholder="Type here"
                            className="radio radio-primary"
                          />
                        </td>
                      </tr>

                      <tr className="max-md:grid">
                        <td>
                          <span className="label-text font-bold text-lg">
                            Answer
                          </span>
                        </td>
                        <td className="max-md:w-[15rem]">
                          {/* <select
                            name="Answer"
                            value={data.Answer}
                            onChange={inputEvent}
                            className="select select-bordered text-black select-sm w-full max-w-md"
                          >
                            <option value="">Select Answer</option>
                            // <option value="1">1</option>
                            // <option value="0">0</option>
                            <option value="A">A</option>
                            <option value="B">B</option>
                          </select> */}

                          <input
                            required
                            type="text"
                            name="Answer"
                            value={data.Answer}
                            onChange={inputEvent}
                            placeholder="Type here"
                            className="input input-bordered w-full max-w-md text-black input-sm"
                          />
                        </td>
                      </tr>
                    </>
                  )}
                  {/* ----- 2. True/False end card----- */}

                  {/* ----- 3. FillBlanks start card -----*/}
                  {questionType === "FillBlanks" && (
                    <>
                      <tr className="max-md:grid">
                        <td>
                          <span className="label-text font-bold text-lg">
                            Questions
                          </span>
                        </td>
                        <td className="max-md:w-[15rem]">
                          <input
                            required
                            type="text"
                            name="Questions"
                            value={data.Questions}
                            onChange={inputEvent}
                            placeholder="Type here"
                            className="input input-bordered w-full max-w-md text-black input-lg"
                          />
                        </td>
                      </tr>
                      {/* row 9  */}
                      <tr className="max-md:grid">
                        <td>
                          <span className="label-text font-bold text-lg">
                            Option A
                          </span>
                        </td>
                        <td className="max-md:w-[15rem]">
                          <input
                            required
                            type="text"
                            name="OptionA"
                            value={data.OptionA}
                            onChange={inputEvent}
                            placeholder="Type here"
                            className="input input-bordered w-full max-w-md text-black input-sm"
                          />
                        </td>
                      </tr>
                      {/* row 10  */}
                      <tr className="max-md:grid">
                        <td>
                          <span className="label-text font-bold text-lg">
                            Option B
                          </span>
                        </td>
                        <td className="max-md:w-[15rem]">
                          <input
                            required
                            type="text"
                            name="OptionB"
                            value={data.OptionB}
                            onChange={inputEvent}
                            placeholder="Type here"
                            className="input input-bordered w-full max-w-md text-black input-sm"
                          />
                        </td>
                      </tr>
                      {/* row 11  */}
                      <tr className="max-md:grid">
                        <td>
                          <span className="label-text font-bold text-lg">
                            Option C
                          </span>
                        </td>
                        <td className="max-md:w-[15rem]">
                          <input
                            required
                            type="text"
                            name="OptionC"
                            value={data.OptionC}
                            onChange={inputEvent}
                            placeholder="Type here"
                            className="input input-bordered w-full max-w-md text-black input-sm"
                          />
                        </td>
                      </tr>
                      {/* row 12  */}
                      <tr className="max-md:grid">
                        <td>
                          <span className="label-text font-bold text-lg">
                            Option D
                          </span>
                        </td>
                        <td className="max-md:w-[15rem]">
                          <input
                            required
                            type="text"
                            name="OptionD"
                            value={data.OptionD}
                            onChange={inputEvent}
                            placeholder="Type here"
                            className="input input-bordered w-full max-w-md text-black input-sm"
                          />
                        </td>
                      </tr>
                      <tr className="max-md:grid">
                        <td>
                          <span className="label-text font-bold text-lg">
                            Answer
                          </span>
                        </td>
                        <td className="max-md:w-[15rem]">
                          {/* <select
                            name="Answer"
                            value={data.Answer}
                            onChange={inputEvent}
                            className="select select-bordered text-black select-sm w-full max-w-md"
                          >
                            <option value="">Select Answer</option>
                            <option value="A">A</option>
                            <option value="B">B</option>
                            <option value="C">C</option>
                            <option value="D">D</option>
                          </select> */}
                          <input
                            required
                            type="text"
                            name="Answer"
                            value={data.Answer}
                            onChange={inputEvent}
                            placeholder="Type here"
                            className="input input-bordered w-full max-w-md text-black input-sm"
                          />
                        </td>
                      </tr>
                    </>
                  )}
                  {/* ----- 3. FillBlanks end card----- */}

                  {/* ----- 4. Subjective start card -----*/}
                  {questionType === "Subjective" && (
                    <>
                      <tr className="max-md:grid">
                        <td>
                          <span className="label-text font-bold text-lg">
                            Questions
                          </span>
                        </td>
                        <td className="max-md:w-[15rem]">
                          <input
                            required
                            type="text"
                            name="Questions"
                            value={data.Questions}
                            onChange={inputEvent}
                            placeholder="Type here"
                            className="input input-bordered w-full max-w-md text-black input-lg"
                          />
                        </td>
                      </tr>

                      <tr className="max-md:grid">
                        <td>
                          <span className="label-text font-bold text-lg">
                            Answer
                          </span>
                        </td>
                        <td className="max-md:w-[15rem]">
                          <textarea
                            required
                            name="Answer"
                            value={data.Answer}
                            onChange={inputEvent}
                            placeholder="Answer"
                            className="textarea textarea-bordered textarea-lg w-full max-w-md"
                          ></textarea>
                          {/* <input
                              required
                              type="text"
                              // name="Questions"
                              // value={data.Questions}
                              // onChange={inputEvent}
                              // placeholder="Type here"
                              className="input input-bordered w-full max-w-md text-black input-lg"
                            /> */}
                        </td>
                      </tr>
                    </>
                  )}
                  {/* ----- 4. Subjective end card----- */}

                  {/* ----- 5. check boxes start card -----*/}
                  {questionType === "CheckBoxes" && (
                    <>
                      <tr className="max-md:grid">
                        <td>
                          <span className="label-text font-bold text-lg">
                            Questions
                          </span>
                        </td>
                        <td className="max-md:w-[15rem]">
                          <input
                            required
                            type="text"
                            name="Questions"
                            value={data.Questions}
                            onChange={inputEvent}
                            placeholder="Type here"
                            className="input input-bordered w-full max-w-md text-black input-lg"
                          />
                        </td>
                      </tr>
                      {/* row 9  */}
                      <tr className="max-md:grid">
                        <td>
                          <span className="label-text font-bold text-lg">
                            Option A
                          </span>
                        </td>
                        <td className="flex item s-center gap-3 max-md:w-[15rem]">
                          <input
                            required
                            type="text"
                            name="OptionA"
                            value={data.OptionA}
                            onChange={inputEvent}
                            placeholder="Type here"
                            className="input input-bordered w-full max-w-md text-black input-sm"
                          />
                        </td>
                      </tr>
                      {/* row 10  */}
                      <tr className="max-md:grid">
                        <td>
                          <span className="label-text font-bold text-lg">
                            Option B
                          </span>
                        </td>
                        <td className="flex item s-center gap-3 max-md:w-[15rem]">
                          <input
                            required
                            type="text"
                            name="OptionB"
                            value={data.OptionB}
                            onChange={inputEvent}
                            placeholder="Type here"
                            className="input input-bordered w-full max-w-md text-black input-sm"
                          />
                        </td>
                      </tr>
                      {/* row 11  */}
                      <tr className="max-md:grid">
                        <td>
                          <span className="label-text font-bold text-lg">
                            Option C
                          </span>
                        </td>
                        <td className="flex item s-center gap-3 max-md:w-[15rem]">
                          <input
                            required
                            type="text"
                            name="OptionC"
                            value={data.OptionC}
                            onChange={inputEvent}
                            placeholder="Type here"
                            className="input input-bordered w-full max-w-md text-black input-sm"
                          />
                        </td>
                      </tr>
                      {/* row 12  */}
                      <tr className="max-md:grid">
                        <td>
                          <span className="label-text font-bold text-lg">
                            Option D
                          </span>
                        </td>
                        <td className="flex item s-center gap-3 max-md:w-[15rem]">
                          <input
                            required
                            type="text"
                            name="OptionD"
                            value={data.OptionD}
                            onChange={inputEvent}
                            placeholder="Type here"
                            className="input input-bordered w-full max-w-md text-black input-sm"
                          />
                        </td>
                      </tr>
                      {/* row 12  */}
                      <tr className="max-md:grid">
                        <td>
                          <span className="label-text font-bold text-lg">
                            Option E
                          </span>
                        </td>
                        <td className="flex item s-center gap-3 max-md:w-[15rem]">
                          <input
                            required
                            type="text"
                            name="OptionE"
                            value={data.OptionE}
                            onChange={inputEvent}
                            placeholder="Type here"
                            className="input input-bordered w-full max-w-md text-black input-sm"
                          />
                        </td>
                      </tr>

                      <tr className="max-md:grid">
                        <td>
                          <span className="label-text font-bold text-lg">
                            Answer
                          </span>
                        </td>
                        <td className="max-md:w-[15rem]">
                          <textarea
                            name="Answer"
                            value={data.Answer}
                            onChange={inputEvent}
                            placeholder="Example: A,B  B,C  C,D"
                            className="textarea textarea-bordered textarea-lg w-full max-w-md"
                          ></textarea>
                        </td>

                        
                      </tr>
                    </>
                  )}
                  {/* ----- 5. check boxes end card----- */}

                  {/* row 14  */}
                  <tr className="max-md:grid">
                    <td>
                      <span className="label-text font-bold text-lg">
                        Description
                      </span>
                    </td>
                    <td className="max-md:w-[15rem]">
                      <input
                        required
                        type="text"
                        name="Description"
                        value={data.Description}
                        onChange={inputEvent}
                        placeholder="Type here"
                        className="input input-bordered w-full max-w-md text-black input-sm"
                      />
                    </td>
                  </tr>
                  {/* row 15  */}
                  <tr className="max-md:grid">
                    <td>
                      <span className="label-text font-bold text-lg">
                        Status
                      </span>
                    </td>
                    <td className="max-md:w-[15rem]">
                      <select
                        name="Status"
                        value={data.Status}
                        onChange={inputEvent}
                        className="select select-bordered text-black select-sm w-full max-w-md"
                      >
                        <option value="">Select Status</option>
                        <option value="Active">Active</option>
                        <option value="Pending">Pending</option>
                        <option value="Suspend">Suspend</option>
                      </select>
                    </td>
                  </tr>
                  {/* row 16  */}
                  <tr className="max-md:grid">
                    <td>
                      <span className="label-text font-bold text-lg">Icon</span>
                    </td>
                    <td className="flex gap-9 text-xl">
                      <PiBookOpenTextFill />
                      <HiOutlineChatBubbleBottomCenterText />
                      <TiTick />
                      <MdOutlineReportProblem />
                    </td>
                  </tr>
                </tbody>
              </table>

              <div className="flex justify-center">
                <button
                  name="submit"
                  type="submit"
                  disabled={!isRadioSelected} // Disable if no radio button is selected
                  className="text-lg font-bold btn btn-primary"
                >
                  Submit
                </button>
              </div>
            </div>
          </form>
        </div>
      </div>
    </>
  );
};

export default QuestionBank;
