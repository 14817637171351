import React, { useState } from "react";
import Sidebar from "../Sidebar/Sidebar";

import { Outlet, Navigate } from "react-router-dom";
import useAxiosPublic from "../../Hooks/useAxiosPublic";
import { FaBars } from "react-icons/fa";
import { IoIosArrowForward, IoIosArrowBack } from "react-icons/io";


const Dashboard = () => {

  const [sidebarOpen, setSidebarOpen] = useState(false);
  const [submenuActive, setSubmenuActive] = useState(false);
  const [activeIndex, setActiveIndex] = useState(null);

  const toggleSidebar = () => {
    setSidebarOpen(!sidebarOpen);
  };

  const handleSubmenuClick = (index) => {
    setActiveIndex(index);
    setSubmenuActive(true);
  };

  const handleSubmenuTitleClick = () => {
    setSubmenuActive(false);
  };


  return (
    <>

      <div className="flex">
        {/* <div class="menu-content">
          <Sidebar />
        </div> */}
        <nav className={`fixed h-full w-[280px] z-40 -mt-9 transition-transform transform ${sidebarOpen ? '' : '-translate-x-full'}`}>
          <div className="flex justify-center">
            <div className="menu-content ">
              <ul className={`menu-items list-none transition-transform duration-500 ${submenuActive ? '-translate-x-1/2' : ''}`}>


                <li className="submenu-item py-4 px-4 cursor-pointer" onClick={() => handleSubmenuClick(0)}>

                </li>

                <Sidebar />
              </ul>

              <div className={`submenu absolute h-full w-full top-0 right-0 bg-gray-900 ${activeIndex === 0 ? 'block' : 'hidden'}`}>
                <div className="menu-title p-4 cursor-pointer" onClick={handleSubmenuTitleClick}>
                  <FaBars className="mr-2" />
                  <span>Back</span>
                </div>
                {/* Submenu content here */}
              </div>
            </div>
            <h1 className=" text-black text-5xl flex flex-col justify-center items-center w-2 ">
              {
                sidebarOpen ? <IoIosArrowBack id="sidebar-close" onClick={toggleSidebar} className="cursor-pointer mt-24 z-50 ml-4 rounded-r-full w-6 bg-primary  border-4 border-primary hover:border-r-[#9b0186] " />
                  : < IoIosArrowForward id="sidebar-close" onClick={toggleSidebar} className="cursor-pointer mt-24 z-50 ml-4 rounded-r-full w-6 bg-primary border-4 border-primary hover:border-r-[#9b0186]" />
              }
              <hr className="h-[105vh] -mt-28 w-2 bg-primary" />
            </h1>
          </div>
        </nav>
        {/* <nav className="fixed left-64 w-full bg-blue-500 text-white p-4 z-50">
          <FaBars id="sidebar-close" onClick={toggleSidebar} className="cursor-pointer" />
        </nav> */}

        <main className={` main h-screen mx-5 bg-gray-100 transition-all duration-500 ${sidebarOpen ? 'ml-[300px]' : 'ml-5'}`}>
          <Outlet />
        </main>
      </div>

      {/* <nav class="sidebar">
        <div class="menu-content">
          <Sidebar />
        </div>
      </nav>
      <nav class="navbar">
        <i class="fa-solid fa-bars" id="sidebar-close"></i>
      </nav>
      <main class="main">
        <Outlet />
      </main> */}
      {/* <div className="mr-4 grid grid-cols-5 mt-3">
        

        <div class="h-auto col-span-4">
          
        </div>
      </div> */}
    </>
  );
};

export default Dashboard;
