import React, { useCallback, useEffect, useState } from "react";

import { MdOutlineReportProblem } from "react-icons/md";
import { PiBookOpenTextFill } from "react-icons/pi";
import { HiOutlineChatBubbleBottomCenterText } from "react-icons/hi2";
import { TiTick } from "react-icons/ti";
import useAxiosPublic from "../../../Hooks/useAxiosPublic";


const ViewQuestionBank = () => {

  const axiousPublic = useAxiosPublic();

  // get data
  const [getquestions, setGetQuestions] = useState([]);

  const fetchData = useCallback(async () => {
    try {
      const { data } = await axiousPublic.get(`/getquestionbank`);
      setGetQuestions(data.result);
      // logInfo("fetch question data", data);
    } catch (error) {
      // logError("Error fetching question data", error);
    }
  }, [axiousPublic]);

  useEffect(() => {
    fetchData();
  }, [fetchData]);


  // fetch data by course
  const [selectedCourse, setSelectedCourse] = useState("");
  const [filteredData, setFilteredData] = useState([]);


  const changehandler = (e) => {
    setSelectedCourse(e.target.value);
  };

  const searchHandler = () => {
    filterData(selectedCourse);
  };

  function filterData(course) {
    if (!course.trim()) {
      // If no course is selected, show all data
      setFilteredData(getquestions);
    } else {
      // Filter data based on the selected course
      const filtered = getquestions.filter((item) => item.Course === course);
      setFilteredData(filtered);
    }
  }

  return (
    <>
      <div className="  ">
        <div className="bg-primary gap-7 rounded-t-lg flex flex-row p-5">
          <div className="w-2/4 ">
            <select
              name="Course"
              onChange={changehandler}
              value={selectedCourse}
              className="select select-bordered text-black select-sm max-w-md w-full"
            >
              <option disabled selected value="">
                Select Course
              </option>
              <option value="">All Course</option>
              <option value="Java Developer">Java Developer</option>
              <option value="Node Js">Node Js</option>
              <option value="React Js">React Js</option>
              <option value="Angular Js">Angular Js</option>
              <option value="Vue Js">Vue Js</option>
              <option value="Php">Php</option>
            </select>
          </div>

          <button
            type="button"
            onClick={searchHandler}
            // type="submit"
            className="text-lg font-bold btn btn-sm"
          >
            Search
          </button>
        </div>
      </div>

      <div></div>


      {/* card 2*/}
      <div className="card w-full h-auto bg-base-100 shadow-xl mt-3">
        <div className="card-body">
          {filteredData.length > 0 ? (
            filteredData.map((getquestion, index) => (
              <>
                <p className="text-black text-lg">
                  {index + 1}. {getquestion.Questions}
                </p>
                <p className="ml-3 text-black ">
                  1. {getquestion.OptionA} <br />
                  2. {getquestion.OptionB} <br />
                  3. {getquestion.OptionC} <br />
                  4. {getquestion.OptionD} <br />
                  5. {getquestion.OptionE} <br />
                </p>

                <div className="flex gap-5 ml-3 mt-3">
                  {/* <div className="tooltip" data-tip="Answer"> */}
                  <div className="tooltip" data-tip={getquestion.Answer}>
                    <PiBookOpenTextFill className="w-10 p-2 h-10 rounded-lg border-2 border-primary" />
                    {/* {getquestion.Answer} */}
                  </div>
                  <div className="tooltip" data-tip="Discussion">
                    <HiOutlineChatBubbleBottomCenterText className="w-10 p-2 h-10 rounded-lg border-2 border-primary" />
                  </div>
                  <div className="tooltip" data-tip="Workspace">
                    <TiTick className="w-10 p-2 h-10 rounded-lg border-2 border-primary" />
                  </div>
                  <div className="tooltip" data-tip="Report">
                    <MdOutlineReportProblem className="w-10 p-2 h-10 rounded-lg border-2 border-primary" />
                  </div>
                </div>
              </>

            ))
          ) : (
            <>
              {getquestions &&
                getquestions.map((getquestion, index) => (
                  <div>
                    <p className="text-black text-lg">
                      {index + 1}. {getquestion.Questions}
                    </p>
                    <p className="ml-3 text-black ">
                      1. {getquestion.OptionA} <br />
                      2. {getquestion.OptionB} <br />
                      3. {getquestion.OptionC} <br />
                      4. {getquestion.OptionD} <br />
                      5. {getquestion.OptionE} <br />
                    </p>
                    <div className="flex gap-5 ml-3 mt-3">
                      <div className="tooltip" data-tip={getquestion.Answer}>
                        <PiBookOpenTextFill className="w-10 p-2 h-10 rounded-lg border-2 border-primary" />
                      </div>
                      <div className="tooltip" data-tip="Discussion">
                        <HiOutlineChatBubbleBottomCenterText className="w-10 p-2 h-10 rounded-lg border-2 border-primary" />
                      </div>
                      <div className="tooltip" data-tip="Workspace">
                        <TiTick className="w-10 p-2 h-10 rounded-lg border-2 border-primary" />
                      </div>
                      <div className="tooltip" data-tip="Report">
                        <MdOutlineReportProblem className="w-10 p-2 h-10 rounded-lg border-2 border-primary" />
                      </div>
                    </div>
                  </div>
                ))}
            </>
          )}
        </div>
      </div>
    </>
  );
};

export default ViewQuestionBank;
