
import React, { useCallback, useEffect, useState } from "react";
import { BsArrowLeftRight } from "react-icons/bs";
import * as XLSX from "xlsx";
import { EXCEL_FILE_BASE64 } from "./excelfile";
import FileSaver from "file-saver";
import { RiDeleteBinLine } from "react-icons/ri";
import { FaRegEdit } from "react-icons/fa";
import { FiCheckSquare } from "react-icons/fi";
import EditQuestionBank from "./EditQuestionBank";
import ViewQuestions from "./ViewQuestions";
import { logError, logInfo } from "../../../Elasticsearchinstance";
import useAxiosPublic from "../../../Hooks/useAxiosPublic";

const ImportQuestion = () => {
  const axiousPublic = useAxiosPublic();

  // onchange states
  const [excelFile, setExcelFile] = useState(null);
  const [typeError, setTypeError] = useState(null);
  // submit state
  const [excelData, setExcelData] = useState(null);
  // onchange event
  const handleFile = (e) => {
    let fileTypes = [
      "application/vnd.ms-excel",
      "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
      "text/csv",
    ];
    let selectedFile = e.target.files[0];
    if (selectedFile) {
      if (selectedFile && fileTypes.includes(selectedFile.type)) {
        setTypeError(null);
        let reader = new FileReader();
        reader.readAsArrayBuffer(selectedFile);
        reader.onload = (e) => {
          setExcelFile(e.target.result);
        };
      } else {
        setTypeError("Please select only excel file types");
        setExcelFile(null);
      }
    } else {
      console.log("Please select your file");
    }
  };
  // submit event
  const handleFileSubmit = async (e) => {
    e.preventDefault();
    if (excelFile !== null) {
      const workbook = XLSX.read(excelFile, { type: "buffer" });
      const worksheetName = workbook.SheetNames[0];
      const worksheet = workbook.Sheets[worksheetName];
      const data = XLSX.utils.sheet_to_json(worksheet);
      setExcelData(data);
    }
  };

  // get data
  const [getquestions, setGetQuestions] = useState([]);

  const fetchData = useCallback(async () => {
    try {
      const { data } = await axiousPublic.get(`/getquestionbank`);
      setGetQuestions(data.result);
      logInfo("fetch question data", data);
    } catch (error) {
      logError("Error fetching question data", error);
    }
  }, [axiousPublic]);

  useEffect(() => {
    fetchData();
  }, [fetchData]);



  // submit data
  const handleSave = async () => {
    if (excelData !== null) {
      try {
        await Promise.all(
          excelData.map(async (individualData) => {
            try {
              const response = await axiousPublic.post(`/questionbankcreater`, individualData);
              console.log("Server response:", response.data);
              logInfo('Question data saved', individualData);
            } catch (error) {
              console.error("Error sending data:", error);
              logError('Error sending question data', error);
            }
          })
        );
        window.location.reload();
      } catch (error) {
        console.error("Error sending data:", error);
        logError('Error sending question data', error);
      }
    }
  };


  const downloadSampleFile = (url) => {
    let sliceSize = 1024;
    let byteCharacters = atob(EXCEL_FILE_BASE64);
    let bytesLength = byteCharacters?.length;
    // console.log(bytesLength)
    let slicesCount = Math.ceil(bytesLength / sliceSize);
    let byteArrays = new Array(slicesCount);
    for (let sliceIndex = 0; sliceIndex < slicesCount; ++sliceIndex) {
      let begin = sliceIndex * sliceSize;
      let end = Math.min(begin + sliceSize, bytesLength);
      let bytes = new Array(end - begin);
      for (var offset = begin, i = 0; offset < end; ++i, ++offset) {
        bytes[i] = byteCharacters[offset].charCodeAt(0);
      }
      byteArrays[sliceIndex] = new Uint8Array(bytes);
    }

    let blob = new Blob(byteArrays, { type: "application/vnd.ms-excel" });
    FileSaver.saveAs(new Blob([blob], {}), "sample-question.xlsx");
  };

  //   delete
  const deleteHandler = async (queid) => {
    if (!window.confirm("Are you sure you want to delete this Question?")) {
      return;
    }
    try {
      console.log("heloo queid", queid);
      const res = await axiousPublic.delete(`/deleteQuestionBank`, {
        data: { queid: queid },
      });
      console.log("heloo delete", res);
      logInfo('Question deleted', { queid });
      if (res.data.success) {
        alert("Question deleted successfully!");
        window.location.reload();
      } else {
        alert("Failed to delete Qusetion.");
        logError('Failed to delete question', { queid });
      }
    } catch (error) {
      console.error("Error deleting Question:", error);
      alert("Error deleting Question. Please try again.");
      logError('Error deleting question', error);
    }
  };

  const [editModal, setEditModal] = useState(false);
  const [queid, setqueId] = useState("");
  const [Course, setCourse] = useState("");
  const [Skills, setSkills] = useState("");
  const [Questions, setQuestions] = useState("");
  const [Level, setLevel] = useState("");
  const [Time, setTime] = useState("");
  const [OptionA, setOptionA] = useState("");
  const [OptionB, setOptionB] = useState("");
  const [OptionC, setOptionC] = useState("");
  const [OptionD, setOptionD] = useState("");
  const [OptionE, setOptionE] = useState("");
  const [Answer, setAnswer] = useState("");
  const [Description, setDescription] = useState("");
  const [QuestionType, setQuestionType] = useState("");
  const [Status, setStatus] = useState("");
  const updateHandler = (
    queid,
    Course,
    Skills,
    Level,
    Time,
    Questions,
    OptionA,
    OptionB,
    OptionC,
    OptionD,
    OptionE,
    Answer,
    Description,
    Status,
    QuestionType
  ) => {
    setqueId(queid);
    setEditModal(!editModal);
    setCourse(Course);
    setSkills(Skills);
    setLevel(Level);
    setTime(Time);
    setQuestions(Questions);
    setOptionA(OptionA);
    setOptionB(OptionB);
    setOptionC(OptionC);
    setOptionD(OptionD);
    setOptionE(OptionE);
    setAnswer(Answer);
    setDescription(Description);
    setStatus(Status);
    setQuestionType(QuestionType);
  };

  const truncateQuestion = (question, maxLength) => {
    if (question?.length > maxLength) {
      return question.substring(0, maxLength) + "...";
    }
    return question;
  };

  // view data
  const [viewModal, setViewModal] = useState(false);
  const [vqueid, setVqueId] = useState("");
  const [vCourse, setVCourse] = useState("");
  const [vSkills, setVSkills] = useState("");
  const [vQuestions, setVQuestions] = useState("");
  const [vLevel, setVLevel] = useState("");
  const [vTime, setVTime] = useState("");
  const [vOptionA, setVOptionA] = useState("");
  const [vOptionB, setVOptionB] = useState("");
  const [vOptionC, setVOptionC] = useState("");
  const [vOptionD, setVOptionD] = useState("");
  const [vOptionE, setVOptionE] = useState("");
  const [vAnswer, setVAnswer] = useState("");
  const [vDescription, setVDescription] = useState("");
  const [vQuestionType, setVQuestionType] = useState("");
  const [vStatus, setVStatus] = useState("");
  const viewHandler = (
    queid,
    Course,
    Skills,
    Level,
    Time,
    Questions,
    OptionA,
    OptionB,
    OptionC,
    OptionD,
    OptionE,
    Answer,
    Description,
    Status,
    QuestionType
  ) => {
    setVqueId(queid);
    setViewModal(!viewModal);
    setVCourse(Course);
    setVSkills(Skills);
    setVLevel(Level);
    setVTime(Time);
    setVQuestions(Questions);
    setVOptionA(OptionA);
    setVOptionB(OptionB);
    setVOptionC(OptionC);
    setVOptionD(OptionD);
    setVOptionE(OptionE);
    setVAnswer(Answer);
    setVDescription(Description);
    setVStatus(Status);
    setVQuestionType(QuestionType);
  };

  const [selectedCourse, setSelectedCourse] = useState("");
  const [filteredData, setFilteredData] = useState([]);
  console.log(filteredData)

  function changehandler(e) {
    const selectedCourse = e.target.value;
    setSelectedCourse(selectedCourse);
    filterData(selectedCourse);
  }
  function filterData(course) {
    if (!course.trim()) {
      // If no course is selected, show all data
      setFilteredData(getquestions);
    } else {
      // Filter data based on the selected course
      const filtered = getquestions.filter((item) => item.Course === course);
      setFilteredData(filtered);
    }
  }

  return (
    <>
      {/* <div className="flex gap-5"> */}
      <div className="grid md:grid-cols-4 gap-5">
        <div className="bg-primary text-white join font-medium text-base p-4 ">
          <BsArrowLeftRight className="mt-[5px] mr-2" />
          <button
            onClick={() => document.getElementById("my_modal_13").showModal()}
          >
            Import Export Questions
          </button>
        </div>
        <div className="bg-primary text-white join font-medium text-base p-4 ">
          {/* <button onClick={()=>{downloadSampleFile(XLSX_FILE_URL)}}> */}
          <button onClick={downloadSampleFile}>Download Sample Data</button>
        </div>

        <div className="">
          <select
            name="Course"
            // onChange={handleCourseSelect}
            onChange={changehandler}
            // value={a.name}
            value={selectedCourse}
            // onClick={(e) => fetchdata(e)}
            // value={data.Skills}
            // onChange={inputEvent}
            // className="select select-bordered text-black select-sm w-full max-w-md"
            className="select select-bordered select-lg w-full max-w-xs"
          >
            <option>Select Course</option>
            <option value="Java Developer">Java Developer</option>
            <option value="Node Js">Node Js</option>
            <option value="React Js">React Js</option>
            <option value="Angular Js">Angular Js</option>
            <option value="Vue Js">Vue Js</option>
            <option value="Php">Php</option>
          </select>
        </div>

      </div>

      <dialog id="my_modal_13" className="modal">
        <div className="modal-box w-11/12 max-w-5xl">
          <h3 className="font-bold text-black text-2xl">
            Import Export Questions
          </h3>

          <form onSubmit={handleFileSubmit}>
            <label className=" join mt-7 flex gap-2">
              <input
                type="file"
                className="file-input file-input-bordered w-full max-w-xs text-black"
                required
                onChange={handleFile}
              />
              <button type="submit" className="btn btn-success btn-md">
                UPLOAD
              </button>
              <button
                type="submit"
                className="btn btn-primary btn-md"
                onClick={handleSave}
              >
                SAVE
              </button>
            </label>
            {typeError && (
              <div className="alert alert-danger" role="alert">
                {typeError}
              </div>
            )}
          </form>
          <div className="m-3 shadow-xl rounded-lg">
            {excelData ? (
              <div className="overflow-x-auto">
                <table className="table border-2">
                  <thead className="text-black">
                    <tr className="border-r-2 border-t-2 border-l-2 border-slate-400">
                      {Object.keys(excelData[0]).map((key) => (
                        <th className="border-r-2 border-slate-400" key={key}>
                          {key}
                        </th>
                      ))}
                    </tr>
                  </thead>

                  <tbody className="text-black">
                    {excelData.map((individualExcelData, index) => (
                      <tr
                        className="border-r-2 border-b-2 border-l-2 border-slate-400"
                        key={index}
                      >
                        {Object.keys(individualExcelData).map((key) => (
                          <td className="border-r-2 border-slate-400" key={key}>
                            {individualExcelData[key]}
                          </td>
                        ))}
                      </tr>
                    ))}
                  </tbody>
                </table>
              </div>
            ) : (
              <div className="text-black">No File is uploaded yet!</div>
            )}
          </div>

          <div className="modal-action">
            <form method="dialog">
              <button className="btn btn-sm btn-circle absolute right-2 top-2">
                ✕
              </button>
            </form>
          </div>
        </div>
      </dialog>

      {/* 2nd div */}
      {/* <div className="grid grid-cols-6"> */}
      <div className="">
        <div className="col-start-1 col-end-7 ml-3 mt-5 text-lg font-bold">
          <h1>Questions Lists:</h1>
        </div>
        {/* <div className="m-3 bg-base-300 shadow-xl rounded-lg "> */}
        <div className="overflow-x-auto ">
          <table className="table  table-zebra border-2 border-slate-400">
            <thead>
              <tr className="bg-slate-300 border-slate-400">
                <th className="font-extrabold border-r-2 border-slate-400">
                  Course
                </th>
                <th className="font-extrabold border-r-2 border-slate-400">
                  Skills
                </th>
                <th className="font-extrabold border-r-2 border-slate-400">
                  Questions
                </th>
                <th className="font-extrabold border-r-2 border-slate-400">
                  Option A
                </th>
                <th className="font-extrabold border-r-2 border-slate-400">
                  Option B
                </th>
                <th className="font-extrabold border-r-2 border-slate-400">
                  Option C
                </th>
                <th className="font-extrabold border-r-2 border-slate-400">
                  Option D
                </th>
                <th className="font-extrabold border-r-2 border-slate-400">
                  Option E
                </th>
                <th className="font-extrabold border-r-2 border-slate-400">
                  Answer
                </th>
                <th className="font-extrabold border-r-2 border-slate-400">
                  Description
                </th>
                <th className="font-extrabold border-r-2 border-slate-400">
                  Time
                </th>
                <th className="font-extrabold border-r-2 border-slate-400">
                  Type
                </th>
                <th className="font-extrabold border-r-2 border-slate-400">
                  Level
                </th>
                <th className="font-extrabold border-r-2 border-slate-400">
                  Status
                </th>

                <th className="font-extrabold border-r-2 border-slate-400">
                  Action
                </th>
              </tr>
            </thead>
            <tbody>
              {/* {getquestions &&
                getquestions.map((getquestion, index) => ( */}

              {filteredData?.length > 0 ? (
                filteredData.map((getquestion, index) => (
                  <tr className="bg-slate-300 border-slate-400">
                    <td className="border-r-2 border-slate-400">
                      {getquestion.Course}
                    </td>
                    <td className="border-r-2 border-slate-400">
                      {getquestion.Skills}
                    </td>
                    {/* <td className="border-r-2 border-slate-400">
                      {getquestion.Questions}
                    </td> */}
                    <td className="border-r-2 border-slate-400">
                      {truncateQuestion(getquestion.Questions, 10)}
                    </td>
                    <td className="border-r-2 border-slate-400">
                      {/* {getquestion.OptionA} */}
                      {truncateQuestion(getquestion.OptionA, 10)}
                    </td>
                    <td className="border-r-2 border-slate-400">
                      {truncateQuestion(getquestion.OptionB, 10)}
                    </td>
                    <td className="border-r-2 border-slate-400">
                      {truncateQuestion(getquestion.OptionC, 10)}
                    </td>
                    <td className="border-r-2 border-slate-400">
                      {truncateQuestion(getquestion.OptionD, 10)}
                    </td>
                    <td className="border-r-2 border-slate-400">
                      {truncateQuestion(getquestion.OptionE, 10)}
                    </td>
                    <td className="border-r-2 border-slate-400">
                      {truncateQuestion(getquestion.Answer, 10)}
                    </td>
                    <td className="border-r-2 border-slate-400">
                      {truncateQuestion(getquestion.Description, 10)}
                    </td>
                    <td className="border-r-2 border-slate-400">
                      {getquestion.Time}
                    </td>
                    <td className="border-r-2 border-slate-400">
                      {getquestion.QuestionType}
                    </td>
                    <td className="border-r-2 border-slate-400">
                      {getquestion.Level}
                    </td>
                    <td className="border-r-2 border-slate-400">
                      {getquestion.Status}
                    </td>
                    <td className="flex gap-2">
                      {/* view */}
                      <div className="tooltip" data-tip="View">
                        <label
                          onClick={() =>
                            viewHandler(
                              getquestion.queid,
                              getquestion.Course,
                              getquestion.Skills,
                              getquestion.Level,
                              getquestion.Time,
                              getquestion.Questions,
                              getquestion.OptionA,
                              getquestion.OptionB,
                              getquestion.OptionC,
                              getquestion.OptionD,
                              getquestion.OptionE,
                              getquestion.Answer,
                              getquestion.Description,
                              getquestion.Status,
                              getquestion.QuestionType
                            )
                          }
                          htmlFor="my_modal_10"
                          className="btn btn-xs btn-info text-white"
                        >
                          {/* view */}
                          <FiCheckSquare />
                        </label>
                      </div>

                      {/* edit */}
                      <div className="tooltip" data-tip="Edit">
                        <label
                          onClick={() =>
                            updateHandler(
                              getquestion.queid,
                              getquestion.Course,
                              getquestion.Skills,
                              getquestion.Level,
                              getquestion.Time,
                              getquestion.Questions,
                              getquestion.OptionA,
                              getquestion.OptionB,
                              getquestion.OptionC,
                              getquestion.OptionD,
                              getquestion.OptionE,
                              getquestion.Answer,
                              getquestion.Description,
                              getquestion.Status,
                              getquestion.QuestionType
                            )
                          }
                          htmlFor="my_modal_9"
                          className="btn btn-xs btn-warning text-white"
                        >
                          {/* Edit */}
                          <FaRegEdit />
                        </label>
                      </div>

                      {/* delete */}
                      <div className="tooltip" data-tip="Delete">
                        <button
                          onClick={() => deleteHandler(getquestion.queid)}
                          className="btn btn-xs bg-red-500 hover:bg-red-500 font-bold text-white"
                        >
                          <RiDeleteBinLine />
                        </button>
                      </div>
                    </td>
                  </tr>
                  // ))}
                ))
              ) : (
                <>
                  {/* <td colSpan="15" className="text-black text-center">
                    No data found.
                  </td> */}
                  {getquestions &&
                    getquestions.map((getquestion, index) => (
                      <tr className="bg-slate-300 border-slate-400">
                        <td className="border-r-2 border-slate-400">
                          {getquestion.Course}
                        </td>
                        <td className="border-r-2 border-slate-400">
                          {getquestion.Skills}
                        </td>
                        {/* <td className="border-r-2 border-slate-400">
                      {getquestion.Questions}
                    </td> */}
                        <td className="border-r-2 border-slate-400">
                          {truncateQuestion(getquestion.Questions, 20)}
                        </td>
                        <td className="border-r-2 border-slate-400">
                          {/* {getquestion.OptionA} */}
                          {truncateQuestion(getquestion.OptionA, 15)}
                        </td>
                        <td className="border-r-2 border-slate-400">
                          {truncateQuestion(getquestion.OptionB, 10)}
                        </td>
                        <td className="border-r-2 border-slate-400">
                          {truncateQuestion(getquestion.OptionC, 10)}
                        </td>
                        <td className="border-r-2 border-slate-400">
                          {truncateQuestion(getquestion.OptionD, 10)}
                        </td>
                        <td className="border-r-2 border-slate-400">
                          {truncateQuestion(getquestion.OptionE, 10)}
                        </td>
                        <td className="border-r-2 border-slate-400">
                          {truncateQuestion(getquestion.Answer, 10)}
                        </td>
                        <td className="border-r-2 border-slate-400">
                          {truncateQuestion(getquestion.Description, 10)}
                        </td>
                        <td className="border-r-2 border-slate-400">
                          {getquestion.Time}
                        </td>
                        <td className="border-r-2 border-slate-400">
                          {getquestion.QuestionType}
                        </td>
                        <td className="border-r-2 border-slate-400">
                          {getquestion.Level}
                        </td>
                        <td className="border-r-2 border-slate-400">
                          {getquestion.Status}
                        </td>
                        <td className="flex gap-2">
                          {/* view */}
                          <div className="tooltip" data-tip="View">
                            <label
                              onClick={() =>
                                viewHandler(
                                  getquestion.queid,
                                  getquestion.Course,
                                  getquestion.Skills,
                                  getquestion.Level,
                                  getquestion.Time,
                                  getquestion.Questions,
                                  getquestion.OptionA,
                                  getquestion.OptionB,
                                  getquestion.OptionC,
                                  getquestion.OptionD,
                                  getquestion.OptionE,
                                  getquestion.Answer,
                                  getquestion.Description,
                                  getquestion.Status,
                                  getquestion.QuestionType
                                )
                              }
                              htmlFor="my_modal_10"
                              className="btn btn-xs btn-info text-white"
                            >
                              {/* view */}
                              <FiCheckSquare />
                            </label>
                          </div>

                          {/* edit */}
                          <div className="tooltip" data-tip="Edit">
                            <label
                              onClick={() =>
                                updateHandler(
                                  getquestion.queid,
                                  getquestion.Course,
                                  getquestion.Skills,
                                  getquestion.Level,
                                  getquestion.Time,
                                  getquestion.Questions,
                                  getquestion.OptionA,
                                  getquestion.OptionB,
                                  getquestion.OptionC,
                                  getquestion.OptionD,
                                  getquestion.OptionE,
                                  getquestion.Answer,
                                  getquestion.Description,
                                  getquestion.Status,
                                  getquestion.QuestionType
                                )
                              }
                              htmlFor="my_modal_9"
                              className="btn btn-xs btn-warning text-white"
                            >
                              {/* Edit */}
                              <FaRegEdit />
                            </label>
                          </div>

                          {/* delete */}
                          <div className="tooltip" data-tip="Delete">
                            <button
                              onClick={() => deleteHandler(getquestion.queid)}
                              className="btn btn-xs bg-red-500 hover:bg-red-500 font-bold text-white"
                            >
                              <RiDeleteBinLine />
                            </button>
                          </div>
                        </td>
                      </tr>
                    ))}
                </>
              )}
            </tbody>
          </table>
        </div>
        {/* </div> */}
      </div>

      {editModal && (
        <EditQuestionBank
          setEditModal={setEditModal}
          editModal={editModal}
          queid={queid}
          // title={title}
          // content={content}
          Course={Course}
          Skills={Skills}
          Level={Level}
          Time={Time}
          Questions={Questions}
          OptionA={OptionA}
          OptionB={OptionB}
          OptionC={OptionC}
          OptionD={OptionD}
          OptionE={OptionE}
          Answer={Answer}
          Description={Description}
          Status={Status}
          QuestionType={QuestionType}
        />
      )}

      {viewModal && (
        <ViewQuestions
          setViewModal={setViewModal}
          viewModal={viewModal}
          // id={vid}
          // title={vtitle}
          // content={vcontent}
          queid={vqueid}
          Course={vCourse}
          Skills={vSkills}
          Level={vLevel}
          Time={vTime}
          Questions={vQuestions}
          OptionA={vOptionA}
          OptionB={vOptionB}
          OptionC={vOptionC}
          OptionD={vOptionD}
          OptionE={vOptionE}
          Answer={vAnswer}
          Description={vDescription}
          Status={vStatus}
          QuestionType={vQuestionType}
        />
      )}
    </>
  );
};

export default ImportQuestion;
