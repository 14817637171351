import React from 'react'
import { Link } from "react-router-dom";

const BottomFooter = () => {
  return (
    <>
      <div className="bg-primary h-auto">
        <div className="text-center p-16 max-md:p-7 ">
          <div className="text-xl  font-medium text-white mx-auto md:w-[50rem]">
            <h1 className="">
              Ready to take your exams to the next level? Sign up for Hire Exam
              Demo today and experience the highest level of exam security and
              efficiency.
            </h1>
          </div>

          <Link to="/ContactUs">
            <button className="btn mt-10 text-lg bg-white btn-md m-1 text-black hover:text-white hover:bg-primary">
              Contact Sales
            </button>
          </Link>
        </div>
      </div>
    </>
  );
}

export default BottomFooter